import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { getCollection } from "../../../../utils/api/auth";

const FullContainer = styled.div`
  width: 90%;
  min-weight: 330px;
  max-width: 1080px;
  border-radius: 32px;
  background-color: ${palette.white};
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  margin-bottom: 30px;
  margin-top: 38px;
  padding: 40px 3%;
`;

const CategoryContainer = styled.div``;

const CategoryTitle = styled.div`
  ${Typography.Heading2}
  color: ${palette.light_black};
  margin-left: 15px;
  margin-bottom: 30px;
  margin-top: 0px;
`;

const NftImageBox = styled.div`
  width: 140px;
  height: 140px;
  text-align: center;
  line-height: 140px;
  box-shadow: 0px 2px 10px #57599240;
  border-radius: 10px;
  overflow: hidden;
  margin: 9px;
  //   filter: drop-shadow(0px 2px 10px #57599240);
`;

const NftImage = styled.img`
  max-width: 140px;
  max-height: 140px;
  margin: auto;
  vertical-align: middle;
`;

const BadgeContainer = styled.div`
  display: grid;
  // flex-wrap: wrap;
  // justify-content: flex-start;
  // align-content: space-between;
  grid-template-columns: repeat(auto-fill, 140px);
  gap: 20px;
  position: relative;
  justify-content: center;
  margin: auto;
  margin-bottom: 40px;
`;

const ButtonButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const CreateNFTButton = styled.button`
  width: 130px;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  border: hidden;
`;

const nftLimit = 6;
const poapLimit = 20;

const MyCosmosNft = () => {
  const [nftData, setNftData] = useState([
    {
      image: "",
      image_url: "",
    },
  ]);

  useEffect(() => {
    (async () => {
      const userId = localStorage.getItem("userID");
      if (userId) {
        const getMypageResult = await getCollection(userId).then((data) => {
          console.log(data);
          setNftData(data.nftList.keplr.slice(0, nftLimit));
        });
      }
    })();
  }, []);

  return (
    <FullContainer>
      {/* <ContainerHeader>
        <HeaderTitle>My Cosmos NFTs</HeaderTitle>
      </ContainerHeader> */}
      <CategoryContainer>
        <CategoryTitle>My Cosmos NFTs</CategoryTitle>
        <BadgeContainer>
          {nftData.map((val, idx) => (
            <NftImageBox>
              <NftImage src={val.image ? val.image : val.image_url} />
            </NftImageBox>
          ))}
        </BadgeContainer>
      </CategoryContainer>
      <ButtonButtonContainer>
        <CreateNFTButton onClick={() => alert("Service is being prepared.")}>
          Create NFT
        </CreateNFTButton>
      </ButtonButtonContainer>
    </FullContainer>
  );
};

export default MyCosmosNft;
