import React, { useState, useReducer, useEffect } from "react";
import { createBrowserHistory } from "history";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";

import { useWeb3React } from "../hooks";
import { brokenTokens } from "../constants";
import { amountFormatter, calculateGasMargin, isAddress } from "../utils";

import { useTokenDetails, INITIAL_TOKENS_CONTEXT } from "../contexts/Tokens";
import { useAddressBalance, useExchangeReserves } from "../contexts/Balances";
import { useAddressAllowance } from "../contexts/Allowances";
import CurrencyInputPanel from "./CurrencyInputPanel";

const INPUT = 0;
const OUTPUT = 1;

const ETH_TO_TOKEN = 0;
const TOKEN_TO_ETH = 1;
const TOKEN_TO_TOKEN = 2;

// denominated in bips
const ALLOWED_SLIPPAGE_DEFAULT = 50;
const TOKEN_ALLOWED_SLIPPAGE_DEFAULT = 50;

function calculateSlippageBounds(
  value,
  token = false,
  tokenAllowedSlippage,
  allowedSlippage
) {
  if (value) {
    const offset = value
      .mul(token ? tokenAllowedSlippage : allowedSlippage)
      .div(ethers.utils.bigNumberify(10000));
    const minimum = value.sub(offset);
    const maximum = value.add(offset);
    return {
      minimum: minimum.lt(ethers.constants.Zero)
        ? ethers.constants.Zero
        : minimum,
      maximum: maximum.gt(ethers.constants.MaxUint256)
        ? ethers.constants.MaxUint256
        : maximum,
    };
  } else {
    return {};
  }
}

function getSwapType(inputCurrency, outputCurrency) {
  if (!inputCurrency || !outputCurrency) {
    return null;
  } else if (inputCurrency === "ETH") {
    return ETH_TO_TOKEN;
  } else if (outputCurrency === "ETH") {
    return TOKEN_TO_ETH;
  } else {
    return TOKEN_TO_TOKEN;
  }
}

// this mocks the getInputPrice function, and calculates the required output
function calculateEtherTokenOutputFromInput(
  inputAmount,
  inputReserve,
  outputReserve
) {
  const inputAmountWithFee = inputAmount.mul(ethers.utils.bigNumberify(997));
  const numerator = inputAmountWithFee.mul(outputReserve);
  const denominator = inputReserve
    .mul(ethers.utils.bigNumberify(1000))
    .add(inputAmountWithFee);
  return numerator.div(denominator);
}

// this mocks the getOutputPrice function, and calculates the required input
function calculateEtherTokenInputFromOutput(
  outputAmount,
  inputReserve,
  outputReserve
) {
  const numerator = inputReserve
    .mul(outputAmount)
    .mul(ethers.utils.bigNumberify(1000));
  const denominator = outputReserve
    .sub(outputAmount)
    .mul(ethers.utils.bigNumberify(997));
  return numerator.div(denominator).add(ethers.constants.One);
}

function getInitialSwapState(state) {
  return {
    independentValue:
      state.exactFieldURL && state.exactAmountURL ? state.exactAmountURL : "", // this is a user input
    dependentValue: "", // this is a calculated number
    independentField: state.exactFieldURL === "output" ? OUTPUT : INPUT,
    inputCurrency: state.inputCurrencyURL ? state.inputCurrencyURL : "ETH",
    outputCurrency: state.outputCurrencyURL
      ? state.outputCurrencyURL === "ETH"
        ? state.inputCurrencyURL && state.inputCurrencyURL !== "ETH"
          ? "ETH"
          : ""
        : state.outputCurrencyURL
      : state.initialCurrency
      ? state.initialCurrency
      : "",
  };
}

function swapStateReducer(state, action) {
  switch (action.type) {
    case "FLIP_INDEPENDENT": {
      const { independentField, inputCurrency, outputCurrency } = state;
      return {
        ...state,
        dependentValue: "",
        independentField: independentField === INPUT ? OUTPUT : INPUT,
        inputCurrency: outputCurrency,
        outputCurrency: inputCurrency,
      };
    }
    case "SELECT_CURRENCY": {
      const { inputCurrency, outputCurrency } = state;
      const { field, currency } = action.payload;

      const newInputCurrency = field === INPUT ? currency : inputCurrency;
      const newOutputCurrency = field === OUTPUT ? currency : outputCurrency;

      if (newInputCurrency === newOutputCurrency) {
        return {
          ...state,
          inputCurrency: field === INPUT ? currency : "",
          outputCurrency: field === OUTPUT ? currency : "",
        };
      } else {
        return {
          ...state,
          inputCurrency: newInputCurrency,
          outputCurrency: newOutputCurrency,
        };
      }
    }
    case "UPDATE_INDEPENDENT": {
      const { field, value } = action.payload;
      const { dependentValue, independentValue } = state;
      return {
        ...state,
        independentValue: value,
        dependentValue: value === independentValue ? dependentValue : "",
        independentField: field,
      };
    }
    case "UPDATE_DEPENDENT": {
      return {
        ...state,
        dependentValue: action.payload,
      };
    }
    default: {
      return getInitialSwapState();
    }
  }
}

function getExchangeRate(
  inputValue,
  inputDecimals,
  outputValue,
  outputDecimals,
  invert = false
) {
  try {
    if (
      inputValue &&
      (inputDecimals || inputDecimals === 0) &&
      outputValue &&
      (outputDecimals || outputDecimals === 0)
    ) {
      const factor = ethers.utils
        .bigNumberify(10)
        .pow(ethers.utils.bigNumberify(18));

      if (invert) {
        return inputValue
          .mul(factor)
          .mul(
            ethers.utils
              .bigNumberify(10)
              .pow(ethers.utils.bigNumberify(outputDecimals))
          )
          .div(
            ethers.utils
              .bigNumberify(10)
              .pow(ethers.utils.bigNumberify(inputDecimals))
          )
          .div(outputValue);
      } else {
        return outputValue
          .mul(factor)
          .mul(
            ethers.utils
              .bigNumberify(10)
              .pow(ethers.utils.bigNumberify(inputDecimals))
          )
          .div(
            ethers.utils
              .bigNumberify(10)
              .pow(ethers.utils.bigNumberify(outputDecimals))
          )
          .div(inputValue);
      }
    }
  } catch {}
}

export default function LoadCurrencyInputPanel({ initialCurrency, params }) {
  const { t } = useTranslation();
  const { account, chainId, error } = useWeb3React();

  const urlAddedTokens = {};
  if (params.inputCurrency) {
    urlAddedTokens[params.inputCurrency] = true;
  }
  if (params.outputCurrency) {
    urlAddedTokens[params.outputCurrency] = true;
  }
  if (isAddress(initialCurrency)) {
    urlAddedTokens[initialCurrency] = true;
  }

  // check if URL specifies valid slippage, if so use as default
  const initialSlippage = (token = false) => {
    let slippage = Number.parseInt(params.slippage);
    if (!isNaN(slippage) && (slippage === 0 || slippage >= 1)) {
      return slippage; // round to match custom input availability
    }
    // check for token <-> token slippage option
    return token ? TOKEN_ALLOWED_SLIPPAGE_DEFAULT : ALLOWED_SLIPPAGE_DEFAULT;
  };

  const [brokenTokenWarning, setBrokenTokenWarning] = useState();
  const [isOpen, setIsOpen] = useState(true);

  const [rawSlippage, setRawSlippage] = useState(() => initialSlippage());
  const [rawTokenSlippage, setRawTokenSlippage] = useState(() =>
    initialSlippage(true)
  );

  const allowedSlippageBig = ethers.utils.bigNumberify(rawSlippage);
  const tokenAllowedSlippageBig = ethers.utils.bigNumberify(rawTokenSlippage);

  // core swap state
  const [swapState, dispatchSwapState] = useReducer(
    swapStateReducer,
    {
      initialCurrency: initialCurrency,
      inputCurrencyURL: params.inputCurrency,
      outputCurrencyURL: params.outputCurrency,
      exactFieldURL: params.exactField,
      exactAmountURL: params.exactAmount,
    },
    getInitialSwapState
  );

  // console.log(swapState);

  const {
    independentValue,
    dependentValue,
    independentField,
    inputCurrency,
    outputCurrency,
  } = swapState;

  useEffect(() => {
    setBrokenTokenWarning(false);
    for (let i = 0; i < brokenTokens.length; i++) {
      if (
        brokenTokens[i].toLowerCase() === outputCurrency.toLowerCase() ||
        brokenTokens[i].toLowerCase() === inputCurrency.toLowerCase()
      ) {
        setBrokenTokenWarning(true);
      }
    }
  }, [outputCurrency, inputCurrency]);
  // get swap type from the currency types
  const swapType = getSwapType(inputCurrency, outputCurrency);

  // get decimals and exchange address for each of the currency types
  // console.log(inputCurrency);
  const {
    symbol: inputSymbol,
    decimals: inputDecimals,
    exchangeAddress: inputExchangeAddress,
  } = useTokenDetails(inputCurrency);
  const {
    symbol: outputSymbol,
    decimals: outputDecimals,
    exchangeAddress: outputExchangeAddress,
  } = useTokenDetails(outputCurrency);
  // get input allowance
  const inputAllowance = useAddressAllowance(
    account,
    inputCurrency,
    inputExchangeAddress
  );

  // fetch reserves for each of the currency types
  const { reserveETH: inputReserveETH, reserveToken: inputReserveToken } =
    useExchangeReserves(inputCurrency);
  const { reserveETH: outputReserveETH, reserveToken: outputReserveToken } =
    useExchangeReserves(outputCurrency);

  // get balances for each of the currency types
  const inputBalance = useAddressBalance(account, inputCurrency);
  const outputBalance = useAddressBalance(account, outputCurrency);
  const outputBalanceFormatted = !!(
    outputBalance && Number.isInteger(outputDecimals)
  )
    ? amountFormatter(
        outputBalance,
        outputDecimals,
        Math.min(4, outputDecimals)
      )
    : "";

  // compute useful transforms of the data above
  const independentDecimals =
    independentField === INPUT ? inputDecimals : outputDecimals;
  const dependentDecimals =
    independentField === OUTPUT ? inputDecimals : outputDecimals;

  // declare/get parsed and formatted versions of input/output values
  const [independentValueParsed, setIndependentValueParsed] = useState();
  const dependentValueFormatted = !!(
    dependentValue &&
    (dependentDecimals || dependentDecimals === 0)
  )
    ? amountFormatter(
        dependentValue,
        dependentDecimals,
        Math.min(4, dependentDecimals),
        false
      )
    : "";
  const outputValueFormatted =
    independentField === OUTPUT ? independentValue : dependentValueFormatted;

  // validate + parse independent value
  const [independentError, setIndependentError] = useState();
  useEffect(() => {
    if (
      independentValue &&
      (independentDecimals || independentDecimals === 0)
    ) {
      try {
        const parsedValue = ethers.utils.parseUnits(
          independentValue,
          independentDecimals
        );

        if (
          parsedValue.lte(ethers.constants.Zero) ||
          parsedValue.gte(ethers.constants.MaxUint256)
        ) {
          throw Error();
        } else {
          setIndependentValueParsed(parsedValue);
          setIndependentError(null);
        }
      } catch {
        setIndependentError(t("inputNotValid"));
      }

      return () => {
        setIndependentValueParsed();
        setIndependentError();
      };
    }
  }, [independentValue, independentDecimals, t]);

  // calculate slippage from target rate
  const { minimum: dependentValueMinumum, maximum: dependentValueMaximum } =
    calculateSlippageBounds(
      dependentValue,
      swapType === TOKEN_TO_TOKEN,
      tokenAllowedSlippageBig,
      allowedSlippageBig
    );

  // validate input allowance + balance
  const [inputError, setInputError] = useState();
  const [showUnlock, setShowUnlock] = useState(false);
  useEffect(() => {
    const inputValueCalculation =
      independentField === INPUT
        ? independentValueParsed
        : dependentValueMaximum;
    if (
      inputBalance &&
      (inputAllowance || inputCurrency === "ETH") &&
      inputValueCalculation
    ) {
      if (inputBalance.lt(inputValueCalculation)) {
        setInputError(t("insufficientBalance"));
      } else if (
        inputCurrency !== "ETH" &&
        inputAllowance.lt(inputValueCalculation)
      ) {
        setInputError(t("unlockTokenCont"));
        setShowUnlock(true);
      } else {
        setInputError(null);
        setShowUnlock(false);
      }
      return () => {
        setInputError();
        setShowUnlock(false);
      };
    }
  }, [
    independentField,
    independentValueParsed,
    dependentValueMaximum,
    inputBalance,
    inputCurrency,
    inputAllowance,
    t,
  ]);

  // calculate dependent value
  useEffect(() => {
    const amount = independentValueParsed;

    if (swapType === ETH_TO_TOKEN) {
      const reserveETH = outputReserveETH;
      const reserveToken = outputReserveToken;

      if (amount && reserveETH && reserveToken) {
        try {
          const calculatedDependentValue =
            independentField === INPUT
              ? calculateEtherTokenOutputFromInput(
                  amount,
                  reserveETH,
                  reserveToken
                )
              : calculateEtherTokenInputFromOutput(
                  amount,
                  reserveETH,
                  reserveToken
                );

          if (calculatedDependentValue.lte(ethers.constants.Zero)) {
            throw Error();
          }

          dispatchSwapState({
            type: "UPDATE_DEPENDENT",
            payload: calculatedDependentValue,
          });
        } catch {
          setIndependentError(t("insufficientLiquidity"));
        }
        return () => {
          dispatchSwapState({ type: "UPDATE_DEPENDENT", payload: "" });
        };
      }
    } else if (swapType === TOKEN_TO_ETH) {
      const reserveETH = inputReserveETH;
      const reserveToken = inputReserveToken;

      if (amount && reserveETH && reserveToken) {
        try {
          const calculatedDependentValue =
            independentField === INPUT
              ? calculateEtherTokenOutputFromInput(
                  amount,
                  reserveToken,
                  reserveETH
                )
              : calculateEtherTokenInputFromOutput(
                  amount,
                  reserveToken,
                  reserveETH
                );

          if (calculatedDependentValue.lte(ethers.constants.Zero)) {
            throw Error();
          }

          dispatchSwapState({
            type: "UPDATE_DEPENDENT",
            payload: calculatedDependentValue,
          });
        } catch {
          setIndependentError(t("insufficientLiquidity"));
        }
        return () => {
          dispatchSwapState({ type: "UPDATE_DEPENDENT", payload: "" });
        };
      }
    } else if (swapType === TOKEN_TO_TOKEN) {
      const reserveETHFirst = inputReserveETH;
      const reserveTokenFirst = inputReserveToken;

      const reserveETHSecond = outputReserveETH;
      const reserveTokenSecond = outputReserveToken;

      if (
        amount &&
        reserveETHFirst &&
        reserveTokenFirst &&
        reserveETHSecond &&
        reserveTokenSecond
      ) {
        try {
          if (independentField === INPUT) {
            const intermediateValue = calculateEtherTokenOutputFromInput(
              amount,
              reserveTokenFirst,
              reserveETHFirst
            );
            if (intermediateValue.lte(ethers.constants.Zero)) {
              throw Error();
            }
            const calculatedDependentValue = calculateEtherTokenOutputFromInput(
              intermediateValue,
              reserveETHSecond,
              reserveTokenSecond
            );
            if (calculatedDependentValue.lte(ethers.constants.Zero)) {
              throw Error();
            }
            dispatchSwapState({
              type: "UPDATE_DEPENDENT",
              payload: calculatedDependentValue,
            });
          } else {
            const intermediateValue = calculateEtherTokenInputFromOutput(
              amount,
              reserveETHSecond,
              reserveTokenSecond
            );
            if (intermediateValue.lte(ethers.constants.Zero)) {
              throw Error();
            }
            const calculatedDependentValue = calculateEtherTokenInputFromOutput(
              intermediateValue,
              reserveTokenFirst,
              reserveETHFirst
            );
            if (calculatedDependentValue.lte(ethers.constants.Zero)) {
              throw Error();
            }
            dispatchSwapState({
              type: "UPDATE_DEPENDENT",
              payload: calculatedDependentValue,
            });
          }
        } catch {
          setIndependentError(t("insufficientLiquidity"));
        }
        return () => {
          dispatchSwapState({ type: "UPDATE_DEPENDENT", payload: "" });
        };
      }
    }
  }, [
    independentValueParsed,
    swapType,
    outputReserveETH,
    outputReserveToken,
    inputReserveETH,
    inputReserveToken,
    independentField,
    t,
  ]);

  useEffect(() => {
    const history = createBrowserHistory();
    history.push(window.location.pathname + "");
  }, []);

  const estimatedText = `(${t("estimated")})`;
  function formatBalance(value) {
    return `Balance: ${value}`;
  }

  return (
    <>
      <CurrencyInputPanel
        isOpen={isOpen}
        onDismiss={()=>setIsOpen(false)}
        title={t("output")}
        description={
          outputValueFormatted && independentField === INPUT
            ? estimatedText
            : ""
        }
        extraText={
          outputBalanceFormatted && formatBalance(outputBalanceFormatted)
        }
        urlAddedTokens={urlAddedTokens}
        onCurrencySelected={(outputCurrency) => {
          dispatchSwapState({
            type: "SELECT_CURRENCY",
            payload: { currency: outputCurrency, field: OUTPUT },
          });
        }}
        onValueChange={(outputValue) => {
          dispatchSwapState({
            type: "UPDATE_INDEPENDENT",
            payload: { value: outputValue, field: OUTPUT },
          });
        }}
        selectedTokens={[inputCurrency, outputCurrency]}
        selectedTokenAddress={outputCurrency}
        value={outputValueFormatted}
        errorMessage={independentField === OUTPUT ? independentError : ""}
        disableUnlock
      />
    </>
  );
}
