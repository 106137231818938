import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-wrong.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import {
  login,
  checkSignup,
  getWalletList,
} from "../../../../utils/api/auth.js";
import XIcon from "../../../../assets/icons/dashboard/icon-x-gray.svg";
import { setuid } from "process";

const LogoContainer = styled.div`
  // margin-top: 280px;
  margin-top: 70px;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
`;

const ContentContainer = styled.div`
  align-items: left;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading3}
`;

const MainComment = styled.div`
  height: 32px;
  ${Typography.Heading1}
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 8px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  border: 1px solid ${palette.light_gray};
  // box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.white};
  margin: 0px auto;
  margin-top: 32px;
`;

const UserNameInput = styled.input`
  width: 90%;
  max-width: 327px;
  height: 44px;
  padding-left: 8px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${palette.light_gray};
  }
`;

const UserNameFinal = styled.div`
  width: 90%;
  max-width: 327px;
  height: auto;
  // padding-left: 8px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 16px;
  max-width: 16px;
  height: 16px;
  border: 0;
  margin: auto 0px;
  margin-left: 8px;
`;

const ErrorComment = styled.div`
  height: 15px;
  border: 0;
  background-color: transparent;
  ${Typography.Body}
  text-align: left;
  color: ${palette.light_red};
  margin-top: 13px;
`;

const NextButton = styled.button`
  width: 100px;
  height: 36px;
  background-color: ${palette.marine};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
  margin-top: 50px;
`;

const TextDeleteButton = styled.button`
  background-image: url(${XIcon});
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: hidden;
  margin: auto 0px;
`;

const FindUserIdButton = styled.button`
  border: 0;
  background-color: transparent;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${palette.gray};
  text-decoration: underline;
`;

const FindBox = styled.div`
  display: flex;
  margin-top: 30px;
`;

const LeadComment = styled.div`
  ${Typography.Body}
  color: ${palette.gray};
  margin-right: 13px;
`;

const ComponentDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 62px;
  color: ${palette.gray};
`;

const EnterUserID = (props) => {
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);

  useEffect(() => {
    var regExp = /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{4,18}$/g;
    var onlyEngExp = /[a-zA-Z0-9_]$/g;
    var testResult = regExp.test(userName);
    var onlyEngTestResult = onlyEngExp.test(userName);

    if (!onlyEngTestResult && userName) {
      setErrorComment("Your User ID can only contain letters, numbers and '_'");
    } else if ((userName.length <= 4 || userName.length >= 20) && userName) {
      setErrorComment(
        "Your User ID must be longer than 4, less than 20 characters."
      );
    } else {
      setErrorComment("");
    }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  useEffect(() => {}, [connectWallet]);

  const continueOnClick = async () => {
    const checkSignupResult = await checkSignup(userName).then(async (data) => {
      if (data == 1) {
        const getWalletListResult = await getWalletList(userName).then(
          (data) => {
            console.log(data);
            props.setWalletList(data);
            props.setUserName(userName);
            props.setPageStatus(1);
          }
        );
        // const loginResult = await login(userName).then((data) => {
        //   console.log(data);
        //   props.setWalletList(data.userWallets);
        //   props.setUserName(userName);
        //   props.setPageStatus(1);
        // });
      } else {
        setErrorComment("Invalid user! Please check again.");
      }
    });

    // api가 작동을 안할 때 테스트 코드
    // props.setWalletList([
    //   {
    //     index: 92,
    //     user: "gkrry2723",
    //     walletAddress: "0xed6631bD706BC910A37cdc41ACd48a5d94F7bCC0",
    //     walletType: "Metamask",
    //     walletIcon:
    //       "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
    //     createdAt: "2022-05-10T04:07:36.000+00:00",
    //   },
    //   {
    //     index: 93,
    //     user: "gkrry2723",
    //     walletAddress: "0xed6631bD706BC910A37cdc41ACd48a5d94F7bCC0",
    //     walletType: "Metamask",
    //     walletIcon:
    //       "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
    //     createdAt: "2022-05-10T04:08:04.000+00:00",
    //   },
    // ]);
    // props.setUserName(userName);
    // props.setPageStatus(1);
  };

  const deleteOnClick = () => {
    setUserName("");
  };

  const findOnClick = () => {
    window.location.href = "/findid";
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <HeaderTitle>Sign In to MEPE</HeaderTitle>
        <LogoContainer>
          <MainComment>Enter your User ID</MainComment>
        </LogoContainer>
        <UserNameInputBox
          style={
            errorComment
              ? { borderColor: `${palette.light_red}` }
              : userName
              ? { borderColor: palette.blue }
              : { backgroundColor: palette.background }
          }
        >
          {errorComment && userName ? (
            <ModifiedCheck src={errorComment ? NotValid : Valid} />
          ) : (
            <></>
          )}
          <UserNameInput
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            placeholderTextColor={palette.light_red}
            placeholder="Please Enter Your User ID"
          />
          <TextDeleteButton onClick={deleteOnClick} />
        </UserNameInputBox>
        {errorComment ? <ErrorComment>{errorComment}</ErrorComment> : <></>}
        <FindBox>
          <LeadComment>Forgot Your User ID?</LeadComment>
          <FindUserIdButton onClick={findOnClick}>
            Find User ID
          </FindUserIdButton>
        </FindBox>
        <BottomContainer>
          {!errorComment && userName ? (
            <NextButton onClick={continueOnClick}>Confirm</NextButton>
          ) : (
            <NextButton style={{ backgroundColor: `${palette.marine}33` }}>
              Confirm
            </NextButton>
          )}
        </BottomContainer>
      </ContentContainer>
    </ModalContainer>
  );
};

export default EnterUserID;
