import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";

const FullContainer = styled.div`
  width: 100%;
  min-height: 480px;
  border-radius: 32px;
  background-color: ${palette.white};
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  margin-bottom: 30px;
  padding: 40px 3%;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading2}
`;

const CategoryContainer = styled.div``;

const ViewAllButton = styled.button`
  ${Typography.Heading4}
  text-align: right;
  background-color: transparent;
  border: 0;
  color: ${palette.gray};
`;

const Tokens = (props) => {
  //   const [nftData, setNftData] = useState(props.nftData?.slice(0, 8));
  //   const [poapData, setPoapData] = useState(props.poapData?.slice(0, 8));
  const tokenData = [
    {
      name: "Ethereum",
      myToken: 6217.55,
      unit: "ETH",
      dollar: 17536710.07,
      img: "",
    },
    {
      name: "Ethereum Name Shalla",
      myToken: 49303.38,
      unit: "ENS",
      dollar: 1033254.99,
      img: "",
    },
    {
      name: "USD Coin",
      myToken: 791706.65,
      unit: "USDC",
      dollar: 794118.39,
      img: "",
    },
    {
      name: "Friends With Benefit",
      myToken: 60,
      unit: "FWB",
      dollar: 2021.78,
      img: "",
    },
    {
      name: "Gitcoin",
      myToken: 982.49,
      unit: "GTC",
      dollar: 4720.94,
      img: "",
    },
    {
      name: "Dai",
      myToken: 2048.21,
      unit: "DAI",
      dollar: 2057.27,
      img: "",
    },
    {
      name: "Tether USD",
      myToken: 6990,
      unit: "USDT",
      dollar: 6995.22,
      img: "",
    },
  ];

  return (
    <FullContainer>
      <ContainerHeader>
        <HeaderTitle>Tokens</HeaderTitle>
        <ViewAllButton>view all</ViewAllButton>
      </ContainerHeader>
      <CategoryContainer></CategoryContainer>
    </FullContainer>
  );
};

export default Tokens;
