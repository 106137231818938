import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import IntroComments from "../../../assets/images/intro_comments.svg";
// import IntroImage from "../../../assets/icons/dashboard/icon-image.svg";
import { COLORS as palette } from "../../../utils/styles/colors";
import { Easing } from "react-native-web";
import IntroSidebarContent from "../../../components/global/IntroSidebarContent";
import IntroToolHeader from "../../../components/global/IntroToolHeader";
import CreateAccountFirst from "./components/CreateAccountFirst";
import SignUpWithWallet from "./components/SignUpWithWallet";

const FullContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 500px;
  // height: auto;
  position: absolute;
  z-index: 1;
  background-image: url(${IntroComments});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 23%;
`;

const BackgroundImg = styled.img`
  height: 100%;
  width: 100%;
`;

const WhiteBoardContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  align-items: center;
  background-color: ${palette.white};
`;

const MainComponent = styled.div`
  width: 100%;
  max-width: 418px;
  height: auto;
  margin: 0px auto;
  margin-top: 5vw;
  padding-left: 24px;
  padding-right: 24px;
  background-color: transparent;
  margin-bottom: 40px;
`;

const SignUpPage = (props) => {
  const [backColor, setBackColor] = useState(palette.background);
  const [userName, setUserName] = useState("");
  const [pageStatus, setPageStatus] = useState(0);

  useEffect(() => {
    console.log(pageStatus);
  }, [pageStatus]);

  return (
    <div
      className={"f-column a-center"}
      style={{ backgroundColor: backColor, position: "relative" }}
    >
      <>
        <FullContainer>
          <IntroSidebarContent />
          <WhiteBoardContainer>
            {pageStatus == 0 ? (
              <IntroToolHeader
                description={"Already Signed Up?"}
                buttonText={"Sign In"}
              />
            ) : (
              <IntroToolHeader
                description={"Already Signed Up?"}
                buttonText={"Sign In"}
                statusSetter={setPageStatus}
                status={pageStatus}
              />
            )}
            <MainComponent>
              {pageStatus == 0 ? (
                <CreateAccountFirst
                  setUserName={setUserName}
                  setPageStatus={setPageStatus}
                />
              ) : (
                <></>
              )}
              {pageStatus == 1 ? (
                <SignUpWithWallet
                  userName={userName}
                  setPageStatus={setPageStatus}
                />
              ) : (
                <></>
              )}
            </MainComponent>
          </WhiteBoardContainer>
        </FullContainer>
      </>
    </div>
  );
};

export default SignUpPage;
