import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-x.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import BasicButton from "../../../../components/basic/BasicButton";
import WalletButtonGroup from "../../../../components/basic/WalletGroup/WalletButtonGroup";
import FindUserIdFound from "./FindUserIdFound";
import { findUserId } from "../../../../utils/api/wallets";

const LogoContainer = styled.div`
  // margin-top: 80px;
  margin-top: 10%;
`;

const ModalContainer = styled.div`
  width: 100%;
  //max-width: 1240px;
  min-height: 900px;
  // margin-top: 90px;
  // margin-top: 10%;
  margin-bottom: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  padding-top: 47.5px;
  padding-left: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  align-items: center;
`;

const MainComment = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const StateComment = styled.div`
  width: 100%;
  ${Typography.Heading1}
  text-align: center;
  color: ${palette.black};
  margin-bottom: 48px;
`;

const UserNameInputBox = styled.div`
  width: 80%;
  height: 64px;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 36px;
  border: 2px solid ${palette.blue};
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.background};
  margin: 0px auto;
  margin-bottom: 56px;
  margin-top: 32px;
`;

const UserNameInput = styled.div`
  width: 80%;
  max-width: 327px;
  padding-left: 10px;
  color: ${palette.blue};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
`;

const BackArrowImage = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-right: 8px;
`;

const BackButton = styled.button`
  height: 24px;
  //   margin-top: 47.5px;
  display: flex;
  background-color: transparent;
  border: 0;
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.gray};
`;

const NotNowButton = styled.button`
  width: 97px;
  max-width: 97px;
  height: 44px;
  background-color: ${palette.black};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const WalletButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FindUserIdConnectWallet = (props) => {
  const [userName, setUserName] = useState(props.userName);
  const [walletAddress, setWalletAddress] = useState("");
  const [walletId, setWalletId] = useState(0);
  const [userNameList, setUserNameList] = useState([]);
  const [userInfoCheckSuccess, setUserInfoCheckSuccess] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    (async () => {
      if (walletAddress) {
        //연결하여 받은 walletAddress가 가입된 이력이 있는지 확인하는 api 호출
        //있으면 연결된 username 연결 리스트도 받아와서 값을 반환함.

        const findUserIdResult = await findUserId(walletAddress).then(
          (data) => {
            setUserNameList(data);
            setUserInfoCheckSuccess(true);
          }
        );
      }
    })();
  }, [walletAddress]);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  return (
    <React.Fragment>
      {userInfoCheckSuccess ? (
        <FindUserIdFound
          walletAddress={walletAddress}
          walletId={walletId}
          userNameList={userNameList}
          setPageStack={setUserInfoCheckSuccess}
        />
      ) : (
        <ModalContainer>
          <ControlHeader>
            <BackButton onClick={backButtonOnClick}>
              <BackArrowImage src={BackArrow} />
              Go Back to Previous
            </BackButton>
          </ControlHeader>
          <div className={"f-column a-center"}>
            <ContentContainer>
              <LogoContainer>
                <StateComment>Find Your User ID</StateComment>
                <MainComment>
                  To find your user id, <br /> please connect your wallet
                  address verified previously.
                </MainComment>
              </LogoContainer>
              <WalletButtonContainer>
                <WalletButtonGroup
                  setPageStack={setWalletAddress}
                  setWalletId={setWalletId}
                />
              </WalletButtonContainer>
            </ContentContainer>
          </div>
        </ModalContainer>
      )}
    </React.Fragment>
  );
};

export default FindUserIdConnectWallet;
