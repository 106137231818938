import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-wrong.svg";
import Valid from "../../../../assets/icons/dashboard/icon-verified.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { checkSignup } from "../../../../utils/api/auth.js";
import XIcon from "../../../../assets/icons/dashboard/icon-x-gray.svg";
import { setuid } from "process";
import WalletButtonGroupMini from "../../../../components/basic/WalletGroup/WalletButtonGroupMini";
import ConnectedWalletButtonMini from "../../../../components/basic/ConnectedWallet/ConnectedWalletButtonMini";
import WalletListData from "../../../../datas/WalletListData";
import { createUser, login } from "../../../../utils/api/auth.js";

const LogoContainer = styled.div`
  // margin-top: 280px;
  margin-top: 70px;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
`;

const ContentContainer = styled.div`
  align-items: left;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading3}
`;

const MainComment = styled.div`
  height: 32px;
  ${Typography.Heading1}
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 8px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  border: 1px solid ${palette.light_gray};
  // box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.white};
  margin: 0px auto;
  margin-top: 32px;
`;

const UserNameFinal = styled.div`
  width: 90%;
  max-width: 327px;
  height: auto;
  // padding-left: 8px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 16px;
  max-width: 16px;
  height: 16px;
  border: 0;
  margin: auto 0px;
  margin-left: 8px;
`;

const ConnectListContainer = styled.div`
  width: 100%;
  max-width: 400px;
  gap: 20px;
`;

const BlankSpace = styled.div`
  width: 100%;
  max-width: 400px;
  height: 24px;
`;

const TinyTitle = styled.div`
  width: 100%;
  ${Typography.Body}
  text-align: center;
  margin: 28px 0px;
  color: ${palette.black};
`;

const SignInWallet = (props) => {
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [walletId, setWalletId] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [connectSuccess, setConnectSuccess] = useState(false);
  const [walletList, setWalletList] = useState(props.walletList);

  useEffect(() => {
    (async () => {
      if (connectSuccess) {
        const loginResult = await login(props.userName).then((data) => {
          console.log(data);
          alert("Login Successfully! Welcome to Mepe!");
          // login success
          window.location.href = "/mypage";
        });
      }
    })();
  }, [connectSuccess]);

  useEffect(() => {
    var regExp = /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{4,18}$/g;
    var testResult = regExp.test(userName);

    if ((userName.length <= 4 || userName.length >= 20) && userName) {
      setErrorComment(
        "Your user ID must be longer than 4, less than 20 characters."
      );
    } else if (!testResult && userName) {
      setErrorComment(
        "Your username can only contain letters, numbers and '_'"
      );
    } else {
      setErrorComment("");
    }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  useEffect(() => {}, [connectWallet]);

  return (
    <ModalContainer>
      <ContentContainer>
        <HeaderTitle>Sign In to MEPE</HeaderTitle>
        <LogoContainer>
          <MainComment>Sign In with Wallet</MainComment>
        </LogoContainer>
        <UserNameInputBox style={{ borderColor: `${palette.blue}` }}>
          <ModifiedCheck src={Valid} />
          <UserNameFinal>{props.userName}</UserNameFinal>
        </UserNameInputBox>
        <TinyTitle>Sign in With</TinyTitle>
        <ConnectListContainer>
          {walletList?.map((v) => (
            <React.Fragment>
              <ConnectedWalletButtonMini
                setConnectSuccess={setConnectSuccess}
                deepLink={
                  WalletListData[
                    WalletListData.findIndex(
                      (x) => x.walletName === v.walletType
                    )
                  ]?.deepLink
                }
                img={
                  WalletListData[
                    WalletListData.findIndex(
                      (x) => x.walletName === v.walletType
                    )
                  ]?.walletIcon
                }
                name={
                  WalletListData[
                    WalletListData.findIndex(
                      (x) => x.walletName === v.walletType
                    )
                  ]?.walletName
                }
                walletAddress={v.walletAddress}
                walletId={WalletListData.findIndex(
                  (x) => x.walletName === v.walletType
                )}
              />
              <BlankSpace />
            </React.Fragment>
          ))}
        </ConnectListContainer>
      </ContentContainer>
    </ModalContainer>
  );
};

export default SignInWallet;
