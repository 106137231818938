import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import LinkIcon from "../../../../assets/icons/dashboard/icon-linkExternal.svg";
import FinalConfirmation from "../../FinancePage/components/FinalConfirmation";
import WalletList from "../../../../datas/WalletListData";
import {
  cancelRequest,
  getRequestStatus,
  declineRequest,
} from "../../../../utils/api/wallets";

const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  min-height: 200px;
  background-color: ${palette.white};
  border-radius: 40px;
  padding: 30px;
  margin-top: 32px;
  padding-bottom: 12px;
  box-shadow: 0px 2px 10px #c4c4c440;
`;

const TransactionUnit = styled.div`
  width: 100%;
  min-height: 160px;
  padding: 20px;
  border: 1px solid ${palette.light_gray};
  border-radius: 20px;
  margin-bottom: 24px;
`;

const InfoBox = styled.div`
  display: flex;
`;

const InfoTextBox = styled.div`
  margin-top: 10px;
`;

const UserIdBox = styled.div`
  ${Typography.Heading2}
  color: ${palette.black};
  margin-bottom: 3px;
  display: flex;
`;

const MemoBox = styled.div`
  ${Typography.Heading3}
  color: ${palette.gray};
  margin-top: 4px;
`;

const SideBox = styled.div`
  display: flex;
`;

const DateBox = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${palette.gray};
  margin-top: 4px;
`;

const ProfileImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-top: 5px;
  margin-right: 16px;
`;

const BoxHeader = styled.div`
  display: flex;
  align-items: bottom;
  margin-left: 50px;
  margin-bottom: 20px;
`;

const BoxHeaderLength = styled.div`
  ${Typography.Heading1}
  color: ${palette.marine};
  display: inline-block;
  align-self: flex-end;
  margin-right: 5px;
`;

const BoxHeaderText = styled.div`
  ${Typography.Heading2}
  color: ${palette.black};
  display: inline-block;
  align-self: flex-end;
  padding-bottom: 2px;
`;

const ButtonContainer = styled.div`
  width: 98%;
  display: flex;
  justify-content: right;
  gap: 18px;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 4;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.white};
  background-color: ${palette.marine};
  z-index: 4;
`;

function pad(n) {
  return n < 10 ? "0" + n : n;
}

const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

const dateFormat = (dateStr) => {
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const newString =
    pad(new Date(dateStr).getUTCHours()) +
    ":" +
    pad(new Date(dateStr).getUTCMinutes()) +
    ", " +
    monthNamesShort[new Date(dateStr).getUTCMonth() + 0] +
    " " +
    pad(new Date(dateStr).getUTCDate()) +
    nth(new Date(dateStr).getUTCDate()) +
    ", " +
    pad(new Date(dateStr).getFullYear().toString());

  return newString;
};

const RequestProgressBox = ({ txList }) => {
  const [txLiFrom, setTxLiFrom] = useState([]);
  const [txLiTo, setTxLiTo] = useState([]);
  const [finalConfirmationVisible, setFinalConfirmationVisible] =
    useState(false);
  const [toFinalConfirmationVisible, setToFinalConfirmationVisible] =
    useState(false);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [chainNetwork, setChainNetwork] = useState("");
  const [friendId, setFriendId] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [receiverIcon, setReceiverIcon] = useState("");
  const [senderIcon, setSenderIcon] = useState("");
  const [currency, setCurrency] = useState("");
  const [memo, setMemo] = useState("");
  const [friendImg, setFriendImg] = useState("");
  const [senderWallet, setSenderWallet] = useState(
    localStorage.getItem("currentWalletType")
  );

  useEffect(() => {
    setTxLiFrom(txList?.requestFrom);
    setTxLiTo(txList?.requestTo);
  }, [txList]);

  const linkButtonOnClick = (txHash) => {
    window.location.href = "https://evm.evmos.org/tx/" + txHash;
  };

  const sendOnClick = async (data, type) => {
    const networkVersion = await window.ethereum.request({
      method: "net_version",
    });
    console.log(networkVersion);

    console.log(
      WalletList.findIndex((val) => val.walletName == data.walletType)
    );

    console.log(data);

    setReceiverAddress(data.reqWalletAddress);
    setSenderAddress(data.reqWalletAddress);
    setReceiverIcon(
      WalletList[
        WalletList.findIndex((val) => val.walletName == data.walletType)
      ].walletIcon
    );
    setSenderIcon(
      WalletList[
        WalletList.findIndex((val) => val.walletName == data.walletType)
      ].walletIcon
    );
    setAmount(data.reqTokenAmount);
    setChainNetwork(data.chainID);
    setCurrency(data.udenom);
    setMemo(data.memo);
    if (type == "send") {
      setFinalConfirmationVisible(true);
      setFriendImg(data.toUserProfileImg);
      setFriendId(data.toUserID);
    } else if (type == "request") {
      setToFinalConfirmationVisible(true);
      setFriendImg(data.fromUserProfileImg);
      setFriendId(data.fromUserID);
    }
    setSenderWallet(
      WalletList.findIndex((val) => val.walletName == data.walletType)
    );
  };

  const closeFinalConfirmationModal = () => {
    setFinalConfirmationVisible(false);
  };

  const closeToFinalConfirmationModal = () => {
    setToFinalConfirmationVisible(false);
  };

  const cancelOnClick = async (idx) => {
    const cancelRequestResult = await cancelRequest(
      localStorage.getItem("userID"),
      idx
    ).then((data) => {
      console.log(data);
      window.location.reload();
    });
  };

  const declineOnClick = async (idx) => {
    const declineRequestResult = await declineRequest(
      localStorage.getItem("userID"),
      idx
    ).then((data) => {
      console.log(data);
      window.location.reload();
    });
  };

  return (
    <>
      <Container>
        {finalConfirmationVisible ? (
          <FinalConfirmation
            visible={finalConfirmationVisible}
            closable={true}
            maskClosable={true}
            onClose={closeFinalConfirmationModal}
            type="send-in-request-progress"
            confirmInfo={{
              receiverAddress: receiverAddress,
              senderAddress: senderAddress,
              receiverIcon: receiverIcon,
              senderIcon: senderIcon,
              senderWallet: senderWallet,
              amount: amount,
              chainId: chainNetwork,
              currency: currency,
              memo: memo,
              myImg: localStorage.getItem("profileImage"),
              friendImg: friendImg,
              friendId: friendId,
            }}
            sendFunction={WalletList[senderWallet].sendAction}
          />
        ) : (
          <>
            {toFinalConfirmationVisible ? (
              <FinalConfirmation
                visible={toFinalConfirmationVisible}
                closable={true}
                maskClosable={true}
                onClose={closeToFinalConfirmationModal}
                type="request"
                confirmInfo={{
                  receiverAddress: receiverAddress,
                  senderAddress: senderAddress,
                  receiverIcon: receiverIcon,
                  senderIcon: senderIcon,
                  senderWallet: senderWallet,
                  amount: amount,
                  chainId: chainNetwork,
                  currency: currency,
                  memo: memo,
                  myImg: localStorage.getItem("profileImage"),
                  friendImg: friendImg,
                  friendId: friendId,
                }}
                sendFunction={WalletList[senderWallet].sendAction}
              />
            ) : (
              <></>
            )}
          </>
        )}
        <BoxHeader>
          <BoxHeaderLength>{txLiFrom?.length}</BoxHeaderLength>
          <BoxHeaderText>Request From</BoxHeaderText>
        </BoxHeader>
        {txLiFrom ? (
          <>
            {txLiFrom.map((val, idx) => (
              <>
                <TransactionUnit
                  style={
                    val.isDeclined
                      ? { backgroundColor: palette.light_gray }
                      : {}
                  }
                >
                  <SideBox>
                    <InfoBox>
                      <ProfileImage src={val.toUserProfileImg} />
                      <InfoTextBox>
                        <UserIdBox>
                          @{val.toUserID}{" "}
                          <div
                            style={{ margin: "0px 5px", color: palette.gray }}
                          >
                            requests
                          </div>{" "}
                          {val.reqTokenAmount} {val.udenom?.toUpperCase()}
                        </UserIdBox>
                        <MemoBox>{val.memo}</MemoBox>
                        <DateBox>{dateFormat(val.createdAt)}</DateBox>
                      </InfoTextBox>
                    </InfoBox>
                  </SideBox>
                  {val.isDeclined ? (
                    <></>
                  ) : (
                    <ButtonContainer>
                      <LeftButton onClick={() => declineOnClick(val.index)}>
                        Decline
                      </LeftButton>
                      <RightButton onClick={() => sendOnClick(val, "send")}>
                        Send
                      </RightButton>
                    </ButtonContainer>
                  )}
                </TransactionUnit>
              </>
            ))}
          </>
        ) : (
          <></>
        )}
      </Container>
      <Container>
        <BoxHeader>
          <BoxHeaderLength>{txLiTo?.length}</BoxHeaderLength>
          <BoxHeaderText>Request To</BoxHeaderText>
        </BoxHeader>
        {txLiTo ? (
          <>
            {txLiTo.map((val, idx) => (
              <>
                <TransactionUnit
                  style={
                    val.isDeclined
                      ? { backgroundColor: palette.light_gray }
                      : {}
                  }
                >
                  <SideBox>
                    <InfoBox>
                      <ProfileImage src={val.fromUserProfileImg} />
                      <InfoTextBox>
                        <UserIdBox>
                          @{val.fromUserID}{" "}
                          <div
                            style={{ margin: "0px 5px", color: palette.gray }}
                          >
                            requests
                          </div>{" "}
                          {val.reqTokenAmount} {val.udenom?.toUpperCase()}
                        </UserIdBox>
                        <MemoBox>{val.memo}</MemoBox>
                        <DateBox>{dateFormat(val.createdAt)}</DateBox>
                      </InfoTextBox>
                    </InfoBox>
                  </SideBox>
                  <ButtonContainer>
                    <LeftButton onClick={() => cancelOnClick(val.index)}>
                      Cancel
                    </LeftButton>
                    <RightButton onClick={() => sendOnClick(val, "request")}>
                      Remind
                    </RightButton>
                  </ButtonContainer>
                </TransactionUnit>
              </>
            ))}
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default RequestProgressBox;
