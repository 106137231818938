import { Bech32Address } from "@keplr-wallet/cosmos";
import { cosmosIcon } from "../assets/tokens/cosmos/cosmos.svg";

const ChainInfo = [
  {
    chainName: "Polygon",
    currencies: [
      {
        coinDenom: "MATIC",
        coinMinimalDenom: "umatic",
      },
    ],
  },{
    chainName: "Dai",
    currencies: [
      {
        coinDenom: "DAI",
        coinMinimalDenom: "udai",
      },
    ],
  },{
    chainName: "Chainlink",
    currencies: [
      {
        coinDenom: "RAY",
        coinMinimalDenom: "uray",
      },
    ],
  },{
    chainName: "Aave",
    currencies: [
      {
        coinDenom: "AAVE",
        coinMinimalDenom: "uaave",
      },
    ],
  },{
    chainName: "The Graph",
    currencies: [
      {
        coinDenom: "GRT",
        coinMinimalDenom: "ugrt",
      },
    ],
  },{
    chainName: "Curve DAO Token",
    currencies: [
      {
        coinDenom: "CRV",
        coinMinimalDenom: "ucrv",
      },
    ],
  },
  
];

export default ChainInfo;
