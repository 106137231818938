import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import MarineArrow from "../../../../assets/icons/dashboard/icon-marineArrow.svg";
import {
  remitmentTx,
  requestTx,
  declineRequest,
} from "../../../../utils/api/wallets";
import DropBox from "./dropbox";
import WalletList from "../../../../datas/WalletListData";

const BoxContainer = styled.div`
  width: 90%;
  height: 600px;
  max-width: 856px;
  min-height: 288px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid ${palette.light_gray};
`;

const PersonInfoBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const ArrowImg = styled.img`
  width: 48px;
`;

const ToInfo = styled.div`
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.black};
  margin-top: 20px;
`;

const ToUserId = styled.p`
  display: inline;
  color: ${palette.marine};
  ${Typography.Heading2}
`;

const InfoContainer = styled.div`
  width: 100%;
  min-height: 254px;
  background-color: ${palette.background};
  border-radius: 20px;
  margin-top: 12px;
  padding: 16px;
`;

const SubTitle = styled.li`
  ${Typography.Heading3}
  height: 20px;
  color: ${palette.gray};
  margin-bottom: 2px;
  margin-top: 10px;
`;

const WalletIconBox = styled.img`
  width: 24px;
  height: 24px;
`;

const WalletAddress = styled.div`
  max-width: 200px;
  font-family: Roboto Mono;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: ${palette.light_black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const WalletAddressBox = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: left;
  border: hidden;
  text-align: left;
  padding: 8px 16px;
  gap: 10px;
`;

const AmountBox = styled.div`
  ${Typography.Heading2}
  color: ${palette.black};
  margin-left: 20px;
`;

const MemoBox = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${palette.gray};
  padding: 0px 20px;
`;

const ButtonContainer = styled.div`
  width: 93%;
  display: flex;
  justify-content: right;
  margin-top: 50px;
  gap: 18px;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 10;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.white};
  background-color: ${palette.marine};
  z-index: 10;
`;

const GoToRequestProgress = styled.a`
  display: inline-block;
  ${Typography.Link}
  color: ${palette.gray};
  margin-top: 14px;
  text-decoration: underline;
`;

const walletAddressConverter = (walletAddress) => {
  let returnString = "";
  if (walletAddress?.length > 15) {
    returnString =
      walletAddress.substr(0, 6) +
      "..." +
      walletAddress.substr(walletAddress.length - 6, walletAddress.length);
  }
  return returnString;
};

const DropBoxContainer = styled.div`
  display: flex;
  max-width: fit-content;
  jusify-content: left;
  align-items: left;
`;

function FinalConfirmation({
  className,
  onClose,
  maskClosable,
  visible,
  type,
  confirmInfo,
  sendFunction,
}) {
  const [personList, setPersonList] = useState([]);
  const [unFollowIdxList, setUnFollowIdxList] = useState([]);
  const [confirmInfomation, setConfirmInfomation] = useState({});
  const [followButtonClick, setFollowButtonClick] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [senderWallet, setSenderWallet] = useState(
    JSON.parse(localStorage.getItem("walletList"))[0].walletType
  );
  const [senderAddress, setSenderAddress] = useState(
    JSON.parse(localStorage.getItem("walletList"))[0].walletAddress
  );
  const [senderIcon, setSenderIcon] = useState(WalletList[0]?.walletIcon);
  const [userWalletList, setUserWalletList] = useState(
    JSON.parse(localStorage.getItem("walletList"))
  );

  useEffect(() => { }, [followButtonClick]);

  useEffect(() => {
    console.log(confirmInfo);
    console.log(WalletList[confirmInfo?.senderWallet]?.walletName);
    setConfirmInfomation(confirmInfo);
  }, [confirmInfo]);

  useEffect(() => {
    (async () => {
      console.log(transactionHash);
      console.log(type);
      if (
        transactionHash.txHash &&
        (type == "send" || type == "send-in-request-progress")
      ) {
        console.log("hello");
        const remitmentTxResult = await remitmentTx(transactionHash)
          .then((data) => {
            console.log(data);
            alert("Success!");
            onClose();
          })
          .catch((err) => {
            console.log(err);
            alert("Something's wrong. Please try again.");
          });
      }
    })();
  }, [transactionHash]);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  const declineOnClick = async (idx) => {
    const declineRequestResult = await declineRequest(
      localStorage.getItem("userID"),
      idx
    ).then((data) => {
      console.log(data);
      window.location.reload();
    });
  };

  const sendOnClick = async () => {
    if (type == "send" || type == "send-in-request-progress") {
      var setting = {
        fromAddr: confirmInfomation?.senderAddress,
        toAddr: confirmInfomation?.receiverAddress,
        value: confirmInfomation?.amount,
        chainId: confirmInfomation?.chainId,
        toUser: confirmInfomation?.friendId,
        fromUser: localStorage.getItem("userID"),
        memo: confirmInfomation?.memo,
        udenom: confirmInfomation?.currency,
      };

      if (type == "send-in-request-progress") {
        setting.fromAddr = senderAddress;
      }

      console.log(setting);

      console.log("udenom", confirmInfomation?.currency);
      console.log("reqTokenAmount", confirmInfomation?.amount);
      const sendFunctionResult = await sendFunction(
        setTransactionHash,
        setting
      );
    } else if (type == "request") {
      const SendData = {
        reqWalletAddress: confirmInfomation?.senderAddress,
        reqTokenAmount: confirmInfomation?.amount,
        toUser: confirmInfomation?.friendId,
        fromUser: localStorage.getItem("userID"),
        chainID: confirmInfomation?.chainId,
        memo: confirmInfomation?.memo,
        walletType: WalletList[confirmInfomation?.senderWallet].walletName,
        udenom: confirmInfomation?.currency,
      };
      console.log(SendData);
      console.log("udenom", confirmInfomation?.currency);
      console.log("reqTokenAmount", confirmInfomation?.amount);
      const requestTxResult = await requestTx(SendData)
        .then((data) => {
          console.log(data);
          alert("Success!");
          onClose();
        })
        .catch((err) => {
          alert("Something's wrong! Please try again.");
        });
    } else if (type == "send-in-noti") {
      var setting = {
        fromAddr: senderAddress,
        toAddr: confirmInfomation?.receiverAddress,
        value: confirmInfomation?.amount,
        chainId: confirmInfomation?.chainId,
        toUser: confirmInfomation?.friendId,
        fromUser: localStorage.getItem("userID"),
        memo: confirmInfomation?.memo,
        udenom: confirmInfomation?.currency,
        walletType: senderWallet,
      };

      window.location.href = `/finance/type=${type}/fromAddr=${setting.fromAddr}/toAddr=${setting.toAddr}/value=${setting.value}/chainId=${setting.chainId}/fromUser=${setting.fromUser}/toUser=${setting.toUser}/memo=${setting.memo}/udenom=${setting.udenom}/walletType=${setting.walletType}`;
    } else if (type == "request-in-noti") {
      var setting = {
        reqWalletAddress: confirmInfomation?.senderAddress,
        reqTokenAmount: confirmInfomation?.amount,
        toUser: confirmInfomation?.friendId,
        fromUser: localStorage.getItem("userID"),
        chainID: confirmInfomation?.chainId,
        memo: confirmInfomation?.memo,
        walletType: senderWallet,
        udenom: confirmInfomation?.currency,
      };
      const requestTxResult = await requestTx(setting)
        .then((data) => {
          console.log(data);
          alert("Success!");
          onClose();
        })
        .catch((err) => {
          alert("Something's wrong! Please try again.");
        });
      // window.location.href = `/finance/type=${type}/fromAddr=${setting.reqWalletAddress}/toAddr=${setting.toAddr}/value=${setting.reqTokenAmount}/chainId=${setting.chainID}/fromUser=${setting.fromUser}/toUser=${setting.toUser}/memo=${setting.memo}/udenom=${setting.udenom}/walletType=${setting.walletType}`;
    }
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>Final Confirmation</PopupTitle>
            <BoxContainer>
              {type == "send" ||
              type == "send-in-request-progress" ||
              type == "send-in-noti" ? (
                <>
                  <PersonInfoBox>
                    <ProfileImage
                      style={{
                        backgroundImage: `url(${confirmInfomation?.myImg})`,
                      }}
                    />
                    <ArrowImg src={MarineArrow} />
                    <ProfileImage
                      style={{
                        backgroundImage: `url(${confirmInfomation?.friendImg})`,
                      }}
                    />
                  </PersonInfoBox>

                  <ToInfo>
                    Send Token to <ToUserId>@{confirmInfo?.friendId}</ToUserId>
                  </ToInfo>
                  <InfoContainer>
                    <SubTitle style={{ marginTop: "0px" }}>
                      My Wallet Address
                    </SubTitle>
                    {type == "send" ? (
                      <WalletAddressBox>
                        <WalletIconBox src={confirmInfomation?.senderIcon} />
                        <WalletAddress>
                          {walletAddressConverter(
                            confirmInfomation?.senderAddress
                          )}
                        </WalletAddress>
                      </WalletAddressBox>
                    ) : (
                      <DropBoxContainer>
                        <DropBox
                          setSenderAddress={setSenderAddress}
                          setSenderWallet={setSenderWallet}
                          setSenderIcon={setSenderIcon}
                          itemList={userWalletList}
                          dropboxType="myWallet"
                        />
                      </DropBoxContainer>
                    )}
                    <SubTitle>Friend Wallet Address</SubTitle>
                    <WalletAddressBox>
                      <WalletIconBox src={confirmInfomation?.receiverIcon} />
                      <WalletAddress>
                        {walletAddressConverter(
                          confirmInfomation?.receiverAddress
                        )}
                      </WalletAddress>
                    </WalletAddressBox>
                    <SubTitle>Send Token Amount</SubTitle>
                    <AmountBox>
                      {confirmInfomation?.amount} {confirmInfomation?.currency}
                    </AmountBox>
                    <SubTitle>Memo</SubTitle>
                    <MemoBox>{confirmInfomation?.memo}</MemoBox>
                  </InfoContainer>
                </>
              ) : (
                <>
                  {type == "request" || type == "request-in-noti" ? (
                    <>
                      <PersonInfoBox>
                        <ProfileImage
                          style={{
                            backgroundImage: `url(${confirmInfomation?.friendImg})`,
                          }}
                        />
                        <ArrowImg src={MarineArrow} />

                        <ProfileImage
                          style={{
                            backgroundImage: `url(${confirmInfomation?.myImg})`,
                          }}
                        />
                      </PersonInfoBox>

                      <ToInfo>
                        Request Token to{" "}
                        <ToUserId>@{confirmInfo?.friendId}</ToUserId>
                      </ToInfo>
                      <InfoContainer>
                        <SubTitle style={{ marginTop: "0px" }}>
                          Wallet Address
                        </SubTitle>
                        <WalletAddressBox>
                          <WalletIconBox src={confirmInfomation?.senderIcon} />
                          <WalletAddress>
                            {walletAddressConverter(
                              confirmInfomation?.senderAddress
                            )}
                          </WalletAddress>
                        </WalletAddressBox>
                        <SubTitle>Request Token Amount</SubTitle>
                        <AmountBox>
                          {confirmInfomation?.amount}{" "}
                          {confirmInfomation?.currency}
                        </AmountBox>
                        <SubTitle>Memo</SubTitle>
                        <MemoBox>{confirmInfomation?.memo}</MemoBox>
                      </InfoContainer>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {type == "request-in-noti" ? (
                <GoToRequestProgress href="/requestProgress">
                  You can check more info on the request progress page.
                </GoToRequestProgress>
              ) : (
                <></>
              )}
              <ButtonContainer>
                {type == "send-in-noti" ? (
                  <LeftButton
                    onClick={() => declineOnClick(confirmInfomation?.index)}
                  >
                    Decline
                  </LeftButton>
                ) : (
                  <>
                    {type == "request-in-noti" ? (
                      <></>
                    ) : (
                      <LeftButton onClick={closeOnClick}>Cancel</LeftButton>
                    )}
                  </>
                )}
                {type == "request-in-noti" ? (
                  <RightButton onClick={sendOnClick}>Resend</RightButton>
                ) : (
                  <RightButton onClick={sendOnClick}>Send</RightButton>
                )}
              </ButtonContainer>
            </BoxContainer>

            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

FinalConfirmation.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  // overflow: auto;
  overflow: hidden;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 740px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 36px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

export default FinalConfirmation;
