import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";

const FullContainer = styled.div`
  width: 100%;
  min-height: 480px;
  border-radius: 32px;
  background-color: ${palette.white};
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  margin-bottom: 30px;
  padding: 40px 3%;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading2}
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  //   gap: 20px;
`;

const BadgeCard = styled.div`
  width: 150px;
  height: 210px;
  padding: 30px 5px;
  text-align: center;
  margin: 9px;
`;

const BadgeImageBox = styled.div`
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0px auto;
  margin-bottom: 10px;
  line-height: 100px;
  box-shadow: 0px 2px 10px #57599240;
  border-radius: 10px;
  overflow: hidden;
  //   filter: drop-shadow(0px 2px 10px #57599240);
`;

const BadgeImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin: auto;
  vertical-align: middle;
`;

const BadgeTitleBox = styled.div`
  height: 42px;
  width: 140px;
  display: flex;
`;

const BadgeTitle = styled.div`
  ${Typography.Heading4}
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin: auto;
`;

const BadgeObtainedDate = styled.div`
  ${Typography.Body}
  text-align: center;
  color: ${palette.gray};
  margin-top: 4px;
`;

const BadgeTagBox = styled.div`
  width: 100%;
  min-height: 23px;
  background-color: ${palette.background};
  border-radius: 8px;
  ${Typography.Body}
  color: ${palette.gray};
  display: flex;
  text-align: center;
  margin-top: 8px;
  padding: 4px 10px;
`;

const BadgeTag = styled.div`
  margin: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const HeaderButton = styled.button`
  min-height: 36px;
  padding: 7px 24px;
  border: 2px solid ${palette.black};
  color: ${palette.black};
  background-color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 18px;
`;

const PinnedBadges = (props) => {
  const [badgeData, setBadgeData] = useState(props.badgeData);

  useEffect(() => {
    setBadgeData(props.badgeData);
  }, [props.badgeData]);

  return (
    <FullContainer>
      <ContainerHeader>
        <HeaderTitle>Pinned Badges</HeaderTitle>
        <ButtonContainer>
          <HeaderButton>All Badges</HeaderButton>
          <HeaderButton>Creation</HeaderButton>
        </ButtonContainer>
      </ContainerHeader>
      <BadgeContainer>
        {badgeData.map((val, idx) => (
          <BadgeCard>
            <BadgeImageBox>
              <BadgeImage referrerpolicy="no-referrer" src={val.image} />
            </BadgeImageBox>
            <BadgeTitleBox>
              <BadgeTitle>{val.badgeTitle}</BadgeTitle>
            </BadgeTitleBox>
            <BadgeObtainedDate>
              {new Date(val.obtainedAt).getFullYear().toString().slice(2, 4)}.
              {new Date(val.obtainedAt).getMonth()}.
              {new Date(val.obtainedAt).getDate()} obtained
            </BadgeObtainedDate>
            <React.Fragment>
              {val.tag ? (
                <BadgeTagBox>
                  <BadgeTag>{val.tag}</BadgeTag>
                </BadgeTagBox>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
          </BadgeCard>
        ))}
      </BadgeContainer>
    </FullContainer>
  );
};

export default PinnedBadges;
