import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import SettingIcon from "../../../assets/icons/dashboard/icon-setting.svg";
import LogoutIcon from "../../../assets/icons/dashboard/icon-logout.svg";
import Typography from "../../../utils/styles/typography";

const DropBoxContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background-color: ${palette.white};
  position: absolute;
  right: 0px;
  top: 60px;
  z-index: 5;
  box-shadow: 0px 2px 10px #c4c4c440;
`;

const NickNameBox = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 9px 7px 4px;
  color: ${palette.black};
`;

const UserIDBox = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  margin-left: 7px;
  margin-bottom: 12px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${palette.light_gray};
`;

const DropboxButton = styled.button`
  height: 32px;
  border: hidden;
  background-color: transparent;
  display: flex;
  justify-content: left;
  padding-left: 4px;
`;

const DropBoxIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: auto 0px;
`;

const DropBoxButtonText = styled.div`
  ${Typography.Link}
  text-align: center;
  margin: auto 4px;
`;

const ProfileDropbox = () => {
  const [nickName, setNickName] = useState(localStorage.getItem("userID"));
  const [userId, setUserId] = useState(localStorage.getItem("userID"));

  const settingOnClick = () => {
    window.location.href = "/setting";
  };

  const logoutOnClick = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <DropBoxContainer>
      <NickNameBox>{nickName}</NickNameBox>
      {userId ? (
        <UserIDBox>@{userId}</UserIDBox>
      ) : (
        <UserIDBox>Undefined</UserIDBox>
      )}
      <Divider />
      <DropboxButton onClick={settingOnClick} style={{ color: palette.gray }}>
        <DropBoxIcon src={SettingIcon} />
        <DropBoxButtonText>Settings</DropBoxButtonText>
      </DropboxButton>
      <DropboxButton
        onClick={logoutOnClick}
        style={{ color: palette.light_red }}
      >
        <DropBoxIcon src={LogoutIcon} />
        {userId ? (
          <DropBoxButtonText>Log Out</DropBoxButtonText>
        ) : (
          <DropBoxButtonText>Log In</DropBoxButtonText>
        )}
      </DropboxButton>
    </DropBoxContainer>
  );
};

export default ProfileDropbox;
