import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import LogoImage from "../../../assets/images/main_logo_image.svg";
import AlarmIcon from "../../../assets/icons/dashboard/icon-alert.svg";
import SearchIcon from "../../../assets/icons/dashboard/icon-search.svg";
import Hamburger from "hamburger-react";
import Sidebar from "react-sidebar";
import { checkUnreadNoti } from "../../../utils/api/notifications";

const HeaderContainer = styled.div`
  width: 100%;
  height: 48px;
  background-color: ${palette.white};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  box-shadow: 0px 2px 10px #c4c4c440;
  z-index: 100;
  position: fixed;
  top: 0px;
`;

const LogoButton = styled.button`
  width: 92;
  height: 24px;
  margin: auto 0px;
  background-color: transparent;
  border: 0;
`;

const LogoImageContainer = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin: auto 0px;
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-right: 13px;
`;


const SidebarContainer = styled.div`
  width: 300px;
  height: 700px;
  color: black;
  background-color: white;
`;

const SidebarLine = styled.div`
  border-bottom: 1px solid #dddddd;
  width: 100%;
  height: 3px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
`;

const SidebarContext = styled.button`
  width: auto;
  height: 70px;
  border: 0;
  background-color: white;
  //   border-bottom: 1px solid #dddddd;
  text-align: left;
  padding-left: 4%;
  font-size: 18px;
  font-weight: 500;
  font-family: Apple SD Gothic Neo, Spoqa Han Sans Neo, sans-serif;
  font-style: normal;
`;

const SideMent = styled.div`
  font-size: 14px;
  color: #828282;
  margin-bottom: 25px;
`;

const ProfileImageBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;

const HeaderLoginButton2 = styled.a`
  width: 100px;
  text-align: center;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;

  font-family: Apple SD Gothic Neo, Spoqa Han Sans Neo, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;

  color: #f761a6;
`;

const ProfileBox = styled.div`
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 60px;
  padding-bottom: 40px;
`;

const MobileHeader = (props) => {
  const [profileImg, setProfileImg] = useState(props.profileImg);
  const [newNotiExist, setNewNotiExist] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [auth, setAuth] = useState(true);

  useEffect(() => {
    setProfileImg(props.profileImg);
    if(!props.profileImg){
      // 기본 프로필 이미지로 설정
    }
  }, [props.profileImg]);

  useEffect(() => {
    // 새로운 noti가 있는지 체크하는 api 호츌
    (async () => {
      if (localStorage.getItem("userID")) {
        const checkUnreadNotiResult = await checkUnreadNoti(
          localStorage.getItem("userID")
        ).then((data) => {
          setNewNotiExist(data);
          //true : 남은 거 있음
          //false: 남은 거 없음
        });
      }
    })();
  }, []);

  const menuName = ["Log In", "Sign Up"];

  const moveToPage = (index) => {
    if (index == 0) {
      window.location.href = "/signin";
    } else if (index == 1) {
      window.location.href = "/signup";
    } else {
      alert("서비스 준비중입니다.");
    }
  };

  const logoOnClick = () => {
    window.location.href = "/mypage"
  }

  const alarmOnClick = () => {
    window.location.href = "/noti";
  };

  return (
    <HeaderContainer>
      {isOpen ? (
        <Sidebar
          style={{ position: "fixed", zIndex: "5", height: "100%" }}
          sidebar={
            <div style={{ position: "relative", zIndex: "5", height: "100%" }}>
              <SidebarContainer
                style={{ position: "relative", zIndex: "5", height: "100%" }}
              >
                <SidebarLine />
                {menuName.map((value, index) => (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{ paddingTop: "23px", paddingLeft: "30px" }}
                    ></div>
                    <SidebarContext
                      onClick={() => {
                        moveToPage(index);
                      }}
                    >
                      {value}
                    </SidebarContext>
                  </div>
                ))}
              </SidebarContainer>
            </div>
          }
          open={isOpen}
          onSetOpen={setOpen}
          styles={
            ({ sidebar: { background: "white" } },
            { root: { height: "100vh" } })
          }
          pullRight={false}
        ></Sidebar>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Hamburger
        size={16}
        style={{ width: "12px", height: "12px" }}
        toggled={isOpen}
        toggle={setOpen}
      />
      <LogoButton onClick={logoOnClick}>
        <LogoImageContainer src={LogoImage} />
      </LogoButton>
      <ButtonContainer>
        {/* <TextButton>Discover Badges</TextButton>
        <TextButton>My Page</TextButton> */}
        {/* <SearchButton>
          <SearchImage src={SearchIcon} />
        </SearchButton>
        <AlarmButton onClick={alarmOnClick}>
          {newNotiExist ? <AlarmPoint /> : <React.Fragment></React.Fragment>}
          <AlarmImage src={AlarmIcon} />
        </AlarmButton> */}
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default MobileHeader;
