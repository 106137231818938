import styled from "styled-components";
import { COLORS as c } from "../../../utils/styles/colors";
import SizeBox from "../../../components/util/SizeBox";
import UrlImageLoader from "../../../components/util/UrlImageLoader";
import BadgeOwnerDetailDto, {
  OwnerTx,
} from "../../../datas/dtos/BadgeOwnerDetailDto";
import Spacer from "../../../components/util/Spacer";
import ColoredBtn from "../../../components/basic/ColoredBtn";
import Divider from "../../../components/util/Divider";
import Header from "../../../components/global/Header/index";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

const BackBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  width: calc(100% - 190px);
  //max-width: 1240px;
  height: 940px;

  padding: 0px 80px;
  border-radius: 40px;
  background-color: ${c.white};
`;

const TitleText = styled.text`
  color: ${c.black};
  font-weight: 700;
  font-size: 18px;

  margin-bottom: 20px;
`;

const MintedText = styled.text`
  color: ${c.gray};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Id = styled.div`
  display: flex;

  width: 216px;

  font-weight: 600;
  font-size: 12px;
  color: ${c.gray};
`;

const Wallet = styled.div`
  display: flex;

  width: 330px;

  font-weight: 600;
  font-size: 12px;
  color: ${c.gray};
`;

const Date = styled.div`
  display: flex;

  width: 300px;

  font-weight: 600;
  font-size: 12px;
  color: ${c.gray};
`;

function BadgeOwnerDetail() {
  const [profileImg, setProfileImg] = useState(
    localStorage.getItem("profileImg")
  );
  let data = new BadgeOwnerDetailDto(1, "Tele Frenz's Holder", true, "", [
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
    new OwnerTx(
      "CatLover",
      "",
      "0x983d...5edCb6",
      "sun, 31 Oct 2021 20:20:50 GMT"
    ),
  ]);

  return (
    <div className={"f-column a-center j-center"}>
      <Header profileImg={profileImg} />
      <SizeBox h={97} />
      <BackBoard>
        <SizeBox h={85} />
        <div className={"f-row a-center"}>
          <UrlImageLoader w={72} />

          <SizeBox w={20} />
          <div className={"f-row a-center j-start"}>
            <TitleText>{data.title}</TitleText>

            <SizeBox w={20} />
            <MintedText>{data.isMinted ? "민팅됨" : "민팅 안됨"}</MintedText>

            <Spacer />
            <ColoredBtn color={c.black} w={160}>
              Download CSV
            </ColoredBtn>
          </div>
        </div>

        <SizeBox h={81} />
        <div
          className={"f-row a-center"}
          style={{ color: c.gray, fontWeight: 600, fontSize: "12px" }}
        >
          <SizeBox w={76} />
          <Id>user id</Id>

          <Wallet>wallet address</Wallet>

          <Date>minting date</Date>
        </div>

        <SizeBox h={9} />
        <Divider thick={2} c={c.light_gray} />

        {Array.from(
          data.txs.length < 10 ? data.txs : data.txs.slice(0, 10),
          (v) => {
            return <OwnerItem data={v} />;
          }
        )}
      </BackBoard>
    </div>
  );
}

//////

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 48px;

  margin: 4px 0px;
`;

const IdContainer = styled.div`
  display: flex;

  width: 216px;

  font-weight: 600;
  font-size: 16px;
  color: ${c.light_black};
`;

const WalletContainer = styled.div`
  display: flex;

  width: 330px;

  font-weight: 700;
  font-size: 16px;
  color: ${c.gray};
`;

const DateContainer = styled.div`
  display: flex;
  text-align: start;

  width: 300px;

  font-weight: 700;
  font-size: 16px;
  color: ${c.gray};
`;

function OwnerItem({ data }) {
  return (
    <ItemContainer>
      <SizeBox w={24} />
      <SizeBox w={32} h={32}>
        <UrlImageLoader w={32} round={"100%"} />
      </SizeBox>

      <SizeBox w={18} />
      <IdContainer>{data.id}</IdContainer>
      <WalletContainer>{data.walletAdd}</WalletContainer>
      <DateContainer>{data.mintingDate}</DateContainer>
    </ItemContainer>
  );
}

export default BadgeOwnerDetail;
