import vitalik_badge_1 from "./vitalik_badge/vitalik_badge_1.svg";
import vitalik_badge_2 from "./vitalik_badge/vitalik_badge_2.svg";
import vitalik_badge_3 from "./vitalik_badge/vitalik_badge_3.svg";
import vitalik_badge_4 from "./vitalik_badge/vitalik_badge_4.svg";
import vitalik_banner from "./vitalik/vitalik_banner.jpeg";
import vitalik_profile from "./vitalik/vitalik_profile.jpeg";
import vitalik_nft_1 from "./vitalik/nfts/vitalik_nft_1.svg";
import vitalik_nft_2 from "./vitalik/nfts/vitalik_nft_2.svg";
import vitalik_nft_3 from "./vitalik/nfts/vitalik_nft_3.svg";
import vitalik_nft_4 from "./vitalik/nfts/vitalik_nft_4.svg";
import vitalik_nft_5 from "./vitalik/nfts/vitalik_nft_5.svg";
import vitalik_nft_6 from "./vitalik/nfts/vitalik_nft_6.svg";
import vitalik_nft_7 from "./vitalik/nfts/vitalik_nft_7.svg";
import vitalik_nft_8 from "./vitalik/nfts/vitalik_nft_8.svg";
import vitalik_nft_9 from "./vitalik/nfts/vitalik_nft_9.svg";
import vitalik_nft_10 from "./vitalik/nfts/vitalik_nft_10.svg";
import vitalik_nft_11 from "./vitalik/nfts/vitalik_nft_11.svg";
import vitalik_nft_12 from "./vitalik/nfts/vitalik_nft_12.svg";
import vitalik_nft_13 from "./vitalik/nfts/vitalik_nft_13.svg";
import vitalik_nft_14 from "./vitalik/nfts/vitalik_nft_14.svg";
import vitalik_nft_15 from "./vitalik/nfts/vitalik_nft_15.svg";
import vitalik_nft_16 from "./vitalik/nfts/vitalik_nft_16.svg";
import vitalik_nft_17 from "./vitalik/nfts/vitalik_nft_17.svg";
import vitalik_nft_18 from "./vitalik/nfts/vitalik_nft_18.svg";
import vitalik_nft_19 from "./vitalik/nfts/vitalik_nft_19.svg";
import vitalik_nft_20 from "./vitalik/nfts/vitalik_nft_20.svg";
import vitalik_poap_1 from "./vitalik/poaps/vitalik_poap_1.svg";
import vitalik_poap_2 from "./vitalik/poaps/vitalik_poap_2.svg";
import vitalik_poap_3 from "./vitalik/poaps/vitalik_poap_3.svg";
import vitalik_poap_4 from "./vitalik/poaps/vitalik_poap_4.svg";
import vitalik_poap_5 from "./vitalik/poaps/vitalik_poap_5.svg";
import vitalik_poap_6 from "./vitalik/poaps/vitalik_poap_6.svg";
import vitalik_poap_7 from "./vitalik/poaps/vitalik_poap_7.svg";
import vitalik_poap_8 from "./vitalik/poaps/vitalik_poap_8.svg";
import vitalik_poap_9 from "./vitalik/poaps/vitalik_poap_9.svg";
import vitalik_poap_10 from "./vitalik/poaps/vitalik_poap_10.svg";
import vitalik_poap_11 from "./vitalik/poaps/vitalik_poap_11.svg";
import vitalik_poap_12 from "./vitalik/poaps/vitalik_poap_12.svg";
import vitalik_poap_13 from "./vitalik/poaps/vitalik_poap_13.svg";
import vitalik_poap_14 from "./vitalik/poaps/vitalik_poap_14.svg";
import vitalik_poap_15 from "./vitalik/poaps/vitalik_poap_15.svg";
import vitalik_poap_16 from "./vitalik/poaps/vitalik_poap_16.svg";
import vitalik_poap_17 from "./vitalik/poaps/vitalik_poap_17.svg";
import vitalik_poap_18 from "./vitalik/poaps/vitalik_poap_18.svg";
import vitalik_poap_19 from "./vitalik/poaps/vitalik_poap_19.svg";
import vitalik_poap_20 from "./vitalik/poaps/vitalik_poap_20.svg";
import jangwon_banner from "./jangwon/jangwon_banner.jpeg";
import jangwon_profile from "./jangwon/jangwon_profile.jpeg";
import dudu_image from "../images/dudu_image.svg";
import coco_image from "../images/coco_image.svg";
import coco_badge_1 from "./coco_badge/coco_badge_1.svg";
import coco_badge_2 from "./coco_badge/coco_badge_2.svg";
import coco_badge_3 from "./coco_badge/coco_badge_3.svg";
import coco_badge_4 from "./coco_badge/coco_badge_4.svg";
import dudu_badge_1 from "./dudu_badge/dudu_badge_1.svg";
import dudu_badge_2 from "./dudu_badge/dudu_badge_2.svg";
import dudu_badge_3 from "./dudu_badge/dudu_badge_3.svg";
import dudu_badge_4 from "./dudu_badge/dudu_badge_4.svg";
import jangwon_nft_1 from "./jangwon/nfts/jangwon_nft_1.svg";
import jangwon_nft_2 from "./jangwon/nfts/jangwon_nft_2.svg";
import jangwon_nft_3 from "./jangwon/nfts/jangwon_nft_3.svg";
import jangwon_nft_4 from "./jangwon/nfts/jangwon_nft_4.svg";
import jangwon_nft_5 from "./jangwon/nfts/jangwon_nft_5.svg";
import jangwon_nft_6 from "./jangwon/nfts/jangwon_nft_6.svg";
import jangwon_nft_7 from "./jangwon/nfts/jangwon_nft_7.svg";
import jangwon_nft_8 from "./jangwon/nfts/jangwon_nft_8.svg";
import jangwon_nft_9 from "./jangwon/nfts/jangwon_nft_9.svg";
import jangwon_nft_10 from "./jangwon/nfts/jangwon_nft_10.svg";
import jangwon_nft_11 from "./jangwon/nfts/jangwon_nft_11.svg";
import jangwon_nft_12 from "./jangwon/nfts/jangwon_nft_12.svg";
import jangwon_nft_13 from "./jangwon/nfts/jangwon_nft_13.svg";
import jangwon_nft_14 from "./jangwon/nfts/jangwon_nft_14.svg";
import jangwon_nft_16 from "./jangwon/nfts/jangwon_nft_16.svg";
import jangwon_badge_1 from "./jangwon_badge/jangwon_badge_1.svg";
import jangwon_badge_2 from "./jangwon_badge/jangwon_badge_2.svg";
import jangwon_badge_3 from "./jangwon_badge/jangwon_badge_3.svg";
import jangwon_badge_4 from "./jangwon_badge/jangwon_badge_4.svg";
import user_nft_01 from "./user_nfts/user_nft_01.svg";
import user_nft_02 from "./user_nfts/user_nft_02.svg";
import user_nft_03 from "./user_nfts/user_nft_03.svg";
import user_nft_04 from "./user_nfts/user_nft_04.svg";
import user_nft_05 from "./user_nfts/user_nft_05.svg";
import user_nft_06 from "./user_nfts/user_nft_06.svg";
import user_nft_07 from "./user_nfts/user_nft_07.svg";
import user_nft_08 from "./user_nfts/user_nft_08.svg";
import user_poap_01 from "./user_poaps/user_poap_01.svg";
import user_poap_02 from "./user_poaps/user_poap_02.svg";
import user_poap_03 from "./user_poaps/user_poap_03.svg";
import user_poap_04 from "./user_poaps/user_poap_04.svg";
import user_poap_05 from "./user_poaps/user_poap_05.svg";
import user_poap_06 from "./user_poaps/user_poap_06.svg";
import user_poap_07 from "./user_poaps/user_poap_07.svg";
import user_poap_08 from "./user_poaps/user_poap_08.svg";
import user_badge_1 from "./user_badge/user_badge_1.svg";
import user_badge_2 from "./user_badge/user_badge_2.svg";
import main_banner_image from "../images/main_banner_image.svg";
import main_example_image from "../images/main_example_image.svg";

const UserDummyData = [
  {
    friendCount: 1,
    user: {
      id: "vitalikbuterin",
      profileImage: vitalik_profile,
      introduction: "Ethereum. Abolish daylight savings time and leap seconds",
      url: "/@vitalikbuterin",
      hits: 0,
      todayHits: 0,
      // "createdAt": 1652153620000,
      createdAt: "2022-05-10T03:33:40.000+00:00",
      nftRefreshLeft: 10,
      backImage: vitalik_banner,
      nickname: "vitalik.eth",
      index: 3,
      wallets: [
        {
          index: 92,
          user: "vitalikbuterin",
          walletAddress: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045",
          walletType: "Metamask",
          walletIcon:
            "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
          createdAt: "2022-05-10T04:07:36.000+00:00",
        },
      ],
      social: {
        userId: "vitalikbuterin",
        twitter: "https://twitter.com/VitalikButerin",
        facebook: "https://www.facebook.com/",
        discord: "https://www.discord.gg/",
        link: "https://vitalik.ca/",
      },
    },
    badges: [
      {
        badgeIndex: 1,
        badgeWalletIndex: 1,
        address: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Ethereum Founder",
        image: vitalik_badge_1,
        tag: "Reminder: Ethereum is neutral, but I am not.",
      },
      {
        badgeIndex: 2,
        badgeWalletIndex: 1,
        address: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "WoW Lover",
        image: vitalik_badge_2,
        tag: "World of Warcraft",
      },
      {
        badgeIndex: 3,
        badgeWalletIndex: 1,
        address: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Endless Questioner",
        image: vitalik_badge_3,
        tag: "full of question marks",
      },
      {
        badgeIndex: 4,
        badgeWalletIndex: 1,
        address: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Soulbound",
        image: vitalik_badge_4,
        tag: "",
      },
      {
        badgeIndex: 5,
        badgeWalletIndex: 1,
        address: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe user",
        image: user_badge_1,
        tag: "",
      },
      {
        badgeIndex: 6,
        badgeWalletIndex: 1,
        address: "0xd8da6bf26964af9d7eed9e03e53415d37aa96045",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe earlybird",
        image: user_badge_2,
        tag: "",
      },
    ],
    comments: [
      {
        userId: "dudu",
        customName: "dudu",
        nickName: "dudu",
        comment: "WOW",
        hidden: false,
        image: dudu_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "coco",
        customName: "coco",
        nickName: "coco",
        comment: "I became a discord bot thanks to you!",
        hidden: false,
        image: coco_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "cfers",
        customName: "cfers",
        nickName: "cfers",
        comment: "ethereum save the world, cfers save our lives.",
        hidden: false,
        image: jangwon_profile,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
    ],
    nfts: [
      {
        title: "Bored Ape Yacht Club Degenerates - YUNG ADONIS #2254",
        image: vitalik_nft_1,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Flower Shoppe by Photosynthesis",
        image: vitalik_nft_2,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1403",
        image: vitalik_nft_3,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1402",
        image: vitalik_nft_4,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1401",
        image: vitalik_nft_5,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1400",
        image: vitalik_nft_6,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1399",
        image: vitalik_nft_7,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1398",
        image: vitalik_nft_8,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1397",
        image: vitalik_nft_9,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1396",
        image: vitalik_nft_10,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1395",
        image: vitalik_nft_11,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "MoonHeads ZooMers #1394",
        image: vitalik_nft_12,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Gutter Punks Flyer - VOX",
        image: vitalik_nft_13,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Painting",
        image: vitalik_nft_14,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Gutter Punks - tubby cats",
        image: vitalik_nft_15,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Green Heart",
        image: vitalik_nft_16,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Whale Boss 2022",
        image: vitalik_nft_17,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "The Blinkless: Fully Dilated #386",
        image: vitalik_nft_18,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Monkey Boss#22",
        image: vitalik_nft_19,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "may4th Popagandashanghai",
        image: vitalik_nft_20,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
    poaps: [
      {
        title: "Toucan Office Hours - Season 1 Kickoff",
        image: vitalik_poap_1,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Picture This on NFT Photography Episode 6",
        image: vitalik_poap_2,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "The launch of Up Heal DAO website",
        image: vitalik_poap_3,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "KCWeb3 Hack Night 05/03/22",
        image: vitalik_poap_4,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "WANGMI RADIO OG'S",
        image: vitalik_poap_5,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "blr dev cartel genesis",
        image: vitalik_poap_6,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "XiangQi Club -Auxiliary Prime Minister",
        image: vitalik_poap_7,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "XiangQi Club -Marshal Han",
        image: vitalik_poap_8,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Legends of Lolla #3",
        image: vitalik_poap_9,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "XiangQi Club -King of Chu",
        image: vitalik_poap_10,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "web3con",
        image: vitalik_poap_11,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Stoner Cats Valentines Day 2022 POAP",
        image: vitalik_poap_12,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "ETHDenver",
        image: vitalik_poap_13,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Chinese New Year with Vitalik",
        image: vitalik_poap_14,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "ETHRank.io Season One",
        image: vitalik_poap_15,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Stoner Cats 2022 POAP",
        image: vitalik_poap_16,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "#LetsCyberConnect SZN 1 &amp;quot; Web3 KOL &amp;quot;",
        image: vitalik_poap_17,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Token Not Needed OG (2021)",
        image: vitalik_poap_18,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "POAP Test 123 -t",
        image: vitalik_poap_19,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "12 Days of AlchemixMas. Day02",
        image: vitalik_poap_20,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
  },
  {
    friendCount: 1,
    user: {
      id: "cocoisperson",
      profileImage: coco_image,
      introduction:
        "gm. I'm Coco and My favorite job is MEPE discord bot. Luv yall❤️",
      url: "/@cocoisperson",
      hits: 0,
      todayHits: 0,
      // "createdAt": 1652153620000,
      createdAt: "2022-05-10T03:33:40.000+00:00",
      nftRefreshLeft: 10,
      backImage: main_banner_image,
      nickname: "Coco Nut",
      index: 3,
      wallets: [
        {
          index: 92,
          user: "cocoisperson",
          walletAddress: "",
          walletType: "Metamask",
          walletIcon:
            "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
          createdAt: "2022-05-10T04:07:36.000+00:00",
        },
      ],
      social: {
        userId: "cocoisperson",
        twitter: "https://www.twitter.com/cocoisperson",
        facebook: "https://www.facebook.com/",
        discord: "https://www.discord.gg/",
        link: "/",
      },
    },
    badges: [
      {
        badgeIndex: 1,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Coco's birthday",
        image: coco_badge_1,
        tag: " Thank you my friends :D",
      },
      {
        badgeIndex: 2,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Discord bot",
        image: coco_badge_2,
        tag: "My first job on web3 !!!",
      },
      {
        badgeIndex: 3,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Metaverse Flower Festa",
        image: coco_badge_3,
        tag: "@mff2022",
      },
      {
        badgeIndex: 4,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "bestie badge",
        image: coco_badge_4,
        tag: "Dudu and me :)",
      },
      {
        badgeIndex: 5,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe user",
        image: user_badge_1,
        tag: "Gotta make my dashboard pretty",
      },
      {
        badgeIndex: 6,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe earlybird",
        image: user_badge_2,
        tag: "precious💎",
      },
    ],
    comments: [
      {
        userId: "cfers",
        customName: "cfers",
        nickName: "cfers",
        comment: "Hey, come to my home. Kitty just wait for you",
        hidden: false,
        image: jangwon_profile,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "coco",
        customName: "coco",
        nickName: "coco",
        comment: "Hi! Have a nice day Dudu :)❤️",
        hidden: false,
        image: coco_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "oliver styles",
        customName: "oliver styles",
        nickName: "oliver styles",
        comment: "I luv yr new cocktail recipe🍹",
        hidden: false,
        image: main_example_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
    ],
    nfts: [
      {
        title: "",
        image: user_nft_01,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_02,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_03,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_04,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_05,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_06,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_07,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_08,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
    poaps: [
      {
        title: "",
        image: user_poap_01,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_02,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_03,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_04,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_05,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_06,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_07,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_08,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
  },
  {
    friendCount: 1,
    user: {
      id: "dudutheworld",
      profileImage: dudu_image,
      introduction: "just du it. Owner&Bartener of Du-st Club.",
      url: "/@dudutheworld",
      hits: 0,
      todayHits: 0,
      // "createdAt": 1652153620000,
      createdAt: "2022-05-10T03:33:40.000+00:00",
      nftRefreshLeft: 10,
      backImage: main_banner_image,
      nickname: "Dudu shot",
      index: 3,
      wallets: [
        {
          index: 92,
          user: "dudutheworld",
          walletAddress: "",
          walletType: "Metamask",
          walletIcon:
            "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
          createdAt: "2022-05-10T04:07:36.000+00:00",
        },
      ],
      social: {
        userId: "dudutheworld",
        twitter: "https://www.twitter.com/dudutheworld",
        facebook: "https://www.facebook.com/",
        discord: "https://www.discord.gg/",
        link: "/",
      },
    },
    badges: [
      {
        badgeIndex: 1,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "AHLA Bartender certification",
        image: dudu_badge_1,
        tag: "",
      },
      {
        badgeIndex: 2,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "University of Bartending",
        image: dudu_badge_2,
        tag: "",
      },
      {
        badgeIndex: 3,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Owner of Du-st Club",
        image: dudu_badge_3,
        tag: "actually bartender",
      },
      {
        badgeIndex: 4,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Oliver, friend of mine",
        image: dudu_badge_4,
        tag: "hey",
      },
      {
        badgeIndex: 5,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe user",
        image: user_badge_1,
        tag: "",
      },
      {
        badgeIndex: 6,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe earlybird",
        image: user_badge_2,
        tag: "nice",
      },
    ],
    comments: [
      {
        userId: "dudu",
        customName: "dudu",
        nickName: "dudu",
        comment: "Malibu's Ingredient.",
        hidden: false,
        image: dudu_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "oliver styles",
        customName: "oliver styles",
        nickName: "oliver styles",
        comment: "Thanks Coco, You're the only one saves me from web3 discord.",
        hidden: false,
        image: main_example_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "passenger",
        customName: "passenger",
        nickName: "passenger",
        comment: "Best discord bot ever.",
        hidden: false,
        image: jangwon_profile,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
    ],
    nfts: [
      {
        title: "",
        image: user_nft_01,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_02,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_03,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_04,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_05,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_06,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_07,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_nft_08,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
    poaps: [
      {
        title: "",
        image: user_poap_01,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_02,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_03,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_04,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_05,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_06,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_07,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_08,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
  },
  {
    friendCount: 1,
    user: {
      id: "jangwonleebm",
      profileImage: jangwon_profile,
      introduction:
        "CEO & Founder of BeyondMusic Inc, Contents Technologies Inc. NFT Collector. Forbes Asia 30 under 30.",
      url: "/@jangwonleebm",
      hits: 0,
      todayHits: 0,
      // "createdAt": 1652153620000,
      createdAt: "2022-05-10T03:33:40.000+00:00",
      nftRefreshLeft: 10,
      backImage: jangwon_banner,
      nickname: "jangwon.eth",
      index: 3,
      wallets: [
        {
          index: 92,
          user: "jangwonleebm",
          walletAddress: "0xb5444e964e1516a9907ab2c9ac2d20fffb46a15b",
          walletType: "Metamask",
          walletIcon:
            "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
          createdAt: "2022-05-10T04:07:36.000+00:00",
        },
      ],
      social: {
        userId: "jangwonleebm",
        twitter: "https://twitter.com/JangwonLeeBM",
        facebook: "https://www.facebook.com/",
        discord: "https://www.discord.gg/",
        link: "/",
      },
    },
    badges: [
      {
        badgeIndex: 1,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "influencer",
        image: jangwon_badge_1,
        tag: "Own the culture.",
      },
      {
        badgeIndex: 2,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Media NFT Holder",
        image: jangwon_badge_2,
        tag: "It's a new medium. A movement.",
      },
      {
        badgeIndex: 3,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "early Music NFT holder",
        image: jangwon_badge_3,
        tag: "discovering music again.",
      },
      {
        badgeIndex: 4,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "Bankless DAO Member",
        image: jangwon_badge_4,
        tag: "Bankless Movement",
      },
      {
        badgeIndex: 5,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe user",
        image: user_badge_1,
        tag: "",
      },
      {
        badgeIndex: 6,
        badgeWalletIndex: 1,
        address: "",
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        badgeTitle: "mepe earlybird",
        image: user_badge_2,
        tag: "",
      },
    ],
    comments: [
      {
        userId: "dudu",
        customName: "dudu",
        nickName: "dudu",
        comment: "plz buy my bar🍸",
        hidden: false,
        image: dudu_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "coco",
        customName: "coco",
        nickName: "coco",
        comment: "your music NFTs are coooooooool",
        hidden: false,
        image: coco_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
      {
        userId: "oliver",
        customName: "oliver",
        nickName: "oliver",
        comment: "🎧🎵🎶",
        hidden: false,
        image: main_example_image,
        createdAt: "2022-04-01T13:24:01.000+00:00",
      },
    ],
    nfts: [
      {
        title: "Frost Transponder (LNRZ) 100/100",
        image: jangwon_nft_1,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Frost Transponder (LNRZ) 99/100",
        image: jangwon_nft_2,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Gutter Punks Flyer - ENS",
        image: jangwon_nft_3,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "msft Genesis Ticket",
        image: jangwon_nft_4,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Ether (ft. MNDR) #57",
        image: jangwon_nft_5,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Touchdown ft. TK #9",
        image: jangwon_nft_6,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Buried Alive #30",
        image: jangwon_nft_7,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Take / Back #24",
        image: jangwon_nft_8,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "wherever you go #19",
        image: jangwon_nft_9,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "New Dawn #14",
        image: jangwon_nft_10,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "ULTRA 1 #18",
        image: jangwon_nft_11,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Ensemble Mix #123",
        image: jangwon_nft_12,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "about you (with Nightly) #61",
        image: jangwon_nft_13,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "Infinite #35",
        image: jangwon_nft_14,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "21",
        image: jangwon_nft_16,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
    poaps: [
      {
        title: "",
        image: user_poap_01,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_02,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_03,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_04,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_05,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_06,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_07,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
      {
        title: "",
        image: user_poap_08,
        hidden: false,
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
      },
    ],
  },
];

export default UserDummyData;
