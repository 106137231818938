import Caver from "caver-js";
import { create } from "ipfs-http-client";
import Web3 from "web3";

const getEnv = (env) => {
  const value = process.env[env];

  if (typeof value === "undefined") {
    throw new Error("ENV NOT SET");
  }

  return value;
};

const projectId = getEnv("REACT_APP_IPFS_ID");
const projectSecret = getEnv("REACT_APP_IPFS_SECRET");

const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});
// const web3 = new Web3(new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/f9cb229a18104764a359db6ac3e7b4c2'));
const Factory = require("../datas/evmos_contracts_dev/BWLBadgeFactory.json");
const KlaytnFactory = require("../datas/contracts_klaytn/BWLBadgeFactory.json");

export const WEB3_ERRORS = {
  SUCCESS: "SUCCESS",
  NO_DATA: "NO_DATA",
  NO_WALLET: "NO_WALLET",
  NET_ERROR: "NET_ERROR",
  REJECTED: "REJECTED",
  IPFS_ERROR: "IPFS_ERROR",
  META_ERROR: "META_ERROR",
  MINT_ERROR: "MINT_ERROR",
  NO_FILE: "NO_FILE",
};

class ActionAPI {
  getContract = (web3) => {
    const contract = new web3.eth.Contract(
      Factory.abi,
      Factory.networks['9000'].address
    );

    return contract;
  };

  getContractKlaytn = (web3) => {
    const contract = new web3.klay.Contract(
      KlaytnFactory.abi,
      KlaytnFactory.networks['1001'].address
    );

    return contract;
  };

  connect = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        const netId = await window.ethereum.request({
          method: "net_version",
        });

        if (netId === "9000") {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const contract = this.getContract(web3);

          return {
            account: accounts[0],
            contract,
            web3,
          };
        } else {
          return WEB3_ERRORS.NET_ERROR;
        }
      } catch (e) {
        return WEB3_ERRORS.REJECTED;
      }
    } else {
      return WEB3_ERRORS.NO_WALLET;
    }
  };

  klaytnConnect = async () => {
    if (window.klaytn) {
      const caver = new Caver(window.klaytn);
      try {
        const netId = "1001";
        const isEnable = await window.klaytn.enable(netId);
        if (isEnable) {
          const account = window.klaytn.selectedAddress;
          const contract = this.getContractKlaytn(caver);
          return {
            account,
            web3: caver,
            contract
          };
        } else {
          return WEB3_ERRORS.NET_ERROR;
        }
      } catch (e) {
        console.log("KLAYTN:Connect ERROR");
        console.log(e);
        return WEB3_ERRORS.REJECTED;
      }
    } else {
      return WEB3_ERRORS.NO_WALLET;
    }
  };

  uploadImgIpfs = async (file) => {
    let resultPath = "";
    if (typeof file !== "undefined") {
      try {
        const result = await client.add(file);
        resultPath = `https://ipfs.infura.io/ipfs/${result.path}`;
      } catch (e) {
        console.log("ipfs image upload error: ", e);
        return WEB3_ERRORS.IPFS_ERROR;
      }
    } else {
      return WEB3_ERRORS.NO_FILE;
    }

    return resultPath;
  };

  uploadMetaIpfs = async (name, description, externalLink, image, category) => {
    let resultPath = "";
    try {
      const result = await client.add(
        JSON.stringify({
          name,
          description,
          external_url: externalLink,
          image,
          attributes: category,
        })
      );

      resultPath = `https://ipfs.infura.io/ipfs/${result.path}`;
    } catch (e) {
      console.log("ipfs metadata upload error: ", e);
      return WEB3_ERRORS.META_ERROR;
    }

    return resultPath;
  };

  mintBadge = async (contract, account, id, amount, uri, to) => {
    let result = true;
    try {
      const tx = await contract.methods
        .mintWithUri(to, id, amount, uri)
        .send({ from: account, gas: 1000000 }, function (err, tx) {
          console.log(err);
          if (err) result = false;
        });
        console.log(tx);
    } catch (e) {
      console.log(e);
      result = false;
    }

    return result;
  };

  createBadge = async (
    contract,
    account,
    amount,
    name,
    description,
    externalLink,
    file,
    category,
    to
  ) => {
    try {
      const image = await this.uploadImgIpfs(file);
      console.log("IMG :", image);
      const metadataURI = await this.uploadMetaIpfs(
        name,
        description,
        externalLink,
        image,
        category
      );
      console.log("META :", metadataURI);

      const id = await this.getTotal(contract);
      console.log("ID :", id);
      const mintResult = await this.mintBadge(
        contract,
        account,
        id + 1,
        amount,
        metadataURI,
        to
      );
      console.log("RESULT :", mintResult);

      return mintResult ? WEB3_ERRORS.SUCCESS : WEB3_ERRORS.MINT_ERROR;
    } catch (e) {
      console.log("ipfs metadata upload error: ", e);
      return WEB3_ERRORS.MINT_ERROR;
    }
  };

  klaytnCreateBadge = async (
    contract,
    account,
    amount,
    name,
    description,
    externalLink,
    file,
    category,
    to
  ) => {
    try {
      const image = await this.uploadImgIpfs(file);
      console.log("IMG :", image);
      const metadataURI = await this.uploadMetaIpfs(
        name,
        description,
        externalLink,
        image,
        category
      );
      console.log("META :", metadataURI);

      const id = await this.getTotal(contract);
      console.log("ID :", id);
      const mintResult = await this.mintBadge(
        contract,
        account,
        id + 1,
        amount,
        metadataURI,
        to
      );
      console.log("RESULT :", mintResult);

      return mintResult ? WEB3_ERRORS.SUCCESS : WEB3_ERRORS.MINT_ERROR;
    } catch (e) {
      console.log("ipfs metadata upload error: ", e);
      return WEB3_ERRORS.MINT_ERROR;
    }
  };

  // Utils
  getTotal = async (contract) => {
    const id = parseInt(await contract.methods.totalSupply().call());
    return id;
  };
}

export default ActionAPI;
