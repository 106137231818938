const Chainlist = [
  {
    pageProps: {
      chain: {
        name: "Ethereum Mainnet",
        chain: "ETH",
        icon: "ethereum",
        rpc: [
          "https://api.mycryptoapi.com/eth",
          "https://cloudflare-eth.com",
          "https://rpc.flashbots.net",
          "https://eth-mainnet.gateway.pokt.network/v1/5f3453978e354ab992c4da79",
          "https://mainnet-nethermind.blockscout.com",
          "https://nodes.mewapi.io/rpc/eth",
          "https://main-rpc.linkpool.io",
          "https://mainnet.eth.cloud.ava.do",
          "https://ethereumnodelight.app.runonflux.io",
          "https://rpc.ankr.com/eth",
          "https://eth-rpc.gateway.pokt.network",
          "https://main-light.eth.linkpool.io",
          "https://eth-mainnet.public.blastapi.io",
          "http://18.211.207.34:8545",
          "https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7",
          "wss://eth-mainnet.nodereal.io/ws/v1/1659dfb40aa24bbb8153a677b98064d7",
          "https://api.bitstack.com/v1/wNFxbiJyQsSeLrX8RRCHi7NpRxrlErZk/DjShIqLishPCTB9HiMkPHXjUM9CNM9Na/ETH/mainnet",
          "https://eth-mainnet.unifra.io/v1/d157f0245608423091f5b4b9c8e2103e",
        ],
        faucets: [],
        nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
        infoURL: "https://ethereum.org",
        shortName: "eth",
        chainId: 1,
        networkId: 1,
        slip44: 60,
        ens: { registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e" },
        explorers: [
          {
            name: "etherscan",
            url: "https://etherscan.io",
            standard: "EIP3091",
          },
        ],
        tvl: 51446750666.4789,
        chainSlug: "ethereum",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Görli",
        title: "Ethereum Testnet Görli",
        chain: "ETH",
        rpc: [
          "https://rpc.goerli.mudit.blog",
          "https://eth-goerli.g.alchemy.com/v2/demo",
          "https://rpc.ankr.com/eth_goerli",
          "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          "https://eth-goerli.public.blastapi.io",
        ],
        faucets: [
          "http://fauceth.komputing.org?chain=5&address=${ADDRESS}",
          "https://goerli-faucet.slock.it?address=${ADDRESS}",
          "https://faucet.goerli.mudit.blog",
        ],
        nativeCurrency: { name: "Görli Ether", symbol: "ETH", decimals: 18 },
        infoURL: "https://goerli.net/#about",
        shortName: "gor",
        chainId: 5,
        networkId: 5,
        ens: { registry: "0x112234455c3a32fd11230c42e7bccd4a84e02010" },
        explorers: [
          {
            name: "etherscan-goerli",
            url: "https://goerli.etherscan.io",
            standard: "EIP3091",
          },
        ],
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Rinkeby",
        title: "Ethereum Testnet Rinkeby",
        chain: "ETH",
        rpc: [
          "https://rpc.ankr.com/eth_rinkeby",
          "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        ],
        faucets: [
          "http://fauceth.komputing.org?chain=4&address=${ADDRESS}",
          "https://faucet.rinkeby.io",
        ],
        nativeCurrency: { name: "Rinkeby Ether", symbol: "ETH", decimals: 18 },
        infoURL: "https://www.rinkeby.io",
        shortName: "rin",
        chainId: 4,
        networkId: 4,
        ens: { registry: "0xe7410170f87102df0055eb195163a03b7f2bff4a" },
        explorers: [
          {
            name: "etherscan-rinkeby",
            url: "https://rinkeby.etherscan.io",
            standard: "EIP3091",
          },
        ],
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Binance Smart Chain Mainnet",
        chain: "BSC",
        rpc: [
          "https://bsc-dataseed1.binance.org",
          "https://bsc-dataseed2.binance.org",
          "https://bsc-dataseed3.binance.org",
          "https://bsc-dataseed4.binance.org",
          "https://bsc-dataseed1.defibit.io",
          "https://bsc-dataseed2.defibit.io",
          "https://bsc-dataseed3.defibit.io",
          "https://bsc-dataseed4.defibit.io",
          "https://bsc-dataseed1.ninicoin.io",
          "https://bsc-dataseed2.ninicoin.io",
          "https://bsc-dataseed3.ninicoin.io",
          "https://bsc-dataseed4.ninicoin.io",
          "wss://bsc-ws-node.nariox.org",
          "https://bsc-dataseed.binance.org",
          "https://bsc-mainnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3",
          "wss://bsc-mainnet.nodereal.io/ws/v1/64a9df0874fb4a93b9d0a3849de012d3",
          "https://rpc.ankr.com/bsc",
          "https://bscrpc.com",
          "https://bsc.mytokenpocket.vip",
          "https://binance.nodereal.io",
          "https://rpc-bsc.bnb48.club",
          "https://bscapi.terminet.io/rpc",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        nativeCurrency: {
          name: "Binance Chain Native Token",
          symbol: "BNB",
          decimals: 18,
        },
        infoURL: "https://www.binance.org",
        shortName: "bnb",
        chainId: 56,
        networkId: 56,
        slip44: 714,
        explorers: [
          { name: "bscscan", url: "https://bscscan.com", standard: "EIP3091" },
        ],
        tvl: 6381549769.234353,
        chainSlug: "binance",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Avalanche C-Chain",
        chain: "AVAX",
        rpc: [
          "https://api.avax.network/ext/bc/C/rpc",
          "https://rpc.ankr.com/avalanche",
          "https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc",
          "https://avalancheapi.terminet.io/ext/bc/C/rpc",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        nativeCurrency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
        infoURL: "https://www.avax.network/",
        shortName: "avax",
        chainId: 43114,
        networkId: 43114,
        slip44: 9005,
        explorers: [
          {
            name: "snowtrace",
            url: "https://snowtrace.io",
            standard: "EIP3091",
          },
        ],
        tvl: 1908250226.4854922,
        chainSlug: "avalanche",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Polygon Mainnet",
        chain: "Polygon",
        rpc: [
          "https://polygon-rpc.com",
          "https://rpc-mainnet.matic.network",
          "https://matic-mainnet.chainstacklabs.com",
          "https://rpc-mainnet.maticvigil.com",
          "https://rpc-mainnet.matic.quiknode.pro",
          "https://matic-mainnet-full-rpc.bwarelabs.com",
          "https://matic-mainnet-archive-rpc.bwarelabs.com",
          "https://poly-rpc.gateway.pokt.network",
          "https://rpc.ankr.com/polygon",
          "https://polygon-mainnet.public.blastapi.io",
          "https://polygonapi.terminet.io/rpc",
        ],
        faucets: [],
        nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
        infoURL: "https://polygon.technology/",
        shortName: "matic",
        chainId: 137,
        networkId: 137,
        slip44: 966,
        explorers: [
          {
            name: "polygonscan",
            url: "https://polygonscan.com",
            standard: "EIP3091",
          },
        ],
        tvl: 1638587533.7295463,
        chainSlug: "polygon",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Arbitrum One",
        chainId: 42161,
        shortName: "arb1",
        chain: "ETH",
        networkId: 42161,
        nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
        rpc: [
          "https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}",
          "https://arb1.arbitrum.io/rpc",
          "https://rpc.ankr.com/arbitrum",
          "https://arbitrum-mainnet.infura.io/v3/${INFURA_API_KEY}",
        ],
        faucets: [],
        explorers: [
          { name: "Arbiscan", url: "https://arbiscan.io", standard: "EIP3091" },
          {
            name: "Arbitrum Explorer",
            url: "https://explorer.arbitrum.io",
            standard: "EIP3091",
          },
        ],
        infoURL: "https://arbitrum.io",
        parent: {
          type: "L2",
          chain: "eip155-1",
          bridges: [{ url: "https://bridge.arbitrum.io" }],
        },
        tvl: 983809978.1672049,
        chainSlug: "arbitrum",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Optimism",
        chain: "ETH",
        rpc: [
          "https://mainnet.optimism.io",
          "https://optimism-mainnet.public.blastapi.io",
          "https://rpc.ankr.com/optimism",
        ],
        faucets: [],
        nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
        infoURL: "https://optimism.io",
        shortName: "oeth",
        chainId: 10,
        networkId: 10,
        explorers: [
          {
            name: "etherscan",
            url: "https://optimistic.etherscan.io",
            standard: "EIP3091",
          },
        ],
        tvl: 952123958.9250797,
        chainSlug: "optimism",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Cronos Mainnet Beta",
        chain: "CRO",
        rpc: [
          "https://evm.cronos.org",
          "https://cronos-rpc.heavenswail.one",
          "https://cronosrpc-1.xstaking.sg",
          "https://cronos-rpc.elk.finance",
        ],
        faucets: [],
        nativeCurrency: { name: "Cronos", symbol: "CRO", decimals: 18 },
        infoURL: "https://cronos.org/",
        shortName: "cro",
        chainId: 25,
        networkId: 25,
        explorers: [
          {
            name: "Cronos Explorer",
            url: "https://cronos.org/explorer",
            standard: "none",
          },
        ],
        tvl: 786849291.6880041,
        chainSlug: "cronos",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Fantom Opera",
        chain: "FTM",
        rpc: [
          "https://rpc.ftm.tools",
          "https://fantom-mainnet.gateway.pokt.network/v1/lb/62759259ea1b320039c9e7ac",
          "https://rpc.ankr.com/fantom",
          "https://rpc.fantom.network",
          "https://rpc2.fantom.network",
          "https://rpc3.fantom.network",
          "https://rpcapi.fantom.network",
          "https://fantom-mainnet.public.blastapi.io",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        nativeCurrency: { name: "Fantom", symbol: "FTM", decimals: 18 },
        infoURL: "https://fantom.foundation",
        shortName: "ftm",
        chainId: 250,
        networkId: 250,
        icon: "fantom",
        explorers: [
          {
            name: "ftmscan",
            url: "https://ftmscan.com",
            icon: "ftmscan",
            standard: "EIP3091",
          },
        ],
        tvl: 674682765.5593956,
        chainSlug: "fantom",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Klaytn Mainnet Cypress",
        chain: "KLAY",
        rpc: [
          "https://public-node-api.klaytnapi.com/v1/cypress",
          "https://klaytn01.fandom.finance",
          "https://klaytn02.fandom.finance",
          "https://klaytn03.fandom.finance",
          "https://klaytn04.fandom.finance",
          "https://klaytn05.fandom.finance",
          "https://cypress.fandom.finance/archive",
        ],
        faucets: [],
        nativeCurrency: { name: "KLAY", symbol: "KLAY", decimals: 18 },
        infoURL: "https://www.klaytn.com/",
        shortName: "Cypress",
        chainId: 8217,
        networkId: 8217,
        slip44: 8217,
        explorers: [
          {
            name: "Klaytnscope",
            url: "https://scope.klaytn.com",
            standard: "none",
          },
        ],
        tvl: 364774063.18370044,
        chainSlug: "klaytn",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Klaytn Testnet Baobab",
        chain: "KLAY",
        rpc: ["https://api.baobab.klaytn.net:8651"],
        faucets: [],
        nativeCurrency: { name: "KLAY", symbol: "KLAY", decimals: 18 },
        infoURL: "https://www.klaytn.com/",
        shortName: "Cypress",
        chainId: 1001,
        networkId: 1001,
        slip44: 1001,
        explorers: [
          {
            name: "Klaytnscope",
            url: "https://baobab.scope.klaytn.com",
            standard: "none",
          },
        ],
        tvl: 364774063.18370044,
        chainSlug: "klaytn",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Kava EVM",
        chain: "KAVA",
        rpc: [
          "https://evm.kava.io",
          "https://evm2.kava.io",
          "wss://wevm.kava.io",
          "wss://wevm2.kava.io",
        ],
        faucets: [],
        nativeCurrency: { name: "Kava", symbol: "KAVA", decimals: 18 },
        infoURL: "https://www.kava.io",
        shortName: "kava",
        chainId: 2222,
        networkId: 2222,
        icon: "kava",
        explorers: [
          {
            name: "Kava EVM Explorer",
            url: "https://explorer.kava.io",
            standard: "EIP3091",
            icon: "kava",
          },
        ],
        tvl: 268310295.52171177,
        chainSlug: "kava",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Gnosis",
        chain: "GNO",
        icon: "gnosis",
        rpc: [
          "https://rpc.gnosischain.com",
          "https://rpc.ankr.com/gnosis",
          "https://gnosischain-rpc.gateway.pokt.network",
          "https://gnosis-mainnet.public.blastapi.io",
          "wss://rpc.gnosischain.com/wss",
          "https://xdai-rpc.gateway.pokt.network",
          "https://xdai-archive.blockscout.com",
          "https://rpc.ap-southeast-1.gateway.fm/v1/gnosis/non-archival/mainnet",
        ],
        faucets: [
          "https://gnosisfaucet.com",
          "https://faucet.gimlu.com/gnosis",
          "https://stakely.io/faucet/gnosis-chain-xdai",
          "https://faucet.prussia.dev/xdai",
        ],
        nativeCurrency: { name: "xDAI", symbol: "xDAI", decimals: 18 },
        infoURL: "https://docs.gnosischain.com",
        shortName: "gno",
        chainId: 100,
        networkId: 100,
        slip44: 700,
        explorers: [
          {
            name: "gnosisscan",
            url: "https://gnosisscan.io",
            icon: "gnosisscan",
            standard: "EIP3091",
          },
          {
            name: "blockscout",
            url: "https://blockscout.com/xdai/mainnet",
            icon: "blockscout",
            standard: "EIP3091",
          },
        ],
        tvl: 195530968.09447694,
        chainSlug: "xdai",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Aurora Mainnet",
        chain: "NEAR",
        rpc: ["https://mainnet.aurora.dev"],
        faucets: [],
        nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
        infoURL: "https://aurora.dev",
        shortName: "aurora",
        chainId: 1313161554,
        networkId: 1313161554,
        explorers: [
          {
            name: "aurorascan.dev",
            url: "https://aurorascan.dev",
            standard: "EIP3091",
          },
        ],
        tvl: 170942894.9386799,
        chainSlug: "aurora",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Fusion Mainnet",
        chain: "FSN",
        rpc: [
          "https://mainnet.anyswap.exchange",
          "https://fsn.dev/api",
          "https://mainway.freemoon.xyz/gate",
        ],
        faucets: [],
        nativeCurrency: { name: "Fusion", symbol: "FSN", decimals: 18 },
        infoURL: "https://www.fusion.org/",
        shortName: "fsn",
        chainId: 32659,
        networkId: 32659,
        tvl: 140980079.39259046,
        chainSlug: "fusion",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Huobi ECO Chain Mainnet",
        chain: "Heco",
        rpc: [
          "https://http-mainnet.hecochain.com",
          "wss://ws-mainnet.hecochain.com",
          "https://http-mainnet-node.huobichain.com",
          "https://pub001.hg.network/rpc",
          "https://hecoapi.terminet.io/rpc",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        nativeCurrency: {
          name: "Huobi ECO Chain Native Token",
          symbol: "HT",
          decimals: 18,
        },
        infoURL: "https://www.hecochain.com",
        shortName: "heco",
        chainId: 128,
        networkId: 128,
        slip44: 1010,
        explorers: [
          {
            name: "hecoinfo",
            url: "https://hecoinfo.com",
            standard: "EIP3091",
          },
        ],
        tvl: 132072340.95876865,
        chainSlug: "heco",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Celo Mainnet",
        chainId: 42220,
        shortName: "CELO",
        chain: "CELO",
        networkId: 42220,
        nativeCurrency: { name: "CELO", symbol: "CELO", decimals: 18 },
        rpc: [
          "https://forno.celo.org",
          "wss://forno.celo.org/ws",
          "https://rpc.ankr.com/celo",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        infoURL: "https://docs.celo.org/",
        explorers: [
          {
            name: "blockscout",
            url: "https://explorer.celo.org",
            standard: "none",
          },
        ],
        tvl: 130794839.44902772,
        chainSlug: "celo",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Moonriver",
        chain: "MOON",
        rpc: [
          "https://rpc.api.moonriver.moonbeam.network",
          "wss://wss.api.moonriver.moonbeam.network",
          "https://moonriver.api.onfinality.io/rpc?apikey=673e1fae-c9c9-4c7f-a3d5-2121e8274366",
          "https://moonriver.api.onfinality.io/public",
          "https://moonriver.public.blastapi.io",
        ],
        faucets: [],
        nativeCurrency: { name: "Moonriver", symbol: "MOVR", decimals: 18 },
        infoURL: "https://moonbeam.network/networks/moonriver/",
        shortName: "mriver",
        chainId: 1285,
        networkId: 1285,
        explorers: [
          {
            name: "moonscan",
            url: "https://moonriver.moonscan.io",
            standard: "none",
          },
        ],
        tvl: 78622721.49820565,
        chainSlug: "moonriver",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Moonbeam",
        chain: "MOON",
        rpc: [
          "https://rpc.api.moonbeam.network",
          "wss://wss.api.moonbeam.network",
          "https://moonbeam.public.blastapi.io",
          "https://rpc.ankr.com/moonbeam",
        ],
        faucets: [],
        nativeCurrency: { name: "Glimmer", symbol: "GLMR", decimals: 18 },
        infoURL: "https://moonbeam.network/networks/moonbeam/",
        shortName: "mbeam",
        chainId: 1284,
        networkId: 1284,
        explorers: [
          {
            name: "moonscan",
            url: "https://moonbeam.moonscan.io",
            standard: "none",
          },
        ],
        tvl: 76883365.77640377,
        chainSlug: "moonbeam",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "RSK Mainnet",
        chain: "RSK",
        rpc: ["https://public-node.rsk.co", "https://mycrypto.rsk.co"],
        faucets: ["https://faucet.rsk.co/"],
        nativeCurrency: { name: "Smart Bitcoin", symbol: "RBTC", decimals: 18 },
        infoURL: "https://rsk.co",
        shortName: "rsk",
        chainId: 30,
        networkId: 30,
        slip44: 137,
        explorers: [
          {
            name: "RSK Explorer",
            url: "https://explorer.rsk.co",
            standard: "EIP3091",
          },
        ],
        tvl: 64616720.952105574,
        chainSlug: "rsk",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Metis Andromeda Mainnet",
        chain: "ETH",
        rpc: ["https://andromeda.metis.io/?owner=1088"],
        faucets: [],
        nativeCurrency: { name: "Metis", symbol: "METIS", decimals: 18 },
        infoURL: "https://www.metis.io",
        shortName: "metis-andromeda",
        chainId: 1088,
        networkId: 1088,
        explorers: [
          {
            name: "blockscout",
            url: "https://andromeda-explorer.metis.io",
            standard: "EIP3091",
          },
        ],
        parent: {
          type: "L2",
          chain: "eip155-1",
          bridges: [{ url: "https://bridge.metis.io" }],
        },
        tvl: 56270440.562171474,
        chainSlug: "metis",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "KCC Mainnet",
        chain: "KCC",
        rpc: [
          "https://rpc-mainnet.kcc.network",
          "wss://rpc-ws-mainnet.kcc.network",
        ],
        faucets: [],
        nativeCurrency: { name: "KuCoin Token", symbol: "KCS", decimals: 18 },
        infoURL: "https://kcc.io",
        shortName: "kcs",
        chainId: 321,
        networkId: 1,
        explorers: [
          {
            name: "KCC Explorer",
            url: "https://explorer.kcc.io/en",
            standard: "EIP3091",
          },
        ],
        tvl: 52943057.57140958,
        chainSlug: "kucoin",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Astar",
        chain: "ASTR",
        rpc: [
          "https://rpc.astar.network:8545",
          "https://astar.public.blastapi.io",
          "https://evm.astar.network",
        ],
        faucets: [],
        nativeCurrency: { name: "Astar", symbol: "ASTR", decimals: 18 },
        infoURL: "https://astar.network/",
        shortName: "astr",
        chainId: 592,
        networkId: 592,
        icon: "astar",
        explorers: [
          {
            name: "subscan",
            url: "https://astar.subscan.io",
            standard: "none",
            icon: "subscan",
          },
        ],
        tvl: 52730656.75060559,
        chainSlug: "astar",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Telos EVM Mainnet",
        chain: "TLOS",
        rpc: [
          "https://mainnet.telos.net/evm",
          "https://rpc1.eu.telos.net/evm",
          "https://rpc1.us.telos.net/evm",
          "https://rpc2.us.telos.net/evm",
          "https://api.kainosbp.com/evm",
          "https://rpc2.eu.telos.net/evm",
          "https://evm.teloskorea.com/evm",
          "https://rpc2.teloskorea.com/evm",
          "https://rpc01.us.telosunlimited.io/evm",
          "https://rpc02.us.telosunlimited.io/evm",
          "https://rpc.telosarabia.net/evm",
        ],
        faucets: [],
        nativeCurrency: { name: "Telos", symbol: "TLOS", decimals: 18 },
        infoURL: "https://telos.net",
        shortName: "TelosEVM",
        chainId: 40,
        networkId: 40,
        explorers: [
          { name: "teloscan", url: "https://teloscan.io", standard: "EIP3091" },
        ],
        tvl: 39630046.26356177,
        chainSlug: "telos",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Harmony Mainnet Shard 0",
        chain: "Harmony",
        rpc: [
          "https://api.harmony.one",
          "https://api.s0.t.hmny.io",
          "https://harmony-0-rpc.gateway.pokt.network",
          "https://a.api.s0.t.hmny.io",
          "https://rpc.ankr.com/harmony",
          "https://harmony-mainnet.chainstacklabs.com",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        nativeCurrency: { name: "ONE", symbol: "ONE", decimals: 18 },
        infoURL: "https://www.harmony.one/",
        shortName: "hmy-s0",
        chainId: 1666600000,
        networkId: 1666600000,
        explorers: [
          {
            name: "Harmony Block Explorer",
            url: "https://explorer.harmony.one",
            standard: "EIP3091",
          },
        ],
        tvl: 39209757.076895975,
        chainSlug: "harmony",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "IoTeX Network Mainnet",
        chain: "iotex.io",
        rpc: [
          "https://babel-api.mainnet.iotex.io",
          "https://iotex-mainnet.gateway.pokt.network/v1/lb/6176f902e19001003499f492",
          "https://rpc.ankr.com/iotex",
          "https://babel-api.mainnet.iotex.one",
          "https://pokt-api.iotex.io",
        ],
        faucets: [],
        nativeCurrency: { name: "IoTeX", symbol: "IOTX", decimals: 18 },
        infoURL: "https://iotex.io",
        shortName: "iotex-mainnet",
        chainId: 4689,
        networkId: 4689,
        explorers: [
          {
            name: "iotexscan",
            url: "https://iotexscan.io",
            standard: "EIP3091",
          },
        ],
        tvl: 34816081.25836641,
        chainSlug: "iotex",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Wanchain",
        chain: "WAN",
        rpc: ["https://gwan-ssl.wandevs.org:56891"],
        faucets: [],
        nativeCurrency: { name: "Wancoin", symbol: "WAN", decimals: 18 },
        infoURL: "https://www.wanscan.org",
        shortName: "wan",
        chainId: 888,
        networkId: 888,
        slip44: 5718350,
        tvl: 28203177.995244857,
        chainSlug: "wanchain",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Theta Mainnet",
        chain: "Theta",
        rpc: ["https://eth-rpc-api.thetatoken.org/rpc"],
        faucets: [],
        nativeCurrency: { name: "Theta Fuel", symbol: "TFUEL", decimals: 18 },
        infoURL: "https://www.thetatoken.org/",
        shortName: "theta-mainnet",
        chainId: 361,
        networkId: 361,
        explorers: [
          {
            name: "Theta Mainnet Explorer",
            url: "https://explorer.thetatoken.org",
            standard: "EIP3091",
          },
        ],
        tvl: 27485638.632155877,
        chainSlug: "theta",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Songbird Canary-Network",
        chain: "SGB",
        icon: "songbird",
        rpc: [
          "https://songbird.towolabs.com/rpc",
          "https://songbird-api.flare.network/ext/C/rpc",
          "https://sgb.ftso.com.au/ext/bc/C/rpc",
          "https://sgb.lightft.so/rpc",
          "https://sgb-rpc.ftso.eu",
        ],
        faucets: [],
        nativeCurrency: { name: "Songbird", symbol: "SGB", decimals: 18 },
        infoURL: "https://flare.xyz",
        shortName: "sgb",
        chainId: 19,
        networkId: 19,
        explorers: [
          {
            name: "blockscout",
            url: "https://songbird-explorer.flare.network",
            standard: "EIP3091",
          },
        ],
        tvl: 26449371.9486162,
        chainSlug: "songbird",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Oasis Emerald ParaTime Mainnet",
        chain: "Emerald",
        icon: "oasis",
        rpc: ["https://emerald.oasis.dev", "wss://emerald.oasis.dev/ws"],
        faucets: [],
        nativeCurrency: { name: "Emerald Rose", symbol: "ROSE", decimals: 18 },
        infoURL: "https://docs.oasis.dev/general/developer-resources/overview",
        shortName: "emerald",
        chainId: 42262,
        networkId: 42262,
        explorers: [
          {
            name: "Emerald ParaTime Mainnet Explorer",
            url: "https://explorer.emerald.oasis.dev",
            standard: "EIP3091",
          },
        ],
        tvl: 25618768.660616387,
        chainSlug: "oasis",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Dogechain Mainnet",
        chain: "DC",
        icon: "dogechain",
        rpc: [
          "https://rpc-sg.dogechain.dog",
          "https://rpc-us.dogechain.dog",
          "https://rpc.dogechain.dog",
          "https://rpc01-sg.dogechain.dog",
          "https://rpc02-sg.dogechain.dog",
          "https://rpc03-sg.dogechain.dog",
        ],
        faucets: [],
        nativeCurrency: { name: "Dogecoin", symbol: "DOGE", decimals: 18 },
        infoURL: "https://dogechain.dog",
        shortName: "dc",
        chainId: 2000,
        networkId: 2000,
        explorers: [
          {
            name: "dogechain explorer",
            url: "https://explorer.dogechain.dog",
            standard: "EIP3091",
          },
        ],
        tvl: 13559684.878270777,
        chainSlug: "dogechain",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "OKXChain Mainnet",
        chain: "okxchain",
        rpc: [
          "https://exchainrpc.okex.org",
          "https://okc-mainnet.gateway.pokt.network/v1/lb/6275309bea1b320039c893ff",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/?"],
        nativeCurrency: {
          name: "OKXChain Global Utility Token",
          symbol: "OKT",
          decimals: 18,
        },
        infoURL: "https://www.okex.com/okc",
        shortName: "okt",
        chainId: 66,
        networkId: 66,
        explorers: [
          {
            name: "OKLink",
            url: "https://www.oklink.com/en/okc",
            standard: "EIP3091",
          },
        ],
        tvl: 12212231.182474818,
        chainSlug: "okexchain",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Ultron Mainnet",
        chain: "Ultron",
        icon: "ultron",
        rpc: ["https://ultron-rpc.net"],
        faucets: [],
        nativeCurrency: { name: "Ultron", symbol: "ULX", decimals: 18 },
        infoURL: "https://ultron.foundation",
        shortName: "UtronMainnet",
        chainId: 1231,
        networkId: 1231,
        explorers: [
          {
            name: "Ultron Explorer",
            url: "https://ulxscan.com",
            icon: "ultron",
            standard: "none",
          },
        ],
        tvl: 8746451.324971395,
        chainSlug: "ultron",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Vision - Mainnet",
        chain: "Vision",
        rpc: ["https://infragrid.v.network/ethereum/compatible"],
        faucets: [],
        nativeCurrency: { name: "VS", symbol: "VS", decimals: 18 },
        infoURL: "https://www.v.network",
        explorers: [
          {
            name: "Visionscan",
            url: "https://www.visionscan.org",
            standard: "EIP3091",
          },
        ],
        shortName: "vision",
        chainId: 888888,
        networkId: 888888,
        slip44: 60,
        tvl: 8204761.483693511,
        chainSlug: "vision",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "ThunderCore Mainnet",
        chain: "TT",
        rpc: [
          "https://mainnet-rpc.thundercore.com",
          "https://mainnet-rpc.thundertoken.net",
          "https://mainnet-rpc.thundercore.io",
        ],
        faucets: ["https://faucet.thundercore.com"],
        nativeCurrency: {
          name: "ThunderCore Token",
          symbol: "TT",
          decimals: 18,
        },
        infoURL: "https://thundercore.com",
        shortName: "TT",
        chainId: 108,
        networkId: 108,
        slip44: 1001,
        explorers: [
          {
            name: "thundercore-viewblock",
            url: "https://viewblock.io/thundercore",
            standard: "EIP3091",
          },
        ],
        tvl: 6432817.795386284,
        chainSlug: "thundercore",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Velas EVM Mainnet",
        chain: "Velas",
        icon: "velas",
        rpc: [
          "https://evmexplorer.velas.com/rpc",
          "https://explorer.velas.com/rpc",
        ],
        faucets: [],
        nativeCurrency: { name: "Velas", symbol: "VLX", decimals: 18 },
        infoURL: "https://velas.com",
        shortName: "vlx",
        chainId: 106,
        networkId: 106,
        explorers: [
          {
            name: "Velas Explorer",
            url: "https://evmexplorer.velas.com",
            standard: "EIP3091",
          },
        ],
        tvl: 6311001.668332134,
        chainSlug: "velas",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Boba Network",
        chain: "ETH",
        rpc: [
          "https://mainnet.boba.network",
          "https://boba-mainnet.gateway.pokt.network/v1/lb/623ad21b20354900396fed7f",
          "https://lightning-replica.boba.network",
        ],
        faucets: [],
        nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
        infoURL: "https://boba.network",
        shortName: "Boba",
        chainId: 288,
        networkId: 288,
        explorers: [
          {
            name: "Blockscout",
            url: "https://blockexplorer.boba.network",
            standard: "none",
          },
        ],
        parent: {
          type: "L2",
          chain: "eip155-1",
          bridges: [{ url: "https://gateway.boba.network" }],
        },
        tvl: 6213579.3031530045,
        chainSlug: "boba",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Smart Bitcoin Cash",
        chain: "smartBCH",
        rpc: [
          "https://smartbch.greyh.at",
          "https://rpc-mainnet.smartbch.org",
          "https://smartbch.fountainhead.cash/mainnet",
          "https://smartbch.devops.cash/mainnet",
          "https://global.uat.cash",
          "https://rpc.uatvo.com",
        ],
        faucets: [],
        nativeCurrency: { name: "Bitcoin Cash", symbol: "BCH", decimals: 18 },
        infoURL: "https://smartbch.org/",
        shortName: "smartbch",
        chainId: 10000,
        networkId: 10000,
        tvl: 5870577.277417365,
        chainSlug: "smartbch",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "CoinEx Smart Chain Mainnet",
        chain: "CSC",
        rpc: [
          "https://rpc.coinex.net",
          "https://rpc1.coinex.net",
          "https://rpc2.coinex.net",
          "https://rpc3.coinex.net",
          "https://rpc4.coinex.net",
        ],
        faucets: [],
        nativeCurrency: {
          name: "CoinEx Chain Native Token",
          symbol: "cet",
          decimals: 18,
        },
        infoURL: "https://www.coinex.org/",
        shortName: "cet",
        chainId: 52,
        networkId: 52,
        explorers: [
          {
            name: "coinexscan",
            url: "https://www.coinex.net",
            standard: "none",
          },
        ],
        tvl: 4256196.991461369,
        chainSlug: "csc",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Fuse Mainnet",
        chain: "FUSE",
        rpc: ["https://rpc.fuse.io", "https://fuse-rpc.gateway.pokt.network"],
        faucets: [],
        nativeCurrency: { name: "Fuse", symbol: "FUSE", decimals: 18 },
        infoURL: "https://fuse.io/",
        shortName: "fuse",
        chainId: 122,
        networkId: 122,
        tvl: 3860045.98377781,
        chainSlug: "fuse",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Elastos Smart Chain",
        chain: "ETH",
        rpc: [
          "https://api.elastos.io/eth",
          "https://api.elastos.io/esc",
          "https://api.trinity-tech.io/esc",
        ],
        faucets: ["https://faucet.elastos.org/"],
        nativeCurrency: { name: "Elastos", symbol: "ELA", decimals: 18 },
        infoURL: "https://www.elastos.org/",
        shortName: "elaeth",
        chainId: 20,
        networkId: 20,
        explorers: [
          {
            name: "elastos eth explorer",
            url: "https://eth.elastos.io",
            standard: "EIP3091",
          },
        ],
        tvl: 3168594.6891868236,
        chainSlug: "elastos",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Evmos",
        chain: "Evmos",
        rpc: [
          "https://eth.bd.evmos.org:8545",
          "https://evmos-mainnet.gateway.pokt.network/v1/lb/627586ddea1b320039c95205",
          "https://evmos-json-rpc.stakely.io",
          "https://jsonrpc-evmos-ia.notional.ventures",
          "https://json-rpc.evmos.blockhunters.org",
        ],
        faucets: [],
        nativeCurrency: { name: "Evmos", symbol: "EVMOS", decimals: 18 },
        infoURL: "https://evmos.org",
        shortName: "evmos",
        chainId: 9001,
        networkId: 9001,
        icon: "evmos",
        explorers: [
          {
            name: "Evmos EVM Explorer (Blockscout)",
            url: "https://evm.evmos.org",
            standard: "none",
            icon: "evmos",
          },
          {
            name: "Evmos Cosmos Explorer (Mintscan)",
            url: "https://www.mintscan.io/evmos",
            standard: "none",
            icon: "evmos",
          },
        ],
        tvl: 2224905.5246484308,
        chainSlug: "evmos",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Godwoken Mainnet",
        chain: "GWT",
        rpc: ["https://v1.mainnet.godwoken.io/rpc"],
        faucets: [],
        nativeCurrency: { name: "pCKB", symbol: "pCKB", decimals: 18 },
        infoURL: "https://www.nervos.org",
        shortName: "gw-mainnet-v1",
        chainId: 71402,
        networkId: 71402,
        explorers: [
          {
            name: "GWScout Explorer",
            url: "https://gw-mainnet-explorer.nervosdao.community",
            standard: "none",
          },
          {
            name: "GWScan Block Explorer",
            url: "https://v1.gwscan.com",
            standard: "none",
          },
        ],
        tvl: 2048564.7997288643,
        chainSlug: "godwoken",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Callisto Mainnet",
        chain: "CLO",
        rpc: ["https://rpc.callisto.network", "https://clo-geth.0xinfra.com"],
        faucets: [],
        nativeCurrency: { name: "Callisto", symbol: "CLO", decimals: 18 },
        infoURL: "https://callisto.network",
        shortName: "clo",
        chainId: 820,
        networkId: 1,
        slip44: 820,
        tvl: 1874688.1847798151,
        chainSlug: "callisto",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Meter Mainnet",
        chain: "METER",
        rpc: ["https://rpc.meter.io"],
        faucets: ["https://faucet.meter.io"],
        nativeCurrency: { name: "Meter", symbol: "MTR", decimals: 18 },
        infoURL: "https://www.meter.io",
        shortName: "Meter",
        chainId: 82,
        networkId: 82,
        explorers: [
          {
            name: "Meter Mainnet Scan",
            url: "https://scan.meter.io",
            standard: "EIP3091",
          },
        ],
        tvl: 1569584.6458322143,
        chainSlug: "meter",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Nahmii Mainnet",
        chain: "Nahmii",
        rpc: ["https://l2.nahmii.io"],
        faucets: [],
        nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
        infoURL: "https://nahmii.io",
        shortName: "Nahmii",
        chainId: 5551,
        networkId: 5551,
        icon: "nahmii",
        explorers: [
          {
            name: "Nahmii mainnet explorer",
            url: "https://explorer.nahmii.io",
            icon: "nahmii",
            standard: "EIP3091",
          },
        ],
        parent: {
          type: "L2",
          chain: "eip155-1",
          bridges: [{ url: "https://bridge.nahmii.io" }],
        },
        tvl: 1542391.5477397114,
        chainSlug: "nahmii",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Syscoin Mainnet",
        chain: "SYS",
        rpc: [
          "https://rpc.syscoin.org",
          "wss://rpc.syscoin.org/wss",
          "https://rpc.ankr.com/syscoin",
        ],
        faucets: ["https://faucet.syscoin.org"],
        nativeCurrency: { name: "Syscoin", symbol: "SYS", decimals: 18 },
        infoURL: "https://www.syscoin.org",
        shortName: "sys",
        chainId: 57,
        networkId: 57,
        explorers: [
          {
            name: "Syscoin Block Explorer",
            url: "https://explorer.syscoin.org",
            standard: "EIP3091",
          },
        ],
        tvl: 927411.647592095,
        chainSlug: "syscoin",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Ethereum Classic Mainnet",
        chain: "ETC",
        rpc: [
          "https://www.ethercluster.com/etc",
          "https://blockscout.com/etc/mainnet/api/eth-rpc",
          "https://etc.etcdesktop.com",
          "https://etc.mytokenpocket.vip",
          "https://etc-network.info:8545",
        ],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/?"],
        nativeCurrency: {
          name: "Ethereum Classic Ether",
          symbol: "ETC",
          decimals: 18,
        },
        infoURL: "https://ethereumclassic.org",
        shortName: "etc",
        chainId: 61,
        networkId: 1,
        slip44: 61,
        explorers: [
          {
            name: "blockscout",
            url: "https://blockscout.com/etc/mainnet",
            standard: "none",
          },
        ],
        tvl: 890162.6347344159,
        chainSlug: "ethereumclassic",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Energy Web Chain",
        chain: "Energy Web Chain",
        rpc: ["https://rpc.energyweb.org", "wss://rpc.energyweb.org/ws"],
        faucets: [
          "https://faucet.carbonswap.exchange",
          "https://free-online-app.com/faucet-for-eth-evm-chains/",
        ],
        nativeCurrency: {
          name: "Energy Web Token",
          symbol: "EWT",
          decimals: 18,
        },
        infoURL: "https://energyweb.org",
        shortName: "ewt",
        chainId: 246,
        networkId: 246,
        slip44: 246,
        explorers: [
          {
            name: "blockscout",
            url: "https://explorer.energyweb.org",
            standard: "none",
          },
        ],
        tvl: 884555.1087670473,
        chainSlug: "energyweb",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "TomoChain",
        chain: "TOMO",
        rpc: ["https://rpc.tomochain.com"],
        faucets: [],
        nativeCurrency: { name: "TomoChain", symbol: "TOMO", decimals: 18 },
        infoURL: "https://tomochain.com",
        shortName: "tomo",
        chainId: 88,
        networkId: 88,
        slip44: 889,
        tvl: 834084.0374760022,
        chainSlug: "tomochain",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "XinFin XDC Network",
        chain: "XDC",
        rpc: [
          "https://erpc.xinfin.network",
          "https://rpc.xinfin.network",
          "https://rpc1.xinfin.network",
          "https://xdcrpc.com",
        ],
        faucets: [],
        nativeCurrency: { name: "XinFin", symbol: "XDC", decimals: 18 },
        infoURL: "https://xinfin.org",
        shortName: "xdc",
        chainId: 50,
        networkId: 50,
        icon: "xdc",
        explorers: [
          {
            name: "xdcscan",
            url: "https://xdcscan.io",
            icon: "blocksscan",
            standard: "EIP3091",
          },
          {
            name: "blocksscan",
            url: "https://xdc.blocksscan.io",
            icon: "blocksscan",
            standard: "EIP3091",
          },
        ],
        tvl: 451409.03632205847,
        chainSlug: "xdc",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Hoo Smart Chain",
        chain: "HSC",
        rpc: [
          "https://http-mainnet.hoosmartchain.com",
          "https://http-mainnet2.hoosmartchain.com",
          "wss://ws-mainnet.hoosmartchain.com",
          "wss://ws-mainnet2.hoosmartchain.com",
        ],
        faucets: [],
        nativeCurrency: {
          name: "Hoo Smart Chain Native Token",
          symbol: "HOO",
          decimals: 18,
        },
        infoURL: "https://www.hoosmartchain.com",
        shortName: "hsc",
        chainId: 70,
        networkId: 70,
        slip44: 1170,
        explorers: [
          {
            name: "hooscan",
            url: "https://www.hooscan.com",
            standard: "EIP3091",
          },
        ],
        tvl: 417822.4537847303,
        chainSlug: "hoo",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Shiden",
        chain: "SDN",
        rpc: [
          "https://rpc.shiden.astar.network:8545",
          "wss://shiden.api.onfinality.io/public-ws",
          "https://shiden.public.blastapi.io",
        ],
        faucets: [],
        nativeCurrency: { name: "Shiden", symbol: "SDN", decimals: 18 },
        infoURL: "https://shiden.astar.network/",
        shortName: "sdn",
        chainId: 336,
        networkId: 336,
        icon: "shiden",
        explorers: [
          {
            name: "subscan",
            url: "https://shiden.subscan.io",
            standard: "none",
            icon: "subscan",
          },
        ],
        tvl: 384805.97827739053,
        chainSlug: "shiden",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "REI Network",
        chain: "REI",
        rpc: ["https://rpc.rei.network", "wss://rpc.rei.network"],
        faucets: [],
        nativeCurrency: { name: "REI", symbol: "REI", decimals: 18 },
        infoURL: "https://rei.network/",
        shortName: "REI",
        chainId: 47805,
        networkId: 47805,
        explorers: [
          {
            name: "rei-scan",
            url: "https://scan.rei.network",
            standard: "none",
          },
        ],
        tvl: 331314.207538923,
        chainSlug: "rei",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Ubiq",
        chain: "UBQ",
        rpc: ["https://rpc.octano.dev", "https://pyrus2.ubiqscan.io"],
        faucets: [],
        nativeCurrency: { name: "Ubiq Ether", symbol: "UBQ", decimals: 18 },
        infoURL: "https://ubiqsmart.com",
        shortName: "ubq",
        chainId: 8,
        networkId: 8,
        slip44: 108,
        explorers: [
          { name: "ubiqscan", url: "https://ubiqscan.io", standard: "EIP3091" },
        ],
        tvl: 221765.1097418273,
        chainSlug: "ubiq",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Nova Network",
        chain: "NNW",
        icon: "novanetwork",
        rpc: [
          "https://connect.novanetwork.io",
          "https://0x57.redjackstudio.com",
          "https://rpc.novanetwork.io:9070",
          "https://dev.rpc.novanetwork.io",
          "http://dataseed-0.rpc.novanetwork.io:8545",
          "http://dataseed-1.rpc.novanetwork.io:8545",
          "http://dataseed-2.rpc.novanetwork.io:8545",
          "http://dataseed-3.rpc.novanetwork.io:8545",
          "http://dataseed-4.rpc.novanetwork.io:8545",
          "http://dataseed-5.rpc.novanetwork.io:8545",
          "http://dataseed-6.rpc.novanetwork.io:8545",
          "http://dataseed-f.rpc.novanetwork.io:8545",
        ],
        faucets: [],
        nativeCurrency: { name: "Supernova", symbol: "SNT", decimals: 18 },
        infoURL: "https://novanetwork.io",
        shortName: "nnw",
        chainId: 87,
        networkId: 87,
        explorers: [
          {
            name: "novanetwork",
            url: "https://explorer.novanetwork.io",
            standard: "EIP3091",
          },
        ],
        tvl: 191950.0766480056,
        chainSlug: "nova network",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Zyx Mainnet",
        chain: "ZYX",
        rpc: [
          "https://rpc-1.zyx.network",
          "https://rpc-2.zyx.network",
          "https://rpc-3.zyx.network",
          "https://rpc-4.zyx.network",
          "https://rpc-5.zyx.network",
          "https://rpc-6.zyx.network",
        ],
        faucets: [],
        nativeCurrency: { name: "Zyx", symbol: "ZYX", decimals: 18 },
        infoURL: "https://zyx.network/",
        shortName: "ZYX",
        chainId: 55,
        networkId: 55,
        explorers: [
          { name: "zyxscan", url: "https://zyxscan.com", standard: "none" },
        ],
        tvl: 100400.33923458889,
        chainSlug: "zyx",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "High Performance Blockchain",
        chain: "HPB",
        rpc: ["https://hpbnode.com", "wss://ws.hpbnode.com"],
        faucets: ["https://myhpbwallet.com/"],
        nativeCurrency: {
          name: "High Performance Blockchain Ether",
          symbol: "HPB",
          decimals: 18,
        },
        infoURL: "https://hpb.io",
        shortName: "hpb",
        chainId: 269,
        networkId: 269,
        slip44: 269,
        explorers: [
          { name: "hscan", url: "https://hscan.org", standard: "EIP3091" },
        ],
        tvl: 91100.46281314858,
        chainSlug: "hpb",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Polis Mainnet",
        chain: "Olympus",
        icon: "polis",
        rpc: ["https://rpc.polis.tech"],
        faucets: ["https://faucet.polis.tech"],
        nativeCurrency: { name: "Polis", symbol: "POLIS", decimals: 18 },
        infoURL: "https://polis.tech",
        shortName: "olympus",
        chainId: 333999,
        networkId: 333999,
        tvl: 81575.76029160417,
        chainSlug: "polis",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "GoChain",
        chain: "GO",
        rpc: ["https://rpc.gochain.io"],
        faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        nativeCurrency: { name: "GoChain Ether", symbol: "GO", decimals: 18 },
        infoURL: "https://gochain.io",
        shortName: "go",
        chainId: 60,
        networkId: 60,
        slip44: 6060,
        explorers: [
          {
            name: "GoChain Explorer",
            url: "https://explorer.gochain.io",
            standard: "EIP3091",
          },
        ],
        tvl: 23598.966684957064,
        chainSlug: "gochain",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "REI Chain Mainnet",
        chain: "REI",
        icon: "reichain",
        rpc: ["https://rei-rpc.moonrhythm.io"],
        faucets: ["http://kururu.finance/faucet?chainId=55555"],
        nativeCurrency: { name: "Rei", symbol: "REI", decimals: 18 },
        infoURL: "https://reichain.io",
        shortName: "reichain",
        chainId: 55555,
        networkId: 55555,
        explorers: [
          { name: "reiscan", url: "https://reiscan.com", standard: "EIP3091" },
        ],
        tvl: 17022.08421169646,
        chainSlug: "reichain",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Palm",
        chain: "Palm",
        icon: "palm",
        rpc: [
          "https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b",
          "https://palm-mainnet.public.blastapi.io",
        ],
        faucets: [],
        nativeCurrency: { name: "PALM", symbol: "PALM", decimals: 18 },
        infoURL: "https://palm.io",
        shortName: "palm",
        chainId: 11297108109,
        networkId: 11297108109,
        explorers: [
          {
            name: "Palm Explorer",
            url: "https://explorer.palm.io",
            standard: "EIP3091",
            icon: "palm",
          },
        ],
        tvl: 852.0017657541175,
        chainSlug: "palm",
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Expanse Network",
        chain: "EXP",
        rpc: ["https://node.expanse.tech", "https://node.eggs.cool"],
        faucets: [],
        nativeCurrency: {
          name: "Expanse Network Ether",
          symbol: "EXP",
          decimals: 18,
        },
        infoURL: "https://expanse.tech",
        shortName: "exp",
        chainId: 2,
        networkId: 1,
        slip44: 40,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "ThaiChain",
        chain: "TCH",
        rpc: ["https://rpc.dome.cloud"],
        faucets: [],
        nativeCurrency: {
          name: "ThaiChain Ether",
          symbol: "TCH",
          decimals: 18,
        },
        infoURL: "https://thaichain.io",
        shortName: "tch",
        chainId: 7,
        networkId: 7,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Metadium Mainnet",
        chain: "META",
        rpc: ["https://api.metadium.com/prod", "https://api.metadium.com/dev"],
        faucets: [],
        nativeCurrency: {
          name: "Metadium Mainnet Ether",
          symbol: "META",
          decimals: 18,
        },
        infoURL: "https://metadium.com",
        shortName: "meta",
        chainId: 11,
        networkId: 11,
        slip44: 916,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Flare Mainnet",
        chain: "FLR",
        icon: "flare",
        rpc: ["https://flare-api.flare.network/ext/C/rpc"],
        faucets: [],
        nativeCurrency: { name: "Flare", symbol: "FLR", decimals: 18 },
        infoURL: "https://flare.xyz",
        shortName: "flr",
        chainId: 14,
        networkId: 14,
        explorers: [
          {
            name: "blockscout",
            url: "https://flare-explorer.flare.network",
            standard: "EIP3091",
          },
        ],
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Diode Prenet",
        chain: "DIODE",
        rpc: ["https://prenet.diode.io:8443", "wss://prenet.diode.io:8443/ws"],
        faucets: [],
        nativeCurrency: { name: "Diodes", symbol: "DIODE", decimals: 18 },
        infoURL: "https://diode.io/prenet",
        shortName: "diode",
        chainId: 15,
        networkId: 15,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "ThaiChain 2.0 ThaiFi",
        chain: "TCH",
        rpc: ["https://rpc.thaifi.com"],
        faucets: [],
        nativeCurrency: { name: "Thaifi Ether", symbol: "TFI", decimals: 18 },
        infoURL: "https://exp.thaifi.com",
        shortName: "tfi",
        chainId: 17,
        networkId: 17,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "ELA-DID-Sidechain Mainnet",
        chain: "ETH",
        rpc: ["https://api.trinity-tech.io/eid", "https://api.elastos.io/eid"],
        faucets: [],
        nativeCurrency: { name: "Elastos", symbol: "ELA", decimals: 18 },
        infoURL: "https://www.elastos.org/",
        shortName: "eladid",
        chainId: 22,
        networkId: 22,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Dithereum Mainnet",
        chain: "DTH",
        icon: "dithereum",
        rpc: [
          "https://node-mainnet.dithereum.io",
          "https://node-mainnet.dithereum.io  ",
        ],
        faucets: ["https://faucet.dithereum.org"],
        nativeCurrency: { name: "Dither", symbol: "DTH", decimals: 18 },
        infoURL: "https://dithereum.org",
        shortName: "dthmainnet",
        chainId: 24,
        networkId: 24,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "ShibaChain",
        chain: "SHIB",
        rpc: ["https://rpc.shibachain.net"],
        faucets: [],
        nativeCurrency: {
          name: "SHIBA INU COIN",
          symbol: "SHIB",
          decimals: 18,
        },
        infoURL: "https://www.shibachain.net",
        shortName: "shib",
        chainId: 27,
        networkId: 27,
        explorers: [
          {
            name: "Shiba Explorer",
            url: "https://exp.shibachain.net",
            standard: "none",
          },
        ],
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Genesis L1",
        chain: "genesis",
        rpc: ["https://rpc.genesisl1.org"],
        faucets: [],
        nativeCurrency: { name: "L1 coin", symbol: "L1", decimals: 18 },
        infoURL: "https://www.genesisl1.com",
        shortName: "L1",
        chainId: 29,
        networkId: 29,
        explorers: [
          {
            name: "Genesis L1 blockchain explorer",
            url: "https://explorer.genesisl1.org",
            standard: "none",
          },
        ],
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "GoodData Mainnet",
        chain: "GooD",
        rpc: ["https://rpc.goodata.io"],
        faucets: [],
        nativeCurrency: {
          name: "GoodData Mainnet Ether",
          symbol: "GooD",
          decimals: 18,
        },
        infoURL: "https://www.goodata.org",
        shortName: "GooD",
        chainId: 33,
        networkId: 33,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "TBWG Chain",
        chain: "TBWG",
        rpc: ["https://rpc.tbwg.io"],
        faucets: [],
        nativeCurrency: { name: "TBWG Ether", symbol: "TBG", decimals: 18 },
        infoURL: "https://tbwg.io",
        shortName: "tbwg",
        chainId: 35,
        networkId: 35,
      },
      messages: {
        Common: {
          "connect-wallet": "Connect Wallet",
          "view-source-code": "View Code",
          "join-our-discord": "Join Discord",
          currency: "Currency",
          "search-networks": "Search Networks",
          description:
            "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          "help-info": "Helping users connect to EVM powered networks",
          "add-your-network": "Add Your Network",
          "add-your-rpc": "Add Your RPC",
          language: "中文",
          "add-to-metamask": "Add to Metamask",
          "add-to-imToken": "Add to imToken",
          "add-to-wallet": "Add to Wallet",
          "add-to-brave": "Add to Brave",
          "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
];

export default Chainlist;
