import "./App.css";
import {
  IntroPage,
  MyPage,
  SettingPage,
  EditProfilePage,
  LeaderboardPage,
  Web3Page,
  NotificationPage,
  SolanaPage,
  PolygonPage,
  FinancePage,
  CommentsPage,
  TransactionsPage,
  RequestProgressPage,
  SignUpPage,
  SignInPage,
  FindYourID
} from "./pages";
import React, { useEffect, useState, Suspense } from "react";
import "./utils/styles/font.css";
import styled from "styled-components";
import { COLORS as c } from "./utils/styles/colors";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./utils/functions/ScrollTop";
import MintingMain from "./pages/minting/MintingMain/MintingMain";
import BadgeClaim from "./pages/minting/BadgeClaim/BadgeClaim";
import BadgeOwnerDetail from "./pages/minting/BadgeOwnerDetail/BadgeOwnerDetail";
import BadgeCreate from "./pages/minting/BadgeCreate/BadgeCreate";
import BadgeDetail from "./pages/minting/BadgeDetail/BadgeDetail";
import Header from "./components/global/Header/index";
import SizeBox from "./components/util/SizeBox";
import CreateSuccess from "./pages/minting/BadgeCreate/CreateSuccess";

const BodyInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${c.background};
`;

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/*<Header></Header>*/}
        <BodyInner>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<IntroPage />} />
            {/** minting */}
            <Route path={"/minting"} element={<MintingMain />} />
            <Route path={"/badge/claim"} element={<BadgeClaim />} />
            <Route path={"/badge/detail/:id"} element={<BadgeDetail />} />
            <Route
              path={"/badge/owner/detail/:id"}
              element={<BadgeOwnerDetail />}
            />
            <Route path={"/badge/create"} element={<BadgeCreate />} />
            <Route path={"/badge/create/success"} element={<CreateSuccess />} />
            <Route path={"/badge/claim"} element={<BadgeClaim />} />
            <Route path={"/badgeDetail"} element={<BadgeDetail />} />
            <Route path={"/badge/detail/:id"} element={<BadgeDetail />} />
            <Route
              path={"/badge/owner/detail/:id"}
              element={<BadgeOwnerDetail />}
            />
            <Route path={"/minting"} element={<MintingMain />} />
            <Route path={"/badge/create"} element={<BadgeCreate />} />
            <Route path={"/setting"} element={<SettingPage />} />
            <Route path={"/signup"} element={<SignUpPage />} />
            <Route path={"/signin"} element={<SignInPage />} />
            <Route path={"/findid"} element={<FindYourID />} />
            <Route path={"/mypage"} element={<MyPage />} /> {/** mypage */}
            <Route path={"/@:userID"} element={<MyPage />} />
            <Route path={"/editProfile"} element={<EditProfilePage />} />
            <Route path={"/leaderboard"} element={<LeaderboardPage />} />
            <Route path={"/cosmos"} element={<Web3Page />} />
            <Route path={"/notification"} element={<NotificationPage />} />
            <Route path={"/noti"} element={<NotificationPage />} />
            <Route path={"/solana"} element={<SolanaPage />} />
            <Route path={"/polygon"} element={<PolygonPage />} />
            <Route path={"/finance"} element={<FinancePage />} />
            <Route path={"/finance/@:userID"} element={<FinancePage />} />
            <Route path={"/finance/type=:type/fromAddr=:fromAddr/toAddr=:toAddr/value=:value/chainId=:chainId/fromUser=:fromUser/toUser=:toUser/memo=:memo/udenom=:udenom/walletType=:walletType"} element={<FinancePage />} />
            <Route path={"/transactions"} element={<TransactionsPage />} />
            <Route path={"/requestProgress"} element={<RequestProgressPage />} />
            <Route path={"/@:userID/comments"} element={<CommentsPage />} />
            {/** userpage */}
          </Routes>
        </BodyInner>
        {/*<Footer></Footer>*/}
      </BrowserRouter>
    </div>
  );
}

export default App;
