import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import leftArrow from "../../../../assets/icons/dashboard/left-arrow.svg";
import rightArrow from "../../../../assets/icons/dashboard/right-arrow.svg";
import mobileLeft from "../../../../assets/icons/dashboard/mobile-left.svg";
import mobileRight from "../../../../assets/icons/dashboard/mobile-right.svg";
import {
  hideProfileHistory,
  deleteProfileHistory,
  getProfileHistory,
} from "../../../../utils/api/auth";

const BoxContainer = styled.div`
  width: 90%;
  max-width: 856px;
  min-height: 288px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
`;

const HistoryDate = styled.div`
  ${Typography.Body}
  text-align: center;
  color: ${palette.gray};
  margin-top: 4px;
`;

const ImageBox = styled.div`
  width: 90%;
  max-width: 320px;
  max-height: 320px;
  text-align: center;
  margin: 0px auto;
  margin-bottom: 10px;
  line-height: 320px;
  background-color: ${palette.white};
  overflow: hidden;
  //   filter: drop-shadow(0px 2px 10px #57599240);
`;

const Image = styled.img`
  width: 100%;
  max-width: 320px;
  max-height: 320px;
  object-fit: contain;
  margin: auto;
  vertical-align: middle;
`;

const LeftArrowContainer = styled.button`
  width: 100px;
  height: 100px;
  margin-left: -150px;
  margin-top: 220px;
  background-image: url(${leftArrow});
  background-size: cover;
  background-color: transparent;
  border: hidden;
`;

const RightArrowContainer = styled.button`
  width: 100px;
  height: 100px;
  margin-right: -150px;
  margin-top: 220px;
  background-image: url(${rightArrow});
  background-size: cover;
  background-color: transparent;
  border: hidden;
`;

const MobileArrowBox = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 260px;
  z-index: 10;
`;

const MobileLeft = styled.button`
  width: 30px;
  height: 30px;
  background-image: url(${mobileLeft});
  background-size: cover;
  background-color: transparent;
  border: hidden;
`;

const MobileRight = styled.button`
  width: 30px;
  height: 30px;
  background-image: url(${mobileRight});
  background-size: cover;
  background-color: transparent;
  border: hidden;
`;

const InnerFullContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  min-height: 540px;
`;

const ButtonContainer = styled.div`
  width: 93%;
  display: flex;
  justify-content: right;
  margin-top: 24px;
  gap: 18px;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 10;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.light_red};
  color: ${palette.white};
  background-color: ${palette.light_red};
  z-index: 10;
`;

function isMobileDevice() {
  return "ontouchstart" in window || "onmsgesturechange" in window;
}

function ProfileHistory({
  className,
  onClose,
  maskClosable,
  visible,
  historyList,
  setHistoryList,
  isUserPage,
}) {
  const [history, setHistory] = useState(historyList);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setHistory(historyList);
    console.log(historyList);
  }, [historyList]);

  const handleCopyClipBoard = async (text) => {
    var textarea = document.createElement("textarea");
    textarea.value = text; // 복사할 메시지
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 9999); // For IOS
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("링크 복사 완료!");
  };

  const rightOnClick = () => {
    if (history.length > 0) {
      let indexNum = (history.length + selectedIndex + 1) % history.length;
      setSelectedIndex(indexNum);
    }
  };

  const leftOnClick = () => {
    if (history.length > 0) {
      let indexNum = (history.length + selectedIndex - 1) % history.length;
      setSelectedIndex(indexNum);
    }
  };

  const deleteOnClick = async (idx) => {
    const deleteResult = await deleteProfileHistory(
      localStorage.getItem("userID"),
      idx
    ).then(async (data) => {
      console.log(data);
      const profileHistoryResult = await getProfileHistory(
        localStorage.getItem("userID")
      ).then((data) => {
        setHistory(data);
      });
    });
  };

  const hideOnClick = async (idx, isHide) => {
    const hideResult = await hideProfileHistory(
      localStorage.getItem("userID"),
      idx,
      isHide
    ).then(async (data) => {
      console.log(data);
      const profileHistoryResult = await getProfileHistory(
        localStorage.getItem("userID")
      ).then((data) => {
        setHistory(data);
      });
    });
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <InnerFullContainer>
            {isMobileDevice() ? (
              <React.Fragment>
                <MobileArrowBox>
                  <MobileLeft onClick={leftOnClick} />
                  <MobileRight onClick={rightOnClick} />
                </MobileArrowBox>
              </React.Fragment>
            ) : (
              <LeftArrowContainer onClick={leftOnClick}></LeftArrowContainer>
            )}
            <ContentContainer>
              <PopupTitle>Profile History</PopupTitle>
              {history[selectedIndex] ? (
                <HistoryDate>
                  {new Date(history[selectedIndex]?.createdAt)
                    .getFullYear()
                    .toString()}
                  .{new Date(history[selectedIndex]?.createdAt).getMonth() + 1}.
                  {new Date(history[selectedIndex]?.createdAt).getDate()}
                </HistoryDate>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <BoxContainer>
                <ImageBox>
                  <Image src={history[selectedIndex]?.imgUrl} />
                </ImageBox>
                {isUserPage || history.length < 1 ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <ButtonContainer>
                    {history[selectedIndex]?.hide ? (
                      <LeftButton
                        onClick={() =>
                          hideOnClick(history[selectedIndex]?.index, false)
                        }
                      >
                        Unhide
                      </LeftButton>
                    ) : (
                      <LeftButton
                        onClick={() =>
                          hideOnClick(history[selectedIndex]?.index, true)
                        }
                      >
                        Hide
                      </LeftButton>
                    )}

                    <RightButton
                      onClick={() =>
                        deleteOnClick(history[selectedIndex]?.index)
                      }
                    >
                      Delete
                    </RightButton>
                  </ButtonContainer>
                )}
              </BoxContainer>
              <CloseButton onClick={closeOnClick}>
                <RiCloseFill />
              </CloseButton>
            </ContentContainer>
            {isMobileDevice() ? (
              <React.Fragment></React.Fragment>
            ) : (
              <RightArrowContainer onClick={rightOnClick}></RightArrowContainer>
            )}
          </InnerFullContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

ProfileHistory.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 420px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 540px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 8px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

export default ProfileHistory;
