import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import SettingIcon from "../../../assets/icons/dashboard/icon-setting.svg";
import LogoutIcon from "../../../assets/icons/dashboard/icon-logout.svg";
import Typography from "../../../utils/styles/typography";
import NotificationAction from "./NotificationAction";
import NotiDetail from "../../../pages/dashboard/NotificationPage/components/NotiDetail";
import {
  getAllNoti,
  deleteListNoti,
  getNotiDetail,
  checkAllNoti,
} from "../../../utils/api/notifications";
import SetNickname from "../../../pages/dashboard/NotificationPage/components/SetNickname";
import FinalConfirmation from "../../../pages/dashboard/FinancePage/components/FinalConfirmation";
import WalletList from "../../../datas/WalletListData";
import NotiForm from "../../../pages/dashboard/NotificationPage/forms/NotiForm";
import { View } from "react-native-web";
import GoldenBell from "../../../assets/icons/dashboard/icon-goldenBell.svg";
import NotiDeleteIcon from "../../../assets/icons/dashboard/icon-notiDelete.svg";
import BubbleTail from "../../../assets/icons/dashboard/icon-bubbleTail.svg";
import ChevronRight from "../../../assets/icons/dashboard/icon-chevronRight.svg";

const DropBoxContainer = styled.div`
  width: 400px;
  max-height: 604px;
  //   min-height: 445px;
  border-radius: 20px;
  background-color: ${palette.background};
  position: absolute;
  right: 50px;
  top: 86px;
  z-index: 5;
  box-shadow: 0px 2px 10px #c4c4c440;
`;

const DropBoxTitle = styled.div`
  width: 100%;
  height: 64px;
  border-radius: 20px 20px 0px 0px;
  background-color: ${palette.white};
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
`;

const DropBoxTitleText = styled.div`
  ${Typography.Heading2}
`;

const MakeToReadButton = styled.button`
  ${Typography.Heading5}
  color: ${palette.blue};
  border: hidden;
  background-color: transparent;
`;

const SingleNotiBox = styled.button`
  display: flex;
  width: 100%;
  min-height: 120px;
  padding: 24px 20px;
  border: 0px;
  border-bottom: 1px solid ${palette.footer};
  background-color: ${palette.white};
`;

const ThumbnailBox = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 28px;
`;

const BoxContent = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;
`;

const ContentHeader = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
`;

const ContentTitle = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${palette.light_black};
`;

const MinutesAgo = styled.div`
  ${Typography.Body}
  color: ${palette.gray};
  margin: auto 0px;
`;

const ContentDetail = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${palette.light_black};
`;

const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const ActionButton = styled.button`
  height: 31px;
  background-color: ${palette.noti_action_button};
  border-radius: 70px;
  border: hidden;
  color: ${palette.marine};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 8px 14px;
  margin-top: 20px;
`;

const BottomBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
  justify-content: right;
`;

const ViewAllButton = styled.button`
  border: hidden;
  ${Typography.Heading5}
  color: ${palette.light_black};
  background-color: transparent;
  height: 20px;
  display: flex;
`;

const ListContainer = styled.div`
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 321px;
  padding-top: 80px;
  align-items: center;
  justify-content: center;
`;

const BellBox = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

const EmptyTitle = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const EmptyDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: ${palette.gray};
  margin-top: 6px;
`;

const NotiDeleteButton = styled.button`
  width: 18px;
  height: 18px;
  background-color: transparent;
  background-image: url(${NotiDeleteIcon});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const WindowTriangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent ${palette.white} transparent;
  position: absolute;
  top: -15px;
  right: 40px;
  //   box-shadow: 0px 2px 10px #c4c4c440;
`;

const BubbleTailBox = styled.div`
  width: 16px;
  height: 10px;
  position: absolute;
  top: -8px;
  right: 35px;
  background-color: transparent;
  background-image: url(${BubbleTail});
  //   box-shadow: 0px 2px 10px #c4c4c440;
  filter: drop-shadow(0px 2px 10px #c4c4c444);
`;

const ViewAllText = styled.div`
  margin: auto 0px;
`;

const ArrowBox = styled.img`
  width: 16px;
  height: 16px;
  margin: auto 0px;
  margin-left: 4px;
`;

const minuteConvert = (value) => {
  var units = {
    year: 24 * 60 * 365,
    month: 24 * 60 * 30,
    week: 24 * 60 * 7,
    day: 24 * 60,
    hour: 60,
    minute: 1,
  };

  var result = [];

  for (var name in units) {
    var p = Math.floor(value / units[name]);
    if (p == 1) {
      result.push(p + " " + name);
      break;
    }
    if (p >= 2) {
      result.push(p + " " + name + "s");
      break;
    }
    value %= units[name];
  }

  return result;
};

const NotificationBox = ({ className, onClose, maskClosable, visible }) => {
  const [nickName, setNickName] = useState(localStorage.getItem("userID"));
  const [userId, setUserId] = useState(localStorage.getItem("userID"));
  const [notiList, setNotiList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [selectNoti, setSelectNoti] = useState({});
  const [acceptNotiInfo, setAcceptNotiInfo] = useState({});
  const [finalConfirmationVisible, setFinalConfirmationVisible] =
    useState(false);
  const [deleteOn, setDeleteOn] = useState(false);
  const [finalConfirmationType, setFinalConfirmationType] = useState("");
  const [requestInfo, setRequestInfo] = useState({});
  const [mouseOnIdx, setMouseOnIdx] = useState(-1);
  const [deleteOnClickFirst, setDeleteOnClickFirst] = useState(false);
  const [markOn, setMarkOn] = useState(false);

  //   useEffect(() => {
  //     setNotiList([
  //       {
  //         index: 482,
  //         user: "gkrry2723",
  //         notificationType: 7,
  //         relatedObjIdx: 17,
  //         thumbnailImg:
  //           "https://daotool.s3.ap-northeast-2.amazonaws.com/static/notification_icon/bdff088d-bbd6-495b-9388-68cf72f9f3a7finance_received.png",
  //         thumbnailUserId: null,
  //         message: "zoozoo_club3 sends you token. Check it out.",
  //         createdAt: "2022-10-24T13:01:08.000+00:00",
  //         typeMeta: "get token",
  //         relatedTable: "transaction",
  //         title: "Receive Token",
  //         checked: true,
  //         minitesAgo: 20200,
  //       },
  //       {
  //         index: 481,
  //         user: "gkrry2723",
  //         notificationType: 8,
  //         relatedObjIdx: 18,
  //         thumbnailImg:
  //           "https://daotool.s3.ap-northeast-2.amazonaws.com/static/notification_icon/ac908f4d-a459-4ba7-87a6-a4dd5af66a23finance_requested.png",
  //         thumbnailUserId: null,
  //         message: "zoozoo_club3 requests1.2. \ntest2\n Would you like to send?",
  //         createdAt: "2022-10-24T12:46:11.000+00:00",
  //         typeMeta: "get token reqeust",
  //         relatedTable: "tokenReq",
  //         title: "Token Request",
  //         checked: true,
  //         minitesAgo: 20215,
  //       },
  //       {
  //         index: 482,
  //         user: "gkrry2723",
  //         notificationType: 2,
  //         relatedObjIdx: 18,
  //         thumbnailImg:
  //           "https://daotool.s3.ap-northeast-2.amazonaws.com/static/notification_icon/ac908f4d-a459-4ba7-87a6-a4dd5af66a23finance_requested.png",
  //         thumbnailUserId: null,
  //         message:
  //           "@UserID requested a friend. Press the button to accept the friend request.",
  //         createdAt: "2022-10-24T12:46:11.000+00:00",
  //         typeMeta: "get token reqeust",
  //         relatedTable: "tokenReq",
  //         title: "Friend Request",
  //         checked: true,
  //         minitesAgo: 20215,
  //       },
  //     ]);
  //   }, []);

  useEffect(() => {
    (async () => {
      const getAllNotiResult = await getAllNoti(
        localStorage.getItem("userID")
      ).then((data) => {
        setNotiList(data);
      });
    })();
  }, [deleteOn, markOn]);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeFinalConfirmationModal = () => {
    setFinalConfirmationVisible(false);
  };

  const closeSetNicknameModal = () => {
    setIsAccept(false);
  };

  const viewAllOnClick = () => {
    window.location.href = "/noti";
  };

  const singleNotiOnClick = async (idx, type, thumbnailUserId) => {
    const getNotiDetailResult = await getNotiDetail(
      idx,
      localStorage.getItem("userID")
    ).then((data) => {
      console.log(data);
    });

    if (type == 1) {
      // welcome
      window.location.href = "/leaderboard";
    } else if (type == 3 || type == 5) {
      // friend request accepted, new follower | 친구 대시보드로 이동
      window.location.href = `/@${thumbnailUserId}`;
    } else if (type == 4) {
      // new comment | 나의 코멘트 페이지로 이동
      window.location.href = `/@${localStorage.getItem("userID")}/comments`;
    } else if (type == 6) {
      // event | 해당 이벤트 페이지로 이동
      window.location.href = "http://POAP.xyz/claim/3x0wub";
    } else if (type == 7) {
      // receive token | 받은 토큰 확인을 위해 트랜젝션 페이지로 이동.
      window.location.href = "/transactions";
    }
  };

  const deleteOnClick = async (idx, event) => {
    event.stopPropagation();
    const deleteListNotiResult = await deleteListNoti(
      [idx],
      localStorage.getItem("userID")
    ).then((data) => {
      console.log(data);
      setDeleteOn(!deleteOn);
    });
  };

  const markAllAsReadOnClick = async () => {
    const checkAllNotiResult = await checkAllNoti(
      localStorage.getItem("userID")
    ).then((data) => {
      console.log(data);
      setMarkOn(!markOn);
    });
  };

  return (
    <>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        {modalVisible ? (
          <NotiDetail
            visible={modalVisible}
            closable={true}
            maskClosable={true}
            onClose={closeModal}
            noti={selectNoti}
            setIsAccept={setIsAccept}
            setAcceptNotiInfo={setAcceptNotiInfo}
            setFinalConfirmationVisible={setFinalConfirmationVisible}
          />
        ) : (
          <React.Fragment>
            {isAccept ? (
              <SetNickname
                visible={isAccept}
                closable={true}
                maskClosable={true}
                onClose={closeSetNicknameModal}
                noti={acceptNotiInfo}
              />
            ) : (
              <React.Fragment>
                {finalConfirmationVisible ? (
                  <FinalConfirmation
                    visible={finalConfirmationVisible}
                    closable={true}
                    maskClosable={true}
                    onClose={closeFinalConfirmationModal}
                    type={finalConfirmationType}
                    confirmInfo={requestInfo}
                    sendFunction={() => {}}
                  />
                ) : (
                  <></>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <DropBoxContainer>
          {/* <WindowTriangle /> */}
          <BubbleTailBox />
          <DropBoxTitle>
            <DropBoxTitleText>Notifications</DropBoxTitleText>
            {notiList.length > 0 ? (
              <MakeToReadButton onClick={markAllAsReadOnClick}>Mark all as read</MakeToReadButton>
            ) : (
              <MakeToReadButton style={{ color: palette.light_gray }}>
                Mark all as read
              </MakeToReadButton>
            )}
          </DropBoxTitle>
          {notiList.length > 0 ? (
            <ListContainer>
              {notiList.map((v, i) => (
                <SingleNotiBox
                  onClick={() =>
                    singleNotiOnClick(
                      v.index,
                      v.notificationType,
                      v.thumbnailUserId
                    )
                  }
                  onMouseEnter={() => setMouseOnIdx(i)}
                  onMouseLeave={() => setMouseOnIdx(-1)}
                  style={
                    v.checked ? {} : { backgroundColor: palette.noti_back }
                  }
                >
                  <ThumbnailBox src={v.thumbnailImg} />
                  <BoxContent>
                    <ContentHeader>
                      <ContentTitle>{v.title}</ContentTitle>
                      <MinutesAgo>{minuteConvert(v.minitesAgo)}</MinutesAgo>
                    </ContentHeader>
                    <ContentDetail>
                      {v.type == "8" ? (
                        <>
                          {v.message.split("/n").map((it, i) => (
                            <div key={"x" + i}>{it}</div>
                          ))}
                        </>
                      ) : (
                        <>
                          {v.message.split("\n").map((it, i) => (
                            <div key={"x" + i}>{it}</div>
                          ))}
                        </>
                      )}
                    </ContentDetail>
                    {NotificationAction[v.notificationType - 1].actionText ? (
                      <ActionContainer>
                        <ActionButton
                          onClick={() => {
                            setSelectNoti(v);
                            if (v.notificationType == 2) {
                              NotiForm[v.notificationType]
                                ?.NotiAcceptAction(
                                  localStorage.getItem("userID"),
                                  v.thumbnailUserId,
                                  "bestie",
                                  setIsAccept,
                                  setAcceptNotiInfo
                                )
                                .then(() => {
                                  closeModal();
                                });
                            } else if (
                              v.notificationType == 8 ||
                              v.notificationType == 9
                            ) {
                              NotiForm[v.notificationType]?.NotiAcceptAction(
                                closeModal,
                                setFinalConfirmationVisible,
                                setFinalConfirmationType,
                                setRequestInfo,
                                v.relatedObjIdx
                              );
                            } else {
                              NotiForm[v.notificationType]?.NotiAcceptAction();
                            }
                          }}
                        >
                          {
                            NotificationAction[v.notificationType - 1]
                              .actionText
                          }
                        </ActionButton>
                      </ActionContainer>
                    ) : (
                      <></>
                    )}
                    {mouseOnIdx == i ? (
                      <NotiDeleteButton
                        onClick={(e) => deleteOnClick(v.index, e)}
                      />
                    ) : (
                      <></>
                    )}
                  </BoxContent>
                </SingleNotiBox>
              ))}
            </ListContainer>
          ) : (
            <EmptyContainer>
              <BellBox src={GoldenBell} />
              <EmptyTitle>No notifications</EmptyTitle>
              <EmptyDescription>
                There are no new notifications yet!
              </EmptyDescription>
            </EmptyContainer>
          )}
          <BottomBox>
            <ViewAllButton onClick={viewAllOnClick}>
              <ViewAllText>View All</ViewAllText>
              <ArrowBox src={ChevronRight} />
            </ViewAllButton>
          </BottomBox>
        </DropBoxContainer>
      </ModalWrapper>
    </>
  );
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  z-index: 999;
`;

export default NotificationBox;
