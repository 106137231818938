export default class BadgeOwnerDetailDto {
    constructor(id, title, isMinted, imgUrl, txs: OwnerTx) {
        this.id = id;
        this.title = title;
        this.isMinted = isMinted;
        this.imgUrl = imgUrl;
        this.txs = txs;
    }
}

export class OwnerTx {
    constructor(id, imgUrl, walletAdd, mintingDate) {
        this.id = id;
        this.imgUrl = imgUrl;
        this.walletAdd = walletAdd;
        this.mintingDate = mintingDate;
    }
}