import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import useWindowDimensions from "../../../../utils/functions/useWindowDimensions";
import Rank1 from "../../../../assets/icons/dashboard/rank1.svg";
import Rank2 from "../../../../assets/icons/dashboard/rank2.svg";
import Rank3 from "../../../../assets/icons/dashboard/rank3.svg";

const Container = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  max-width: 680px;
  margin: auto;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
`;

const RankBlock = styled.button`
  width: 100%;
  max-width: 210px;
  height: 260px;
  background-color: ${palette.white};
  border-radius: 32px;
  align-self: end;
  //   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  border: hidden;
  //   padding-bottom: 23px;
`;

const CommentProfileImage = styled.img`
  //   width: 50%;
  // height: 30%;
  width: 100%;
  object-fit: cover;
  border-radius: 200px;
`;

const ImageBox = styled.div`
  position: relative;
  border-radius: 200px;
  border: 1px solid ${palette.black};
  margin: 0px auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const CommentInfoBox = styled.div`
  // width: 100%;
  align-self: center;
  margin-bottom: 12px;
  margin-top: 30px;
`;

const CommentCustomName = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  //   line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: auto;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  //   line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: auto;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RankImage = styled.img`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: -30px;
  width: 60px;
  z-index: 5;
`;

const TopRank = (props) => {
  const { height, width } = useWindowDimensions();
  const [rankList, setRankList] = useState(props.userList);

  useEffect(() => {
    setRankList(props.userList);

    console.log(props.userList);
  }, [props.userList]);

  return (
    <Container>
      {rankList[1] ? (
        <React.Fragment>
          {width > 600 ? (
            <React.Fragment>
              <RankBlock
                onClick={() => {
                  window.location.href = "/@" + rankList[1].user.id;
                }}
                style={{ maxWidth: "200px", height: "220px" }}
              >
                <RankImage src={Rank2} />
                <CommentInfoBox>
                  <CommentCustomName>
                    {rankList[1].user.nickname}
                  </CommentCustomName>
                  <CommentUserId>@{rankList[1].user.id}</CommentUserId>
                </CommentInfoBox>
                <ImageBox
                  style={{
                    height: "100px",
                    width: 0,
                    paddingRight: "100px",
                    backgroundImage: `url(${rankList[1].user.profileImg})`,
                  }}
                />
              </RankBlock>
              <RankBlock
                onClick={() => {
                  window.location.href = "/@" + rankList[0].user.id;
                }}
              >
                <RankImage src={Rank1} />
                <CommentInfoBox>
                  <CommentCustomName>
                    {rankList[0].user.nickname}
                  </CommentCustomName>
                  <CommentUserId>@{rankList[0].user.id}</CommentUserId>
                </CommentInfoBox>
                <ImageBox
                  style={{
                    height: "120px",
                    width: 0,
                    paddingRight: "120px",
                    backgroundImage: `url(${rankList[0].user.profileImg})`,
                  }}
                />
              </RankBlock>
              <RankBlock
                onClick={() => {
                  window.location.href = "/@" + rankList[2].user.id;
                }}
                style={{ maxWidth: "200px", height: "200px" }}
              >
                <RankImage src={Rank3} />
                <CommentInfoBox>
                  <CommentCustomName>
                    {rankList[2].user.nickname}
                  </CommentCustomName>
                  <CommentUserId>@{rankList[2].user.id}</CommentUserId>
                </CommentInfoBox>
                <ImageBox
                  style={{
                    height: "80px",
                    width: 0,
                    paddingRight: "80px",
                    backgroundImage: `url(${rankList[2].user.profileImg})`,
                  }}
                />
              </RankBlock>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <RankBlock style={{ maxWidth: "200px", height: "220px" }}>
                <RankImage src={Rank2} />
                <CommentInfoBox>
                  <CommentCustomName style={{ fontSize: "14px" }}>
                    {rankList[1].user.nickname}
                  </CommentCustomName>
                  <CommentUserId style={{ fontSize: "10px" }}>
                    @{rankList[1].user.id}
                  </CommentUserId>
                </CommentInfoBox>
                <ImageBox
                  style={{
                    width: "60%",
                    height: 0,
                    paddingBottom: "60%",
                    backgroundImage: `url(${rankList[1].user.profileImg})`,
                  }}
                />
              </RankBlock>
              <RankBlock>
                <RankImage src={Rank1} />
                <CommentInfoBox>
                  <CommentCustomName style={{ fontSize: "14px" }}>
                    {rankList[0].user.nickname}
                  </CommentCustomName>
                  <CommentUserId style={{ fontSize: "10px" }}>
                    @{rankList[0].user.id}
                  </CommentUserId>
                </CommentInfoBox>
                <ImageBox
                  style={{
                    width: "80%",
                    height: 0,
                    paddingBottom: "80%",
                    backgroundImage: `url(${rankList[0].user.profileImg})`,
                  }}
                />
              </RankBlock>
              <RankBlock style={{ maxWidth: "200px", height: "200px" }}>
                <RankImage src={Rank3} />
                <CommentInfoBox>
                  <CommentCustomName style={{ fontSize: "14px" }}>
                    {rankList[2].user.nickname}
                  </CommentCustomName>
                  <CommentUserId style={{ fontSize: "10px" }}>
                    @{rankList[2].user.id}
                  </CommentUserId>
                </CommentInfoBox>
                <ImageBox
                  style={{
                    width: "50%",
                    height: 0,
                    paddingBottom: "50%",
                    backgroundImage: `url(${rankList[2].user.profileImg})`,
                  }}
                />
              </RankBlock>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Container>
  );
};

export default TopRank;
