import NewCardData from "./dtos/NewCardData";
import user_badge_1 from "../assets/mepe_dummy_data/user_badge/user_badge_1.svg";
import user_badge_2 from "../assets/mepe_dummy_data/user_badge/user_badge_2.svg";
import user_nft_01 from "../assets/mepe_dummy_data/user_nfts/user_nft_01.svg";
import user_nft_02 from "../assets/mepe_dummy_data/user_nfts/user_nft_02.svg";
import user_nft_03 from "../assets/mepe_dummy_data/user_nfts/user_nft_03.svg";
import user_nft_04 from "../assets/mepe_dummy_data/user_nfts/user_nft_04.svg";
import user_nft_05 from "../assets/mepe_dummy_data/user_nfts/user_nft_05.svg";
import user_nft_06 from "../assets/mepe_dummy_data/user_nfts/user_nft_06.svg";
import user_nft_07 from "../assets/mepe_dummy_data/user_nfts/user_nft_07.svg";
import user_nft_08 from "../assets/mepe_dummy_data/user_nfts/user_nft_08.svg";
import user_poap_01 from "../assets/mepe_dummy_data/user_poaps/user_poap_01.svg";
import user_poap_02 from "../assets/mepe_dummy_data/user_poaps/user_poap_02.svg";
import user_poap_03 from "../assets/mepe_dummy_data/user_poaps/user_poap_03.svg";
import user_poap_04 from "../assets/mepe_dummy_data/user_poaps/user_poap_04.svg";
import user_poap_05 from "../assets/mepe_dummy_data/user_poaps/user_poap_05.svg";
import user_poap_06 from "../assets/mepe_dummy_data/user_poaps/user_poap_06.svg";
import user_poap_07 from "../assets/mepe_dummy_data/user_poaps/user_poap_07.svg";
import user_poap_08 from "../assets/mepe_dummy_data/user_poaps/user_poap_08.svg";
import coco_image from "../assets/images/coco_image.svg";
import dudu_image from "../assets/images/dudu_image.svg";

export const newCardDummyData = [
  new NewCardData(
    1,
    "SuperRich",
    312,
    0.0,
    ["new", "property", "data"],
    "",
    false
  ),
  new NewCardData(2, "Uni", 0, 0.0, [], "", true),
  new NewCardData(3, "SuperRich", 0, 0.0, [], "", false),
  new NewCardData(4, "SuperRich", 0, 0.0, [], "", false),
  new NewCardData(5, "SuperRich", 0, 0.0, [], "", true),
  new NewCardData(6, "Uni", 0, 0.0, [], "", false),
  new NewCardData(7, "SuperRich", 3123, 0.0, [], "", true),
  new NewCardData(8, "SuperRich", 0, 0.0, [], "", false),
  new NewCardData(9, "SuperRich", 0, 0.0, [], "", false),
  new NewCardData(10, "Uni", 0, 0.0, [], "", true),
  new NewCardData(11, "SuperRich", 0, 0.0, [], "", false),
  new NewCardData(12, "SuperRich", 0, 0.0, [], "", true),
];

export const badgeDummyData = [
  {
    badgeIndex: 1,
    badgeWalletIndex: 1,
    address: "0x7D8820FA92EB1584636f4F5b8515B5476B75171a",
    hidden: false,
    tag: "",
    badgeTitle: "mepe user",
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
    image: user_badge_1,
  },
  {
    badgeIndex: 2,
    badgeWalletIndex: 1,
    address: "0x7D8820FA92EB1584636f4F5b8515B5476B75171a",
    hidden: false,
    tag: "",
    badgeTitle: "mepe earlybird",
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
    image: user_badge_2,
  },
];

export const nftDummyData = [
  {
    title: "",
    image: user_nft_01,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_nft_02,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_nft_03,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_nft_04,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_nft_05,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_nft_06,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_nft_07,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_nft_08,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
];

export const poapDummyData = [
  {
    title: "",
    image: user_poap_01,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_poap_02,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_poap_03,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_poap_04,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_poap_05,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_poap_06,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_poap_07,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
  {
    title: "",
    image: user_poap_08,
    hidden: false,
    obtainedAt: "2022-05-08T03:33:40.000+00:00",
  },
];

export const commentDummyData = [
  {
    userId: "dudu",
    customName: "dudu",
    nickName: "dudu",
    comment: "WOW",
    hidden: false,
    image: dudu_image,
    createdAt: "2022-04-01T13:24:01.000+00:00",
  },
  {
    userId: "coco",
    customName: "coco",
    nickName: "coco",
    comment: "I became a discord bot thanks to you!",
    hidden: false,
    image: coco_image,
    createdAt: "2022-04-01T13:24:01.000+00:00",
  },
];
