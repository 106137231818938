import styled from "styled-components";
import { COLORS as c } from "../../../utils/styles/colors";
import SizeBox from "../../../components/util/SizeBox";
import { ReactComponent as LeftArrow } from "../../../assets/icons/minting/icon-leftArrow.svg";
import { ReactComponent as Ether } from "../../../assets/icons/chain/icon-ether.svg";
import UrlImageLoader from "../../../components/util/UrlImageLoader";
import BasicInput from "../../../components/basic/BasicInput";
import BadgeClaimDto from "../../../datas/dtos/BadgeClaimDto";
import ColoredBtn from "../../../components/basic/ColoredBtn";
import { Link } from "react-router-dom";
import Header from "../../../components/global/Header/index";
import { useState } from "react";

const BackBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  width: calc(100% - 170px);
  //max-width: 1240px;
  height: 840px;

  padding: 0px 60px;
  border-radius: 40px;
  background-color: ${c.white};
`;

const BackBtnContainer = styled.button`
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  border: none;
  background: transparent;
`;

const BackText = styled.text`
  color: ${c.gray};
  font-weight: 700;
  font-size: 14px;
`;

const MainText = styled.text`
  font-size: 24px;
  font-weight: 800;
  color: ${c.black};
`;

const AuthorText = styled.button`
  cursor: pointer;
  user-select: none;

  border: none;
  background-color: transparent;

  font-size: 12px;
  font-weight: 600;
  color: ${c.gray};
`;

const Price = styled.div`
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 700;
  color: ${c.light_black};
`;

const UnderLineText = styled.button`
  cursor: pointer;
  user-select: none;

  border: none;
  background-color: transparent;

  text-decoration-line: underline;
  font-size: 12px;
  font-weight: 600;
  color: ${c.gray};
`;

function BadgeClaim() {
  const [profileImg, setProfileImage] = useState(
    localStorage.getItem("profileImage")
  );
  let data = new BadgeClaimDto(1, "MGD pledge", "mgd", 131230.0, "DAI");

  return (
    <div className={"f-column a-center j-center"}>
      <Header profileImg={profileImg}/>
      <SizeBox h={97} />
      <BackBoard>
        <SizeBox h={40} />
        <Link to={"/"}>
          <BackBtnContainer>
            <LeftArrow />

            <SizeBox w={8} />
            <BackText>Back to Previous</BackText>
          </BackBtnContainer>
        </Link>

        <SizeBox h={100} />
        <div className={"f-column a-center j-center"}>
          <MainText>Claim Your Badge</MainText>

          <SizeBox h={28} />
          <UrlImageLoader w={160} imgUrl={""} />

          <SizeBox h={28} />
          <MainText>{data.title}</MainText>

          <SizeBox h={5} />
          <AuthorText>by @{data.author}</AuthorText>

          <SizeBox h={14} />
          <Price>
            Price :
            <SizeBox w={4} />
            <Ether />
            <SizeBox w={4} />
            {data.price.toFixed(1)} {data.symbol}
          </Price>

          <SizeBox h={20} />
          <SizeBox w={327}>
            <BasicInput holder={"Your Wallet Address"} iconOn={false} />
          </SizeBox>

          <SizeBox h={10} />
          <UnderLineText>or find your wallet address</UnderLineText>

          <SizeBox h={45} />
          <ColoredBtn>Claim</ColoredBtn>
        </div>
      </BackBoard>
    </div>
  );
}

export default BadgeClaim;
