import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../../utils/functions/useWindowDimensions";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

const Header = (props) => {
  const [profileImg, setProfileImg] = useState(props.profileImg);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    setProfileImg(props.profileImg);
  }, [props.profileImg]);

  return (
    <React.Fragment>
      {width > 650 ? (
        <DesktopHeader profileImg={profileImg} />
      ) : (
        <MobileHeader profileImg={profileImg} />
      )}
    </React.Fragment>
  );
};

export default Header;
