import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-x.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import DefaultProfileImage from "../../../../assets/images/dudu_image.svg";
import DefaultBgImage from "../../../../assets/images/main_banner_image.svg";
import { editProfile, login } from "../../../../utils/api/auth";

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 900px;
  // margin-top: 90px;
  margin-top: 0%;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
`;

const ControlHeader = styled.div`
  height: 70px;
  padding-top: 47.5px;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const ContentContainer = styled.div`
  align-items: center;
  width: 100%;
`;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  margin-left: 10px;
  width: 100%;
  // padding-left: 16px;
  border: 0;
  margin-top: 18px;
`;

const BasicUrl = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.black};
`;

const CreateButton = styled.button`
  width: 97px;
  max-width: 97px;
  height: 36px;
  background-color: ${palette.marine};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
  margin-bottom: 111px;
  margin-top: 64px;
`;

const BottomContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const BackgroundImageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 26px;
`;

const BackgroundImageButton = styled.button`
  width: 100%;
  height: 200px;
  border: 0;
`;

const ProfileImageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: -60px;
`;

const ProfileImageButton = styled.button`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 1px solid #777777;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 30px;
  margin: auto;
  margin-left: 7%;
`;

const InputFullContainer = styled.div`
  width: 95%;
  margin: 0px auto;
`;

const InputTitle = styled.div`
  ${Typography.Heading2}
  color: ${palette.light_black};
  margin-top: 24px;
`;

const InputBox = styled.input`
  height: 42px;
  margin-left: 5px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  padding-left: 16px;
  background-color: ${palette.background};
  border: 0;
  border-radius: 8px;
  // margin-top: 18px;
`;

const TextBox = styled.textarea`
  height: 100px;
  margin-left: 20px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  padding-left: 16px;
  padding-top: 15px;
  background-color: ${palette.background};
  border: 0;
  border-radius: 8px;
  margin-top: 18px;
  resize: none;
`;

const SocialMediaContainer = styled.div`
  width: 100%;
  // padding-left: 20px;
  padding-left: 3%;
`;

const SNSTitle = styled.li`
  ${Typography.Heading3}
  height: 20px;
  color: ${palette.gray};
  margin-top: 16px;
`;

const CreateProfile = (props) => {
  const [userName, setUserName] = useState(props.userName);
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);
  const [profileImage, setProfileImage] = useState({
    file: DefaultProfileImage,
    imagePreviewUrl: DefaultProfileImage,
  });
  const [bgImage, setBgImage] = useState({
    file: DefaultBgImage,
    imagePreviewUrl: DefaultBgImage,
  });
  const [introduction, setIntroduction] = useState("");
  const [twitterLink, setTwiiterLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [discordLink, setDiscordLink] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    var regExp = /[a-zA-Z0-9_]{5,19}$/g;

    var testResult = regExp.test(userName);

    if (userName.length <= 4 || userName.length >= 20) {
      setErrorComment(
        "Your user ID must be longer than 4, less than 20 characters."
      );
    } else if (!testResult) {
      setErrorComment(
        "Your username can only contain letters, numbers and '_'"
      );
    } else {
      setErrorComment("");
    }
    // else if(){ //중복체크
    // setErrorComment("That user ID has been taken. Please choose another.")
    // }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  const nextButtonOnClick = async () => {
    // edit profile하기
    if (userName) {
      const formData = new FormData();

      console.log(profileImage.file);
      console.log(profileImage);
      console.log(bgImage.file);
      console.log(bgImage);

      formData.append("profileImage", profileImage.file);
      formData.append("backImage", bgImage.file);
      console.log(localStorage.getItem("token"));
      const userInfoValue = {
        jwtToken: localStorage.getItem("token"),
        profileName: userName,
        introduction: introduction,
        url: "https://mepe.app/@" + props.userName,
        twitter: twitterLink,
        facebook: facebookLink,
        discord: discordLink,
        link: link,
      };
      formData.append("json", JSON.stringify(userInfoValue));

      const editProfileResult = await editProfile(
        props.userName,
        formData,
        "create"
      )
        .then(async (data) => {
          setConnectWallet(true);
          if (data.code == 200) {
            const loginResult = await login(props.userName).then((data) => {
              console.log(data);
              window.location.href = `/mypage`;
            });
          } else {
            alert("Error! Please try again.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Profile Name cannot be blank. Please enter at least 1 character.");
    }
  };

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
    console.log("??");
  };

  const handleChange = (event) => {
    console.log("hello");
    let reader = new FileReader();
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    setProfileImage({ file: fileUploaded, imagePreviewUrl: fileUploaded });
    reader.onloadend = () => {
      setProfileImage({ file: fileUploaded, imagePreviewUrl: reader.result });
    };
    reader.readAsDataURL(fileUploaded);
  };

  const hiddenFileInputBg = useRef(null);

  const handleBgClick = (event) => {
    hiddenFileInputBg.current.click();
  };

  const handleBgChange = (event) => {
    let reader = new FileReader();
    const fileUploaded = event.target.files[0];
    setBgImage({ file: fileUploaded, imagePreviewUrl: fileUploaded });
    reader.onloadend = () => {
      setBgImage({ file: fileUploaded, imagePreviewUrl: reader.result });
    };
    reader.readAsDataURL(fileUploaded);
  };

  const profileNameOnChange = (e) => {
    setUserName(e.target.value);
  };

  const introductionOnChange = (e) => {
    setIntroduction(e.target.value);
  };

  const twitterOnChange = (e) => {
    setTwiiterLink(e.target.value);
  };

  const facebookOnChange = (e) => {
    setFacebookLink(e.target.value);
  };

  const discordOnChange = (e) => {
    setDiscordLink(e.target.value);
  };

  const linkOnChange = (e) => {
    setLink(e.target.value);
  };

  return (
    <React.Fragment>
      <ModalContainer>
        <Wrapper>
          <ControlHeader>
            <HeaderTitle>Create Profile</HeaderTitle>
          </ControlHeader>
          <div className={"f-column a-center"}>
            <ContentContainer>
              <BackgroundImageButtonContainer>
                <BackgroundImageButton
                  onClick={handleBgClick}
                  style={{
                    backgroundImage: `url(${bgImage.imagePreviewUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <input
                  type="file"
                  ref={hiddenFileInputBg}
                  onChange={(e) => {
                    handleBgChange(e);
                  }}
                  style={{ display: "none" }}
                />
              </BackgroundImageButtonContainer>
              <ProfileImageButtonContainer>
                <ProfileImageButton
                  onClick={handleClick}
                  style={{
                    backgroundImage: `url(${profileImage.imagePreviewUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ display: "none" }}
                />
              </ProfileImageButtonContainer>
              <InputFullContainer>
                <InputTitle>Profile Name</InputTitle>
                <InputBox
                  value={userName}
                  onChange={(e) => profileNameOnChange(e)}
                />
                <InputTitle>Bio</InputTitle>
                <TextBox
                  value={introduction}
                  onChange={(e) => introductionOnChange(e)}
                />
                <InputTitle>Social Media</InputTitle>
                <SocialMediaContainer>
                  <SNSTitle>twitter</SNSTitle>
                  <UrlContainer>
                    <BasicUrl>https://twitter.com/</BasicUrl>
                    <InputBox
                      value={twitterLink}
                      onChange={(e) => twitterOnChange(e)}
                    />
                  </UrlContainer>
                  <SNSTitle>facebook</SNSTitle>
                  <UrlContainer>
                    <BasicUrl>https://www.facebook.com/</BasicUrl>
                    <InputBox
                      value={facebookLink}
                      onChange={(e) => facebookOnChange(e)}
                    />
                  </UrlContainer>
                  <SNSTitle>discord</SNSTitle>
                  <InputBox
                    value={discordLink}
                    onChange={(e) => discordOnChange(e)}
                  />
                  <SNSTitle>link</SNSTitle>
                  <InputBox value={link} onChange={(e) => linkOnChange(e)} />
                </SocialMediaContainer>
              </InputFullContainer>
              <BottomContainer>
                <CreateButton onClick={nextButtonOnClick}>Create</CreateButton>
              </BottomContainer>
            </ContentContainer>
          </div>
        </Wrapper>
      </ModalContainer>
    </React.Fragment>
  );
};

export default CreateProfile;
