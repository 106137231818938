import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../utils/styles/colors";
import WalletButtonGroup from "../WalletGroup/WalletButtonGroup";
import WalletList from "../../../datas/WalletListData";
import { createWallet } from "../../../utils/api/wallets";
import Typography from "../../../utils/styles/typography";

function AddWalletAddress({
  className,
  onClose,
  maskClosable,
  closable,
  visible,
  children,
  setWalletList,
  walletList,
  mode,
}) {
  const [newWalletAddress, setNewWalletAddress] = useState("");
  const [newWalletId, setNewWalletId] = useState(-1);

  useEffect(() => {
    if (Array.isArray(newWalletAddress)) {
      var tmpWalletList = walletList;
      if (newWalletId != -1) {
        newWalletAddress.map((v, i) => {
          if (walletList.findIndex((val) => val.walletAddress == v) > -1) {
            alert(`The wallet address ${v} already exists!`);
          } else {
            tmpWalletList.push({
              walletAddress: v,
              walletId: newWalletId,
              walletType: WalletList[newWalletId].walletName,
              walletIcon: WalletList[newWalletId].walletIcon,
            });
            console.log(tmpWalletList);
            if (mode == "setting") {
              // wallet 추가
              (async () => {
                const createWalletResult = await createWallet(
                  localStorage.getItem("userID"),
                  v,
                  newWalletId
                )
                  .then((data) => {
                    // setWalletList(tmpWalletList);
                    onClose();
                  })
                  .catch(() => {
                    alert("error!");
                  });
              })();
            } else {
              setWalletList(tmpWalletList);
              onClose();
            }
          }
        });
        setWalletList(tmpWalletList);
      }
    } else {
      if (newWalletId != -1) {
        if (
          walletList.findIndex((val) => val.walletAddress == newWalletAddress) >
          -1
        ) {
          alert("The wallet already exists! Please try another wallet.");
        } else {
          var tmpWalletList = walletList;
          tmpWalletList.push({
            walletAddress: newWalletAddress,
            walletId: newWalletId,
            walletType: WalletList[newWalletId].walletName,
            walletIcon: WalletList[newWalletId].walletIcon,
          });
          console.log(tmpWalletList);
          if (mode == "setting") {
            // wallet 추가
            (async () => {
              const createWalletResult = await createWallet(
                localStorage.getItem("userID"),
                newWalletAddress,
                newWalletId
              )
                .then((data) => {
                  setWalletList(tmpWalletList);
                  onClose();
                })
                .catch(() => {
                  alert("error!");
                });
            })();
          } else {
            setWalletList(tmpWalletList);
            onClose();
          }
        }
      }
    }
  }, [newWalletId]);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>Add Wallet Address</PopupTitle>
            <WalletButtonGroup
              columnNum={1}
              setPageStack={setNewWalletAddress}
              setWalletId={setNewWalletId}
            />
            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

AddWalletAddress.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 740px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 36px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

export default AddWalletAddress;
