import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-x.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import CreateAccountConnectWallet from "./CreateAccountConnectWallet";
import { checkSignup } from "../../../../utils/api/auth.js";

const LogoContainer = styled.div`
  // margin-top: 280px;
  margin-top: 10%;
`;

const ModalContainer = styled.div`
  width: 100%;
  //max-width: 1240px;
  // height: 900px;
  height: 100%;
  // margin-top: 10%;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  // padding-top: 47.5px;
  padding-top: 5%;
  padding-left: 60px;
`;

const ContentContainer = styled.div`
  align-items: center;
`;

const MainComment = styled.div`
  height: 32px;
  ${Typography.Heading1}
  text-align: center;
`;

const RedComment = styled.div`
  color: ${palette.marine};
  width: 100%;
  ${Typography.Heading2}
  text-align: center;
  // color: ${palette.light_red};
`;

const UserNameInputBox = styled.div`
  width: 80%;
  height: 64px;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 36px;
  border: 2px solid ${palette.blue};
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.background};
  margin: 0px auto;
  margin-bottom: 8px;
  margin-top: 32px;
`;

const UserNameInput = styled.input`
  width: 80%;
  max-width: 327px;
  height: 44px;
  padding-left: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
`;

const BackArrowImage = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-right: 8px;
`;

const ErrorComment = styled.div`
  height: 15px;
  border: 0;
  background-color: transparent;
  ${Typography.Body}
  text-align: center;
  color: ${palette.light_red};
  margin-bottom: 20%;
`;

const BackButton = styled.button`
  height: 24px;
  display: flex;
  background-color: transparent;
  border: 0;
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.gray};
`;

const NextButton = styled.button`
  width: 97px;
  max-width: 97px;
  height: 44px;
  background-color: ${palette.black};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const CreateAccount = (props) => {
  const [userName, setUserName] = useState(props.userName);
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);

  useEffect(() => {
    (async () => {
      // 랜더링 초기에 바로 닉네임 중복 체크 하기
      if (errorComment) {
      } else {
        const checkUserId = await checkSignup(userName).then((data) => {
          console.log(data);
          if (data == 1) {
            // 이미 존재하는 아이디
            setErrorComment(
              "That user ID has been taken. Please choose another."
            );
          } else {
            var regExp = /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{4,18}$/g;

            var testResult = regExp.test(userName);

            if (userName.length <= 4 || userName.length >= 20) {
              setErrorComment(
                "Your user ID must be longer than 4, less than 20 characters."
              );
            } else if (!testResult) {
              setErrorComment(
                "Your username can only contain letters, numbers and '_'"
              );
            } else {
              setErrorComment("");
              setConnectWallet(true);
            }
          }
        });
      }
    })();
  }, [props.userName]);

  useEffect(() => {
    var regExp = /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{4,18}$/g;

    var testResult = regExp.test(userName);

    if (userName.length <= 4 || userName.length >= 20) {
      setErrorComment(
        "Your user ID must be longer than 4, less than 20 characters."
      );
    } else if (!testResult) {
      setErrorComment(
        "Your username can only contain letters, numbers and '_'"
      );
    } else {
      setErrorComment("");
    }
    // else if(){ //중복체크
    // setErrorComment("That user ID has been taken. Please choose another.")
    // }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  const nextButtonOnClick = async () => {
    if (errorComment) {
    } else {
      const checkUserId = await checkSignup(userName).then((data) => {
        console.log(data);
        if (data == 1) {
          // 이미 존재하는 아이디
          setErrorComment(
            "That user ID has been taken. Please choose another."
          );
        } else {
          setConnectWallet(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      {connectWallet ? (
        <CreateAccountConnectWallet
          setBackColor={props.setBackColor}
          userName={userName}
          setPageStack={setConnectWallet}
        />
      ) : (
        <ModalContainer>
          <ControlHeader>
            <BackButton onClick={backButtonOnClick}>
              <BackArrowImage src={BackArrow} />
              Go Back to Previous
            </BackButton>
          </ControlHeader>
          <div className={"f-column a-center"}>
            <ContentContainer>
              <LogoContainer>
                <MainComment>Enter the user ID</MainComment>
                <RedComment>
                  User ID is permanent and cannot be modified.
                </RedComment>
              </LogoContainer>
              <UserNameInputBox
                style={
                  errorComment ? { borderColor: `${palette.light_red}` } : {}
                }
              >
                <UserNameInput
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  placeholder="Name"
                />
                <ModifiedCheck src={errorComment ? NotValid : Valid} />
              </UserNameInputBox>
              <ErrorComment>{errorComment}</ErrorComment>
              <BottomContainer>
                <NextButton onClick={nextButtonOnClick}>Next</NextButton>
              </BottomContainer>
            </ContentContainer>
          </div>
        </ModalContainer>
      )}
    </React.Fragment>
  );
};

export default CreateAccount;
