const acceptFriendRequest = (modalVisible) => {
  modalVisible(true);
};

const sendTokenRequest = (modalVisible) => {
  modalVisible(true);
};

const resendTokenRequest = (modalVisible) => {
  modalVisible(true);
};

const NotificationAction = [
  {
    id: 1,
    title: "Welcome",
    action: "",
    actionText: "",
  },
  {
    id: 2,
    title: "Friend Request",
    action: acceptFriendRequest,
    actionText: "Accept",
  },
  {
    id: 3,
    title: "Friend Request Accepted",
    action: "",
    actionText: "",
  },
  {
    id: 4,
    title: "New Comment",
    action: "",
    actionText: "",
  },
  {
    id: 5,
    title: "New Follower",
    action: "",
    actionText: "",
  },
  {
    id: 6,
    title: "Event",
    action: "",
    actionText: "",
  },
  {
    id: 7,
    title: "Receive Token",
    action: "",
    actionText: "",
  },
  {
    id: 8,
    title: "Token Request",
    action: sendTokenRequest,
    actionText: "Send",
  },
  {
    id: 9,
    title: "Token Request Declined",
    action: resendTokenRequest,
    actionText: "Resend",
  },
];

export default NotificationAction;
