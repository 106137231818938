import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import LeaderMain from "../../../../assets/images/leader_main2.svg";

const BackgroundImageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: 72px;
  position: relative;
`;

const BackgroundImageButton = styled.button`
  width: 100%;
  height: 320px;
  border: 0;
  background-color: white;
  background-image: url(${LeaderMain});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const MainBanner = () => {
  return (
    <BackgroundImageButtonContainer>
      <BackgroundImageButton
        onClick={() => (window.location.href = "https://www.buidl.asia/")}
      />
    </BackgroundImageButtonContainer>
  );
};

export default MainBanner;
