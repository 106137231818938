import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Web3 from "web3";

const LoginButton = styled.button`
  width: 100px;
  height: 30px;
  background-color: white;
  border-radius: 5px;
  margin: 20px;
`;

const WalletTest = () => {
  const loginOnClick = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information
      var web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.request({
          // method: "wallet_requestPermissions",
          method: "eth_requestAccounts",
          // params: [
          //   {
          //     eth_accounts: {},
          //   },
          // ],
        });

        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        // console.log(accounts[0]);
        console.log(accounts);
        var account = "account";
        if (accounts[0] != account) {
          console.log("hello");
          window.ethereum.on("accountsChanged", function (accounts) {
            console.log("accountsChanges", accounts);
          });
          await window.ethereum.request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          });
        }
      } catch {}
    }
  };

  return <LoginButton onClick={loginOnClick}>metamask</LoginButton>;
};

export default WalletTest;
