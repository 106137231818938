import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import PinnedComments from "../components/PinnedComments";
import CommentFriend from "../components/CommentFriend";
import { getUnhiddenComment } from "../../../../utils/api/comments";
import { getMypage } from "../../../../utils/api/auth";
import PinnedIcon from "../../../../assets/icons/dashboard/icon-pinned.svg";

const FullContainer = styled.div`
  width: 100%;
  // min-height: 320px;
  border-radius: 32px;
  background-color: ${palette.white};
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  margin-bottom: 30px;
  padding: 44px 3% 1px 3%;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading2}
`;

const ViewAllButton = styled.button`
  ${Typography.Heading4}
  text-align: right;
  background-color: transparent;
  border: 0;
  color: ${palette.gray};
`;

const CommentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-top: 15px;
`;

const CommentCard = styled.div`
  width: 300px;
  height: 132px;
  text-align: left;
  margin: 10px;
  line-height: 100px;
  box-shadow: 0px 2px 10px #c4c4c440;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${palette.background};
  position: relative;
  padding: 20px;
`;

const CommentCardHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 4px;
  height: 24px;
`;

const CommentProfileImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
`;

const CommentCustomName = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
`;

const CommentBox = styled.div`
  width: 100%;
  height: 55px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
`;

const CreatedDate = styled.div`
  width: 100%;
  text-align: right;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  color: ${palette.gray};
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const EditPinnedCommentButton = styled.button`
  width: 219px;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.white};
  border: 2px solid ${palette.black};
  color: ${palette.black};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin-top: 20px;
`;

const CommentButton = styled.button`
  width: auto;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.black};
  border: 2px solid ${palette.black};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
`;

const PinnedMark = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  text-align: left;
`;

function pad(n) {
  return n < 10 ? "0" + n : n;
}

const Comment = (props) => {
  const [userInfo, setUserInfo] = useState(props.userInfo);
  const [commentData, setCommentData] = useState(props.commentData);
  const [commentInfo, setCommentInfo] = useState(props.commentInfo);
  const [modalVisible, setModalVisible] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [isUserPage, setIsUserPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("@")) {
      setIsUserPage(true);
    }
  });

  useEffect(() => {
    (async () => {
      if (!modalVisible) {
        console.log("hide");
        const getMypageResult = await getMypage(
          localStorage.getItem("userID")
        ).then((data) => {
          console.log(data);
          setCommentData(data.comments);
        });

        // const getUnhiddenCommentResult = await getUnhiddenComment(
        //   localStorage.getItem("userID")
        // ).then((data) => {
        //   console.log(data);
        //   setCommentData(data);
        // });
      }
    })();
  }, [modalVisible]);

  useEffect(() => {
    setCommentData(props.commentData);
  }, props.commentData);

  useEffect(() => {
    setCommentInfo(props.commentInfo);
  }, [props.commentInfo]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const pinnedOnClick = () => {
    if (commentData) {
      setModalVisible(true);
    }
  };

  const commentCloseModal = () => {
    setCommentModalVisible(false);
  };

  const commentOnClick = () => {
    if (!localStorage.getItem("token")) {
      alert("Your session has ended. please login again.");
      window.location.href = "/";
    } else {
      setCommentModalVisible(true);
    }
  };

  const viewAllOnClick = () => {
    if (isUserPage) {
      window.location.href =
        location.pathname.slice(1).split("/")[0] + "/comments";
    } else {
      window.location.href = "@" + localStorage.getItem("userID") + "/comments";
    }
  };

  return (
    <React.Fragment>
      {modalVisible ? (
        <PinnedComments
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
          comments={commentData}
        />
      ) : (
        <React.Fragment>
          {commentModalVisible ? (
            <CommentFriend
              visible={commentModalVisible}
              closable={true}
              maskClosable={true}
              onClose={commentCloseModal}
              userImg={commentInfo?.profileImg} //내 프로필 이미지. 일단 임의로.
              userId={commentInfo.userID} //내 userId
              userName={commentInfo?.userFriendNickname} // 이 사람이 나한테 지정한 customName.(api로 불러와)
              friendId={commentInfo?.friendID}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      )}
      <FullContainer>
        <ContainerHeader>
          <HeaderTitle>Comments from Friends</HeaderTitle>
          <ViewAllButton onClick={viewAllOnClick}>view all</ViewAllButton>
        </ContainerHeader>
        <CommentContainer>
          {commentData?.map((val, idx) => (
            <CommentCard>
              {val.pinned ? <PinnedMark src={PinnedIcon} /> : <></>}
              <CommentCardHeader>
                <CommentProfileImage src={val.profileImg} />
                <CommentUserId>@{val.commentFrom}</CommentUserId>
                <CommentCustomName>{val.friendNickname}</CommentCustomName>
              </CommentCardHeader>
              <CommentBox>{val.commentMessage}</CommentBox>
              <CreatedDate>
                {pad(new Date(val.createdAt).getFullYear().toString())}.
                {pad(new Date(val.createdAt).getUTCMonth() + 1)}.
                {pad(new Date(val.createdAt).getUTCDate())}{" "}
                {pad(new Date(val.createdAt).getUTCHours())}:
                {pad(new Date(val.createdAt).getUTCMinutes())}:
                {pad(new Date(val.createdAt).getUTCSeconds())}
              </CreatedDate>
            </CommentCard>
          ))}
          <ButtonContainer>
            {isUserPage ? (
              <CommentButton onClick={commentOnClick}>
                comments for {location.pathname.slice(1)}
              </CommentButton>
            ) : (
              <EditPinnedCommentButton onClick={pinnedOnClick}>
                Edit Pinned Comments
              </EditPinnedCommentButton>
            )}
          </ButtonContainer>
        </CommentContainer>
      </FullContainer>
    </React.Fragment>
  );
};

export default Comment;
