import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import LOGO from "../../../assets/images/main_logo_image.svg";
import IntroImage from "../../../assets/images/main_example_image.svg";
import IntroComments from "../../../assets/images/intro_comments.svg";
// import IntroImage from "../../../assets/icons/dashboard/icon-image.svg";
import { COLORS as palette } from "../../../utils/styles/colors";
import CreateAccount from "./CreateAccount/CreateAccount";
import LoginUserID from "./Login/LoginUserID";
import RainbowText from "react-rainbow-text";
import { Easing } from "react-native-web";
import Fly from "react-flying-objects";
import IntroHeader from "../../../components/global/IntroHeader";
import Web3 from "web3";

const LogoContainer = styled.div`
  // width: 324px;
  width: 60%;
  max-width: 324px;
  // margin-top: 148px;
`;

const LogoImage = styled.img`
  width: 100%;
  margin-bottom: 8px;
`;

const SubTitle = styled.div`
  color: ${palette.gray};
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 5%;
  font-weight: 800;
`;

const IntroDoorImage = styled.img`
  width: 80%;
  // height: 80%;
  max-width: 637px;
  margin-top: 20px;
  margin-bottom: 46px;
  z-index: 2;
`;

const IntroContainer = styled.div`
  // height: 100vh;
  width: 90%;
  // padding-top: 10%;
  padding-top: 15%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 500px;
  // height: auto;
  position: absolute;
  z-index: 1;
  background-image: url(${IntroComments});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 23%;
`;

const BackgroundImg = styled.img`
  height: 100%;
  width: 100%;
`;

const WhiteBoardContainer = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  height: 90vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${palette.white};
  border-radius: 40px;
`;

const HiddenBox = styled.div`
  position: absolute;
  color: transparent;
  max-width: 320px;
  // width: 100%;
`;

const DELAY = 1000;
const DURATION = 5000;
const SIZE = 25;

const random = (min, max) => Math.floor(Math.random() * (max - min) + min);

const IntroPage = (props) => {
  const [createButtonClicked, setCreateButtonClicked] = useState(false);
  const [LoginButtonClicked, setLoginButtonClicked] = useState(false);
  const [backColor, setBackColor] = useState(palette.background);
  const [userName, setUserName] = useState("");
  const [animatedComments, setAnimatedComments] = useState(
    <BackgroundImg src={IntroComments} />
  );
  const [flyingObjects, setFlyingObjects] = useState([]);

  const introCreateButtonOnClicked = () => {
    setCreateButtonClicked(true);
  };

  const loginButtonOnClicked = () => {
    setLoginButtonClicked(true);
  };

  const objectConfig = useMemo(
    () => ({
      right: {
        fromValue: 0,
        toValue: 800,
        duration: DURATION,
        delay: DELAY,
      },
      top: {
        fromValue: random(100, 200),
        toValue: random(100, 200),
        duration: DURATION,
        easing: Easing.elastic(5),
        delay: DELAY,
      },
      width: {
        fromValue: random(SIZE - 10, SIZE + 10),
        toValue: SIZE,
        duration: DURATION,
        easing: Easing.elastic(5),
        delay: DELAY,
      },
      height: {
        fromValue: random(SIZE - 10, SIZE + 10),
        toValue: SIZE,
        duration: DURATION,
        easing: Easing.elastic(5),
        delay: DELAY,
      },
      opacity: {
        fromValue: 1,
        toValue: 0,
        duration: DURATION,
        easing: Easing.exp,
        delay: DELAY,
      },
    }),
    [animatedComments]
  );

  const testOnClick = () => {
    const connectMetamaskWallet = async () => {
      let balanceList = [];

      if (typeof window.ethereum !== "undefined") {
        // Instance web3 with the provided information
        var web3 = new Web3(window.ethereum);
        try {
          let balance = -1;
          let walletAddr = "";
          // Request account access
          await window.ethereum.enable();
          var address = await web3.eth.getPastLogs({
            address: "0x11f4d0A3c12e86B4b5F39B213F7E19D048276DAe",
          });
          console.log(address);
          var accounts = await web3.eth.getAccounts();
          walletAddr = accounts[0];
          console.log(accounts[0]);
          console.log(props.setWalletAddress);
          props.setWalletAddress(walletAddr);
          var ethBalance = await web3.eth.getBalance(walletAddr);
          console.log(ethBalance / Math.pow(10, 18));
        } catch {}
      }
    };

    connectMetamaskWallet();
  };

  return (
    <div
      className={"f-column a-center"}
      style={{ backgroundColor: backColor, position: "relative" }}
    >
      <IntroHeader />
      {createButtonClicked ? (
        <WhiteBoardContainer>
          <CreateAccount
            userName={userName}
            setPageStack={setCreateButtonClicked}
            setBackColor={setBackColor}
          />
        </WhiteBoardContainer>
      ) : (
        <React.Fragment>
          {LoginButtonClicked ? (
            <WhiteBoardContainer>
              <LoginUserID setPageStack={setLoginButtonClicked} />
            </WhiteBoardContainer>
          ) : (
            <React.Fragment>
              <BackgroundContainer />
              {/* <Fly 
        objectToFly={animatedComments}
        objectConfig={objectConfig}
        flyingObjects={flyingObjects}
        setFlyingObjects={setFlyingObjects}
      /> */}
              {/* <BackgroundImg src={IntroComments} /> */}
              {/* </BackgroundContainer> */}
              <IntroContainer>
                <LogoContainer>
                  {/* <button onClick={testOnClick}>test</button> */}
                  <LogoImage src={LOGO} />
                  <SubTitle>YOUR WEB 3 PERSONA IN CRYPTO WORLD</SubTitle>
                  <HiddenBox>
                    MEPE is the Web3 Multi-Wallet Personal Dashboard supporting
                    multi-chain, multi-wallet connections and
                    multi-persona.Claim your badge to prove your qualifications
                    and show off your on & offline experience. Issue and
                    liquidate social tokens for individual and community.
                  </HiddenBox>
                </LogoContainer>
                <IntroDoorImage src={IntroImage} />
                {/* <UserNameInputBox>
                  <UserNameInput
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Name"
                  />
                  <CreateButton onClick={introCreateButtonOnClicked}>
                    Create
                  </CreateButton>
                </UserNameInputBox>
                <LoginButton onClick={loginButtonOnClicked}>
                  <RainbowText lightness={0.5} saturation={1}>
                    Already have an account?
                  </RainbowText>
                </LoginButton> */}
              </IntroContainer>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default IntroPage;
