import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Arrow from "../../../../assets/icons/blue_right_arrow_icon.png";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import ConnectedWallet from "../../../../components/basic/ConnectedWallet/ConnectedWallet";
import WalletListData from "../../../../datas/WalletListData";
import CreateAccount from "../CreateAccount/CreateAccount";
import AddWalletAddress from "../../../../components/basic/AddWalletAddress/AddWalletAddress";
import { login } from "../../../../utils/api/auth";

const LogoContainer = styled.div`
  margin-top: 10%;
`;

const ModalContainer = styled.div`
  width: 100%;
  min-height: 900px;
  margin-bottom: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  padding-top: 47.5px;
  padding-left: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  align-items: center;
`;

const MainComment = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const StateComment = styled.div`
  color: ${palette.black};
  width: 100%;
  ${Typography.Heading1}
  text-align: center;
  margin-bottom: 72px;
`;

const UserNameInputBox = styled.button`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 36px;
  border: 2px solid ${palette.blue};
  color: ${palette.blue};
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.white};
  margin: auto 0px;
  margin-top: 18px;
  &:hover {
    background-color: ${palette.blue};
    color: ${palette.white};
  }
`;

const UserNameInputBoxEmpty = styled.button`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 36px;
  border: 2px dashed ${palette.gray};
  color: ${palette.gray};
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.white};
  margin: auto 0px;
  margin-top: 18px;
`;

const UserNameInput = styled.div`
  width: 80%;
  max-width: 327px;
  padding-left: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
`;

const BackArrowImage = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-right: 8px;
`;

const BackButton = styled.button`
  height: 24px;
  //   margin-top: 47.5px;
  display: flex;
  background-color: transparent;
  border: 0;
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.gray};
`;

const NotNowButton = styled.button`
  width: 173px;
  max-width: 173px;
  height: 44px;
  background-color: ${palette.black};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
  margin-top: 24px;
  border: 2px solid ${palette.black};
`;

const ConnectButton = styled.button`
  width: 173px;
  max-width: 173px;
  height: 44px;
  background-color: ${palette.white};
  color: ${palette.black};
  border: 0;
  border-radius: 22px;
  ${Typography.Link}
  text-align: center;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 210px;
  justify-content: center;
  margin: 0px auto;
  margin-bottom: 122px;
`;

const CompleteContainer = styled.div`
  width: 90%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  min-height: 100px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 14px;
  margin-bottom: 58px;
`;

const CenterText = styled.div`
  ${Typography.Heading4}
  text-align: center;
  margin: auto 0px;
`;

const ConnectListContainer = styled.div`
  width: 100%;
  max-width: 400px;
  gap: 20px;
`;

const BlankSpace = styled.div`
  width: 100%;
  max-width: 400px;
  height: 20px;
`;

const UserNameListContainer = styled.div`
  width: 100%;
  max-width: 400px;
  gap: 20px;
`;

const FindUserIdFound = (props) => {
  const [userNameList, setUserNameList] = useState(props.userNameList);
  // const [userNameList, setUserNameList] = useState();
  const [gotoCreateProfile, setGotoCreateProfile] = useState(false);
  //   const [walletList, setWalletList] = useState(props.walletList);
  const [backColor, setBackColor] = useState(palette.background);
  const [walletAddress, setWalletAddress] = useState(props.walletAddress);
  const [walletId, setWalletId] = useState(props.walletId);
  const [modalVisible, setModalVisible] = useState(false);
  const [mouseOnIndex, setMouseOnIndex] = useState(-1);

  useEffect(() => {}, []);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  const createAccountOnClick = () => {
    setGotoCreateProfile(true);
  };

  const notNowButtonOnClick = () => {
    props.setBackColor(palette.lavender_alpha25);
    setGotoCreateProfile(true);
  };

  const connectAnotherOnClick = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const buttonOnMouseEnter = (idx) => {
    setMouseOnIndex(idx);
  };

  const buttonOnMouseLeave = (idx) => {
    setMouseOnIndex(-1);
  };

  const loginOnClick = async (idx) => {
    const loginResult = await login(userNameList[idx]).then((data) => {
      console.log(data);
      // login success
      window.location.href = "/mypage";
    });
  };

  return (
    <React.Fragment>
      {gotoCreateProfile ? (
        <CreateAccount
          userName={""}
          setPageStack={setGotoCreateProfile}
          setBackColor={setBackColor}
        />
      ) : (
        // <CreateProfile walletList={walletList} userName={userName} />
        <ModalContainer>
          <ControlHeader>
            <BackButton onClick={backButtonOnClick}>
              <BackArrowImage src={BackArrow} />
              Go Back to Previous
            </BackButton>
          </ControlHeader>
          {modalVisible ? (
            <AddWalletAddress
              visible={modalVisible}
              closable={true}
              maskClosable={true}
              onClose={closeModal}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <div className={"f-column a-center"}>
            {userNameList ? (
              <ContentContainer>
                <LogoContainer>
                  <StateComment>We Found Your User ID</StateComment>
                  <MainComment>
                    The user ID linked to your wallet address is as follows.
                  </MainComment>
                </LogoContainer>
                <CompleteContainer>
                  <ConnectedWallet
                    img={WalletListData[walletId].walletIcon}
                    name={WalletListData[walletId].walletName}
                    walletAddress={walletAddress}
                  />
                  <CenterText>with</CenterText>
                  <UserNameListContainer>
                    {userNameList.map((val, i) => (
                      <React.Fragment>
                        <UserNameInputBox
                          onMouseEnter={() => buttonOnMouseEnter(i)}
                          onMouseLeave={() => buttonOnMouseLeave(i)}
                          onClick={() => loginOnClick(i)}
                        >
                          {i == mouseOnIndex ? (
                            <React.Fragment>
                              <UserNameInput>Log in as @{val}</UserNameInput>
                              <ModifiedCheck src={Arrow} />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <UserNameInput>{val}</UserNameInput>
                              <ModifiedCheck src={Arrow} />
                            </React.Fragment>
                          )}
                        </UserNameInputBox>
                        <BlankSpace />
                      </React.Fragment>
                    ))}
                  </UserNameListContainer>

                  <BlankSpace />
                </CompleteContainer>
                <BottomContainer>
                  <NotNowButton onClick={backButtonOnClick}>
                    try another
                  </NotNowButton>
                  <ConnectButton>contact us</ConnectButton>
                </BottomContainer>
              </ContentContainer>
            ) : (
              <ContentContainer>
                <LogoContainer>
                  <StateComment style={{ color: palette.light_red }}>
                    Can Not Find Your User Id
                  </StateComment>
                  <MainComment>
                    The user ID linked to your wallet address does not exist.
                    <br />
                    Would you like to create an account?
                  </MainComment>
                </LogoContainer>
                <CompleteContainer>
                  <ConnectedWallet
                    img={WalletListData[walletId].walletIcon}
                    name={WalletListData[walletId].walletName}
                    walletAddress={walletAddress}
                    verified={false}
                  />
                  <CenterText style={{ color: palette.blue }}>with</CenterText>
                  <UserNameListContainer>
                    <UserNameInputBoxEmpty>
                      <UserNameInput>nothing :(</UserNameInput>
                    </UserNameInputBoxEmpty>
                    <BlankSpace />
                  </UserNameListContainer>

                  <BlankSpace />
                </CompleteContainer>
                <BottomContainer>
                  <NotNowButton onClick={createAccountOnClick}>
                    Create Account
                  </NotNowButton>
                  <ConnectButton>contact us</ConnectButton>
                </BottomContainer>
              </ContentContainer>
            )}
          </div>
        </ModalContainer>
      )}
    </React.Fragment>
  );
};

export default FindUserIdFound;
