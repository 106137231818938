import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { checkSignup } from "../../../../utils/api/auth.js";
import XIcon from "../../../../assets/icons/dashboard/icon-x-gray.svg";
import WalletListData from "../../../../datas/WalletListData";
import RightArrow from "../../../../assets/icons/dashboard/icon-whiteRightArrow.png";
import { login } from "../../../../utils/api/auth";

const LogoContainer = styled.div`
  // margin-top: 280px;
  margin-top: 54px;
  margin-bottom: 30px;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
`;

const ContentContainer = styled.div`
  align-items: left;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading3}
`;

const MainComment = styled.div`
  ${Typography.Heading1}
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 87px;
  display: flex;
  justify-content: space-between;
  padding-right: 29px;
  padding-left: 22px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  background-color: ${palette.background};
  margin: 0px auto;
  margin-bottom: 16px;
  position: relative;
`;

const UserIDMark = styled.div`
  ${Typography.Body}
  color: ${palette.blue};
  margin-bottom: 16px;
`;

const UserNameLeftBox = styled.div`
  padding-top: 16px;
`;

const UserNameRightBox = styled.div`
  padding-top: 39px;
`;

const UserNameFinal = styled.div`
  width: 90%;
  max-width: 327px;
  height: auto;
  // padding-left: 8px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-left: 8px;
`;

const ConnectListContainer = styled.div`
  width: 100%;
  max-width: 400px;
  gap: 20px;
`;

const TinyTitle = styled.div`
  width: 100%;
  ${Typography.Body}
  text-align: center;
  margin: 28px 0px;
  color: ${palette.black};
`;

const WalletBox = styled.div`
  width: 100%;
  height: 87px;
  padding: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  background-color: ${palette.white};
  margin: 0px auto;
  border: 1px solid ${palette.blue};
`;

const WalletInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
`;

const WalletInfoLeft = styled.div`
  display: flex;
`;

const WalletInfoRight = styled.div`
  display: flex;
`;

const WalletIcon = styled.img`
  width: 32px;
  height: 32px;
  margin: auto 0px;
`;

const WalletName = styled.div`
  ${Typography.Heading3}
  height: auto;
  margin: auto 0px;
  margin-left: 8px;
  color: ${palette.light_black};
`;

const VerifiedBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 6.5px;
  height: 15px;
`;

const VerifiedText = styled.div`
  ${Typography.Body}
  color: ${palette.blue};
  margin: auto 0px;
  margin-right: 2px;
`;

const VerifiedIcon = styled.img`
  width: 14px;
  height: 14px;
  margin: auto 0px;
`;

const WalletAddress = styled.div`
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  margin: auto 0px;
`;

const HoverContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${palette.blue}DD;
  border-radius: 10px;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: right;
  padding: 16px;
`;

const HoverText = styled.div`
  width: 100%;
  text-align: right;
  ${Typography.Heading4}
  color: ${palette.white};
  margin: auto 0px;
`;

const RightArrowBox = styled.img`
  width: 24px;
  height: 24px;
  margin: auto 0px;
  margin-left: 4px;
`;

const SelectUserID = (props) => {
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [walletId, setWalletId] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [connectSuccess, setConnectSuccess] = useState(false);
  const [walletList, setWalletList] = useState(props.walletList);
  const [userNameList, setUserNameList] = useState([]);
  const [mouseOnIndex, setMouseOnIndex] = useState(-1);

  useEffect(() => {
    if (connectSuccess) {
      alert("Login Successfully! Welcome to Mepe!");
      window.location.href = "/mypage";
    }
  }, [connectSuccess]);

  useEffect(() => {
    console.log(props.walletAddress);
    console.log(props.walletId);
    console.log(props.userNameList);
    setWalletAddress(props.walletAddress);
    setWalletId(props.walletId);
    setUserNameList(props.userNameList);
  }, [props.walletAddress, props.walletId, props.userNameList]);

  useEffect(() => {
    // if (walletAddress != "") {
    //   (async () => {
    //     console.log("ra");
    //     const createUserId = await createUser(userName, walletAddress, walletId)
    //       .then((data) => {
    //         console.log(data);
    //         setModalVisible(true);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   })();
    // }
    if (walletAddress != "") {
      // api 동작 안되는 상황 테스트코드
      setModalVisible(true);
    }
  }, [walletAddress]);

  useEffect(() => {
    var regExp = /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{4,18}$/g;
    var testResult = regExp.test(userName);

    if ((userName.length <= 4 || userName.length >= 20) && userName) {
      setErrorComment(
        "Your user ID must be longer than 4, less than 20 characters."
      );
    } else if (!testResult && userName) {
      setErrorComment(
        "Your username can only contain letters, numbers and '_'"
      );
    } else {
      setErrorComment("");
    }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  useEffect(() => {}, [connectWallet]);

  const nextButtonOnClick = async () => {
    if (errorComment) {
    } else {
      const checkUserId = await checkSignup(userName).then((data) => {
        console.log(data);
        if (data == 1) {
          // 이미 존재하는 아이디
          setErrorComment(
            "That user ID has been taken. Please choose another."
          );
        } else {
          console.log("true");
          setConnectWallet(true);
        }
      });
    }
  };

  const deleteOnClick = () => {
    setUserName("");
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const buttonOnMouseEnter = (idx) => {
    setMouseOnIndex(idx);
  };

  const buttonOnMouseLeave = (idx) => {
    setMouseOnIndex(-1);
  };

  const loginOnClick = async (idx) => {
    const loginResult = await login(userNameList[idx]).then((data) => {
      console.log(data);
      // login success
      window.location.href = "/mypage";
    });

    // api 없는 테스트 코드
    // window.location.href = "/mypage";
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <HeaderTitle>Sign In to MEPE</HeaderTitle>
        <LogoContainer>
          <MainComment>
            You can Sign in to MEPE <br />
            by selecting 1 User ID.
          </MainComment>
        </LogoContainer>
        <WalletBox>
          <WalletInfoBox>
            <WalletInfoLeft>
              <WalletIcon src={WalletListData[walletId]?.walletIcon} />
              <WalletName>{WalletListData[walletId]?.walletName}</WalletName>
            </WalletInfoLeft>
            <WalletInfoRight>
              <WalletAddress>
                {walletAddress.substr(0, 6) +
                  "..." +
                  walletAddress.substr(
                    walletAddress.length - 6,
                    walletAddress.length
                  )}
              </WalletAddress>
            </WalletInfoRight>
          </WalletInfoBox>
          <VerifiedBox>
            <VerifiedText>Verified</VerifiedText>
            <VerifiedIcon src={Valid} />
          </VerifiedBox>
        </WalletBox>
        <TinyTitle>Sign in With</TinyTitle>
        <ConnectListContainer>
          {userNameList.map((v, i) => (
            <UserNameInputBox
              onMouseEnter={() => buttonOnMouseEnter(i)}
              onMouseLeave={() => buttonOnMouseLeave(i)}
              style={{ borderColor: `${palette.blue}` }}
              onClick={() => loginOnClick(i)}
            >
              <UserNameLeftBox>
                <UserIDMark>User ID</UserIDMark>
                <UserNameFinal>{v}</UserNameFinal>
              </UserNameLeftBox>
              <UserNameRightBox>
                <ModifiedCheck src={Valid} />
              </UserNameRightBox>
              {i == mouseOnIndex ? (
                <HoverContainer>
                  <HoverText>Let’s Sign In!</HoverText>
                  <RightArrowBox src={RightArrow} />
                </HoverContainer>
              ) : (
                <></>
              )}
            </UserNameInputBox>
          ))}
        </ConnectListContainer>
      </ContentContainer>
    </ModalContainer>
  );
};

export default SelectUserID;
