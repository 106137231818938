const Chainlist = [
  {
    pageProps: {
      chain: {
        name: "Cypress",
        chain: "KLAY",
        icon: "klaytn",
        rpc: [],
        faucets: [],
        nativeCurrency: { name: "Klaytn", symbol: "KLAY", decimals: 18 },
        // infoURL: "https://ethereum.org",
        shortName: "klay",
        chainId: 8217,
        networkId: 8217,
        slip44: 60,
        ens: { registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e" },
        explorers: [
          {
            name: "klaytnfinder",
            url: "https://www.klaytnfinder.io",
            // standard: "EIP3091",
          },
        ],
        // tvl: 51446750666.4789,
        chainSlug: "klaytn",
      },
      messages: {
        Common: {
          // "connect-wallet": "Connect Wallet",
          // "view-source-code": "View Code",
          // "join-our-discord": "Join Discord",
          // currency: "Currency",
          // "search-networks": "Search Networks",
          // description:
          //   "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          // "help-info": "Helping users connect to EVM powered networks",
          // "add-your-network": "Add Your Network",
          // "add-your-rpc": "Add Your RPC",
          // language: "中文",
          // "add-to-metamask": "Add to Metamask",
          // "add-to-imToken": "Add to imToken",
          // "add-to-wallet": "Add to Wallet",
          // "add-to-brave": "Add to Brave",
          // "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
  {
    pageProps: {
      chain: {
        name: "Baobob Testnet",
        chain: "KLAY",
        icon: "klaytn",
        rpc: [],
        faucets: [],
        nativeCurrency: { name: "Klaytn", symbol: "KLAY", decimals: 18 },
        // infoURL: "https://ethereum.org",
        shortName: "klay",
        chainId: 1001,
        networkId: 1001,
        slip44: 60,
        ens: { registry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e" },
        explorers: [
          {
            name: "klaytnfinder",
            url: "https://baobab.scope.klaytn.com",
            // standard: "EIP3091",
          },
        ],
        // tvl: 51446750666.4789,
        chainSlug: "klaytn",
      },
      messages: {
        Common: {
          // "connect-wallet": "Connect Wallet",
          // "view-source-code": "View Code",
          // "join-our-discord": "Join Discord",
          // currency: "Currency",
          // "search-networks": "Search Networks",
          // description:
          //   "Chainlist is a list of EVM networks. Users can use the information to connect their wallets and Web3 middleware providers to the appropriate Chain ID and Network ID to connect to the correct chain.",
          // "help-info": "Helping users connect to EVM powered networks",
          // "add-your-network": "Add Your Network",
          // "add-your-rpc": "Add Your RPC",
          // language: "中文",
          // "add-to-metamask": "Add to Metamask",
          // "add-to-imToken": "Add to imToken",
          // "add-to-wallet": "Add to Wallet",
          // "add-to-brave": "Add to Brave",
          // "add-to-coinbase": "Add to Coinbase Wallet",
        },
      },
    },
    __N_SSG: true,
  },
];

export default Chainlist;
