import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import useWindowDimensions from "../../../utils/functions/useWindowDimensions";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

const IntroToolHeader = (props) => {
  const { width, height } = useWindowDimensions();
  const [buttonText, setButtonText] = useState("");
  const [description, setDescription] = useState("");
  const [statusSetter, setStatusSetter] = useState("");
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setDescription(props.description);
    setButtonText(props.buttonText);
    if (props.setStatusSetter) {
      setStatusSetter(props.statusSetter);
    }
    setStatus(props.status);
  }, [props.buttonText, props.description, props.statusSetter, props.status]);

  return (
    <React.Fragment>
      {/* {width > 650 ? ( */}
      <DesktopHeader
        buttonText={buttonText}
        description={description}
        statusSetter={props.statusSetter}
        status={status}
      />
      {/* ) : (
        <MobileHeader buttonText={buttonText} description={description} />
      )} */}
    </React.Fragment>
  );
};

export default IntroToolHeader;
