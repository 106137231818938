import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import Typography from "../../../utils/styles/typography";
import { editProfile } from "../../../utils/api/auth";
import Header from "../../../components/global/Header/index";
import PropTypes from "prop-types";

const ModalContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  //   min-height: 900px;
  margin-top: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  padding-top: 47.5px;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const ContentContainer = styled.div`
  align-items: center;
  width: 100%;
`;

const CreateButton = styled.button`
  width: 97px;
  max-width: 97px;
  height: 36px;
  background-color: ${palette.marine};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
  margin-bottom: 111px;
  margin-top: 64px;
`;

const BottomContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const BackgroundImageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 26px;
`;

const BackgroundImageButton = styled.button`
  width: 100%;
  height: 200px;
  border: 0;
`;

const ProfileImageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: -60px;
`;

const ProfileImageButton = styled.button`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 1px solid #777777;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 30px;
  margin: auto;
  margin-left: 7%;
`;

const InputFullContainer = styled.div`
  width: 90%;
  margin: 0px auto;
`;

const InputTitle = styled.div`
  ${Typography.Heading2}
  color: ${palette.light_black};
  margin-top: 24px;
`;

const InputBox = styled.input`
  height: 42px;
  margin-left: 5px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  padding-left: 16px;
  background-color: ${palette.background};
  border: 0;
  border-radius: 8px;
  //   margin-top: 18px;
`;

const TextBox = styled.textarea`
  height: 100px;
  margin-left: 20px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  padding-left: 16px;
  padding-top: 15px;
  background-color: ${palette.background};
  border: 0;
  border-radius: 8px;
  margin-top: 18px;
  resize: none;
`;

const SocialMediaContainer = styled.div`
  width: 100%;
  //   padding-left: 20px;
  padding-left: 3%;
`;

const SNSTitle = styled.li`
  ${Typography.Heading3}
  height: 20px;
  color: ${palette.gray};
  margin-top: 16px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  margin-left: 10px;
  width: 100%;
  // padding-left: 16px;
  border: 0;
  margin-top: 18px;
`;

const BasicUrl = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.black};
`;

const EditProfilePage = () => {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [socials, setSocials] = useState(
    JSON.parse(localStorage.getItem("socials"))
  );
  const [userName, setUserName] = useState(
    userInfo.nickname ? userInfo.nickname : ""
  );
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [profileImageChange, setProfileImageChange] = useState(false);
  const [backImageChange, setBackImageChange] = useState(false);
  const [connectWallet, setConnectWallet] = useState(false);
  const [profileImage, setProfileImage] = useState({
    file: userInfo.profileImg,
    imagePreviewUrl: userInfo.profileImg,
  });
  const [bgImage, setBgImage] = useState({
    file: userInfo.backImage,
    imagePreviewUrl: userInfo.backImage,
  });
  const [introduction, setIntroduction] = useState(
    userInfo.introduction ? userInfo.introduction : ""
  );
  const [twitterLink, setTwiiterLink] = useState(
    socials?.twitter ? socials?.twitter : ""
  );
  const [facebookLink, setFacebookLink] = useState(
    socials?.facebook ? socials?.facebook : ""
  );
  const [discordLink, setDiscordLink] = useState(
    socials?.discord ? socials?.discord : ""
  );
  const [link, setLink] = useState(socials?.link ? socials?.link : "");

  console.log(userInfo);

  useEffect(() => {
    var regExp = /[a-zA-Z0-9_]{5,19}$/g;

    var testResult = regExp.test(userName);

    if (userName.length <= 4 || userName.length >= 20) {
      setErrorComment(
        "Your user ID must be longer than 4, less than 20 characters."
      );
    } else if (!testResult) {
      setErrorComment(
        "Your username can only contain letters, numbers and '_'"
      );
    } else {
      setErrorComment("");
    }
    // else if(){ //중복체크
    // setErrorComment("That user ID has been taken. Please choose another.")
    // }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  const nextButtonOnClick = async () => {
    // edit profile하기
    if (userName) {
      const formData = new FormData();

      if (profileImage) {
        formData.append("profileImage", profileImage.file);
      }

      if (backImageChange) {
        formData.append("backImage", bgImage.file);
      }
      // formData.append("profileImage", profileImageChange?profileImage.file:null);
      // formData.append("backImage", backImageChange?bgImage.file:null);
      const userInfoValue = {
        jwtToken: localStorage.getItem("token"),
        profileName: userName,
        introduction: introduction,
        url: "https://mepe.app/@" + localStorage.getItem("userID"),
        twitter: twitterLink,
        facebook: facebookLink,
        discord: discordLink,
        link: link,
      };
      formData.append("json", JSON.stringify(userInfoValue));

      const editProfileResult = await editProfile(
        localStorage.getItem("userID"),
        formData,
        "edit"
      )
        .then((data) => {
          console.log(data);
          setConnectWallet(true);
          window.location.href = "/mypage";
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Profile Name cannot be blank. Please enter at least 1 character.");
    }
  };

  const hiddenFileInput = useRef(null);

  //   const onMaskClick = (e) => {
  //     if (e.target === e.currentTarget) {
  //       onClose(e);
  //     }
  //   };

  //   const close = (e) => {
  //     if (onClose) {
  //       onClose(e);
  //     }
  //   };

  //   const closeOnClick = () => {
  //     onClose();
  //   };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
    console.log("??");
  };

  const handleChange = (event) => {
    console.log("hello");
    let reader = new FileReader();
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    setProfileImage({ file: fileUploaded, imagePreviewUrl: fileUploaded });
    reader.onloadend = () => {
      setProfileImage({ file: fileUploaded, imagePreviewUrl: reader.result });
    };
    reader.readAsDataURL(fileUploaded);
    setProfileImageChange(true);
  };

  const hiddenFileInputBg = useRef(null);

  const handleBgClick = (event) => {
    hiddenFileInputBg.current.click();
  };

  const handleBgChange = (event) => {
    let reader = new FileReader();
    const fileUploaded = event.target.files[0];
    setBgImage({ file: fileUploaded, imagePreviewUrl: fileUploaded });
    reader.onloadend = () => {
      setBgImage({ file: fileUploaded, imagePreviewUrl: reader.result });
    };
    reader.readAsDataURL(fileUploaded);
    setBackImageChange(true);
  };

  const userNameOnChange = (e) => {
    setUserName(e.target.value);
  };

  const introductionOnChange = (e) => {
    setIntroduction(e.target.value);
  };

  const twitterOnChange = (e) => {
    setTwiiterLink(e.target.value);
  };

  const facebookOnChange = (e) => {
    setFacebookLink(e.target.value);
  };

  const discordOnChange = (e) => {
    setDiscordLink(e.target.value);
  };

  const linkOnChange = (e) => {
    setLink(e.target.value);
  };

  return (
    <React.Fragment>
      <div
        className={"f-column a-center"}
        // style={{ backgroundColor: backColor }}
      >
        <Header profileImg={userInfo?.profileImg} />
        <ModalContainer>
          <ControlHeader>
            <HeaderTitle>Edit Profile</HeaderTitle>
          </ControlHeader>
          <div className={"f-column a-center"}>
            <ContentContainer>
              <BackgroundImageButtonContainer>
                <BackgroundImageButton
                  onClick={handleBgClick}
                  style={{
                    backgroundImage: `url(${bgImage.imagePreviewUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <input
                  type="file"
                  ref={hiddenFileInputBg}
                  onChange={(e) => {
                    handleBgChange(e);
                  }}
                  style={{ display: "none" }}
                />
              </BackgroundImageButtonContainer>
              <ProfileImageButtonContainer>
                <ProfileImageButton
                  onClick={handleClick}
                  style={{
                    backgroundImage: `url(${profileImage.imagePreviewUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ display: "none" }}
                />
              </ProfileImageButtonContainer>
              <InputFullContainer>
                <InputTitle>Profile Name</InputTitle>
                <InputBox
                  value={userName}
                  onChange={(e) => userNameOnChange(e)}
                />
                <InputTitle>Bio</InputTitle>
                <TextBox
                  value={introduction}
                  onChange={(e) => introductionOnChange(e)}
                />
                <InputTitle>Social Media</InputTitle>
                <SocialMediaContainer>
                  <SNSTitle>twitter</SNSTitle>
                  <UrlContainer>
                    <BasicUrl>https://twitter.com/</BasicUrl>
                    <InputBox
                      value={twitterLink}
                      onChange={(e) => twitterOnChange(e)}
                    />
                  </UrlContainer>
                  <SNSTitle>facebook</SNSTitle>
                  <UrlContainer>
                    <BasicUrl>https://www.facebook.com/</BasicUrl>
                    <InputBox
                      value={facebookLink}
                      onChange={(e) => facebookOnChange(e)}
                    />
                  </UrlContainer>
                  <SNSTitle>discord</SNSTitle>
                  <InputBox
                    value={discordLink}
                    onChange={(e) => discordOnChange(e)}
                  />
                  <SNSTitle>link</SNSTitle>
                  <InputBox value={link} onChange={(e) => linkOnChange(e)} />
                </SocialMediaContainer>
              </InputFullContainer>
              <BottomContainer>
                <CreateButton onClick={nextButtonOnClick}>Done</CreateButton>
              </BottomContainer>
              {/* <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton> */}
            </ContentContainer>
          </div>
        </ModalContainer>
      </div>
      {/* </ModalInner>
      </ModalWrapper> */}
    </React.Fragment>
  );
};

EditProfilePage.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  //   height: 0px;
  height: 100vh;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  //   box-sizing: border-box;
  position: relative;
  //   box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  //   background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 100%;
  //   max-width: 700px;
  top: 40%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 300px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 440;
  min-height: 440px;
  border: none;
  border-radius: 20px;
  //   background-color: rgba(255, 255, 255, 1);
  height: auto;
`;

export default EditProfilePage;
