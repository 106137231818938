import styled, {keyframes} from 'styled-components';
import {COLORS as c} from "../../utils/styles/colors";

const PopAni = keyframes`
  0% {opacity:0; transform: scale(0.1); }
  70% {transform: scale(1.1);}
  100% {opacity: 1;}
`;

const Container = styled.button`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: ${props => props.isSmall ? '4px 12px' : '8px 16px'};
  margin: ${props => props.isSmall ? '2px' : '0px 8px 16px 8px' };
  
  border: ${props => props.isSmall ? '1px' : '2px'} solid ${props => props.border};
  border-radius: 16px;
  background-color: ${props => props.color};
  box-shadow: ${props => props.shadow ? '0px 2px 10px rgba(119, 119, 119, 0.25)' : 'none'};
  
  color: ${c.black};
  font-weight: ${props => props.isSmall ? 600 : 700 };
  font-size: ${props => props.isSmall ? '10px' : '14px' };
  
  animation: 0.5s ${PopAni};
  transition: all 0.2s;
`

function CustomTag({color = c.white, isSmall = false, shadow = true, onClick, ...props}) {
    const border = (color === c.white ? c.black : color);
    return (
        <Container color={color} border={border} isSmall={isSmall} shadow={shadow} onClick={onClick}>
            #{props.children}
        </Container>
    );
}

export default CustomTag;