import { ethereum, InstallError } from "@cosmostation/extension-client";

const CosmostationOnClick = (setFunction) => {
  return new Promise(async (resolve, reject) => {
    const cosmostation = window.cosmostation;
    try {
      if (!cosmostation) {
        alert("Please install keplr extension");
      } else {
        const provider = await ethereum();

        // const handler = provider.on("accountsChanged", (accounts) => {
        //   if (accounts.length === 0) {
        //     console.log(provider.request({ method: "eth_requestAccounts" }));
        //   } else {
        //     console.log(accounts);
        //     setFunction(accounts);
        //   }
        // });
        const handler = provider.request({method:"eth_requestAccounts"}).then((result) => {
          console.log(result[0])
          setFunction(result[0]);
        })
      }
      // }
    } catch (error) {
      alert(error.message);
      console.error(error.message);
    }
  });
};

export default CosmostationOnClick;
