import React from "react";
import styled, { css } from "styled-components";
import { animated, useTransition, useSpring } from "react-spring";
import { Spring } from "react-spring/renderprops";

import { DialogOverlay, DialogContent } from "@reach/dialog";
import { isMobile } from "react-device-detect";
import "@reach/dialog/styles.css";
import { transparentize } from "polished";
import { useGesture } from "react-use-gesture";

const white = "#FFFFFF";
const black = "#000000";

const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280,
};

const mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    accumulator[size] = (...args) => css`
      @media (max-width: ${MEDIA_WIDTHS[size]}px) {
        ${css(...args)}
      }
    `;
    return accumulator;
  },
  {}
);

const darkMode = false;

const Theme = {
  white,
  black,
  textColor: darkMode ? white : "#010101",
  greyText: darkMode ? white : "#6C7284",

  // for setting css on <html>
  backgroundColor: darkMode ? "#333639" : white,

  modalBackground: darkMode ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.5)",
  inputBackground: darkMode ? "#202124" : white,
  placeholderGray: darkMode ? "#5F5F5F" : "#E1E1E1",
  shadowColor: darkMode ? "#000" : "#2F80ED",

  // grays
  concreteGray: darkMode ? "#292C2F" : "#FAFAFA",
  mercuryGray: darkMode ? "#333333" : "#E1E1E1",
  silverGray: darkMode ? "#737373" : "#C4C4C4",
  chaliceGray: darkMode ? "#7B7B7B" : "#AEAEAE",
  doveGray: darkMode ? "#C4C4C4" : "#737373",
  mineshaftGray: darkMode ? "#E1E1E1" : "#2B2B2B",
  activeGray: darkMode ? "#292C2F" : "#F7F8FA",
  buttonOutlineGrey: darkMode ? "#FAFAFA" : "#F2F2F2",
  tokenRowHover: darkMode ? "#404040" : "#F2F2F2",

  //blacks
  charcoalBlack: darkMode ? "#F2F2F2" : "#404040",
  // blues
  zumthorBlue: darkMode ? "#212529" : "#EBF4FF",
  malibuBlue: darkMode ? "#E67AEF" : "#5CA2FF",
  royalBlue: darkMode ? "#DC6BE5" : "#2F80ED",
  loadingBlue: darkMode ? "#e4f0ff" : "#e4f0ff",

  // purples
  wisteriaPurple: "#DC6BE5",
  // reds
  salmonRed: "#FF6871",
  // orange
  pizazzOrange: "#FF8F05",
  // yellows
  warningYellow: "#FFE270",
  // pink
  uniswapPink: "#DC6BE5",
  //green
  connectedGreen: "#27AE60",

  //branded
  metaMaskOrange: "#E8831D",

  //specific
  textHover: darkMode ? "#DC6BE5" : (darkMode ? "#C4C4C4" : "#737373"),

  // connect button when loggedout
  buttonFaded: darkMode ? "#DC6BE5" : "#737373",

  // media queries
  mediaWidth: mediaWidthTemplates,
  // css snippets
  flexColumnNoWrap: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flexRowNoWrap: css`
    display: flex;
    flex-flow: row nowrap;
  `,
};

const AnimatedDialogOverlay = animated(DialogOverlay);
const WrappedDialogOverlay = ({
  suppressClassNameWarning,
  mobile,
  ...rest
}) => <AnimatedDialogOverlay {...rest} />;
const StyledDialogOverlay = styled(WrappedDialogOverlay).attrs({
  suppressClassNameWarning: true,
})`
  &[data-reach-dialog-overlay] {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${"transparent"};

    ${({ mobile }) =>
      mobile &&
      css`
        align-items: flex-end;
      `}

    &::after {
      content: "";
      background-color: ${Theme.modalBackground};
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      /* position: absolute; */
      position: fixed;
      z-index: -1;
    }
  }
`;

const FilteredDialogContent = ({
  minHeight,
  maxHeight,
  isOpen,
  slideInAnimation,
  mobile,
  ...rest
}) => <DialogContent {...rest} />;
const StyledDialogContent = styled(FilteredDialogContent)`
  &[data-reach-dialog-content] {
    margin: 0 0 2rem 0;
    border: 1px solid ${Theme.concreteGray};
    background-color: ${Theme.inputBackground};
    box-shadow: 0 4px 8px 0 ${transparentize(0.95, Theme.shadowColor)};
    padding: 0px;
    width: 50vw;

    max-width: 650px;
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: ${maxHeight}vh;
      `}
    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight}vh;
      `}
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    ${Theme.mediaWidth.upToMedium`
      width: 65vw;
      max-height: 65vh;
      margin: 0;
    `}
    ${({ mobile, isOpen }) => Theme.mediaWidth.upToSmall`
      width:  85vw;
      max-height: 66vh;
      ${
        mobile &&
        css`
          width: 100vw;
          border-radius: 20px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      }
    `}
  }
`;

const HiddenCloseButton = styled.button`
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border: none;
`;

export default function Modal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 50,
  initialFocusRef,
  children,
}) {
  const transitions = useTransition(isOpen, null, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }));
  const bind = useGesture({
    onDrag: (state) => {
      let velocity = state.velocity;
      if (velocity < 1) {
        velocity = 1;
      }
      if (velocity > 8) {
        velocity = 8;
      }
      set({
        xy: state.down ? state.movement : [0, 0],
        config: { mass: 1, tension: 210, friction: 20 },
      });
      if (velocity > 3 && state.direction[1] > 0) {
        onDismiss();
      }
    },
  });

  if (isMobile) {
    return transitions.map(
      ({ item, key, props }) =>
        item && (
          <StyledDialogOverlay
            key={key}
            style={props}
            onDismiss={onDismiss}
            initialFocusRef={initialFocusRef}
            mobile={isMobile}
          >
            <Spring // animation for entrance and exit
              from={{
                transform: isOpen ? "translateY(200px)" : "translateY(100px)",
              }}
              to={{
                transform: isOpen ? "translateY(0px)" : "translateY(200px)",
              }}
            >
              {(props) => (
                <animated.div
                  {...bind()}
                  style={{
                    transform: xy.interpolate(
                      (x, y) => `translate3d(${0}px,${y > 0 ? y : 0}px,0)`
                    ),
                  }}
                >
                  <StyledDialogContent
                    style={props}
                    hidden={true}
                    minHeight={minHeight}
                    maxHeight={maxHeight}
                    mobile={isMobile}
                  >
                    <HiddenCloseButton onClick={onDismiss} />
                    {children}
                  </StyledDialogContent>
                </animated.div>
              )}
            </Spring>
          </StyledDialogOverlay>
        )
    );
  } else {
    return transitions.map(
      ({ item, key, props }) =>
        item && (
          <StyledDialogOverlay
            key={key}
            style={props}
            onDismiss={onDismiss}
            initialFocusRef={initialFocusRef}
            mobile={isMobile}
          >
            <StyledDialogContent
              hidden={true}
              minHeight={minHeight}
              maxHeight={maxHeight}
              isOpen={isOpen}
              mobile={isMobile}
            >
              <HiddenCloseButton onClick={onDismiss} />
              {children}
            </StyledDialogContent>
          </StyledDialogOverlay>
        )
    );
  }
}
