import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import TrashIcon from "../../../../assets/icons/trash_icon.svg";
import XIcon from "../../../../assets/icons/icon-modal_x.svg";
import NotiDetail from "./NotiDetail";
import {
  getAllNoti,
  getNotiDetail,
  checkAllNoti,
} from "../../../../utils/api/notifications";
import SetNickname from "./SetNickname";
import FinalConfirmation from "../../FinancePage/components/FinalConfirmation";
import WalletList from "../../../../datas/WalletListData";
import NotificationAction from "../../../../components/global/Header/NotificationAction";
import NotiForm from "../forms/NotiForm";
import TrashIconGray from "../../../../assets/icons/dashboard/icon-trash-gray.svg";
import Checked from "../../../../assets/icons/dashboard/icon-checked.svg";
import Unchecked from "../../../../assets/icons/dashboard/icon-unchecked.svg";
import NotiDeletePopup from "./NotiDeletePopup";
import GoldenBell from "../../../../assets/icons/dashboard/icon-goldenBell.svg";

const NotiContainer = styled.div`
  width: 95%;
  max-width: 800px;
  // border-radius: 40px;
  background-color: ${palette.white};
  min-height: 200px;
  // margin-top: 30px;
  margin-top: 70px;
  padding-top: 46px;
  padding-bottom: 40px;
`;

const DropBoxTitle = styled.div`
  width: 100%;
  height: 64px;
  border-radius: 8px 8px 0px 0px;
  background-color: ${palette.white};
  display: flex;
  justify-content: right;
  padding: 20px;
  align-items: center;
`;

const MakeToReadButton = styled.button`
  ${Typography.Heading5}
  color: ${palette.blue};
  border: hidden;
  background-color: transparent;
`;

const SingleNotiBox = styled.button`
  display: flex;
  width: 100%;
  min-height: 120px;
  padding: 24px 20px;
  border: 0px;
  border-bottom: 1px solid ${palette.footer};
  background-color: ${palette.white};
`;

const ThumbnailBox = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 28px;
`;

const BoxContent = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;
`;

const ContentHeader = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
`;

const ContentTitle = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${palette.light_black};
`;

const MinutesAgo = styled.div`
  ${Typography.Body}
  color: ${palette.gray};
  margin: auto 0px;
`;

const ContentDetail = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${palette.light_black};
`;

const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const ActionButton = styled.button`
  height: 31px;
  background-color: ${palette.noti_action_button};
  border-radius: 70px;
  border: hidden;
  color: ${palette.marine};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 8px 14px;
  margin-top: 20px;
`;

const BottomBox = styled.div`
  width: 100%;
  height: 60px;
  padding: 20px;
  display: flex;
  justify-content: right;
`;

const ListContainer = styled.div`
  width: 100%;
  min-height: 480px;
  border-radius: 16px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-top: 16px;
`;

const TitleBox = styled.div`
  width: 90%;
  max-width: 1080px;
  ${Typography.Heading2}
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  border: hidden;
  border-radius: 46px;
  background-color: transparent;
  color: ${palette.gray};
  ${Typography.Heading4}
  align-items: center;
`;

const TrashImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
`;

const DeleteFooterBox = styled.div`
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 14px;
  background-color: ${palette.background};
  padding-top: 30px;
  position: fixed;
  bottom: 0;
`;

const DeleteNotiButton = styled.button`
  border: 1px solid ${palette.light_gray};
  width: 40%;
  max-width: 330px;
  border: 100px;
  height: 39px;
  min-height: 39px;
  padding: 12px auto;
  text-align: center;
  background-color: ${palette.white};
  color: ${palette.marine};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  border-radius: 20px;
`;

const SelectButton = styled.button`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: hidden;
  background-color: transparent;
  background-image: url(${Unchecked});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 321px;
  padding-top: 80px;
  align-items: center;
  justify-content: center;
`;

const BellBox = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

const EmptyTitle = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const EmptyDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: ${palette.gray};
  margin-top: 6px;
`;

const minuteConvert = (value) => {
  var units = {
    year: 24 * 60 * 365,
    month: 24 * 60 * 30,
    week: 24 * 60 * 7,
    day: 24 * 60,
    hour: 60,
    minute: 1,
  };

  var result = [];

  for (var name in units) {
    var p = Math.floor(value / units[name]);
    if (p == 1) {
      result.push(p + " " + name);
      break;
    }
    if (p >= 2) {
      result.push(p + " " + name + "s");
      break;
    }
    value %= units[name];
  }

  return result;
};

const NotiBox = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [selectNoti, setSelectNoti] = useState({});
  const [acceptNotiInfo, setAcceptNotiInfo] = useState({});
  const [finalConfirmationVisible, setFinalConfirmationVisible] =
    useState(false);
  const [notiList, setNotiList] = useState([
    // {
    //   index: 482,
    //   user: "gkrry2723",
    //   notificationType: 7,
    //   relatedObjIdx: 17,
    //   thumbnailImg:
    //     "https://daotool.s3.ap-northeast-2.amazonaws.com/static/notification_icon/bdff088d-bbd6-495b-9388-68cf72f9f3a7finance_received.png",
    //   thumbnailUserId: null,
    //   message: "zoozoo_club3 sends you token. Check it out.",
    //   createdAt: "2022-10-24T13:01:08.000+00:00",
    //   typeMeta: "get token",
    //   relatedTable: "transaction",
    //   title: "Receive Token",
    //   checked: true,
    //   minitesAgo: 20200,
    // },
    // {
    //   index: 481,
    //   user: "gkrry2723",
    //   notificationType: 8,
    //   relatedObjIdx: 18,
    //   thumbnailImg:
    //     "https://daotool.s3.ap-northeast-2.amazonaws.com/static/notification_icon/ac908f4d-a459-4ba7-87a6-a4dd5af66a23finance_requested.png",
    //   thumbnailUserId: null,
    //   message: "zoozoo_club3 requests1.2. \ntest2\n Would you like to send?",
    //   createdAt: "2022-10-24T12:46:11.000+00:00",
    //   typeMeta: "get token reqeust",
    //   relatedTable: "tokenReq",
    //   title: "Token Request",
    //   checked: true,
    //   minitesAgo: 20215,
    // },
    // {
    //   index: 482,
    //   user: "gkrry2723",
    //   notificationType: 2,
    //   relatedObjIdx: 18,
    //   thumbnailImg:
    //     "https://daotool.s3.ap-northeast-2.amazonaws.com/static/notification_icon/ac908f4d-a459-4ba7-87a6-a4dd5af66a23finance_requested.png",
    //   thumbnailUserId: null,
    //   message:
    //     "@UserID requested a friend. Press the button to accept the friend request.",
    //   createdAt: "2022-10-24T12:46:11.000+00:00",
    //   typeMeta: "get token reqeust",
    //   relatedTable: "tokenReq",
    //   title: "Friend Request",
    //   checked: false,
    //   minitesAgo: 20215,
    // },
  ]);
  const [deleteOn, setDeleteOn] = useState(false);
  const [deleteList, setDeleteList] = useState([]);
  const [deleteListChange, setDeleteListChange] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [finalConfirmationType, setFinalConfirmationType] = useState("");
  const [requestInfo, setRequestInfo] = useState({});
  const [markOn, setMarkOn] = useState(false);

  const deleteTypeList = ["All", "Selected"];

  useEffect(() => {
    if (modalVisible || isAccept || finalConfirmationVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalVisible, isAccept, finalConfirmationVisible]);

  useEffect(() => {
    (async () => {
      const getAllNotiResult = await getAllNoti(
        localStorage.getItem("userID")
      ).then((data) => {
        setNotiList(data);
      });
    })();
  }, [deleteOn, markOn]);

  useEffect(() => {
    setIsAccept(isAccept);
    console.log(modalVisible);
    console.log(isAccept);
  }, [isAccept]);

  useEffect(() => {
    console.log(acceptNotiInfo);
  }, [acceptNotiInfo]);

  useEffect(() => {}, [deleteListChange]);

  const userOnClick = (id) => {
    window.location.href = `/@${id}`;
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeFinalConfirmationModal = () => {
    setFinalConfirmationVisible(false);
  };

  const closeSetNicknameModal = () => {
    setIsAccept(false);
  };

  const deleteOnClick = () => {
    setDeleteOn(!deleteOn);
  };

  const notiOnClick = (notiInfo) => {
    setSelectNoti(notiInfo);
    setModalVisible(true);
  };

  const singleNotiOnClick = async (idx, type, thumbnailUserId) => {
    const getNotiDetailResult = await getNotiDetail(
      idx,
      localStorage.getItem("userID")
    ).then((data) => {
      console.log(data);
    });

    if (type == 1) {
      // welcome
      window.location.href = "/leaderboard";
    } else if (type == 3 || type == 4) {
      // friend request accepted, new follower | 친구 대시보드로 이동
      window.location.href = `/@${thumbnailUserId}`;
    } else if (type == 5) {
      // new comment | 나의 코멘트 페이지로 이동
      window.location.href = `/@${localStorage.getItem("userID")}/comments`;
    } else if (type == 6) {
      // event | 해당 이벤트 페이지로 이동
      window.location.href = "http://POAP.xyz/claim/3x0wub";
    } else if (type == 7) {
      // receive token | 받은 토큰 확인을 위해 트랜젝션 페이지로 이동.
      window.location.href = "/transactions";
    }
  };

  // const deleteOneOnClick = async (idx) => {
  //   const deleteListNotiResult = await deleteListNoti(
  //     idx,
  //     localStorage.getItem("userID")
  //   ).then((data) => {
  //     console.log(data);
  //     setDeleteOn(!deleteOn);
  //   });
  // };

  const deleteBottomOnClick = (idx) => {
    setDeleteType(deleteTypeList[idx]);
    setModalVisible(true);
  };

  const deleteSelectOnClick = (idx, event) => {
    event.stopPropagation();
    var tmpDeleteList = deleteList;
    tmpDeleteList.push(idx);
    console.log(tmpDeleteList);
    setDeleteList(tmpDeleteList);
    setDeleteListChange(!deleteListChange);
  };

  const deleteCancelOnClick = (idx, event) => {
    event.stopPropagation();
    var tmpDeleteList = deleteList;
    var index = tmpDeleteList.indexOf(idx);
    if (index !== -1) {
      tmpDeleteList.splice(index, 1);
    }
    console.log(tmpDeleteList);
    setDeleteList(tmpDeleteList);
    setDeleteListChange(!deleteListChange);
  };

  const markAllAsReadOnClick = async () => {
    if (deleteOn || notiList.length < 1) {
    } else {
      const checkAllNotiResult = await checkAllNoti(
        localStorage.getItem("userID")
      ).then((data) => {
        console.log(data);
        setMarkOn(!markOn);
      });
    }
  };

  return (
    <React.Fragment>
      {modalVisible ? (
        <NotiDeletePopup
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
          deleteType={deleteType}
          userId={localStorage.getItem("userID")}
          deleteList={deleteList}
        />
      ) : (
        <React.Fragment>
          {isAccept ? (
            <SetNickname
              visible={isAccept}
              closable={true}
              maskClosable={true}
              onClose={closeSetNicknameModal}
              noti={acceptNotiInfo}
            />
          ) : (
            <React.Fragment>
              {finalConfirmationVisible ? (
                <FinalConfirmation
                  visible={finalConfirmationVisible}
                  closable={true}
                  maskClosable={true}
                  onClose={closeFinalConfirmationModal}
                  type={finalConfirmationType}
                  confirmInfo={requestInfo}
                  sendFunction={() => {}}
                />
              ) : (
                <></>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <NotiContainer>
        <HeaderContainer>
          <TitleBox>Notifications</TitleBox>

          {notiList.length > 0 ? (
            <DeleteButton onClick={deleteOnClick}>
              {deleteOn ? <>Cancel</> : <>Delete</>}
              <TrashImg src={deleteOn ? XIcon : TrashIconGray} />
            </DeleteButton>
          ) : (
            <></>
          )}
        </HeaderContainer>
        <DropBoxTitle>
          <MakeToReadButton
            onClick={markAllAsReadOnClick}
            style={
              deleteOn || notiList.length < 1
                ? { color: palette.light_gray }
                : {}
            }
          >
            Mark all as read
          </MakeToReadButton>
        </DropBoxTitle>
        {/* <DeleteButton
                onClick={() => {
                  deleteOneOnClick(val.notification.index);
                }}
              /> */}
        {notiList.length > 0 ? (
          <ListContainer>
            {notiList?.map((v, i) => (
              <SingleNotiBox
                onClick={() =>
                  singleNotiOnClick(
                    v.index,
                    v.notificationType,
                    v.thumbnailUserId
                  )
                }
                style={v.checked ? {} : { backgroundColor: palette.noti_back }}
              >
                <ThumbnailBox src={v.thumbnailImg} />
                <BoxContent>
                  <ContentHeader>
                    <ContentTitle>{v.title}</ContentTitle>
                    <MinutesAgo>{minuteConvert(v.minitesAgo)}</MinutesAgo>
                  </ContentHeader>
                  <ContentDetail>
                    {v.type == "8" ? (
                      <>
                        {v.message?.split("/n").map((it, i) => (
                          <div key={"x" + i}>{it}</div>
                        ))}
                      </>
                    ) : (
                      <>
                        {v.message?.split("\n").map((it, i) => (
                          <div key={"x" + i}>{it}</div>
                        ))}
                      </>
                    )}
                  </ContentDetail>
                  {NotificationAction[v.notificationType - 1]?.actionText ? (
                    <ActionContainer>
                      <ActionButton
                        onClick={() => {
                          setSelectNoti(v);
                          if (v.notificationType == 2) {
                            NotiForm[v.notificationType]
                              ?.NotiAcceptAction(
                                localStorage.getItem("userID"),
                                v.thumbnailUserId,
                                "bestie",
                                setIsAccept,
                                setAcceptNotiInfo
                              )
                              .then(() => {
                                closeModal();
                              });
                            // setAcceptNotiInfo(v);
                            // setIsAccept(true);
                          } else if (
                            v.notificationType == 8 ||
                            v.notificationType == 9
                          ) {
                            NotiForm[v.notificationType]?.NotiAcceptAction(
                              closeModal,
                              setFinalConfirmationVisible,
                              setFinalConfirmationType,
                              setRequestInfo,
                              v.relatedObjIdx
                            );
                          } else {
                            NotiForm[v.notificationType]?.NotiAcceptAction();
                          }
                        }}
                      >
                        {NotificationAction[v.notificationType - 1]?.actionText}
                      </ActionButton>
                    </ActionContainer>
                  ) : (
                    <></>
                  )}
                  {deleteOn ? (
                    <>
                      {deleteList.indexOf(v.index) > -1 ? (
                        <SelectButton
                          style={{ backgroundImage: `url(${Checked})` }}
                          onClick={(e) => deleteCancelOnClick(v.index, e)}
                        />
                      ) : (
                        <SelectButton
                          onClick={(e) => deleteSelectOnClick(v.index, e)}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </BoxContent>
              </SingleNotiBox>
            ))}
          </ListContainer>
        ) : (
          <EmptyContainer>
            <BellBox src={GoldenBell} />
            <EmptyTitle>No notifications</EmptyTitle>
            <EmptyDescription>
              There are no new notifications yet!
            </EmptyDescription>
          </EmptyContainer>
        )}
        <BottomBox></BottomBox>
      </NotiContainer>
      {deleteOn ? (
        <DeleteFooterBox>
          <DeleteNotiButton
            style={{ border: `1px solid ${palette.light_gray}` }}
            onClick={() => deleteBottomOnClick(0)}
          >
            Delete all notification
          </DeleteNotiButton>
          <DeleteNotiButton
            style={{ color: palette.white, backgroundColor: palette.marine }}
            onClick={() => deleteBottomOnClick(1)}
          >
            Delete selected notification
          </DeleteNotiButton>
        </DeleteFooterBox>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default NotiBox;
