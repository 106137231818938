import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-x.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import BasicButton from "../../../../components/basic/BasicButton";
import WalletButtonGroup from "../../../../components/basic/WalletGroup/WalletButtonGroup";
import ConnectedWalletButton from "../../../../components/basic/ConnectedWallet/ConnectedWalletButton";
import WalletListData from "../../../../datas/WalletListData";
import CreateProfile from "../CreateProfile/CreateProfile";
import AddWalletAddress from "../../../../components/basic/AddWalletAddress/AddWalletAddress";

const LogoContainer = styled.div`
  // margin-top: 80px;
  margin-top: 10%;
`;

const ModalContainer = styled.div`
  width: 100%;
  //max-width: 1240px;
  min-height: 900px;
  // margin-top: 90px;
  // margin-top: 10%;
  margin-bottom: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  padding-top: 47.5px;
  padding-left: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  align-items: center;
`;

const StateComment = styled.div`
  color: ${palette.black};
  width: 100%;
  ${Typography.Heading2}
  text-align: center;
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 36px;
  border: 2px solid ${palette.blue};
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.white};
  margin: auto 0px;
  margin-top: 18px;
`;

const UserNameInput = styled.div`
  width: 80%;
  max-width: 327px;
  padding-left: 10px;
  color: ${palette.blue};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
`;

const BackArrowImage = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-right: 8px;
`;

const BackButton = styled.button`
  height: 24px;
  display: flex;
  background-color: transparent;
  border: 0;
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.gray};
`;

const CompleteContainer = styled.div`
  width: 90%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  min-height: 100px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 14px;
  margin-bottom: 58px;
`;

const CenterText = styled.div`
  ${Typography.Heading4}
  text-align: center;
  margin: auto 0px;
  //   margin-top: 40px;
`;

const ConnectListContainer = styled.div`
  width: 100%;
  max-width: 400px;
  gap: 20px;
`;

const BlankSpace = styled.div`
  width: 100%;
  max-width: 400px;
  height: 20px;
`;

const LoginConnectWallet = (props) => {
  const [userName, setUserName] = useState(props.userName);
  const [connectSuccess, setConnectSuccess] = useState(false);
  const [walletList, setWalletList] = useState(props.walletList);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (connectSuccess) {
      alert("Login Successfully! Welcome to Mepe!");
      window.location.href = "/mypage";
    }
  }, [connectSuccess]);

  console.log(walletList);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <React.Fragment>
      {connectSuccess ? (
        <React.Fragment></React.Fragment>
      ) : (
        // <CreateProfile walletList={walletList} userName={userName} />
        <ModalContainer>
          <ControlHeader>
            <BackButton onClick={backButtonOnClick}>
              <BackArrowImage src={BackArrow} />
              Go Back to Previous
            </BackButton>
          </ControlHeader>
          {modalVisible ? (
            <AddWalletAddress
              visible={modalVisible}
              closable={true}
              maskClosable={true}
              onClose={closeModal}
              setWalletList={setWalletList}
              walletList={walletList}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <div className={"f-column a-center"}>
            <ContentContainer>
              <LogoContainer>
                <StateComment>Login</StateComment>
              </LogoContainer>
              <CompleteContainer>
                <UserNameInputBox>
                  <UserNameInput>{userName}</UserNameInput>
                  <ModifiedCheck src={Valid} />
                </UserNameInputBox>
                <CenterText>with</CenterText>
                <ConnectListContainer>
                  {walletList?.map((v) => (
                    <React.Fragment>
                      <ConnectedWalletButton
                        setConnectSuccess={setConnectSuccess}
                        deepLink={
                          WalletListData[
                            WalletListData.findIndex(
                              (x) => x.walletName === v.walletType
                            )
                          ]?.deepLink
                        }
                        img={
                          WalletListData[
                            WalletListData.findIndex(
                              (x) => x.walletName === v.walletType
                            )
                          ]?.walletIcon
                        }
                        name={
                          WalletListData[
                            WalletListData.findIndex(
                              (x) => x.walletName === v.walletType
                            )
                          ]?.walletName
                        }
                        walletAddress={v.walletAddress}
                        walletId={WalletListData.findIndex(
                          (x) => x.walletName === v.walletType
                        )}
                      />
                      <BlankSpace />
                    </React.Fragment>
                  ))}
                </ConnectListContainer>
              </CompleteContainer>
            </ContentContainer>
          </div>
        </ModalContainer>
      )}
    </React.Fragment>
  );
};

export default LoginConnectWallet;
