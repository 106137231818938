import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../components/global/Header/index";
import Footer from "../../../components/global/Footer";
import { COLORS as palette } from "../../../utils/styles/colors";
import Typography from "../../../utils/styles/typography";
import CosmosBg from "../../../assets/images/cosmosBackground.svg";
import SpaceBg from "../../../assets/images/spaceBackground.svg";
import BankBox from "./components/BankBox";
import TransactionTable from "./components/TransactionTable";
import ChainInfo from "../../../data/chainInfoPolygon";
import $ from "jquery";
window.$ = $;

const HeaderBox = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  margin-top: 70px;
`;

const HeaderContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
`;

const TitleBox = styled.div`
  width: 90%;
  max-width: 1080px;
  margin-top: 98px;
  ${Typography.Heading1}
`;

const PageHeader = styled.div`
  width: 100%;
  height: 320px;
  border: 0;
  background-color: ${palette.white};
  display: flex;
  justify-content: center;
`;

const PageBackground = styled.div`
  background-color: ${palette.background};
`;

const HeaderTitle = styled.div`
  width: 90%;
  height: auto;
  max-width: 1080px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  color: ${palette.black};
  margin-top: 220px;
`;

const OtherTabButton = styled.button`
  display: flex;
  justify-content: center;
  width: 180px;
  height: 36px;
  border: hidden;
  border-radius: 46px;
  background-color: ${palette.black};
  color: ${palette.white};
  box-shadow: 0px 2px 10px #c4c4c440;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  align-items: center;
  margin-top: 98px;
`;

const FinancePage = () => {
  const [userInfo, setUserInfo] = useState({
    profileImage: localStorage.getItem("profileImage"),
  });
  const [walletAddress, setWalletAddress] = useState("");
  const [walletList, setWalletList] = useState([
    {
      walletName: "Metamask",
      walletId: 0,
      tokens: ChainInfo,
    },
    // {
    //   walletName: "Cosmostation",
    //   walletId: 2,
    //   tokens: ChainInfo,
    // },
  ]);

  useEffect(() => {
    console.log(localStorage.getItem("token"));
    if (!localStorage.getItem("token")) {
      alert("Your session has ended. Please login again.");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    console.log(walletAddress);
  }, [walletAddress]);

  useEffect(() => {
    setUserInfo({
      profileImage: localStorage.getItem("profileImage"),
    });
  }, [localStorage.getItem("profileImage")]);

  const transactionOnClick = () => {
    window.location.href = "/transactions";
  };

  const requestProgressOnClick = () => {
    window.location.href = "/requestProgress";
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <PageBackground className={"f-column a-center"}>
          <Header profileImg={userInfo?.profileImage} />
          <HeaderBox>
            <HeaderContainer>
              <TitleBox>MEPE.FI</TitleBox>
              <OtherTabButton
                style={{
                  backgroundColor: palette.white,
                  color: palette.black,
                  border: `2px solid ${palette.black}`,
                  marginRight: "20px",
                }}
                onClick={requestProgressOnClick}
              >
                Request Progress
              </OtherTabButton>
              <OtherTabButton onClick={transactionOnClick}>
                MEPE Transactions
              </OtherTabButton>
            </HeaderContainer>
          </HeaderBox>
          {/* <button onClick={callCarNumber}>check carnum</button> */}
          <BankBox wallet={walletList} setWalletAddress={setWalletAddress} />
          {/* <TransactionTable walletAddress={walletAddress} /> */}
        </PageBackground>
        <Footer />
      </React.Fragment>
    </React.Fragment>
  );
};

export default FinancePage;
