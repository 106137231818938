import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import LogoImage from "../../../assets/images/main_logo_image.svg";
import AlarmIcon from "../../../assets/icons/dashboard/icon-alert.svg";
import ProfileDropbox from "./ProfileDropbox";
import { checkUnreadNoti } from "../../../utils/api/notifications";
import DefaultProfile from "../../../assets/icons/dashboard/icon-defaultProfile.svg";
import PreviousImage from "../../../assets/icons/dashboard/icon-previous.svg";
import Typography from "../../../utils/styles/typography";

const HeaderContainer = styled.div`
  width: 100%;
  height: 126px;
  display: flex;
  justify-content: space-between;
  // padding-left: 100px;
  // padding-right: 90px;
  padding-left: 6%;
  padding-right: 6%;
  background-color: ${palette.white};
  z-index: 100;
  top: 0px;
`;

const PreviousButton = styled.button`
  background-image: url(${PreviousImage});
  width: 36px;
  height: 36px;
  border: hidden;
  background-color: transparent;
  margin: auto 0px;
`;

const ButtonContainer = styled.div`
  margin: auto 0px;
  display: flex;
  gap: 12px;
  position: relative;
`;

const ButtonCaption = styled.div`
  ${Typography.Heading5}
  text-align: left;
  color: ${palette.gray};
  margin: auto 0px;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.footer};
  color: ${palette.gray};
  background-color: ${palette.footer};
  z-index: 10;
`;

const DesktopHeader = (props) => {
  const [buttonText, setButtonText] = useState("");
  const [description, setDescription] = useState("");
  const [statusSetter, setStatusSetter] = useState("");

  useEffect(() => {
    setDescription(props.description);
    setButtonText(props.buttonText);
    if (props.setStatusSetter) {
      setStatusSetter(props.statusSetter);
    }
    console.log(props.description);
    console.log(props.status);
  }, [props.buttonText, props.description, props.statusSetter, props.status]);

  const buttonOnClick = () => {
    if (buttonText == "Sign In") {
      window.location.href = "/signin";
    } else if (buttonText == "Sign Up") {
      window.location.href = "/signup";
    }
  };

  const previousOnClick = () => {
    props.statusSetter(props.status - 1);
  };

  return (
    <HeaderContainer>
      {props.status > 0 ? (
        <PreviousButton onClick={previousOnClick} />
      ) : (
        <div />
      )}
      <ButtonContainer>
        <ButtonCaption>{description}</ButtonCaption>
        <RightButton onClick={buttonOnClick}>{buttonText}</RightButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default DesktopHeader;
