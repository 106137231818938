export default class NewCardData {
    constructor(id, title, people, price, category, imgUrl, valid) {
        this.id = id;
        this.title = title;
        this.people = people;
        this.price = price;
        this.category = category;
        this.imgUrl = imgUrl;
        this.valid = valid;
    }
}