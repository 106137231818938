import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import CheckIcon from "../../../assets/icons/dashboard/icon-bluecheck.svg";
import Typography from "../../../utils/styles/typography";

const Container = styled.div`
  display: flex;
  padding: 16px 24px 16px 24px;
  background-color: ${palette.light_gray_alpha20};
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  height: 100px;
  max-height: 100px;
`;

const WalletFullInfoBox = styled.div`
  width: 80%;
`;

const StateInfo = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.blue};
  display: flex;
  height: 22px;
`;

const WalletIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const WalletInfoBox = styled.div`
  display: flex;
  justify-content: left;
`;

const WalletName = styled.div`
  ${Typography.Heading3}
  margin: auto 0px;
  margin-left: 8px;
`;

const WalletAddress = styled.div`
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  color: ${palette.gray};
`;

const CheckImage = styled.img`
  width: 12px;
  height: 12px;
  margin: auto 0px;
  margin-left: 2px;
`;

const ConnectedWallet = (props) => {
  const [walletAddress, setWalletAddress] = useState(props.walletAddress);
  const [verified, setVerified] = useState(
    props.verified == false ? props.verified : true
  );

  useEffect(() => {
    if (walletAddress.length > 15) {
      setWalletAddress(
        walletAddress.substr(0, 6) +
          "..." +
          walletAddress.substr(walletAddress.length - 6, walletAddress.length)
      );
    }
  }, []);
  return (
    <Container>
      <WalletFullInfoBox>
        <WalletInfoBox>
          <WalletIcon src={props.img} />
          <WalletName>{props.name}</WalletName>
        </WalletInfoBox>
        <WalletAddress>{walletAddress}</WalletAddress>
      </WalletFullInfoBox>
      {verified ? (
        <StateInfo>
          verified
          <CheckImage src={CheckIcon} />
        </StateInfo>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Container>
  );
};

export default ConnectedWallet;
