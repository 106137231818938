import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import "./UserRank.css";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { addFollowing, deleteFollowing } from "../../../../utils/api/friends";
import TopRank from "./TopRank";
import useWindowDimensions from "../../../../utils/functions/useWindowDimensions";

const Container = styled.div`
  width: 100%;
`;

const TodayRankTitle = styled.div`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: center;
  color: ${palette.white};
`;

const InfoBox = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background-color: ${palette.marine};
  color: ${palette.white};
  ${Typography.Heading3}
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

const SideContainer = styled.button`
  display: flex;
  margin: auto 0px;
  border: hidden;
  background-color: transparent;
`;

const FollowingButton = styled.button`
  width: 120px;
  height: 36px;
  border-radius: 18px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  background-color: ${palette.white};
  z-index: 9;
`;

const RankBox = styled.div`
  width: 100%;
  min-height: 1000px;
  border-radius: 32px;
  background-color: ${palette.marine};
  padding: 20px;
  box-shadow: 0px 2px 10px #c4c4c440;
  margin-bottom: 200px;
`;

const RankHeadline = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 44px;
  padding: 10px;
  align-items: center;
`;

const RankTitle = styled.div`
  ${Typography.Heading2}
  color: ${palette.white};
`;

const RankInfo = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.white};
`;

const RankBlock = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${palette.background};
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
`;

const CommentCardHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 4px;
  height: 24px;
`;

const CommentProfileImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const CommentInfoBox = styled.div`
  align-self: center;
  margin-left: 12px;
`;

const CommentCustomName = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
`;

const ButtonContainer = styled.div`
  width: min(840px, 90%);
  height: auto;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  margin-top: 50px;
  margin-bottom: 26px;
  // padding-left: 300px;
`;

const RankNumber = styled.div`
  width: 20px;
  ${Typography.Heading3}
  text-align: center;
  margin-right: 40px;
`;

const UserRank = (props) => {
  const [rankList, setRankList] = useState(props.userList);
  // const [followIdxList, setFollowIdxList] = useState([]);
  const [followButtonClick, setFollowButtonClick] = useState(false);
  const [followingList, setFollowingList] = useState(props.followingList);
  const [tabData, setTabData] = useState([
    // { category: "Joined Time", className: "one", type: "joinedTime" },
    { category: "Daily Hits", className: "two", type: "dailyHits" },
    { category: "Daily Followers", className: "three", type: "dailyFollowers" },
  ]);
  const [clickIndex, setClickIndex] = useState(0);
  const { height, width } = useWindowDimensions();
  const [userID, setUserID] = useState(localStorage.getItem("userID"));

  useEffect(() => {}, [followButtonClick]);

  useEffect(() => {
    setRankList(props.userList);

    console.log(props.userList);
  }, [props.userList]);

  useEffect(() => {
    setFollowingList(props.followingList);
    console.log(props.followingList);
  }, [props.followingList]);

  const topicOnClick = () => {
    props.setOrder();
  };

  const followOnClick = async (id) => {
    // following 하기
    const addFollingResult = await addFollowing(
      id,
      localStorage.getItem("userID")
    )
      .then((data) => {
        console.log(data);
        let tmpFollowingList = followingList;
        tmpFollowingList.push(id);

        console.log(tmpFollowingList);
        setFollowingList(tmpFollowingList);
        setFollowButtonClick(!followButtonClick);
      })
      .catch(() => {
        alert("Something's wrong. Try again.");
      });
  };

  const unfollowOnClick = async (id) => {
    // unfollowing 하기
    const deleteFollowingResult = await deleteFollowing(
      id,
      localStorage.getItem("userID")
    )
      .then((data) => {
        console.log(data);
        let tmpFollowingList = followingList;
        tmpFollowingList.splice(tmpFollowingList.indexOf(id), 1);

        console.log(tmpFollowingList);
        setFollowingList(tmpFollowingList);
        setFollowButtonClick(!followButtonClick);
      })
      .catch(() => {
        alert("Something's wrong. Try again.");
      });
  };

  return (
    <Container>
      {/* <InfoBox>
        Reach 1st place in "Today's Rank" and receive a special gift!
      </InfoBox> */}
      <RankBox>
        <RankHeadline>
          <RankTitle> </RankTitle>
          <RankInfo>Today’s Rank resets at midnight UTC.</RankInfo>
        </RankHeadline>
        <ButtonContainer>
          <div class="button-container">
            <ul>
              {tabData.map((value, idx) => (
                <li class={value.className}>
                  {clickIndex == idx ? (
                    <button
                      style={{ color: palette.marine }}
                      onClick={() => {
                        setClickIndex(idx);
                        props.setOrder(idx + 1);
                      }}
                      class={value.className}
                    >
                      {value.category}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setClickIndex(idx);
                        props.setOrder(idx + 1);
                      }}
                      class={value.className}
                    >
                      {value.category}
                    </button>
                  )}
                </li>
              ))}
              <hr style={{ marginLeft: `calc(45%*${clickIndex})` }} />
            </ul>
          </div>
        </ButtonContainer>
        <TodayRankTitle>Today's Rank</TodayRankTitle>
        <TopRank userList={rankList?.slice(0, 3)} />
        {rankList.length > 0 ? (
          <React.Fragment>
            {rankList?.slice(0).map((val, idx) => (
              <React.Fragment>
                {width > 600 ? (
                  <RankBlock>
                    <SideContainer
                      onClick={() => {
                        window.location.href = "/@" + val.user.id;
                      }}
                    >
                      <CommentCardHeader>
                        <RankNumber>{idx + 1}</RankNumber>
                        <CommentProfileImage src={val.user.profileImg} />
                        <CommentInfoBox>
                          <CommentCustomName>
                            {val.user.nickname
                              ? val.user.nickname
                              : val.user.id}
                          </CommentCustomName>
                          <CommentUserId>@{val.user.id}</CommentUserId>
                        </CommentInfoBox>
                      </CommentCardHeader>
                    </SideContainer>
                    {userID ? (
                      <SideContainer>
                        {!followingList.includes(val.user.id) ? (
                          <FollowingButton
                            onClick={() => followOnClick(val.user.id)}
                            style={{
                              backgroundColor: palette.marine,
                              color: palette.white,
                            }}
                          >
                            Follow
                          </FollowingButton>
                        ) : (
                          <FollowingButton
                            onClick={() => unfollowOnClick(val.user.id)}
                          >
                            Following
                          </FollowingButton>
                        )}
                      </SideContainer>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </RankBlock>
                ) : (
                  <React.Fragment>
                    <RankBlock>
                      <SideContainer
                        onClick={() => {
                          window.location.href = "/@" + val.user.id;
                        }}
                      >
                        <CommentCardHeader>
                          <RankNumber>{idx + 1}</RankNumber>
                          <CommentProfileImage src={val.user.profileImg} />
                          <CommentInfoBox>
                            <CommentCustomName>
                              {val.user.nickname
                                ? val.user.nickname
                                : val.user.id}
                            </CommentCustomName>
                            <CommentUserId>@{val.user.id}</CommentUserId>
                          </CommentInfoBox>
                        </CommentCardHeader>
                      </SideContainer>
                      {/* <SideContainer>
                        {!followingList.includes(val.user.id) ? (
                          <FollowingButton
                            onClick={() => followOnClick(val.user.id)}
                            style={{
                              backgroundColor: palette.marine,
                              color: palette.white,
                            }}
                          >
                            Follow
                          </FollowingButton>
                        ) : (
                          <FollowingButton
                            onClick={() => unfollowOnClick(val.user.id)}
                          >
                            Following
                          </FollowingButton>
                        )}
                      </SideContainer> */}
                    </RankBlock>
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </RankBox>
    </Container>
  );
};

export default UserRank;
