import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../components/global/Header/index";
import { COLORS as palette } from "../../../utils/styles/colors";
import CosmosBg from "../../../assets/images/cosmosBackground.svg";
import SpaceBg from "../../../assets/images/spaceBackground.svg";
import MyTokens from "./components/MyTokens";
import MyPolygonNft from "./components/MyPolygonNft";
import TransactionTable from "./components/TransactionTable";
import ChainInfo from "../../../data/chainInfoPolygon";
import $ from "jquery";
window.$ = $;

const PageHeader = styled.div`
  width: 100%;
  height: 320px;
  border: 0;
  background-color: ${palette.black};
  background-image: url(${CosmosBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
`;

const PageBackground = styled.div`
  background-color: ${palette.black};
  background-image: url(${SpaceBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const HeaderTitle = styled.div`
  width: 90%;
  height: auto;
  max-width: 1080px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  color: ${palette.white};
  margin-top: 220px;
`;

const PolygonPage = () => {
  const [userInfo, setUserInfo] = useState({
    profileImage: localStorage.getItem("profileImage"),
  });
  const [walletAddress, setWalletAddress] = useState("");
  const [walletList, setWalletList] = useState([
    {
      walletName: "Metamask",
      walletId: 0,
      tokens: ChainInfo,
    },
    // {
    //   walletName: "Cosmostation",
    //   walletId: 2,
    //   tokens: ChainInfo,
    // },
  ]);

  useEffect(() => {
    console.log(walletAddress);
  }, [walletAddress]);

  useEffect(() => {
    setUserInfo({
      profileImage: localStorage.getItem("profileImage"),
    });
  }, [localStorage.getItem("profileImage")]);

  function callCarNumber(pid) {
    if (pid == null || pid == "") {
      pid = "1";
    }

    console.log("choicedate,pid" + "2022-08-28" + pid);
    $.ajax({
      type: "POST",
      url: "https://reservenew.kna.go.kr/rms/callCarNumber",
      dataType: "json",
      // data: `{choiceDate: "2022-08-28",pid: "1"}`,
      data: { choiceDate: "2022-08-28", pid: 1 },
      // headers: { "Allow-Control-Allow-Origin": "*" },
      success: function (data) {
        var obj = JSON.parse(data);
        console.log(obj);
        console.log(obj.carNumCnt[0]);
        // if (obj.code == "-1") {
        //   alert(obj.msg);
        //   return false;
        // }
        // var str =
        //   '<input id="ac-2" class="ac_ip_chck" name="accordion-1" type="checkbox" ></carList> <label class="ac-lb_chck" for="">02. 차량유형 선택</label>' +
        //   '<article class="ac-step_04" style="padding: 30px 15px;">' +
        //   '<input type="radio" value="' +
        //   obj.productid[0] +
        //   '" name="' +
        //   obj.productNameList[0] +
        //   '" id="kind" checked="checked" onchange="checkCarCnt(' +
        //   obj.carNumCnt[0] +
        //   ',this.name);"> ' +
        //   obj.productNameList[0] +
        //   "(잔여주차가능차량수 " +
        //   obj.carNumCnt[0] +
        //   " 대)<br>" +
        //   '<input type="radio" value="' +
        //   obj.productid[1] +
        //   '" name="' +
        //   obj.productNameList[1] +
        //   '" id="kind"  onchange="checkCarCnt(' +
        //   obj.carNumCnt[1] +
        //   ',this.name);"> ' +
        //   obj.productNameList[1] +
        //   "(잔여주차가능차량수 " +
        //   obj.carNumCnt[1] +
        //   " 대)<br>" +
        //   '<input type="radio" value="' +
        //   obj.productid[2] +
        //   '" name="' +
        //   obj.productNameList[2] +
        //   '" id="kind"  onchange="checkCarCnt(' +
        //   obj.carNumCnt[2] +
        //   ',this.name);"> ' +
        //   obj.productNameList[2] +
        //   "(잔여주차가능차량수 " +
        //   obj.carNumCnt[2] +
        //   " 대)" +
        //   "</article>";
        // var html = $("#p_box");
        // html.empty();
        // html.append(str);
        // if ($("#reservationType").val() != "RESERVATION_ADVISER") {
        //   if (!obj.closeOpen) {
        //     if (pid == "1") {
        //       alert(
        //         "금일 오전주차 가능시간이 종료되었습니다. 오후주차하시기 바랍니다"
        //       );
        //     }
        //   }
        // }
        // checkCarCnt(obj.carNumCnt[0], obj.productNameList[0]);
      },
    });
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <PageBackground className={"f-column a-center"}>
          <Header profileImg={userInfo?.profileImage} />
          <PageHeader>
            <HeaderTitle>Solana</HeaderTitle>
          </PageHeader>
          {/* <button onClick={callCarNumber}>check carnum</button> */}
          <MyTokens wallet={walletList} setWalletAddress={setWalletAddress} />
          <TransactionTable walletAddress={walletAddress} />
          <MyPolygonNft />
        </PageBackground>
      </React.Fragment>
    </React.Fragment>
  );
};

export default PolygonPage;
