import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import HomeIcon from "../../../../assets/icons/dashboard/icon-home.svg";
import WalletIcon from "../../../../assets/icons/dashboard/icon-wallet.svg";
import TwitterIcon from "../../../../assets/icons/dashboard/icon-twitter.svg";
import FacebookIcon from "../../../../assets/icons/dashboard/icon-facebook.svg";
import DiscordIcon from "../../../../assets/icons/dashboard/icon-discord.svg";
import LinkedinIcon from "../../../../assets/icons/dashboard/icon-linkedin.svg";
import OtherLinkIcon from "../../../../assets/icons/dashboard/icon-link2.svg";
import TotalWalletAddressModal from "../components/TotalWalletAddressModal";
import ProfileHistory from "../components/ProfileHistory";
import EditProfile from "../components/EditProfile";
import FollowingList from "../components/FollowingList";
import FollowerList from "../components/FollowerList";
import FriendList from "../components/FriendList";
import shareIcon from "../../../../assets/icons/dashboard/icon-share.svg";
import AddFriend from "../components/AddFriend";
import {
  addFollowing,
  deleteFollowing,
  followerList,
  followingList,
  isFollowing,
} from "../../../../utils/api/friends";
import { getProfileHistory } from "../../../../utils/api/auth";
import { checkIsFriend } from "../../../../utils/api/friends";

const FullContainer = styled.div`
  width: 100%;
  min-height: 480px;
  border-radius: 32px;
  background-color: ${palette.white};
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  margin-bottom: 30px;
`;

const ModalContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  min-height: 900px;
  margin-top: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const BackgroundImageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: 26px;
  position: relative;
  border-radius: 32px 32px 0px 0px;
`;

const BackgroundImageButton = styled.div`
  width: 100%;
  height: 200px;
  border: 0;
  border-radius: 32px 32px 0px 0px;
  background-color: white;
`;

const ProfileImageButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: -60px;
  position: relative;
`;

const ProfileImageButton = styled.button`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 1px solid #777777;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: 30px;
`;

const HitsBox = styled.div`
  display: flex;
  position: absolute;
  left: 40px;
  top: 20px;
  justify-content: space-between;
  width: 145px;
  height: 27px;
  border-radius: 20px;
  background-color: ${palette.background};
`;

const HitsTitle = styled.div`
  color: ${palette.gray};
  margin: auto 0px;
  margin-left: 16px;
  ${Typography.Link}
`;

const HitsNumber = styled.div`
  display: flex;
  margin: auto 0px;
  margin-right: 16px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  color: ${palette.gray};
`;

const TodayNumber = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  margin-right: 5px;
  color: ${palette.black};
`;

const TotalNumber = styled.div`
  ${Typography.Link}
  color: ${palette.gray};
`;

const JoinDate = styled.div`
  position: absolute;
  right: 49px;
  top: 20px;
  color: ${palette.white};
  // color: ${palette.black};
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #00000022;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  // mix-blend-mode: difference;
`;

const ProfileImageCombineBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-left: 7%;
  // margin-bottom: 20px;
`;

const ButtonInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 360px;
  align-items: right;
  gap: 11px;
  align-self: flex-end;
`;

const FollowBox = styled.div`
  display: flex;
  gap: 11px;
`;

const InfoButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  // drop-shadow: 0px 2px 10px #C4C4C444;
  border: 0;
  background-color: ${palette.white};
  margin-left: 10px;
`;

const FriendIntoButton = styled.button`
  display: flex;
  height: 36px;
  border-radius: 18px;
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  border: 0;
  background-color: ${palette.white};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  padding: 8px 16px 8px 16px;
`;

const FriendsNumber = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 4px;
`;

const ButtonImg = styled.img`
  width: 24px;
  height: 24px;
`;

const BottomContainer = styled.div`
  margin-left: 7%;
  margin-top: 20px;
`;

const TitleBox = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: auto 0px;
  margin-bottom: 5px;
`;

const UserIdBox = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: auto 0px;
  margin-left: 10px;
  color: ${palette.gray};
`;

const IntroductionBox = styled.div`
  width: 92.5%;
  border-radius: 8px;
  background-color: ${palette.background};
  padding: 14px 20px;
  ${Typography.Body}
  text-align: left;
  margin-top: 12px;
  margin-bottom: 18px;
`;

const SNSContainer = styled.div`
  height: 32px;
  display: flex;
  text-align: left;
  gap: 10px;
  margin-bottom: 30px;
`;

const SNSButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 0;
`;

const SNSImage = styled.img`
  width: 24px;
  height: 24px;
  margin: 4px;
`;

const CommunicateContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding-right: 1%;
  position: absolute;
  right: 0;
  top: 42px;
  // top: 15px;
  // width: 30vw;
  max-width: 390px;
  min-width: 260px;
`;

const FriendFollowContainer = styled.div`
  width: auto;
  max-width: 300px;
`;

const ShareLinkButton = styled.button`
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 18px;
  border: hidden;
  background-image: url(${shareIcon});
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0px 2px 10px #c4c4c444);
  background-color: white;
  // drop-shadow: 0px 2px 10px #C4C4C444;
`;

const EditProfileButton = styled.button`
  padding-left: 40px;
  padding-right: 40px;
  max-width: 180px;
  height: 36px;
  border-radius: 18px;
  border: 2px solid ${palette.marine};
  background-color: ${palette.white};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  color: ${palette.marine};
  // margin-right: 60px;
`;

const AddFriendButton = styled.button`
  // padding-left: 40px;
  // padding-right: 40px;
  width: 140px;
  max-width: 180px;
  height: 36px;
  border-radius: 18px;
  border: 2px solid ${palette.marine};
  background-color: ${palette.marine};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  color: ${palette.white};
  // margin-right: 60px;
  margin: 2px;
`;

const MaskLayer = styled.div`
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, #88888844 1%, transparent 100%);
`;

const NameBox = styled.div`
  display: flex;
  height: auto;
`;

function isMobileDevice() {
  return "ontouchstart" in window || "onmsgesturechange" in window;
}

function getTextColorByBackgroundColor(hexColor) {
  const c = hexColor.substring(1); // 색상 앞의 # 제거
  const rgb = parseInt(c, 16); // rrggbb를 10진수로 변환
  const r = (rgb >> 16) & 0xff; // red 추출
  const g = (rgb >> 8) & 0xff; // green 추출
  const b = (rgb >> 0) & 0xff; // blue 추출
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  // 색상 선택
  return luma < 127.5 ? "white" : "black"; // 글자색이
}

const ProfileBox = (props) => {
  const [userInfo, setUserInfo] = useState(props.userInfo);
  const [modalVisible, setModalVisible] = useState(false);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [addFriendVisible, setAddFriendVisible] = useState(false);
  const [walletList, setWalletList] = useState(props.walletList);
  const [socials, setSocials] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [isUserPage, setIsUserPage] = useState(false);
  const [following, setFollowing] = useState(false);
  const [friendType, setFriendType] = useState(0); // 0: not friend, 1: requested, 2: firend
  const [followerNum, setFollowerNum] = useState(props.followerNum);
  const [followingNum, setFollowingNum] = useState(props.followingNum);
  const [friendNum, setFriendNum] = useState(props.friendNum);
  const [followerModalVisible, setFollowerModalVisible] = useState(false);
  const [followingModalVisible, setFollowingModalVisible] = useState(false);
  const [editProfileModalVisible, setEditProfileModalVisible] = useState(false);
  const [friendModalVisible, setFriendModalVisible] = useState(false);
  const [profileHistory, setProfileHistory] = useState([]);

  const location = useLocation();
  console.log(userInfo);

  useEffect(() => {}, [following]);

  useEffect(() => {
    if (isUserPage) {
      (async () => {
        const checkIsFriendResult = await checkIsFriend(
          localStorage.getItem("userID"),
          userInfo.id
        ).then((data) => {
          if (data == "none") {
            setFriendType(0);
          } else if (data == "wait") {
            setFriendType(1);
          } else if (data == "friend") {
            setFriendType(2);
          } else {
            // logout
            setFriendType(0);
          }
        });
      })();
    }
  }, [isUserPage]);

  useEffect(() => {
    (async () => {
      const profileHistoryResult = await getProfileHistory(
        props.userInfo.id
      ).then((data) => {
        if (isUserPage && data.length > 0) {
          let hideFiltering = data.filter((history) => history.hide == false);
          setProfileHistory(hideFiltering);
        } else {
          setProfileHistory(data);
        }
      });
    })();
  }, [props.userInfo]);

  useEffect(() => {
    (async () => {
      const isFollowingResult = await isFollowing(
        localStorage.getItem("userID"),
        props.userInfo.id
      ).then((data) => {
        setFollowing(data);
      });
    })();
  });

  useEffect(() => {
    setFollowingNum(props.followingNum);
  }, [props.followingNum]);

  useEffect(() => {
    setFollowerNum(props.followerNum);
  }, [props.followerNum]);

  useEffect(() => {
    setFriendNum(props.friendNum);
  }, [props.friendNum]);

  useEffect(() => {
    if (location.pathname.includes("@")) {
      setIsUserPage(true);
    }
  }, []);

  useEffect(() => {
    // if (userInfo.id != localStorage.getItem("userID")) {
    //   setWalletList(userInfo.wallets);
    // }
    if (props.userInfo.social) {
      setSocials(props.userInfo.social);
    } else {
      setSocials(props.socials);
    }
    setWalletList(props.walletList);
  }, [props.userInfo]);

  useEffect(() => {
    console.log(props.userInfo);
    setUserInfo(props.userInfo);
    const tmpHistory = [
      {
        obtainedAt: "2022-05-08T03:33:40.000+00:00",
        image: props.userInfo.profileImg,
        hidden: false,
      },
    ]; //임시 데이터
    setHistoryList(tmpHistory);
  }, [props.userInfo]);

  const handleCopyClipBoard = async (text) => {
    var textarea = document.createElement("textarea");
    textarea.value = text; // 복사할 메시지
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 9999); // For IOS
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("Copy address completed.");
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeEditProfileModal = () => {
    setEditProfileModalVisible(false);
  };

  const closeFollowerModal = () => {
    setFollowerModalVisible(false);
  };

  const closeFollowingModal = () => {
    setFollowingModalVisible(false);
  };

  const closeFriendModal = () => {
    setFriendModalVisible(false);
  };

  const closeAddFriendModal = () => {
    setAddFriendVisible(false);
  };

  const historyCloseModal = () => {
    setHistoryModalVisible(false);
  };

  const walletButtonOnClick = () => {
    setModalVisible(true);
  };

  const historyButtonOnClick = () => {
    setHistoryModalVisible(true);
  };

  const twitterOnClick = () => {
    window.location.href = "https://twitter.com/" + socials.twitter;
  };

  const facebookOnClick = () => {
    window.location.href = "https://www.facebook.com/" + socials.facebook;
  };

  const discordOnClick = () => {
    window.location.href = socials.discord;
  };

  const linkOnClick = () => {
    window.location.href = socials.link;
  };

  const followOnClick = async () => {
    // following 하기
    const addFollingResult = await addFollowing(
      props.userInfo.id,
      localStorage.getItem("userID")
    )
      .then((data) => {
        console.log(data);
        console.log(following);
        if (data) {
          setFollowing(true);
        }
      })
      .catch(() => {
        alert("Something's wrong. Try again.");
      });
  };

  const unfollowOnClick = async () => {
    // unfollowing 하기
    const deleteFollowingResult = await deleteFollowing(
      props.userInfo.id,
      localStorage.getItem("userID")
    )
      .then((data) => {
        console.log(data);

        console.log(following);
        setFollowing(false);
      })
      .catch(() => {
        alert("Something's wrong. Try again.");
      });
  };

  const followerButtonOnClick = async () => {
    const followerListResult = await followerList(props.userInfo.id)
      .then((data) => {
        console.log(data);
        setFollowerModalVisible(true);
      })
      .catch(() => {
        alert("Something's wrong. Try again.");
      });
  };

  const followingButtonOnClick = async () => {
    const followingListResult = await followingList(props.userInfo.id)
      .then((data) => {
        console.log(data);
        setFollowingModalVisible(true);
      })
      .catch(() => {
        alert("Something's wrong. Try again.");
      });
  };

  const editProfileOnClick = () => {
    // setEditProfileModalVisible(true);
    window.location.href = "/editProfile";
  };

  const shareButtonOnClick = () => {
    if (isMobileDevice() && navigator.share) {
      // 만약 모바일이라면
      navigator.share({
        title: "MEPE | Your Web 3.0 Multi-Wallet Dashboard",
        text: "MEPE is the Web3 Multi-Wallet Personal Dashboard supporting multi-chain, multi-wallet connections and multi-persona.Claim your badge to prove your qualifications and show off your on & offline experience. Issue and liquidate social tokens for individual and community.",
        url: window.location.href,
      });
    } else {
      var text = window.location.href;
      var textarea = document.createElement("textarea");
      textarea.value = text; // 복사할 메시지
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 9999); // For IOS
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert("Copy link completed.");
    }
  };

  const addFriendButtonOnClick = () => {
    // 친신 거는 api 호출
    if (!localStorage.getItem("token")) {
      alert("Your session has ended. Please login again.");
      window.location.href = "/";
    } else {
      setAddFriendVisible(true);
    }
    // setFriendType(1); // 성공하면 상태 requested로
  };

  const friendButtonOnClick = () => {
    setFriendModalVisible(true);
  };

  return (
    <React.Fragment>
      {modalVisible ? (
        <TotalWalletAddressModal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
          walletListInit={walletList}
          userName={userInfo.nickname}
          isUserPage={isUserPage}
        />
      ) : (
        <React.Fragment>
          {historyModalVisible ? (
            <ProfileHistory
              visible={historyModalVisible}
              closable={true}
              maskClosable={true}
              onClose={historyCloseModal}
              // historyList={historyList}
              historyList={profileHistory}
              setHistoryList={setHistoryList}
              isUserPage={isUserPage}
            />
          ) : (
            // <React.Fragment>
            //   {editProfileModalVisible ? (
            //     <EditProfile
            //       visible={editProfileModalVisible}
            //       closable={true}
            //       maskClosable={true}
            //       onClose={closeEditProfileModal}
            //       userInfo={JSON.parse(localStorage.getItem("userInfo"))}
            //       socials={JSON.parse(localStorage.getItem("socials"))}
            //     />
            //   ) : (
            <React.Fragment>
              {followingModalVisible ? (
                <FollowingList
                  visible={followingModalVisible}
                  closable={true}
                  maskClosable={true}
                  onClose={closeFollowingModal}
                  userName={props.userInfo.id}
                />
              ) : (
                <React.Fragment>
                  {followerModalVisible ? (
                    <FollowerList
                      visible={followerModalVisible}
                      closable={true}
                      maskClosable={true}
                      onClose={closeFollowerModal}
                      userName={props.userInfo.id}
                    />
                  ) : (
                    <React.Fragment>
                      {addFriendVisible ? (
                        <AddFriend
                          visible={addFriendVisible}
                          closable={true}
                          maskClosable={true}
                          onClose={closeAddFriendModal}
                          friendInfo={{
                            img: userInfo.profileImg,
                            userId: userInfo.id,
                          }}
                          setStatus={setFriendType}
                        />
                      ) : (
                        <React.Fragment>
                          {friendModalVisible ? (
                            <FriendList
                              visible={friendModalVisible}
                              closable={true}
                              maskClosable={true}
                              onClose={closeFriendModal}
                              userName={props.userInfo.id}
                            />
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {/* </React.Fragment>
                  )} */}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {editProfileModalVisible ? (
        <React.Fragment></React.Fragment>
      ) : (
        <FullContainer>
          <BackgroundImageButtonContainer>
            <BackgroundImageButton
              style={{
                // webkitMaskImage:
                //   "linear-gradient(to top, transparent 1%, black 100%)",
                // maskImage: "linear-gradient(to top, transparent 1%, black 100%)",
                backgroundImage: `url(${userInfo.backImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                // background: "linear-gradient(to top, transparent , black)"
              }}
            >
              <MaskLayer />
              <HitsBox>
                <HitsTitle>hits</HitsTitle>
                <HitsNumber>
                  <TodayNumber>{userInfo.todayHits}</TodayNumber>
                  <TotalNumber>/ {userInfo.hits}</TotalNumber>
                </HitsNumber>
              </HitsBox>
              <JoinDate>
                joined{" "}
                {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
                  Date.parse(userInfo.createdAt)
                )}{" "}
                {new Date(userInfo.createdAt).getFullYear()}
              </JoinDate>
            </BackgroundImageButton>
          </BackgroundImageButtonContainer>
          <ProfileImageButtonContainer>
            <ProfileImageCombineBox>
              <ProfileImageButton
                onClick={historyButtonOnClick}
                style={{
                  backgroundImage: `url(${userInfo.profileImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <ButtonInfoBox>
                {/* <InfoButton>
                <ButtonImg src={HomeIcon} />
              </InfoButton> */}
                <FollowBox>
                  <InfoButton onClick={walletButtonOnClick}>
                    <ButtonImg src={WalletIcon} />
                  </InfoButton>
                  <FriendIntoButton onClick={friendButtonOnClick}>
                    <FriendsNumber>{friendNum}</FriendsNumber> Friend
                  </FriendIntoButton>
                  <FriendIntoButton onClick={followerButtonOnClick}>
                    <FriendsNumber>{followerNum}</FriendsNumber> Follower
                  </FriendIntoButton>
                  <FriendIntoButton onClick={followingButtonOnClick}>
                    <FriendsNumber>{followingNum}</FriendsNumber> Following
                  </FriendIntoButton>
                </FollowBox>
              </ButtonInfoBox>
            </ProfileImageCombineBox>
            <CommunicateContainer>
              <ShareLinkButton onClick={shareButtonOnClick} />
              {isUserPage ? (
                <FriendFollowContainer>
                  <React.Fragment>
                    {following ? (
                      <AddFriendButton onClick={unfollowOnClick}>
                        Following
                      </AddFriendButton>
                    ) : (
                      <AddFriendButton
                        onClick={followOnClick}
                        style={{
                          backgroundColor: palette.white,
                          color: palette.marine,
                        }}
                      >
                        Follow
                      </AddFriendButton>
                    )}
                  </React.Fragment>
                  <React.Fragment>
                    {friendType == 0 ? (
                      <AddFriendButton onClick={addFriendButtonOnClick}>
                        Add friend
                      </AddFriendButton>
                    ) : (
                      <React.Fragment>
                        {friendType == 1 ? (
                          <AddFriendButton>Requested</AddFriendButton>
                        ) : (
                          <AddFriendButton>Friend</AddFriendButton>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </FriendFollowContainer>
              ) : (
                <EditProfileButton onClick={editProfileOnClick}>
                  Edit Profile
                </EditProfileButton>
              )}
            </CommunicateContainer>
          </ProfileImageButtonContainer>
          <BottomContainer>
            <NameBox>
              <TitleBox>{userInfo.nickname}</TitleBox>
              <UserIdBox>@{userInfo.id}</UserIdBox>
            </NameBox>
            <IntroductionBox>{userInfo.introduction}</IntroductionBox>
            <SNSContainer>
              {socials?.twitter ? (
                <SNSButton
                  onClick={twitterOnClick}
                  style={{ backgroundColor: palette.sns_twitter }}
                >
                  <SNSImage src={TwitterIcon} />
                </SNSButton>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {socials?.facebook ? (
                <SNSButton
                  onClick={facebookOnClick}
                  style={{ backgroundColor: palette.sns_facebook }}
                >
                  <SNSImage src={FacebookIcon} />
                </SNSButton>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {socials?.discord ? (
                <SNSButton
                  onClick={() => handleCopyClipBoard(socials.discord)}
                  style={{ backgroundColor: palette.sns_discord }}
                >
                  <SNSImage src={DiscordIcon} />
                </SNSButton>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {socials?.link ? (
                <SNSButton
                  onClick={linkOnClick}
                  style={{ backgroundColor: palette.light_black }}
                >
                  <SNSImage src={OtherLinkIcon} />
                </SNSButton>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </SNSContainer>
          </BottomContainer>
        </FullContainer>
      )}
    </React.Fragment>
  );
};

export default ProfileBox;
