import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-wrong.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { checkSignup } from "../../../../utils/api/auth.js";
import XIcon from "../../../../assets/icons/dashboard/icon-x-gray.svg";
import { setuid } from "process";
import WalletButtonGroupMini from "../../../../components/basic/WalletGroup/WalletButtonGroupMini";
import CreateAccountSuccessModal from "./CreateAccountSuccessModal";
import { createUser } from "../../../../utils/api/auth.js";
import { createWallet } from "../../../../utils/api/wallets";

const LogoContainer = styled.div`
  // margin-top: 280px;
  margin-top: 70px;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
`;

const ContentContainer = styled.div`
  align-items: left;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading3}
`;

const MainComment = styled.div`
  height: 32px;
  ${Typography.Heading1}
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 87px;
  display: flex;
  justify-content: space-between;
  padding-right: 29px;
  padding-left: 22px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  background-color: ${palette.valid};
  margin: 0px auto;
  margin-top: 30px;
`;

const UserIDMark = styled.div`
  ${Typography.Body}
  color: ${palette.blue};
  margin-bottom: 16px;
`;

const UserNameLeftBox = styled.div`
  padding-top: 16px;
`;

const UserNameRightBox = styled.div`
  padding-top: 39px;
`;

const UserNameFinal = styled.div`
  width: auto;
  height: auto;
  ${Typography.Heading3}
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-left: 8px;
`;

const NextButton = styled.button`
  width: 100px;
  height: 36px;
  background-color: ${palette.marine}33;
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
  margin-top: 50px;
`;

const TextDeleteButton = styled.button`
  background-image: url(${XIcon});
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: hidden;
  margin: auto 0px;
`;

const FindUserIdButton = styled.button`
  border: 0;
  background-color: transparent;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${palette.gray};
  text-decoration: underline;
`;

const FindBox = styled.div`
  display: flex;
`;

const LeadComment = styled.div`
  ${Typography.Body}
  color: ${palette.gray};
  margin-right: 13px;
`;

const ComponentDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 30px;
  color: ${palette.gray};
`;

const WalletButtonBox = styled.div`
  width: 100%;
  min-height: 200px;
  padding-top: 24px;
  padding-bottom: 45px;
  border-radius: 14px;
  border: hidden;
  background-color: ${palette.background};
  margin-top: 30px;
`;

const WalletButtonBoxTitle = styled.div`
  ${Typography.Body}
  text-align: center;
  color: ${palette.light_black};
`;

const SignUpWithWallet = (props) => {
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [walletId, setWalletId] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (walletAddress != "") {
      (async () => {
        console.log("ra");
        if (walletId == 1) {
          const createUserId = await createUser(
            props.userName,
            walletAddress[0],
            walletId
          )
            .then((data) => {
              console.log(data);
            })
            .then(async (data) => {
              console.log(data);
              const createWalletResult = await createWallet(
                props.userName,
                walletAddress[1],
                walletId
              )
                .then((data) => {
                  setModalVisible(true);
                })
                .catch(() => {
                  alert("error!");
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const createUserId = await createUser(
            props.userName,
            walletAddress,
            walletId
          )
            .then((data) => {
              console.log(data);
              setModalVisible(true);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })();
    }
    // if (walletAddress != "") {
    //   // api 동작 안되는 상황 테스트코드
    //   setModalVisible(true);
    // }
  }, [walletAddress]);

  useEffect(() => {
    var regExp = /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{4,18}$/g;
    var testResult = regExp.test(userName);

    if ((userName.length <= 4 || userName.length >= 20) && userName) {
      setErrorComment(
        "Your user ID must be longer than 4, less than 20 characters."
      );
    } else if (!testResult && userName) {
      setErrorComment(
        "Your username can only contain letters, numbers and '_'"
      );
    } else {
      setErrorComment("");
    }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  useEffect(() => {}, [connectWallet]);

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <ModalContainer>
      {modalVisible ? (
        <CreateAccountSuccessModal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
          walletAddress={walletAddress}
          walletId={walletId}
          userName={props.userName}
        />
      ) : (
        <></>
      )}
      <ContentContainer>
        <HeaderTitle>Sign Up to MEPE</HeaderTitle>
        <LogoContainer>
          <MainComment>Sign Up with Wallet</MainComment>
        </LogoContainer>
        <ComponentDescription>
          User ID is permanent and cannot be modified. So don't forget and
          remember!
        </ComponentDescription>
        <UserNameInputBox style={{ borderColor: `${palette.blue}` }}>
          <UserNameLeftBox>
            <UserIDMark>User ID</UserIDMark>
            <UserNameFinal>{props.userName}</UserNameFinal>
          </UserNameLeftBox>
          <UserNameRightBox>
            <ModifiedCheck src={Valid} />
          </UserNameRightBox>
        </UserNameInputBox>
        {/* <FindBox>
          <LeadComment>Forgot Your User ID?</LeadComment>
          <FindUserIdButton>Find User ID</FindUserIdButton>
        </FindBox> */}
        <WalletButtonBox>
          <WalletButtonBoxTitle>Sign Up with Wallet</WalletButtonBoxTitle>
          <WalletButtonGroupMini
            setPageStack={setWalletAddress}
            setWalletId={setWalletId}
          />
        </WalletButtonBox>
      </ContentContainer>
    </ModalContainer>
  );
};

export default SignUpWithWallet;
