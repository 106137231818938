import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-x.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import BasicButton from "../../../../components/basic/BasicButton";
import WalletButtonGroup from "../../../../components/basic/WalletGroup/WalletButtonGroup";
import ConnectedWallet from "../../../../components/basic/ConnectedWallet/ConnectedWallet";
import WalletListData from "../../../../datas/WalletListData";
import CreateProfile from "../CreateProfile/CreateProfile";
import CreateAccountConnected from "./CreateAccountConnected";
import { createWallet } from "../../../../utils/api/wallets";

const LogoContainer = styled.div`
  // margin-top: 80px;
  margin-top: 0%;
`;

const ModalContainer = styled.div`
  width: 100%;
  //max-width: 1240px;
  min-height: 900px;
  // margin-top: 90px;
  // margin-top: 5%;
  margin-bottom: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  padding-top: 47.5px;
  padding-left: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  align-items: center;
`;

const MainComment = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const StateComment = styled.div`
  color: ${palette.marine};
  width: 100%;
  ${Typography.Heading2}
  text-align: center;
  color: ${palette.marine};
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 36px;
  border: 2px solid ${palette.blue};
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.white};
  margin: auto 0px;
`;

const UserNameInput = styled.div`
  width: 80%;
  max-width: 327px;
  padding-left: 10px;
  color: ${palette.blue};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
`;

const BackArrowImage = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-right: 8px;
`;

const BackButton = styled.button`
  height: 24px;
  //   margin-top: 47.5px;
  display: flex;
  background-color: transparent;
  border: 0;
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.gray};
`;

const NotNowButton = styled.button`
  width: 112px;
  max-width: 112px;
  height: 44px;
  background-color: ${palette.white};
  color: ${palette.black};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
  margin-top: 24px;
  border: 2px solid ${palette.black};
`;

const BottomContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const WalletButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CompleteContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  min-height: 100px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 31px;
  margin-top: 14px;
  margin-bottom: 58px;
`;

const CenterText = styled.div`
  ${Typography.Heading4}
  text-align: center;
  margin: auto 0px;
`;

const CreateAccountConnectAnother = (props) => {
  const [userName, setUserName] = useState(props.userName);
  const [gotoCreateProfile, setGotoCreateProfile] = useState(false);
  const [walletList, setWalletList] = useState([
    {
      walletAddress: props.walletAddress,
      walletId: props.walletId,
    },
  ]);
  const [newWalletAddress, setNewWalletAddress] = useState("");
  const [newWalletId, setNewWalletId] = useState(-1);
  const [newConnect, setNewConnect] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    (async () => {
      if (newWalletId != -1) {
        if (
          walletList.findIndex(
            (val) => val.walletAddress == newWalletAddress
          ) == -1
        ) {
          const createWallets = await createWallet(
            userName,
            newWalletAddress,
            newWalletId
          )
            .then((data) => {
              console.log(data);
              var tmpWalletList = walletList;
              tmpWalletList.push({
                walletAddress: newWalletAddress,
                walletId: newWalletId,
              });
              console.log(tmpWalletList);
              setWalletList(tmpWalletList);
              setNewConnect(true);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("The wallet already exists! Please try another wallet.");
        }
      }
    })();
  }, [newWalletId]);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  const notNowButtonOnClick = () => {
    props.setBackColor(palette.lavender_alpha25);
    setGotoCreateProfile(true);
  };

  return (
    <React.Fragment>
      {newConnect ? (
        <CreateAccountConnected
          setBackColor={props.setBackColor}
          walletList={walletList}
          userName={userName}
        />
      ) : (
        <React.Fragment>
          {gotoCreateProfile ? (
            <CreateProfile walletList={walletList} userName={userName} />
          ) : (
            <ModalContainer>
              <ControlHeader>
                {/* <BackButton onClick={backButtonOnClick}>
          <BackArrowImage src={BackArrow} />
          Go Back to Previous
        </BackButton> */}
              </ControlHeader>
              <div className={"f-column a-center"}>
                <ContentContainer>
                  <LogoContainer>
                    <StateComment>Success!</StateComment>
                    <MainComment>
                      The verified wallet address is as follows.
                    </MainComment>
                  </LogoContainer>
                  <CompleteContainer>
                    <UserNameInputBox>
                      <UserNameInput>{userName}</UserNameInput>
                      <ModifiedCheck src={Valid} />
                    </UserNameInputBox>
                    <CenterText>with</CenterText>
                    <ConnectedWallet
                      img={WalletListData[props.walletId].walletIcon}
                      name={WalletListData[props.walletId].walletName}
                      walletAddress={props.walletAddress}
                    />
                  </CompleteContainer>

                  <MainComment>Connect Another Wallet Address</MainComment>
                  <WalletButtonContainer>
                    <WalletButtonGroup
                      setPageStack={setNewWalletAddress}
                      setWalletId={setNewWalletId}
                    />
                  </WalletButtonContainer>
                  <BottomContainer>
                    <NotNowButton onClick={notNowButtonOnClick}>
                      Not Now
                    </NotNowButton>
                  </BottomContainer>
                </ContentContainer>
              </div>
            </ModalContainer>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CreateAccountConnectAnother;
