import axios from "axios";

export const getRank = async (order, userID) => {
  // order =
  // 0 : "createdAt" -> 회원가입순 ( 삭제 )
  // 1 : "todayHits" -> 오늘 hits
  // 2 : "todayFollows" -> 오늘 follows
  const orderType = ["createdAt", "todayHits", "todayFollows"];
  let returnValue = 1;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/userList?orderBy=${orderType[order]}`,
      `{"userID":"${userID}","jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result;
      console.log(data.data.result);
      if (!data.data.isSuccess) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      }
    })
    .catch((error) => {
      console.log(error);
      alert("Your session has ended. Please log in again.");
    //   localStorage.clear();
    //   window.location.href = "/";
    });

  // 1: 이미 존재하는 id
  // 0: 존재하지 않는 id

  return returnValue;
};
