import styled from "styled-components";
import {COLORS as c} from "../../../utils/styles/colors";
import ModalWrapper from "./ModalWrapper";
import {useEffect} from "react";
import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import {ReactComponent as X} from "../../../assets/icons/icon-modal_x.svg";
import {ReactComponent as Check} from "../../../assets/icons/check_icon.svg";
import SizeBox from "../../util/SizeBox";

const BackBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 500px;
  height: max-content;
  padding: 24px 30px 60px 30px;
  box-sizing: border-box;
  background-color: ${c.white};
  border-radius: 16px;
`;

const XBtn = styled(X)`
  cursor: pointer;

  width: 42px;
  height: 42px;
`;

const CheckIcon = styled(Check)`
  cursor: pointer;

  width: 42px;
  height: 42px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
`;

// const stateTitle = [
//     'Connecting Error'
// ];
// const stateDesc = [
//     'Connecting wallet failed.\nPlease try again.'
// ];

function WalletModal({setModal, loading, setLoading, ...props}) {
    return (
        <ModalWrapper>
            <BackBoard>
                <div className="f-row j-end">
                    <XBtn onClick={() => setModal(false)}/>
                </div>
                {
                    loading ?
                        <>
                            <Loading/>

                            <SizeBox h={20}/>
                            <Title>
                                Connecting Wallet
                            </Title>
                        </> :
                        <>
                            <CheckIcon/>
                            <SizeBox h={20}/>
                            <Title>
                                Connection Success! ...
                            </Title>
                        </>
                }
            </BackBoard>
        </ModalWrapper>
    );
}

function Loading(params) {
    return (
        <div className="f-column a-center j-center">
            <CircularProgress
                sx={{
                    color: c.blue,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                    },
                }}
                thickness={6}
                size={62}
            />
        </div>
    );
}

export default WalletModal;
