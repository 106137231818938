import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-wrong.svg";
import Valid from "../../../../assets/icons/dashboard/icon-verified.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import { checkSignup } from "../../../../utils/api/auth.js";
import XIcon from "../../../../assets/icons/dashboard/icon-x-gray.svg";
import { setuid } from "process";
import Typography from "../../../../utils/styles/typography";

const LogoContainer = styled.div`
  // margin-top: 280px;
  margin-top: 70px;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
`;

const ContentContainer = styled.div`
  align-items: left;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading3}
`;

const MainComment = styled.div`
  height: 32px;
  ${Typography.Heading1};
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 8px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  border: 1px solid ${palette.light_gray};
  // box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.white};
  margin: 0px auto;
  margin-top: 32px;
`;

const UserNameInput = styled.input`
  width: 90%;
  max-width: 327px;
  height: 44px;
  padding-left: 8px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${palette.light_gray};
  }
`;

const UserNameFinal = styled.div`
  width: 90%;
  max-width: 327px;
  height: auto;
  // padding-left: 8px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 16px;
  max-width: 16px;
  height: 16px;
  border: 0;
  margin: auto 0px;
  margin-left: 8px;
`;

const ErrorComment = styled.div`
  height: 15px;
  border: 0;
  background-color: transparent;
  ${Typography.Body}
  color: ${palette.light_red};
  margin-top: 13px;
`;

const NextButton = styled.button`
  width: 100px;
  height: 36px;
  background-color: ${palette.marine}33;
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
  margin-top: 50px;
`;

const TextDeleteButton = styled.button`
  background-image: url(${XIcon});
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: hidden;
  margin: auto 0px;
`;

const FindUserIdButton = styled.button`
  border: 0;
  background-color: transparent;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${palette.gray};
  text-decoration: underline;
`;

const FindBox = styled.div`
  display: flex;
`;

const LeadComment = styled.div`
  ${Typography.Body}
  color: ${palette.gray};
  margin-right: 13px;
`;

const ComponentDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 62px;
  color: ${palette.gray};
`;

const CreateAccountFirst = (props) => {
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);

  useEffect(() => {
    var regExp = /^[a-zA-Z0-9_]+[a-zA-Z0-9_]{4,18}$/g;
    var onlyEngExp = /[a-zA-Z0-9_]$/g;
    var testResult = regExp.test(userName);
    var onlyEngTestResult = onlyEngExp.test(userName);

    if (!onlyEngTestResult && userName) {
      setErrorComment("Your User ID can only contain letters, numbers and '_'");
    } else if ((userName.length <= 4 || userName.length >= 20) && userName) {
      setErrorComment(
        "Your User ID must be longer than 4, less than 20 characters."
      );
    } else {
      setErrorComment("");
    }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  useEffect(() => {}, [connectWallet]);

  const nextButtonOnClick = async () => {
    if (errorComment || !userName) {
    } else {
      if (errorComment) {
      } else {
        const checkUserId = await checkSignup(userName).then((data) => {
          console.log(data);
          if (data == 1) {
            // 이미 존재하는 아이디
            setErrorComment("A user with this User ID alreay exsists.");
          } else {
            console.log("true");
            setConnectWallet(true);
          }
        });
      }
      // setConnectWallet(true); // 일단 api가 막혀있어서
    }
  };

  const continueOnClick = async () => {
    props.setUserName(userName);
    props.setPageStatus(1);
  };

  const deleteOnClick = () => {
    setUserName("");
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <HeaderTitle>Sign Up to MEPE</HeaderTitle>
        {connectWallet ? (
          <>
            <LogoContainer>
              <MainComment>Verified!</MainComment>
            </LogoContainer>
            <ComponentDescription>
              User ID is permanent and cannot be modified. So don't forget and
              remember!
            </ComponentDescription>
            <UserNameInputBox style={{ borderColor: `${palette.blue}` }}>
              <ModifiedCheck src={Valid} />
              <UserNameFinal>{userName}</UserNameFinal>
            </UserNameInputBox>
            <ErrorComment style={{ color: palette.blue }}>
              It's a Correct User ID
            </ErrorComment>
            {/* <FindBox>
          <LeadComment>Forgot Your User ID?</LeadComment>
          <FindUserIdButton>Find User ID</FindUserIdButton>
        </FindBox> */}
            <BottomContainer>
              <NextButton
                style={
                  errorComment && !userName
                    ? {}
                    : { backgroundColor: `${palette.marine}` }
                }
                onClick={continueOnClick}
              >
                Continue
              </NextButton>
            </BottomContainer>
          </>
        ) : (
          <>
            <LogoContainer>
              <MainComment>Create Account First</MainComment>
            </LogoContainer>
            <ComponentDescription>
              Your User ID must be 4 to 20 characters including English,
              numbers, and '-'. User ID is permanet and cannot be modified.{" "}
            </ComponentDescription>
            <UserNameInputBox
              style={
                errorComment
                  ? { borderColor: `${palette.light_red}` }
                  : userName
                  ? { borderColor: palette.blue }
                  : { backgroundColor: palette.background }
              }
            >
              {errorComment && userName ? (
                <ModifiedCheck src={errorComment ? NotValid : Valid} />
              ) : (
                <></>
              )}
              <UserNameInput
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                placeholderTextColor={palette.light_red}
                placeholder="Create Your User ID"
              />
              {userName ? <TextDeleteButton onClick={deleteOnClick} /> : <></>}
            </UserNameInputBox>
            {errorComment ? <ErrorComment>{errorComment}</ErrorComment> : <></>}
            {/* <FindBox>
          <LeadComment>Forgot Your User ID?</LeadComment>
          <FindUserIdButton>Find User ID</FindUserIdButton>
        </FindBox> */}
            <BottomContainer>
              <NextButton
                style={
                  errorComment || !userName
                    ? {}
                    : { backgroundColor: `${palette.marine}` }
                }
                onClick={nextButtonOnClick}
              >
                Confirm
              </NextButton>
            </BottomContainer>
          </>
        )}
      </ContentContainer>
    </ModalContainer>
  );
};

export default CreateAccountFirst;
