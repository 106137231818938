import axios from "axios";
import WalletList from "../../datas/WalletListData";

export const checkSignup = async (userID) => {
  let returnValue = 1;
  const result = await axios
    .get(process.env.REACT_APP_DB_HOST + `/users/check?userID=${userID}`, {
      headers: {
        "Content-Type": "appliction/json",
      },
    })
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result;
    })
    .catch((error) => {
      console.log(error);
    });

  // 1: 이미 존재하는 id
  // 0: 존재하지 않는 id

  return returnValue;
};

export const createUser = async (userID, walletAddress, walletType) => {
  console.log(userID);
  console.log(walletAddress);
  console.log(WalletList[walletType].walletName);
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/users/create?userID=${userID}`,
      `{"userID":"${userID}", "walletAddress":"${walletAddress}", "walletType":"${WalletList[walletType].walletName}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
      console.log(data.data.result.jwtToken);
      localStorage.setItem("token", data.data.result.jwtToken);
    })
    .catch((error) => {
      console.log(error);
    });

  return returnValue;
};

export const login = async (userID) => {
  let returnValue = 0;
  console.log(process.env.REACT_APP_MEPE_API_KEY);
  console.log(process.env.REACT_APP_DB_HOST);
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/users/login?userID=${userID}`,
      `{"frontKey":"${process.env.REACT_APP_MEPE_API_KEY}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; // 연결된 지갑들!
      localStorage.setItem("userID", userID);
      localStorage.setItem("token", returnValue.userToken);
      localStorage.setItem(
        "walletList",
        JSON.stringify(returnValue.userWallets)
      );
    })
    .catch((error) => {
      console.log(error);
    });

  return returnValue;
};

export const editProfile = async (userID, formData, type) => {
  var requestOptions = {
    method: "PATCH",
    headers: {
      "Access-Control-Allow-Private-Network": true,
      "Access-Control-Request-Private-Network": true,
    },
    body: formData,
    redirect: "follow",
  };

  let returnValue = {};

  const result = await fetch(
    process.env.REACT_APP_DB_HOST + `/users/profile?userID=${userID}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      returnValue = JSON.parse(result);
      console.log(returnValue);
      if (returnValue.message != "요청에 성공하였습니다.") {
        if (returnValue.message == "토큰 값이 맞지 않습니다.") {
          alert("Your session has ended. Please log in again.");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert(
            "An error was found. Please try again. (The maximum image capacity is 5 MB.) " +
              returnValue.message
          );
        }
      } else {
        console.log("edit Profile End");
        if (type != "create") {
          window.location.href = `/mypage`;
        }
      }
    })
    .catch((error) => console.log("error", error));

  return returnValue;
};

//마이페이지 정보 불러오기
export const getMypage = async (userId) => {
  let returnValue = {};

  const result = await axios
    .get(process.env.REACT_APP_DB_HOST + `/mypage?userID=${userId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result;
      console.log(localStorage.getItem("userID"));
      if (localStorage.getItem("userID") == returnValue.user.id) {
        localStorage.setItem("profileImage", returnValue.user.profileImg);
      }
      // returnValue.user.id 와 현재 내 아이디가 동일하다면 mypage로 redirection
    })
    .catch((error) => {
      console.log(error);
    });

  return returnValue;
};

export const getCollection = async (userId) => {
  let returnValue = {};

  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST + `/mypage/collections?userID=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result;
      // returnValue.user.id 와 현재 내 아이디가 동일하다면 mypage로 redirection
    })
    .catch((error) => {
      console.log(error);
    });

  return returnValue;
};

export const getProfileHistory = async (userID) => {
  let returnValue = {};

  const result = await axios
    .get(process.env.REACT_APP_DB_HOST + `/profileHistory?userID=${userID}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result;
    })
    .catch((error) => {
      console.log(error);
    });

  return returnValue;
};

export const deleteProfileHistory = async (userID, profileIdx) => {
  let returnValue = {};

  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/profileHistory/delete?userID=${userID}`,
      `{"profileIndex":${profileIdx},"jwtToken":"${localStorage.getItem(
        "token"
      )}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
      console.log(data.data.result);
      if (!data.data.isSuccess) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      }
    })
    .catch((error) => {
      console.log(error);
      alert("Your session has ended. Please log in again.");
      localStorage.clear();
      window.location.href = "/";
    });

  return returnValue;
};

export const hideProfileHistory = async (userID, profileIdx, isHide) => {
  let returnValue = {};

  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/profileHistory?userID=${userID}&profileIndex=${profileIdx}&hide=${isHide}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
      console.log(data.data.result);
      if (!data.data.isSuccess) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      }
    })
    .catch((error) => {
      console.log(error);
      alert("Your session has ended. Please log in again.");
      localStorage.clear();
      window.location.href = "/";
    });

  return returnValue;
};

export const getWalletList = async (userID) => {
  let returnValue = {};

  const result = await axios
    .get(process.env.REACT_APP_DB_HOST + `/users/wallet/all?userID=${userID}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result;
      localStorage.setItem("userID", returnValue.userWallets[0].user);
      localStorage.setItem(
        "walletList",
        JSON.stringify(returnValue.userWallets)
      );
    })
    .catch((error) => {
      console.log(error);
    });

  return returnValue;
};
