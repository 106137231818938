import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import LinkIcon from "../../../../assets/icons/dashboard/icon-linkExternal.svg";
import MetamaskChainlist from "../../FinancePage/components/MetamaskChainlist";
import KaikasChainlist from "../../FinancePage/components/KaikasChainlist";

const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  min-height: 500px;
  background-color: ${palette.white};
  border-radius: 40px;
  padding: 30px;
  margin-top: 32px;
  box-shadow: 0px 2px 10px #c4c4c440;
`;

const TransactionUnit = styled.div`
  width: 100%;
  height: 120px;
  margin: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

const AmountBox = styled.div`
  ${Typography.Heading2}
  width: 240px;
`;

const InfoBox = styled.div`
  display: flex;
`;

const InfoTextBox = styled.div``;

const UserIdBox = styled.div`
  ${Typography.Heading2}
  color: ${palette.black};
  margin-bottom: 3px;
`;

const MemoBox = styled.div`
  ${Typography.Heading3}
  color: ${palette.gray};
`;

const SideBox = styled.div`
  display: flex;
`;

const DateBox = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: right;
  color: ${palette.gray};
`;

const LinkButton = styled.button`
  background-image: url(${LinkIcon});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: hidden;
  margin-left: 25px;
`;

const ProfileImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-top: 5px;
  margin-right: 16px;
`;

function pad(n) {
  return n < 10 ? "0" + n : n;
}

const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const dateFormat = (dateStr) => {
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const newString =
    pad(new Date(dateStr).getUTCHours()) +
    ":" +
    pad(new Date(dateStr).getUTCMinutes()) +
    ", " +
    monthNamesShort[new Date(dateStr).getUTCMonth() + 1] +
    " " +
    pad(new Date(dateStr).getUTCDate()) +
    nth(new Date(dateStr).getUTCDate()) +
    ", " +
    pad(new Date(dateStr).getFullYear().toString());

  return newString;
};

const TransactionBox = ({ txList }) => {
  const [txLi, settxLi] = useState([]);
  useEffect(() => {
    settxLi(txList);
  }, [txList]);
  const [chainList, setChainList] = useState({});

  useEffect(() => {
    const tmpChainList = {
      Metamask: MetamaskChainlist,
      Kaikas: KaikasChainlist,
    };
    setChainList(tmpChainList);
  }, []);

  const linkButtonOnClick = (txHash, chainID, walletType) => {
    // console.log(walletType);
    // console.log(chainList[walletType]);
    const url =
      chainList[walletType]?.[
        chainList[walletType]?.findIndex(
          (val) => "0x" + val.pageProps.chain.chainId == chainID
        )
      ]?.pageProps.chain.explorers[0].url;
    console.log(url);
    if (url) {
      window.location.href = url + "/tx/" + txHash;
    } else {
      alert("Not supported. Coming soon.");
    }
  };
  return (
    <Container>
      {txList ? (
        <>
          {txList.map((val, idx) => (
            <>
              <TransactionUnit>
                <SideBox>
                  {val.toUser == localStorage.getItem("userID") ? (
                    <AmountBox style={{ color: palette.blue }}>
                      + {val.value} {val.udenom}
                    </AmountBox>
                  ) : (
                    <AmountBox style={{ color: palette.light_red }}>
                      - {val.value} {val.udenom}
                    </AmountBox>
                  )}
                  <InfoBox>
                    {val.toUser == localStorage.getItem("userID") ? (
                      <ProfileImage src={val.fromUserProfileImg} />
                    ) : (
                      <ProfileImage src={val.toUserProfileImg} />
                    )}
                    <InfoTextBox>
                      {val.toUser == localStorage.getItem("userID") ? (
                        <UserIdBox>@{val.fromUser}</UserIdBox>
                      ) : (
                        <UserIdBox>@{val.toUser}</UserIdBox>
                      )}
                      <MemoBox>{val.memo}</MemoBox>
                    </InfoTextBox>
                  </InfoBox>
                </SideBox>
                <SideBox>
                  <DateBox>{dateFormat(val.createdAt)}</DateBox>
                  <LinkButton
                    onClick={() =>
                      linkButtonOnClick(val.txHash, val.chainID, val.walletType)
                    }
                  />
                </SideBox>
              </TransactionUnit>
            </>
          ))}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default TransactionBox;
