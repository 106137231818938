import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import TrashIcon from "../../../../assets/icons/trash_icon.svg";
import TrashIconWhite from "../../../../assets/icons/dashboard/icon-trashWhite.svg";
import { deleteAllNoti } from "../../../../utils/api/notifications";

const Header = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  margin-top: 70px;
`;

const HeaderContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
`;

const TitleBox = styled.div`
  width: 90%;
  max-width: 1080px;
  margin-top: 98px;
  ${Typography.Heading1}
`;

const DeleteAllButton = styled.button`
  display: flex;
  justify-content: center;
  width: 180px;
  height: 36px;
  border: hidden;
  border-radius: 46px;
  background-color: ${palette.light_red};
  color: ${palette.white};
  box-shadow: 0px 2px 10px #c4c4c440;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  align-items: center;
  margin-top: 98px;
`;

const TrashImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
`;

const NotiHeader = () => {
  const deleteAllOnClick = async () => {
    const deleteAllNotiResult = await deleteAllNoti(
      localStorage.getItem("userID")
    ).then((data) => {
      window.location.reload();
    });
  };
  return (
    <Header>
      <HeaderContainer>
        <TitleBox>Notifications</TitleBox>
        <DeleteAllButton onClick={deleteAllOnClick}>
          Delete All
          <TrashImg src={TrashIconWhite} />
        </DeleteAllButton>
      </HeaderContainer>
    </Header>
  );
};

export default NotiHeader;
