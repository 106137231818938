import styled from 'styled-components';
import {COLORS as c} from "../../utils/styles/colors";

const Container = styled.button`
  position: relative;
  user-select: none;
  cursor: ${p => p.valid ? 'pointer' : 'auto'};
  
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  border: 2px ${props => props.bColor} solid;
  border-radius: 20px;
  background-color: ${props => props.back};
  box-shadow: ${props => props.shadow ? '0px 2px 10px rgba(85, 82, 255, 0.25)' : null};

  padding: ${props => props.isSmall ? '6px' : '9px'} ${props => props.isSmall ? '18px' : '24px'};

  color: ${props => props.fColor};
  font-family: Montserrat;
  font-size: ${props => props.isSmall ? '12px' : '14px'};
  font-weight: 700;

  transition: 0.2s;

  ${p => p.valid ? `&:hover {
    transform: translateY(-10%);
    box-shadow: 1px 2px 1px ${c.light_gray};
  }` : null}
`;

const setting = { // border, font, back
    1: {
        true: [c.marine, c.white, c.marine],
        false: [c.light_gray, c.white, c.light_gray]
    },
    2: {
        true: [c.marine, c.marine, c.white],
        false: [c.light_gray, c.light_gray, c.white],
    },
}

// mode 1: default 2: border 3: small basic 4: small border
function BasicButton({mode = 1, isValid = false, shadow = false, onClick, ...props}) {
    const color = (mode === 1 || mode === 3 ? setting[1] : setting[2]);
    const isSmall = (mode === 3 || mode === 4);

    return (
        <Container
            bColor={color[isValid][0]}
            isSmall={isSmall}
            fColor={color[isValid][1]}
            back={color[isValid][2]}
            onClick={isValid ? onClick : () => {}}
            shadow={shadow} valid={isValid}>
            {props.children}
        </Container>
    );
}

export default BasicButton;