import { Bech32Address } from "@keplr-wallet/cosmos";
import { cosmosIcon } from "../assets/tokens/cosmos/cosmos.svg";

const ChainInfo = [
  {
    rpc: "https://rpc.sentry-01.theta-testnet.polypore.xyz/",
    rest: "https://rest.sentry-01.theta-testnet.polypore.xyz/",
    chainId: "cosmoshub-4",
    chainName: "Solana",
    stakeCurrency: {
      coinDenom: "SOL",
      coinMinimalDenom: "usol",
      coinDecimals: 6,
      coinGeckoId: "cosmos",
      coinImageUrl: "../assets/tokens/solana/solana.svg",
    },
    bip44: {
      coinType: 118,
    },
    bech32Config: Bech32Address.defaultBech32Config("cosmos"),
    currencies: [
      {
        coinDenom: "SOL",
        coinMinimalDenom: "usol",
        coinDecimals: 6,
        coinGeckoId: "cosmos",
        coinImageUrl: "../assets/tokens/solana.svg",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "SOL",
        coinMinimalDenom: "usol",
        coinDecimals: 6,
        coinGeckoId: "cosmos",
        coinImageUrl: "../assets/tokens/solana/solana.svg",
      },
    ],
    coinType: 118,
    features: ["stargate", "ibc-transfer", "no-legacy-stdTx", "ibc-go"],
    explorerUrlToTx: "https://www.mintscan.io/cosmos/txs/{txHash}",
  },
  {
    rpc: "https://rpc.stargaze-apis.com",
    rest: "https://rest.stargaze-apis.com",
    chainId: "stargaze-1",
    chainName: "Stargaze",
    stakeCurrency: {
      coinDenom: "STARS",
      coinMinimalDenom: "ustars",
      coinDecimals: 6,
      coinGeckoId: "pool:ustars",
      coinImageUrl: window.location.origin + "/public/assets/tokens/stars.png",
    },
    bip44: {
      coinType: 118,
    },
    bech32Config: Bech32Address.defaultBech32Config("stars"),
    currencies: [
      {
        coinDenom: "STARS",
        coinMinimalDenom: "ustars",
        coinDecimals: 6,
        coinGeckoId: "pool:ustars",
        coinImageUrl:
          window.location.origin + "/public/assets/tokens/stars.png",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "STARS",
        coinMinimalDenom: "ustars",
        coinDecimals: 6,
        coinGeckoId: "pool:ustars",
        coinImageUrl:
          window.location.origin + "/public/assets/tokens/stars.png",
      },
    ],
    features: ["stargate", "ibc-transfer", "no-legacy-stdTx"],
    explorerUrlToTx: "https://www.mintscan.io/stargaze/txs/{txHash}",
  },
  {
    // Chain-id of the Osmosis chain.
    chainId: "usd-1",
    // The name of the chain to be displayed to the user.
    chainName: "USD Coin",
    // RPC endpoint of the chain. In this case we are using blockapsis, as it's accepts connections from any host currently. No Cors limitations.
    rpc: "https://rpc-osmosis.blockapsis.com",
    // REST endpoint of the chain.
    rest: "https://lcd-osmosis.blockapsis.com",
    // Staking coin information
    stakeCurrency: {
      // Coin denomination to be displayed to the user.
      coinDenom: "USDC",
      // Actual denom (i.e. uatom, uscrt) used by the blockchain.
      coinMinimalDenom: "uusdc",
      // # of decimal points to convert minimal denomination to user-facing denomination.
      coinDecimals: 6,
      // (Optional) Keplr can show the fiat value of the coin if a coingecko id is provided.
      // You can get id from https://api.coingecko.com/api/v3/coins/list if it is listed.
      // coinGeckoId: ""
    },
    // (Optional) If you have a wallet webpage used to stake the coin then provide the url to the website in `walletUrlForStaking`.
    // The 'stake' button in Keplr extension will link to the webpage.
    // walletUrlForStaking: "",
    // The BIP44 path.
    bip44: {
      // You can only set the coin type of BIP44.
      // 'Purpose' is fixed to 44.
      coinType: 118,
    },
    // Bech32 configuration to show the address to user.
    // This field is the interface of
    // {
    //   bech32PrefixAccAddr: string;
    //   bech32PrefixAccPub: string;
    //   bech32PrefixValAddr: string;
    //   bech32PrefixValPub: string;
    //   bech32PrefixConsAddr: string;
    //   bech32PrefixConsPub: string;
    // }
    bech32Config: {
      bech32PrefixAccAddr: "osmo",
      bech32PrefixAccPub: "osmopub",
      bech32PrefixValAddr: "osmovaloper",
      bech32PrefixValPub: "osmovaloperpub",
      bech32PrefixConsAddr: "osmovalcons",
      bech32PrefixConsPub: "osmovalconspub",
    },
    // List of all coin/tokens used in this chain.
    currencies: [
      {
        // Coin denomination to be displayed to the user.
        coinDenom: "USDC",
        // Actual denom (i.e. uatom, uscrt) used by the blockchain.
        coinMinimalDenom: "uusdc",
        // # of decimal points to convert minimal denomination to user-facing denomination.
        coinDecimals: 6,
        coinImageUrl: "../assets/tokens/solana/usdCoin.svg",
        // (Optional) Keplr can show the fiat value of the coin if a coingecko id is provided.
        // You can get id from https://api.coingecko.com/api/v3/coins/list if it is listed.
        // coinGeckoId: ""
      },
    ],
    // List of coin/tokens used as a fee token in this chain.
    feeCurrencies: [
      {
        // Coin denomination to be displayed to the user.
        coinDenom: "USDC",
        // Actual denom (i.e. uosmo, uscrt) used by the blockchain.
        coinMinimalDenom: "uusdc",
        // # of decimal points to convert minimal denomination to user-facing denomination.
        coinDecimals: 6,
        // (Optional) Keplr can show the fiat value of the coin if a coingecko id is provided.
        // You can get id from https://api.coingecko.com/api/v3/coins/list if it is listed.
        // coinGeckoId: ""
      },
    ],
    // (Optional) The number of the coin type.
    // This field is only used to fetch the address from ENS.
    // Ideally, it is recommended to be the same with BIP44 path's coin type.
    // However, some early chains may choose to use the Cosmos Hub BIP44 path of '118'.
    // So, this is separated to support such chains.
    coinType: 118,
    // (Optional) This is used to set the fee of the transaction.
    // If this field is not provided, Keplr extension will set the default gas price as (low: 0.01, average: 0.025, high: 0.04).
    // Currently, Keplr doesn't support dynamic calculation of the gas prices based on on-chain data.
    // Make sure that the gas prices are higher than the minimum gas prices accepted by chain validators and RPC/REST endpoint.
    gasPriceStep: {
      low: 0.01,
      average: 0.025,
      high: 0.04,
    },
  },
  {
    rpc: "https://rpc-secret.keplr.app",
    rest: "https://lcd-secret.keplr.app",
    chainId: "raydium-4",
    chainName: "Raydium",
    stakeCurrency: {
      coinDenom: "RAY",
      coinMinimalDenom: "uray",
      coinDecimals: 6,
      coinGeckoId: "raydium",
      coinImageUrl: "../assets/tokens/solana/raydium.svg",
    },
    bip44: {
      coinType: 529,
    },
    bech32Config: Bech32Address.defaultBech32Config("secret"),
    currencies: [
      {
        coinDenom: "RAY",
        coinMinimalDenom: "uray",
        coinDecimals: 6,
        coinGeckoId: "raydium",
        coinImageUrl: "../assets/tokens/solana/raydium.svg",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "RAY",
        coinMinimalDenom: "uray",
        coinDecimals: 6,
        coinGeckoId: "raydium",
        coinImageUrl: "../assets/tokens/solana/raydium.svg",
      },
    ],
    coinType: 118,
    features: ["stargate", "ibc-transfer", "no-legacy-stdTx"],
    explorerUrlToTx:
      "https://secretnodes.com/secret/chains/secret-4/transactions/{txHash}",
  },
  {
    // rpc: "https://rpc-akash.keplr.app",
    rest: "https://lcd-akash.keplr.app",
    rpc: "https://rpc.akash.forbole.com:443",
    // rest: "https://lcd-akash.blockapsis.app",
    chainId: "STEPN-2",
    chainName: "STEPN",
    stakeCurrency: {
      coinDenom: "GMT",
      coinMinimalDenom: "ugmt",
      coinDecimals: 6,
      coinGeckoId: "STEPN",
      coinImageUrl: "../assets/tokens/solana/stepn.svg",
    },
    bip44: {
      coinType: 118,
    },
    bech32Config: Bech32Address.defaultBech32Config("akash"),
    currencies: [
      {
        coinDenom: "GMT",
        coinMinimalDenom: "ugmt",
        coinDecimals: 6,
        coinGeckoId: "STEPN",
        coinImageUrl: "../assets/tokens/solana/stepn.svg",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "GMT",
        coinMinimalDenom: "ugmt",
        coinDecimals: 6,
        coinGeckoId: "STEPN",
        coinImageUrl: "../assets/tokens/solana/stepn.svg",
      },
    ],
    coinType: 118,
    features: ["stargate", "ibc-transfer"],
    explorerUrlToTx: "https://www.mintscan.io/akash/txs/{txHash}",
  },
  {
    // rpc: "https://rpc-regen.keplr.app",
    rest: "https://lcd-regen.keplr.app",
    // rpc: "http://redwood.regen.network:26657",
    rpc: "https://regen-rpc.theamsolutions.info:443",
    // rest: "https://lcd-regen.blockapsis.app",
    chainId: "Arweave-1",
    chainName: "Arweave",
    stakeCurrency: {
      coinDenom: "AR",
      coinMinimalDenom: "uar",
      coinDecimals: 6,
      coinImageUrl: "../assets/tokens/solana/arweave.svg",
      coinGeckoId: "Arweave",
    },
    bip44: { coinType: 118 },
    bech32Config: Bech32Address.defaultBech32Config("regen"),
    currencies: [
      {
        coinDenom: "AR",
        coinMinimalDenom: "uar",
        coinDecimals: 6,
        coinImageUrl: "../assets/tokens/solana/arweave.svg",
        coinGeckoId: "Arweave",
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "AR",
        coinMinimalDenom: "uar",
        coinDecimals: 6,
        coinImageUrl: "../assets/tokens/solana/arweave.svg",
        coinGeckoId: "Arweave",
      },
    ],
    features: ["stargate", "ibc-transfer", "no-legacy-stdTx"],
    explorerUrlToTx: "https://regen.aneka.io/txs/{txHash}",
  },
  // {
  //   rpc: "https://rpc-sentinel.keplr.app",
  //   rest: "https://lcd-sentinel.keplr.app",
  //   chainId: "sentinelhub-2",
  //   chainName: "Sentinel",
  //   stakeCurrency: {
  //     coinDenom: "DVPN",
  //     coinMinimalDenom: "udvpn",
  //     coinDecimals: 6,
  //     coinGeckoId: "sentinel",
  //     coinImageUrl: "../assets/tokens/cosmos/dvpn.png",
  //   },
  //   bip44: { coinType: 118 },
  //   bech32Config: Bech32Address.defaultBech32Config("sent"),
  //   currencies: [
  //     {
  //       coinDenom: "DVPN",
  //       coinMinimalDenom: "udvpn",
  //       coinDecimals: 6,
  //       coinGeckoId: "sentinel",
  //       coinImageUrl: "../assets/tokens/cosmos/dvpn.png",
  //     },
  //   ],
  //   feeCurrencies: [
  //     {
  //       coinDenom: "DVPN",
  //       coinMinimalDenom: "udvpn",
  //       coinDecimals: 6,
  //       coinGeckoId: "sentinel",
  //       coinImageUrl: "../assets/tokens/cosmos/dvpn.png",
  //     },
  //   ],
  //   explorerUrlToTx: "https://www.mintscan.io/sentinel/txs/{txHash}",
  //   features: ["stargate", "ibc-transfer"],
  // },
];

export default ChainInfo;
