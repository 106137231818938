import styled from "styled-components";
import SizeBox from "../../../components/util/SizeBox";
import BasicButton from "../../../components/basic/BasicButton";
import ImageLoader from "../../../components/util/ImageLoader";
import Badge from '../../../assets/images/d-f.png';
import {Link, useLocation} from "react-router-dom";
import Header from "../../../components/global/Header/index";
import { useState } from "react";


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

const BackBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  width: 100%;
  max-width: 1080px;

  // margin-top: 30px;
  margin-top: 102px;
  background-color: white;
  border-radius: 40px;
  padding: 20px 30px;
  box-sizing: border-box;
`;

const Success = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #5383FF;
`;

const Desc = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #222222;
`;

const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;

  color: #000000;
`;

const ID = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  text-align: center;

  color: #777777;
`;

const EthLink = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  text-decoration-line: underline;

  color: #777777;
`;

function CreateSuccess() {
    const [profileImg, setProfileImg] = useState(localStorage.getItem("profileImage"));
    const { state } = useLocation();
    const id = state.id;
    const img = state.img;
    const title = state.name;

    return (
        <Container>
          <Header profileImg={profileImg}/>
            <BackBoard>
                <SizeBox h={120}/>
                <Success>
                    Success
                </Success>
                <Desc>
                    You made this badge.
                </Desc>

                <SizeBox h={30}/>
                <ImageLoader src={img} round={20} w={180}/>

                <SizeBox h={30}/>
                <Title>
                    {title}
                </Title>

                <ID>
                    by @goyangtwo
                </ID>

                <SizeBox h={40}/>
                <EthLink>
                    view on etherscan
                </EthLink>

                <SizeBox h={60}/>
                <SizeBox w={210} h={40}>
                    <BasicButton mode={1} isValid={true}>
                        Go to Discover Page
                    </BasicButton>
                </SizeBox>

                <SizeBox h={20}/>
                <Link to={`/badge/detail/${id}`} state={{datas: state}}>
                    <BasicButton mode={10} isValid={true}>
                        Go to Badge Detail
                    </BasicButton>
                </Link>

                <SizeBox h={100}/>
            </BackBoard>
        </Container>
    );
}

export default CreateSuccess;