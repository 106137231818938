const CoinbaseOnClick = (setFunction) => {
  return new Promise(async (resolve, reject) => {
    const coinbase = window.coinbaseSolana
    try {
      if (!coinbase) {
        alert("Please install keplr extension");
      } else {
        const response = await coinbase.connect();
        console.log("wallet account ", coinbase.publicKey.toString());
        setFunction(coinbase.publicKey.toString());
      }
      // }
    } catch (error) {
      alert(error.message);
      console.error(error.message);
    }
  });
};

export default CoinbaseOnClick;
