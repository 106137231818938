import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import useWindowDimensions from "../../../utils/functions/useWindowDimensions";
import IntroImage from "../../../assets/images/main_example_image.svg";
import WhiteLogo from "../../../assets/images/main_logo_image_white.svg";

const Container = styled.div`
  position: relative;
  width: 650px;
  height: 100%;
  min-width: 650px;
  background: linear-gradient(#779cfd, #b6a4ff);
  overflow: hidden;
`;

const IntroDoorImage = styled.img`
  width: 792px;
  margin-top: 20px;
  margin-bottom: 46px;
  z-index: 2;
  position: absolute;
  bottom: -120px;
  left: 100px;
`;

const TextContent = styled.div`
  position: absolute;
  padding: 248px 200px 0px 100px;
  text-align: left;
  align-items: left;
`;

const LogoBox = styled.img`
  width: 134px;
  margin-bottom: 26px;
`;

const Description = styled.div`
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const IntroSidebarContent = () => {
  const { width, height } = useWindowDimensions();

  return (
    <>
      {width > 1050 ? (
        <Container>
          <TextContent>
            <LogoBox src={WhiteLogo} />
            <Description>Create Your Web3 Multi-wallet Dashboard</Description>
          </TextContent>
          {height > 1000 ? <IntroDoorImage src={IntroImage} /> : <></>}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default IntroSidebarContent;
