import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import WalletBox from "./WalletBox";
import "./BankBox.css";
import SendBox from "./SendBox";
import RequestBox from "./RequestBox";
import { useLocation } from "react-router-dom";
import Typography from "../../../../utils/styles/typography";

const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  min-height: 500px;
  background-color: ${palette.white};
  border-radius: 40px;
  padding: 30px;
  margin-top: 32px;
  box-shadow: 0px 2px 10px #c4c4c440;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  width: min(840px, 90%);
  height: auto;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  margin-top: 50px;
  margin-bottom: 26px;
  // padding-left: 300px;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const BetaComment = styled.div`
  width: 100%;
  ${Typography.Body}
  text-align: right;
  color: ${palette.gray};
`;

const BankBox = (props) => {
  const [walletList, setWalletList] = useState(props.wallet);
  const [tabData, setTabData] = useState([
    // { category: "Joined Time", className: "one", type: "joinedTime" },
    { category: "Send", className: "bankBox", type: "Send" },
    { category: "Request", className: "bankBox", type: "Request" },
  ]);
  const [clickIndex, setClickIndex] = useState(0);
  const [urlInfo, setUrlInfo] = useState({});
  const location = useLocation();

  useEffect(() => {
    const pathParams = location.pathname.split("/").slice(2);
    console.log(pathParams);
    var tmpUrlInfo = {};
    pathParams.map((v, i) => {
      var tmpSplit = v.split("=");
      tmpUrlInfo[tmpSplit[0]] = tmpSplit[1];
    });

    if (tmpUrlInfo.type == "request-in-noti") {
      setClickIndex(1);
      tmpUrlInfo.toAddr = tmpUrlInfo.fromAddr;
    }
    console.log(tmpUrlInfo);
    setUrlInfo(tmpUrlInfo);
  }, []);

  useEffect(() => {
    console.log(props.wallet);
    setWalletList(props.wallet);
  }, [props.wallet]);

  return (
    <Container>
      <BetaComment>
        * Beta only supports <font style={{ fontWeight: "700" }}>Metamask</font>{" "}
        and <font style={{ fontWeight: "700" }}>Kaikas</font>.
      </BetaComment>
      <ContainerHeader>
        <ButtonContainer>
          <div class="button-container2">
            <ul class="bankBox">
              {tabData.map((value, idx) => (
                <li class={value.className}>
                  {clickIndex == idx ? (
                    <button
                      style={{ color: palette.white, height: "100%" }}
                      onClick={() => {
                        setClickIndex(idx);
                      }}
                      class={value.className}
                    >
                      {value.category}
                    </button>
                  ) : (
                    <button
                      style={{ height: "100%" }}
                      onClick={() => {
                        setClickIndex(idx);
                      }}
                      class={value.className}
                    >
                      {value.category}
                    </button>
                  )}
                </li>
              ))}
              <hr
                class="bankBox"
                style={{ marginLeft: `calc(45%*${clickIndex})` }}
              />
            </ul>
          </div>
        </ButtonContainer>
      </ContainerHeader>
      {tabData[clickIndex].type == "Send" &&
      urlInfo.type != "request-in-noti" ? (
        <SendBox urlInfo={urlInfo} />
      ) : (
        <RequestBox urlInfo={urlInfo} />
      )}
    </Container>
  );
};

export default BankBox;
