import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-x.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import LoginConnectWallet from "./LoginConnectWallet";
import FoundUserIdFound from "../FindUserID/FindUserIdFound";
import FindUserIdConnectWallet from "../FindUserID/FindUserIdConnectWallet";
import { login, checkSignup } from "../../../../utils/api/auth";

const LogoContainer = styled.div`
  // margin-top: 280px;
  // margin-top: 10%;
  margin-top: 10%;
`;

const ModalContainer = styled.div`
  width: 100%;
  //max-width: 1240px;
  height: 900px;
  // margin-top: 90px;
  // margin-top: 10%;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  padding-top: 47.5px;
  padding-left: 60px;
`;

const ContentContainer = styled.div`
  align-items: center;
  width: 100%;
`;

const MainComment = styled.div`
  height: 32px;
  ${Typography.Heading1}
  text-align: center;
`;

const UserNameInputBox = styled.div`
  width: 80%;
  height: 64px;
  display: flex;
  justify-content: left;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 36px;
  border: 0;
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.background};
  margin: 0px auto;
  margin-bottom: 8px;
  margin-top: 32px;
`;

const UserNameInput = styled.input`
  width: 80%;
  max-width: 327px;
  height: 44px;
  padding-left: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const BackArrowImage = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-right: 8px;
`;

const FindUserIdButton = styled.button`
  height: 15px;
  border: 0;
  background-color: transparent;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: ${palette.gray};
  // margin-bottom: 157px;
  margin-bottom: 20%;
  text-decoration: underline;
  margin-top: 15px;
`;

const BackButton = styled.button`
  height: 24px;
  display: flex;
  background-color: transparent;
  border: 0;
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.gray};
`;

const NextButton = styled.button`
  width: 97px;
  max-width: 97px;
  height: 44px;
  background-color: ${palette.black};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const LoginUserID = (props) => {
  const [userName, setUserName] = useState("");
  const [userNameValid, setUserNameValid] = useState(true);
  const [errorComment, setErrorComment] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [findUserIdClick, setFindUserIdClick] = useState(false);

  useEffect(() => {
    var regExp = /[a-zA-Z0-9_]{5,19}$/g;

    var testResult = regExp.test(userName);

    if (userName.length <= 4 || userName.length >= 20) {
      setErrorComment(
        "Your user ID must be longer than 4, less than 20 characters."
      );
    } else if (!testResult) {
      setErrorComment(
        "Your username can only contain letters, numbers and '_'"
      );
    } else {
      setErrorComment("");
    }
    // else if(){ //중복체크
    // setErrorComment("That user ID has been taken. Please choose another.")
    // }
  }, [userName]);

  useEffect(() => {}, [errorComment]);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  const nextButtonOnClick = async () => {
    // login api 호출. 해당 userid와 연결된 지갑 목록 불러오기

    const checkSignupResult = await checkSignup(userName).then(async (data) => {
      if (data == 1) {
        const loginResult = await login(userName).then((data) => {
          console.log(data);
          setWalletList(data.userWallets);
          setConnectWallet(true);
        });
      } else {
        alert("Invalid user! Please check again.");
      }
    });
  };

  const findUserIdFoundOnClick = () => {
    setFindUserIdClick(true);
  };

  return (
    <React.Fragment>
      {connectWallet ? (
        <LoginConnectWallet
          setPageStack={setConnectWallet}
          walletList={walletList}
          userName={userName}
        />
      ) : (
        <React.Fragment>
          {findUserIdClick ? (
            <FindUserIdConnectWallet setPageStack={setFindUserIdClick} />
          ) : (
            <ModalContainer>
              <ControlHeader>
                <BackButton onClick={backButtonOnClick}>
                  <BackArrowImage src={BackArrow} />
                  Go Back to Previous
                </BackButton>
              </ControlHeader>
              <div className={"f-column a-center"}>
                <ContentContainer>
                  <LogoContainer>
                    <MainComment>Login</MainComment>
                  </LogoContainer>
                  <UserNameInputBox>
                    <UserNameInput
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          nextButtonOnClick();
                        }
                      }}
                      placeholder="user id"
                    />
                  </UserNameInputBox>
                  <FindUserIdButton onClick={findUserIdFoundOnClick}>
                    forgot your user id?
                  </FindUserIdButton>
                  <BottomContainer>
                    <NextButton onClick={nextButtonOnClick}>Next</NextButton>
                  </BottomContainer>
                </ContentContainer>
              </div>
            </ModalContainer>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default LoginUserID;
