export default class BadgeClaimDto {
    constructor(id, title, author, price, symbol) {
        this.id = id;
        this.title = title;
        this.author = author;
        this.price = price;
        this.symbol = symbol;
    }

    getJson() {
        return {
            id: this.id,
            title: this.title,
            author: this.author,
            price: this.price,
            symbol: this.symbol,
        }
    }
}