function ImageLoader({w, h, src, round, border, ...props}) {
    let set = null;
    if(border !== undefined) {
        set = 'solid 1px #222222'
    }
    return (
        <img style={{width: w, height: h, borderRadius: round, border: set}} src={src}>
        </img>
    );
}

export default ImageLoader