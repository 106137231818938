import { css } from "styled-components";

const Heading1 = css`
  //styleName: Heading 1;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const Heading2 = css`
  //styleName: Heading 2;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const Heading3 = css`
  //styleName: Heading 3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const Heading4 = css`
  //styleName: Heading 4;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  //   text-align: center;
`;

const Heading5 = css`
  //styleName: Heading 5;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

const Body = css`
  //styleName: body;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

const Link = css`
  //styleName: link;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
`;

const CollectionHeading = css`
  //styleName: Collection Heading;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-align: left;
`;

const Heading = css`
  //styleName: Heading;
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
`;

const Heading1Mobile = css`
  //styleName: Heading 1 (mobile);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const Heading2Mobile = css`
  //styleName: Heading 2 (mobile);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const Heading3Mobile = css`
  //styleName: Heading 3 (mobile);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const Heading4Mobile = css`
  //styleName: Heading 4 (mobile);
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const BodyMobile = css`
  //styleName: body (mobile);
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

const CollectionHeadingMobile = css`
  //styleName: Collection Heading (mobile);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
`;

const Typography = {
  Heading1: Heading1,
  Heading2: Heading2,
  Heading3: Heading3,
  Heading4: Heading4,
  Heading5: Heading5,
  Body: Body,
  Link: Link,
  CollectionHeading: CollectionHeading,
  Heading: Heading,
  Heading1Mobile: Heading1Mobile,
  Heading2Mobile: Heading2Mobile,
  Heading3Mobile: Heading3Mobile,
  Heading4Mobile: Heading4Mobile,
  BodyMobile: BodyMobile,
  CollectionHeadingMobile: CollectionHeadingMobile,
};

export default Typography;
