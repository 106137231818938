import styled from 'styled-components';
import {COLORS as c} from "../../../utils/styles/colors";
import Spacer from "../../../components/util/Spacer";
import CustomTag from "../../../components/basic/CustomTag";
import ColoredBtn from "../../../components/basic/ColoredBtn";
import SizeBox from "../../../components/util/SizeBox";

import {useEffect, useLayoutEffect, useState} from "react";
import BasicInput from "../../../components/basic/BasicInput";
import NewBody from "./NewBody";
import NewItemDto from "../../../datas/dtos/NewItemDto";
import {Link, useLocation} from "react-router-dom";

import Node from '../../../assets/images/nodes-01.png';
import Poly from '../../../assets/images/polygon dummy.png';

import Dum1 from '../../../assets/images/dum/dum_1.png';
import Dum2 from '../../../assets/images/dum/dum_2.png';
import Dum3 from '../../../assets/images/dum/dum_3.png';
import Dum4 from '../../../assets/images/dum/dum_4.png';
import Dum5 from '../../../assets/images/dum/dum_5.png';
import Dum6 from '../../../assets/images/dum/dum_6.png';
import Dum7 from '../../../assets/images/dum/dum_7.png';
import MintBadge from '../../../assets/images/dum/mint_main.png';

import ActionAPI from "../../../network/ActionAPI";
// import Header from '../../../components/global/header';
import Header from "../../../components/global/Header/index";


const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${c.white};
  box-shadow: 0px 2px 10px rgba(196, 196, 196, 0.25);

  width: 100%;
  height: max-content;
  margin-top: 72px;
`;

const TopInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 95%;
  max-width: 1080px;
  height: max-content;

  background-color: ${c.white};
`;

const MainText = styled.p`
  color: ${c.light_black};
  font-weight: 800;
`;

const CategoryText = styled.pre`
  font-weight: 600;
  color: ${c.light_black};
  line-height: 30px;
`;

const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const NewContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: start;

  width: 100%;
  max-width: 1180px;
  height: max-content;
`;

let dummyData = [
    // new NewItemDto(1, 'Evmos Dao Membership', 52, 0.0, [], Dum1, false),
    new NewItemDto(1, 'Sushi Lover Membership', 12, 0.0, [], Dum2, false),
    new NewItemDto(2, 'FWB Membership', 7, 0.0, [], Dum3, true),
    new NewItemDto(3, 'Gitcoin Membership', 13, 0.0, [], Dum4, true),
    new NewItemDto(4, 'Meta Cartel DAO Membership', 15, 0.0, [], Dum5, true),
    new NewItemDto(5, 'Cats Save The World Supporters', 24, 0.0, [], Dum6, false),
    new NewItemDto(6, 'Forfront DAO Membership', 31, 0.0, [], Dum7, false),
]

function MintingMain() {
    const {state} = useLocation();
    const actionApi = new ActionAPI();
    const [searchInput, setSearchInput] = useState('');
    const [profileImg, setProfileImg] = useState(localStorage.getItem("profileImage"))

    const onChange = (e) => {
        setSearchInput(e.target.value);
    }

    useLayoutEffect(() => {
        if (state?.data) {
            if(dummyData.length < 7) {
                dummyData.push(new NewItemDto(7, '1 Day 1 Coffee Membership', 0, 0.0, [], MintBadge, true));
            }
        }
    }, []);

    return (
        <div className={'f-column a-center'}>
            <Header profileImg={profileImg}/>
            {/* <Header/> */}
            <TopContainer>
                <TopInner>
                    <SizeBox h={98}/>
                    <div className={'f-row a-center'}>
                        <MainText>
                            Discover Badges
                        </MainText>
                        <Spacer/>

                        <Link to={'/badge/create'}>
                            <ColoredBtn>
                                Create New Badges
                            </ColoredBtn>
                        </Link>
                    </div>

                    <SizeBox h={43}/>
                    <BasicInput searchInput={searchInput} onChange={onChange}/>

                    <SizeBox h={30}/>
                    <div className={'f-row'}>
                        <CategoryText>
                            · category
                        </CategoryText>

                        <SizeBox w={25}/>
                        <CategoryContainer>
                            <CustomTag color={c.light_red}>
                                new
                            </CustomTag>
                            <CustomTag>
                                MePe
                            </CustomTag>
                            <CustomTag>
                                Web3.0
                            </CustomTag>
                            <CustomTag>
                                countries
                            </CustomTag>
                            <CustomTag>
                                university
                            </CustomTag>
                            <CustomTag>
                                contributor
                            </CustomTag>
                            <CustomTag>
                                sports
                            </CustomTag>
                            <CustomTag>
                                property
                            </CustomTag>
                            <CustomTag>
                                miscs.
                            </CustomTag>
                        </CategoryContainer>
                    </div>
                </TopInner>
            </TopContainer>

            <SizeBox h={50}/>
            <NewContainer>
                <div className={'f-row'}>
                    <div style={{marginLeft: '4%'}}/>
                    <MainText>
                        New
                    </MainText>
                </div>
                <SizeBox h={30}/>

                <NewBody data={dummyData}/>
            </NewContainer>
        </div>
    );
}

export default MintingMain;