import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletListData from "../../../../datas/WalletListData";
import { GasPrice, SigningStargateClient } from "@cosmjs/stargate";
import axios from "axios";
import PolygonIcon from "../../../../assets/tokens/polygon/polygon.svg";
import DaiIcon from "../../../../assets/tokens/polygon/dai.svg";
import ChainlinkIcon from "../../../../assets/tokens/polygon/chainlink.svg";
import AaveIcon from "../../../../assets/tokens/polygon/aave.svg";
import TheGraphIcon from "../../../../assets/tokens/polygon/theGraph.svg";
import CurveDAOTokenIcon from "../../../../assets/tokens/polygon/curveDaoToken.svg";
import Web3 from "web3";

const IconArr = [
  PolygonIcon,
  DaiIcon,
  ChainlinkIcon,
  AaveIcon,
  TheGraphIcon,
  CurveDAOTokenIcon,
];

const Container = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 18px 20px;
  display: flex;
  border: 1px solid ${palette.gray};
  margin-top: 26px;
  justify-content: space-between;
`;

const ConnectBox = styled.div`
  width: 30%;
`;

const TokenListBox = styled.div`
  width: 67%;
`;

const TokenBox = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid ${palette.light_gray};
  border-radius: 30px;
  margin-bottom: 16px;
`;

const TokenArea = styled.div`
  display: flex;
  align-items: center;
`;

const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const TokenName = styled.div`
  ${Typography.Heading4}
  text-align: left;
  margin-left: 8px;
`;

const TokenBalance = styled.div`
  ${Typography.Heading4}
  text-align: right;
  color: ${palette.light_black};
  margin-right: 20px;
`;

const MoveButton = styled.button`
  width: 88px;
  height: 36px;
  border: hidden;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  border-radius: 20px;
`;

const WalletTitle = styled.div`
  max-width: 420px;
  height: 60px;
  display: flex;
  justify-content: left;
  background-color: transparent;
  padding: 12px 0px;
  ${Typography.Heading3}
`;

const WalletIcon = styled.img`
  width: 36px;
  height: 36px;
`;

const WalletName = styled.div`
  ${Typography.Heading3}
  margin: auto 0px;
  margin-left: 16px;
`;

const ConnectWalletButton = styled.button`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.marine};
  color: ${palette.white};
  border: hidden;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin-top: 10px;
`;

const WalletBox = (props) => {
  const [wallet, setWallet] = useState(props.wallet);
  const [tokenBalance, setTokenBalance] = useState([
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
  ]);
  const [tokenUpdate, setTokenUpdate] = useState(0);
  const [tokenUpdate2, setTokenUpdate2] = useState(0);
  const [tokenUpdate3, setTokenUpdate3] = useState(0);
  const [walletAddress, setWalletAddress] = useState(null);
  const [balance, setBalance] = useState(0);

  //   useEffect(() => {
  //     if (tokenUpdate == false) {
  //       setTokenBalance([
  //         { denom: "uatom", amount: 75000 },
  //         { denom: "uosmo", amount: 885923818 },
  //         { denom: "uscrt", amount: 0 },
  //         { denom: "uakt", amount: 0 },
  //         { denom: "uregen", amount: 140000 },
  //       ]);
  //     }
  //   }, [tokenUpdate]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (tokenUpdate > 0) {
      console.log(tokenBalance);
      setTokenBalance(tokenBalance);
    }
  }, [tokenUpdate, tokenBalance]);

  useEffect(() => {
    if (tokenUpdate2 > 0) {
      console.log(tokenBalance);
      setTokenBalance(tokenBalance);
    }
  }, [tokenUpdate2, tokenBalance]);

  useEffect(() => {
    if (tokenUpdate3 > 0) {
      console.log(tokenBalance);
      setTokenBalance(tokenBalance);
    }
  }, [tokenUpdate3, tokenBalance]);

  useEffect(() => {
    console.log(props.wallet);
    setWallet(props.wallet);
  }, [props.wallet]);

  useEffect(() => {}, [tokenBalance]);

  let accounts, CosmWasmClient, queryHandler, gasPrice;
  var cosmwasmClientList = [];
  const denomList = ["uatom", "uosmo", "uscrt", "uakt", "uregen"];
  const walletHeader = ["cosmos", "osmo", "secret", "akash", "regen"];
  const chainIndex = 2;
  const testWallet = [
    // "cosmos1vmjyygs90uvmtc88djq8kqjz53pc8g3uda0ah4", // 승현님
    // "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8", // 승현님
    // "secret1k86spanweadv7r8xhhyekrnhqh7gc5yc7f7pfw", // 현주
    // "akash1kxpnf0qls2sn0exh5fhnkgfzg79ffz295plrjj", //햔주
    // "regen1kxpnf0qls2sn0exh5fhnkgfzg79ffz29xcecav", // 햔주
    "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8",
    "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8",
    "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8",
  ];

  const connectMetamaskWallet = async () => {
    let balanceList = [];

    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information
      var web3 = new Web3(window.ethereum);
      try {
        let balance = -1;
        let walletAddr = "";
        // Request account access
        await window.ethereum.enable();
        var accounts = await web3.eth.getAccounts();
        walletAddr = accounts[0];
        console.log(accounts[0]);
        console.log(props.setWalletAddress);
        props.setWalletAddress(walletAddr);
        var ethBalance = await web3.eth.getBalance(walletAddr);
        console.log(ethBalance / Math.pow(10, 18));
        var promises = wallet?.tokens.map(async (val, idx) => {
          balance = -1;
          if (idx == 0) {
            const result = await axios
              .get(
                `https://api.polygonscan.com/api?module=account&action=balance&address=${walletAddr}&apikey=${"53NM1NGFKJ8WND2UJRK5NPEZ3UIUK1AB3A"}`,
                {
                  headers: {
                    "Content-Type": "appliction/json",
                  },
                }
              )
              .then((data) => {
                console.log(data);
                balance = data.data.result;

                balanceList.push({
                  balance: balance,
                  denom: val.currencies[0].coinMinimalDenom,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            balanceList.push({
              balance: balance,
              denom: val.currencies[0].coinMinimalDenom,
            });
          }
          console.log("Account balance", {
            user: walletAddr,
            balance: balance,
          });
        });

        Promise.all(promises).then(function () {
          console.log(balanceList);
          setTokenBalance(balanceList);
        });

        console.log(balanceList);
      } catch (e) {
        // User denied access
      }
    }
  };

  return (
    <Container>
      <ConnectBox>
        <WalletTitle>
          <WalletIcon src={WalletListData[wallet.walletId].walletIcon} />
          <WalletName>{wallet.walletName}</WalletName>
        </WalletTitle>
        {wallet.walletName == "Metamask" ? (
          <ConnectWalletButton onClick={connectMetamaskWallet}>
            Connect Wallet
          </ConnectWalletButton>
        ) : (
          <ConnectWalletButton
            onClick={() => alert("Service is being prepared.")}
          >
            Connect Wallet
          </ConnectWalletButton>
        )}
      </ConnectBox>
      <TokenListBox>
        {wallet?.tokens.map((val, idx) => (
          <TokenBox>
            <TokenArea>
              <TokenIcon
                src={
                  // val.currencies[0].coinImageUrl
                  IconArr[idx]
                }
              />
              <TokenName>{val.chainName}</TokenName>
            </TokenArea>
            <TokenArea>
              <TokenBalance>
                {tokenBalance[
                  tokenBalance.findIndex(
                    (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                  )
                ]?.balance != -1 &&
                tokenBalance.findIndex(
                  (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                ) != -1
                  ? tokenBalance[
                      tokenBalance.findIndex(
                        (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                      )
                    ]?.balance / 1000000
                  : "???"}{" "}
                {val.currencies[0].coinDenom}
              </TokenBalance>
              {tokenBalance[
                tokenBalance.findIndex(
                  (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                )
              ]?.amount >= 0 ? (
                <MoveButton onClick={() => alert("Service is being prepared.")}>
                  Move
                </MoveButton>
              ) : (
                <MoveButton style={{ backgroundColor: palette.light_gray }}>
                  Move
                </MoveButton>
              )}
            </TokenArea>
          </TokenBox>
        ))}
      </TokenListBox>
    </Container>
  );
};

export default WalletBox;
