import { BscConnector } from "@binance-chain/bsc-connector";

const BinanceOnClick = (setFunction) => {
  return new Promise(async (resolve, reject) => {
    const BinanceChain = window;
    try {
      if (!BinanceChain) {
        alert("Please install binance extension");
      } else {
        const bsc = new BscConnector({
          supportedChainIds: [56, 97], // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
        });

        const handler = await bsc.getAccount().then((result) => {
          console.log(result);
          setFunction(result);
        });
      }
      // }
    } catch (error) {
      alert(error.message);
      console.error(error.message);
    }
  });
};

export default BinanceOnClick;
