import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import CopyIcon from "../../../../assets/icons/dashboard/icon-copy.svg";
import LinkExternalIcon from "../../../../assets/icons/dashboard/icon-linkExternal.svg";
import WalletListData from "../../../../datas/WalletListData";
import AddWalletAddress from "../../../../components/basic/AddWalletAddress/AddWalletAddress";
import SettingIcon from "../../../../assets/icons/dashboard/icon-setting-white.svg";

//import CloseButton from './CloseButton'
// import ClosebtnIcon from "../images/common/close1.png"

const WalletButton = styled.button`
  max-width: 420px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  background-color: ${palette.light_gray_alpha20};
  border: 0;
  border-radius: 10px;
  padding: 8px 16px 8px 16px;
  ${Typography.Heading3}
  margin: 0px auto;
  width: 100%;
  margin-bottom: 10px;
`;

const WalletIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const BoxContainer = styled.div`
  width: 90%;
  max-width: 856px;
  min-height: 288px;
  max-height: 550px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const WalletAddress = styled.div`
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: auto 0px;
  color: ${palette.gray};
`;

const SideContainer = styled.div`
  display: flex;
  margin: auto 0px;
`;

const IconButton = styled.button`
  border: hidden;
  background-color: transparent;
  width: 32px;
  height: 32px;
  margin-left: 16px;
`;

const IconImage = styled.img`
  width: 24px;
  height: 24px;
`;

const ContentHeaderButton = styled.button`
  width: 130px;
  height: 36px;
  border-radius: 20px;
  border: hidden;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  // line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  position: absolute;
  right: 8%;
  bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconBox = styled.img`
  width: 24px;
  height: 24px;
`;

function TotalWalletAddressModal({
  className,
  onClose,
  maskClosable,
  visible,
  walletListInit,
  isUserPage,
  userName,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [walletList, setWalletList] = useState(walletListInit);

  useEffect(() => {
    if (!isUserPage) {
      // 마이페이지일 때 localStorage walletList도 동기화
      localStorage.setItem("walletList", JSON.stringify(walletList));
    }
  }, [modalVisible]);

  const handleCopyClipBoard = async (text) => {
    var textarea = document.createElement("textarea");
    textarea.value = text; // 복사할 메시지
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 9999); // For IOS
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("Copy address completed.");
  };

  const moveToScan = async (addr) => {
    window.location.href = "https://www.blockchain.com/eth/address/" + addr;
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeOnClick = () => {
    onClose();
  };

  const addButtonOnClick = () => {
    setModalVisible(true);
  };

  const settingOnClick = () => {
    window.location.href = "/setting";
  };

  return (
    <React.Fragment>
      {modalVisible ? (
        <AddWalletAddress
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
          setWalletList={setWalletList}
          walletList={walletList}
          mode={"setting"}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>
              {userName}'{userName[-1] == "s" ? "" : "s"} Wallet Address
            </PopupTitle>
            <BoxContainer>
              {walletList.map((val, idx) => (
                <WalletButton>
                  <SideContainer>
                    <WalletIcon
                      src={
                        WalletListData[
                          WalletListData.findIndex(
                            (x) => x.walletName === val.walletType
                          )
                        ]?.walletIcon
                      }
                    />
                    <WalletAddress>
                      {val.walletAddress.substr(0, 6) +
                        "..." +
                        val.walletAddress.substr(
                          val.walletAddress.length - 6,
                          val.walletAddress.length
                        )}
                    </WalletAddress>
                  </SideContainer>
                  <SideContainer>
                    <IconButton
                      onClick={() => handleCopyClipBoard(val.walletAddress)}
                    >
                      <IconImage src={CopyIcon} />
                    </IconButton>
                    <IconButton onClick={() => moveToScan(val.walletAddress)}>
                      <IconImage src={LinkExternalIcon} />
                    </IconButton>
                  </SideContainer>
                </WalletButton>
              ))}
              {isUserPage ? (
                <React.Fragment></React.Fragment>
              ) : (
                // <ContentHeaderButton onClick={addButtonOnClick}>
                //   Add Wallet
                // </ContentHeaderButton>
                <ContentHeaderButton onClick={settingOnClick}>
                  <div>Settings</div>
                  <img src={SettingIcon} />
                </ContentHeaderButton>
              )}
            </BoxContainer>
            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

TotalWalletAddressModal.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 740px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 36px;
`;

const DescriptionBox = styled.div`
  margin: 0 auto;
  font-family: Roboto Mono;
  font-weight: regular;
  font-size: 16px;
  color: #111111;
  margin-bottom: 30px;
`;

const BadgeImage = styled.img`
  margin: 0 auto;
  width: 100px;
  z-index: 5;
`;

const BadgeTitle = styled.div`
  margin: 0 auto;
  margin-top: 10px;
  font-family: NeoDunggeunmo Pro;
  font-size: 21px;
  font-weight: 400;
  color: #444444;
  z-index: 5;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

const BottomButton = styled.button`
  width: 187px;
  height: 42px;
  border-radius: 21px;
  background-color: #4673e9;
  color: #ffffff;
  font-family: NeoDunggeunmo Pro;
  font-size: 18px;
  border: 0px;
  margin-top: 34px;
  position: absolute;
  right: 40px;
  z-index: 5;
  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(70, 115, 233, 0.5);
  }
`;

export default TotalWalletAddressModal;
