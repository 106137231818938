import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { sendComment } from "../../../../utils/api/comments";
import {
  deleteAllNoti,
  deleteListNoti,
} from "../../../../utils/api/notifications";

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  gap: 18px;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 10;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.white};
  background-color: ${palette.marine};
  z-index: 10;
`;

function NotiDeletePopup({
  className,
  onClose,
  maskClosable,
  visible,
  deleteType,
  userId,
  deleteList,
}) {
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  const deleteOnClick = async () => {
    console.log(deleteList);
    if (deleteType == "Selected") {
      const deleteListNotiResult = await deleteListNoti(
        deleteList,
        localStorage.getItem("userID")
      ).then((data) => {
        console.log(data);
        window.location.reload();
      });
    } else if (deleteType == "All") {
      const deleteAllNotiResult = await deleteAllNoti(
        localStorage.getItem("userID")
      ).then((data) => {
        window.location.reload();
      });
    }
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>Are you sure to delete the notification?</PopupTitle>
            <PopupDescription>
              <font style={{ color: palette.light_red }}>
                {deleteType} notifications
              </font>{" "}
              will be deleted.
              <br />
              Deleted notifications cannot be recovered again.
            </PopupDescription>
            <ButtonContainer>
              <LeftButton onClick={closeOnClick}>Cancel</LeftButton>
              <RightButton onClick={deleteOnClick}>Delete</RightButton>
            </ButtonContainer>

            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

NotiDeletePopup.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 440;
  min-height: 280px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  padding: 60px 56px;
`;

const PopupTitle = styled.div`
  margin: 0 auto;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 20px;
`;

const PopupDescription = styled.div`
  ${Typography.Heading4}
  color: ${palette.black};
  text-align: left;
  margin-bottom: 40px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

export default NotiDeletePopup;
