import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletListData from "../../../../datas/WalletListData";
import { GasPrice, SigningStargateClient } from "@cosmjs/stargate";
import axios from "axios";

const Container = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 18px 20px;
  display: flex;
  border: 1px solid ${palette.gray};
  margin-top: 26px;
  justify-content: space-between;
`;

const ConnectBox = styled.div`
  width: 30%;
`;

const TokenListBox = styled.div`
  width: 67%;
`;

const TokenBox = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border: 1px solid ${palette.light_gray};
  border-radius: 30px;
  margin-bottom: 16px;
`;

const TokenArea = styled.div`
  display: flex;
  align-items: center;
`;

const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const TokenName = styled.div`
  ${Typography.Heading4}
  text-align: left;
  margin-left: 8px;
`;

const TokenBalance = styled.div`
  ${Typography.Heading4}
  text-align: right;
  color: ${palette.light_black};
  margin-right: 20px;
`;

const MoveButton = styled.button`
  width: 88px;
  height: 36px;
  border: hidden;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  border-radius: 20px;
`;

const WalletTitle = styled.div`
  max-width: 420px;
  height: 60px;
  display: flex;
  justify-content: left;
  background-color: transparent;
  padding: 12px 0px;
  ${Typography.Heading3}
`;

const WalletIcon = styled.img`
  width: 36px;
  height: 36px;
`;

const WalletName = styled.div`
  ${Typography.Heading3}
  margin: auto 0px;
  margin-left: 16px;
`;

const ConnectWalletButton = styled.button`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.marine};
  color: ${palette.white};
  border: hidden;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin-top: 10px;
`;

const WalletBox = (props) => {
  const [wallet, setWallet] = useState(props.wallet);
  const [tokenBalance, setTokenBalance] = useState([
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
    { denom: "fail", amount: -1 },
  ]);
  const [tokenUpdate, setTokenUpdate] = useState(0);
  const [tokenUpdate2, setTokenUpdate2] = useState(0);
  const [tokenUpdate3, setTokenUpdate3] = useState(0);

  //   useEffect(() => {
  //     if (tokenUpdate == false) {
  //       setTokenBalance([
  //         { denom: "uatom", amount: 75000 },
  //         { denom: "uosmo", amount: 885923818 },
  //         { denom: "uscrt", amount: 0 },
  //         { denom: "uakt", amount: 0 },
  //         { denom: "uregen", amount: 140000 },
  //       ]);
  //     }
  //   }, [tokenUpdate]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (tokenUpdate > 0) {
      console.log(tokenBalance);
      setTokenBalance(tokenBalance);
    }
  }, [tokenUpdate, tokenBalance]);

  useEffect(() => {
    if (tokenUpdate2 > 0) {
      console.log(tokenBalance);
      setTokenBalance(tokenBalance);
    }
  }, [tokenUpdate2, tokenBalance]);

  useEffect(() => {
    if (tokenUpdate3 > 0) {
      console.log(tokenBalance);
      setTokenBalance(tokenBalance);
    }
  }, [tokenUpdate3, tokenBalance]);

  useEffect(() => {
    console.log(props.wallet);
    setWallet(props.wallet);
  }, [props.wallet]);

  useEffect(() => {}, [tokenBalance]);

  let accounts, CosmWasmClient, queryHandler, gasPrice;
  var cosmwasmClientList = [];
  const denomList = ["uatom", "uosmo", "uscrt", "uakt", "uregen"];
  const walletHeader = ["cosmos", "osmo", "secret", "akash", "regen"];
  const chainIndex = 2;
  const testWallet = [
    // "cosmos1vmjyygs90uvmtc88djq8kqjz53pc8g3uda0ah4", // 승현님
    // "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8", // 승현님
    // "secret1k86spanweadv7r8xhhyekrnhqh7gc5yc7f7pfw", // 현주
    // "akash1kxpnf0qls2sn0exh5fhnkgfzg79ffz295plrjj", //햔주
    // "regen1kxpnf0qls2sn0exh5fhnkgfzg79ffz29xcecav", // 햔주
    "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8",
    "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8",
    "osmo1vmjyygs90uvmtc88djq8kqjz53pc8g3u9xudp8",
  ];

  const connectKeplrWallet = async () => {
    if (window["keplr"]) {
      if (window.keplr["experimentalSuggestChain"]) {
        (async () => {
          var promises = wallet.tokens.map(async (val, idx) => {
            // await window.keplr.experimentalSuggestChain(val);
            await window.keplr.experimentalSuggestChain;
            await window.keplr.enable(val.chainId);
            const offlineSigner = await window.getOfflineSigner(val.chainId);

            accounts = await offlineSigner.getAccounts().then(async (res) => {
              console.log(res[0].address);

              if (res[0].address.startsWith("cosmos")) {
                var tmpData = {
                  //   cosmWasmClient: data,
                  denom: "uatom",
                  address:
                    // walletHeader[
                    //   denomList.findIndex(
                    //     (denom) => denom == val.currencies[0].coinMinimalDenom
                    //   )
                    // ] +
                    // accounts[0].address.slice(-39, accounts[0].address.length),
                    res[0].address,
                };
                cosmwasmClientList.push(tmpData);
              } else {
                CosmWasmClient = await SigningStargateClient.connectWithSigner(
                  val.rpc,
                  // "https://rpc-osmosis.blockapsis.com",
                  // accounts[0].address,
                  offlineSigner
                ).then((data) => {
                  var tmpData = {
                    cosmWasmClient: data,
                    denom: val.currencies[0].coinMinimalDenom,
                    address:
                      // walletHeader[
                      //   denomList.findIndex(
                      //     (denom) => denom == val.currencies[0].coinMinimalDenom
                      //   )
                      // ] +
                      // accounts[0].address.slice(-39, accounts[0].address.length),
                      res[0].address,
                  };
                  cosmwasmClientList.push(tmpData);
                  console.log(tmpData);
                  console.log(cosmwasmClientList);
                });

                console.log("Wallet connected", {
                  offlineSigner: offlineSigner,
                  CosmWasmClient: CosmWasmClient,
                  accounts: accounts,
                  chain: val,
                  // queryHandler: queryHandler,
                  //   gasPrice: gasPrice,
                });
              }
            });
            // console.log(accounts[0].address)
            // CosmWasmClient = await SigningCosmWasmClient.connectWithSigner(
          });

          Promise.all(promises).then(function () {
            console.log(cosmwasmClientList);
            getAddressBalance(cosmwasmClientList);
          });
        })();

        // This async waits for the user to authorize your dApp
        // it returns their account address only after permissions
        // to read that address are granted

        // queryHandler = CosmWasmClient.queryClient.wasm.queryContractSmart;
        // Gas price

        // gasPrice = GasPrice.fromString("0.002uconst");

        async function getAddressBalance(cwList) {
          let balanceList = [];
          console.log(cosmwasmClientList);
          var settingNum = 0;
          var promises = wallet.tokens.map(async (val, idx) => {
            setTimeout(async function () {
              try {
                console.log(cosmwasmClientList[idx].address);
                console.log("hi");
                if (cosmwasmClientList[idx].address.startsWith("cosmos")) {
                  console.log("is cosmos hub?");
                  const cosmosResult = await axios
                    .get(
                      `https://api.cosmos.network/cosmos/bank/v1beta1/balances/${cosmwasmClientList[idx].address}/by_denom?denom=uatom`,
                      {
                        mode: "cors",
                        headers: {
                          "Content-Type": "application/json",
                          "Access-Control-Allow-Origin":
                            "http://localhost:3000",
                        },
                      }
                    )
                    .then((data) => {
                      console.log(data);
                      console.log("Account balance", {
                        user: cosmwasmClientList[idx].address,
                        balance: data.balance,
                      });
                      console.log(data.balance.amount);
                      balanceList.push(data.balance);
                    });
                } else {
                  let balance = await cosmwasmClientList[
                    idx
                  ].cosmWasmClient.getBalance(
                    cosmwasmClientList[idx].address,
                    // val.currencies[0].coinMinimalDenom
                    cosmwasmClientList[idx].denom
                    // "uosmo"
                  );
                  console.log("Account balance", {
                    user: cosmwasmClientList[idx].address,
                    balance: balance,
                  });
                  console.log(balance.amount);
                  balanceList.push(balance);
                  // setBalance(balance.amount);
                }
              } catch (e) {
                console.warn("Error reading account balance", [
                  e,
                  cosmwasmClientList[idx].address,
                  cosmwasmClientList[idx].denom,
                ]);
                balanceList.push({
                  denom: cosmwasmClientList[idx].denom,
                  amount: "Temporary Error",
                });
                console.log(balanceList);
                setTokenBalance(balanceList);
                setTokenUpdate(tokenUpdate + 1);
                setTokenUpdate2(tokenUpdate2 + 1);
                console.log(tokenUpdate);
              }
              settingNum++;
              if (settingNum == 3) {
                console.log(balanceList);
                setTokenBalance(balanceList);
                setTokenUpdate(tokenUpdate + 1);
              }
              if (settingNum == 4) {
                console.log(balanceList);
                setTokenBalance(balanceList);
                setTokenUpdate3(tokenUpdate3 + 1);
              }
            }, 1000);
          });

          Promise.all(promises).then(function () {
            console.log(balanceList);
            setTokenBalance(balanceList);
          });
          //   setTokenUpdate(!tokenUpdate);
        }

        async function sendToken(senderAddress, recipientAddress, amount, fee) {
          try {
            let sendTokenResponse = await CosmWasmClient.sendToken(
              senderAddress,
              recipientAddress,
              amount,
              fee
            );
            console.log("SendToken Response", {
              sendTokenResponse: sendTokenResponse,
            });
          } catch (e) {
            console.warn("Error sending Token", [
              e,
              senderAddress,
              recipientAddress,
            ]);
          }
        }

        // sendToken(accounts[0].address, accounts[0].address, 0.01, 0.084843);

        // getAddressBalance(accounts[0].address);
      } else {
        console.warn(
          "Error accessing experimental features, please update Keplr"
        );
      }
    } else {
      console.warn("Error accessing Keplr, please install Keplr");
    }
  };

  return (
    <Container>
      <ConnectBox>
        <WalletTitle>
          <WalletIcon src={WalletListData[wallet.walletId].walletIcon} />
          <WalletName>{wallet.walletName}</WalletName>
        </WalletTitle>
        {wallet.walletName == "Keplr" ? (
          <ConnectWalletButton onClick={connectKeplrWallet}>
            Connect Wallet
          </ConnectWalletButton>
        ) : (
          <ConnectWalletButton
            onClick={() => alert("Service is being prepared.")}
          >
            Connect Wallet
          </ConnectWalletButton>
        )}
      </ConnectBox>
      <TokenListBox>
        {wallet?.tokens.map((val, idx) => (
          <TokenBox>
            <TokenArea>
              <TokenIcon src={val.currencies[0].coinImageUrl} />
              <TokenName>{val.chainName}</TokenName>
            </TokenArea>
            <TokenArea>
              <TokenBalance>
                {tokenBalance[
                  tokenBalance.findIndex(
                    (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                  )
                ]?.amount != -1 &&
                tokenBalance.findIndex(
                  (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                ) != -1
                  ? tokenBalance[
                      tokenBalance.findIndex(
                        (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                      )
                    ]?.amount / 1000000
                  : "???"}{" "}
                {val.currencies[0].coinDenom}
              </TokenBalance>
              {tokenBalance[
                tokenBalance.findIndex(
                  (tk) => tk.denom == val.currencies[0].coinMinimalDenom
                )
              ]?.amount >= 0 ? (
                <MoveButton onClick={() => alert("Service is being prepared.")}>
                  Move
                </MoveButton>
              ) : (
                <MoveButton style={{ backgroundColor: palette.light_gray }}>
                  Move
                </MoveButton>
              )}
            </TokenArea>
          </TokenBox>
        ))}
      </TokenListBox>
    </Container>
  );
};

export default WalletBox;
