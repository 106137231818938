import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletButtonGroupMini from "../../../../components/basic/WalletGroup/WalletButtonGroupMini";
import { findUserId } from "../../../../utils/api/wallets";

const LogoContainer = styled.div`
  margin-top: 54px;
  margin-bottom: 34px;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
`;

const ContentContainer = styled.div`
  align-items: left;
`;

const HeaderTitle = styled.div`
  ${Typography.Heading3}
`;

const MainComment = styled.div`
  height: auto;
  ${Typography.Heading1}
`;

const WalletButtonBox = styled.div`
  width: 100%;
  min-height: 200px;
  padding-top: 24px;
  padding-bottom: 45px;
  border-radius: 14px;
  border: hidden;
  background-color: ${palette.background};
  margin-top: 30px;
`;

const WalletButtonBoxTitle = styled.div`
  ${Typography.Body}
  text-align: center;
  color: ${palette.light_black};
`;

const SelectWallet = (props) => {
  const [userName, setUserName] = useState("");
  const [connectWallet, setConnectWallet] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [walletId, setWalletId] = useState(0);
  const [userNameList, setUserNameList] = useState([]);
  const [userInfoCheckSuccess, setUserInfoCheckSuccess] = useState(false);

  useEffect(() => {}, [connectWallet]);

  useEffect(() => {
    (async () => {
      console.log(walletAddress);
      if (walletAddress) {
        //연결하여 받은 walletAddress가 가입된 이력이 있는지 확인하는 api 호출
        //있으면 연결된 username 연결 리스트도 받아와서 값을 반환함.

        const findUserIdResult = await findUserId(walletAddress).then(
          (data) => {
            setUserNameList(data);
            setUserInfoCheckSuccess(true);

            props.setUserNameList(data);
            props.setWalletAddress(walletAddress);
            props.setWalletId(walletId);
            props.setPageStatus(1);
          }
        );

        // api 미동작시
        // props.setUserNameList(["test1", "gkrry2723"]);
        // props.setWalletAddress(walletAddress);
        // props.setWalletId(walletId);
        // props.setPageStatus(1);
      }
    })();
  }, [walletAddress]);

  const continueOnClick = async () => {
    // const checkSignupResult = await checkSignup(userName).then(async (data) => {
    //   if (data == 1) {
    //     const loginResult = await login(userName).then((data) => {
    //       console.log(data);
    //       props.setWalletList(data.userWallets);
    //       props.setUserName(userName);
    //       props.setPageStatus(1);
    //     });
    //   } else {
    //     alert("Invalid user! Please check again.");
    //   }
    // });

    // api가 작동을 안할 때 테스트 코드
    props.setWalletList([
      {
        index: 92,
        user: "gkrry2723",
        walletAddress: "0xed6631bD706BC910A37cdc41ACd48a5d94F7bCC0",
        walletType: "Metamask",
        walletIcon:
          "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
        createdAt: "2022-05-10T04:07:36.000+00:00",
      },
      {
        index: 93,
        user: "gkrry2723",
        walletAddress: "0xed6631bD706BC910A37cdc41ACd48a5d94F7bCC0",
        walletType: "Metamask",
        walletIcon:
          "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/da7d03d1-ba3d-46b5-97eb-6cd0f0804e3f1.png",
        createdAt: "2022-05-10T04:08:04.000+00:00",
      },
    ]);
    props.setUserName(userName);
    props.setPageStatus(1);
  };

  return (
    <ModalContainer>
      <ContentContainer>
        <HeaderTitle>Find your User ID</HeaderTitle>
        <LogoContainer>
          <MainComment>
            Select a wallet,
            <br />
            And Find the User ID
          </MainComment>
        </LogoContainer>
        <WalletButtonBox>
          <WalletButtonBoxTitle>Sign Up with Wallet</WalletButtonBoxTitle>
          <WalletButtonGroupMini
            setPageStack={setWalletAddress}
            setWalletId={setWalletId}
          />
        </WalletButtonBox>
      </ContentContainer>
    </ModalContainer>
  );
};

export default SelectWallet;
