import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Comments from "./Comments";
import NFTs from "./NFTs";
import PinnedBadges from "./PinnedBadges";
import ProfileBox from "./ProfileBox";
import Tokens from "./Tokens";
import WalletTest from "./WalletTest";

const FullContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  margin-top: 70px;
`;

const DashboardMe = (props) => {
  const [userInfo, setUserInfo] = useState(props.userInfo);
  const [badgeData, setBadgeData] = useState(props.badgeData);
  const [nftData, setNftData] = useState(props.nftData);
  const [poapData, setPoapData] = useState(props.poapData);
  const [commentData, setCommentData] = useState(props.commentData);
  const [commentInfo, setCommentInfo] = useState(props.commentInfo);
  const [socials, setSocials] = useState(props.socials);
  const [walletList, setWalletList] = useState(props.walletList);
  const [followerNum, setFollowerNum] = useState(props.followerNum);
  const [followingNum, setFollowingNum] = useState(props.followingNum);
  const [friendNum, setFriendNum] = useState(props.friendNum);
  const [loading, setLoading] = useState(props.loading);

  useEffect(() => {
    setUserInfo(props.userInfo);
  }, [props.userInfo]);

  useEffect(() => {
    setBadgeData(props.badgeData);
  }, [props.badgeData]);

  useEffect(() => {
    setCommentData(props.commentData);
  }, [props.commentData]);

  useEffect(() => {
    setCommentInfo(props.commentInfo);
  }, [props.commentInfo]);

  useEffect(() => {
    setNftData(props.nftData);
  }, [props.nftData]);

  useEffect(() => {
    setPoapData(props.poapData);
  }, [props.poapData]);

  useEffect(() => {
    setSocials(props.socials);
  }, [props.socials]);

  useEffect(() => {
    setWalletList(props.walletList);
  }, [props.walletList]);

  useEffect(() => {
    setFollowerNum(props.followerNum);
  }, [props.followerNum]);

  useEffect(() => {
    setFollowingNum(props.followingNum);
  }, [props.followingNum]);

  useEffect(() => {
    setFriendNum(props.friendNum);
  }, [props.friendNum]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  return (
    <FullContainer>
      <ProfileBox
        userInfo={userInfo}
        socials={socials}
        walletList={walletList}
        followerNum={followerNum}
        followingNum={followingNum}
        friendNum={friendNum}
      />
      {/* <WalletTest /> */}
      {/* <PinnedBadges badgeData={badgeData} /> */}
      <NFTs nftData={nftData} poapData={poapData} loading={loading} />
      <Comments
        userInfo={userInfo}
        commentData={commentData}
        commentInfo={commentInfo}
      />
      {/* <Tokens userInfo={userInfo} /> */}
    </FullContainer>
  );
};

export default DashboardMe;
