import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import LogoImage from "../../../assets/images/main_logo_image.svg";
import AlarmIcon from "../../../assets/icons/dashboard/icon-alert.svg";
import ProfileDropbox from "./ProfileDropbox";
import { checkUnreadNoti } from "../../../utils/api/notifications";
import DefaultProfile from "../../../assets/icons/dashboard/icon-defaultProfile.svg";

const HeaderContainer = styled.div`
  width: 100%;
  height: 72px;
  //   border-bottom: 1px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 40px;
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px #c4c4c440;
  z-index: 100;
  position: fixed;
  top: 0px;
  //   box-shadow: 0px 4px 4px ${palette.black}40;
`;

const LogoButton = styled.button`
  width: 173px;
  height: 48px;
  margin: auto 0px;
  background-color: transparent;
  border: 0;
`;

const LogoImageContainer = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin: auto 0px;
  display: flex;
  gap: 13px;
  position: relative;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 10;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.white};
  background-color: ${palette.marine};
  z-index: 10;
`;

const DesktopHeader = (props) => {
  const [profileImg, setProfileImg] = useState(props.profileImg);
  const [newNotiExist, setNewNotiExist] = useState(true);
  const [dropBoxOn, setDropBoxOn] = useState(false);

  useEffect(() => {
    if (!props.profileImg) {
      // 기본 프로필 이미지로 설정
      setProfileImg(DefaultProfile);
    } else {
      setProfileImg(props.profileImg);
    }
  }, [props.profileImg]);

  useEffect(() => {
    // 새로운 noti가 있는지 체크하는 api 호츌
    (async () => {
      if (localStorage.getItem("userID")) {
        const checkUnreadNotiResult = await checkUnreadNoti(
          localStorage.getItem("userID")
        ).then((data) => {
          setNewNotiExist(data);
          //true : 남은 거 있음
          //false: 남은 거 없음
        });
      }
    })();
  }, []);
  const signUpOnClick = () => {
    window.location.href = "/signup";
  };

  const logoOnClick = () => {
    window.location.href = "/mypage";
  };

  const signInOnClick = () => {
    window.location.href = "/signin";
  };

  return (
    <HeaderContainer>
      <LogoButton onClick={logoOnClick}>
        <LogoImageContainer src={LogoImage} />
      </LogoButton>
      <ButtonContainer>
        <LeftButton onClick={signInOnClick}>Log In</LeftButton>
        <RightButton onClick={signUpOnClick}>Sign Up</RightButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default DesktopHeader;
