import styled from 'styled-components';
import {COLORS as c} from "../../../utils/styles/colors";

import NewItemDto from "../../../datas/dtos/NewItemDto";
import SizeBox from "../../../components/util/SizeBox";
import Spacer from "../../../components/util/Spacer";

import {ReactComponent as PeopleImg} from "../../../assets/icons/minting/icon-people.svg";
import {ReactComponent as Ether} from "../../../assets/icons/chain/Ethereum_logo_2014.svg";
import CustomTag from "../../../components/basic/CustomTag";
import UrlImageLoader from "../../../components/util/UrlImageLoader";
import {Link} from "react-router-dom";
import ImageLoader from "../../../components/util/ImageLoader";
import Evmos from "../../../assets/icons/chain/icon-evmos.png";
import Klaytn from "../../../assets/icons/chain/klaytn-logo.png";

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
`;

function NewBody({data = []}) {
    return (
        <>
            <Body>
                {Array.from(data, (v: NewItemDto, i) => {
                    return Item(v.title, v.people, v.price, v.category, v.imgUrl, v.valid, v.id);
                })}
            </Body>
            <SizeBox h={100}/>
        </>
    );
}

const ItemContainer = styled.div`
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 270px;
  height: 150px;
  margin: 9px;
  padding: 12px 20px 10px 12px;

  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(196, 196, 196, 0.25);
  border-radius: 16px;

  color: black;

  transition: all 0.3s ease-in-out;
  position: relative;
  bottom: 0px;

  :hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    bottom: 10px;
  }
`;

const Title = styled.pre`
  color: ${c.black};
  font-weight: 600;
  white-space: pre-wrap;
  overflow: hidden;
`;

const Price = styled.pre`
  color: ${c.gray};
  font-size: 12px;
`;

const People = styled.pre`
  color: ${c.gray};
  font-weight: 600;
  font-size: 11px;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: start;

  width: 100%;
  height: max-content;
`;

const Valid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 4px 12px;
  margin: 2px;

  background: ${props => props.valid ? c.blue : c.light_red};
  border: 1px solid ${props => props.valid ? c.blue : c.light_red};
  border-radius: 20px;

  color: ${c.white};
  font-weight: 600;
  font-size: 10px;
`;

function Item(title, people, price, category, imgUrl, valid, id) {
    return (
        <Link to={`/badge/detail/${id}`}>
            <ItemContainer>
                <div className={'f-row'}>
                    <ImageLoader w={84} h={84} src={imgUrl} round={20}/>

                    <SizeBox w={10}/>
                    <div className={'column'} style={{textAlign: 'start'}}>
                        <SizeBox h={10}/>
                        <Title>
                            {title}
                        </Title>

                        <SizeBox h={8}/>
                        <div className={'f-row a-center j-start'}>
                            <ImageLoader w={20} h={20} src={Klaytn}/>

                            <SizeBox w={5}/>
                            <Price>
                                {price} Klaytn
                            </Price>
                        </div>
                    </div>

                    <Spacer/>

                    <div className={'row a-center'}>
                        <PeopleImg style={{color: c.gray, width: '18px'}}/>
                        <People>
                            {people.toLocaleString()}
                        </People>
                    </div>
                </div>

                <SizeBox h={12}/>
                <CategoryContainer>
                    {Array.from(category, (v, i) => {
                        return <CustomTag isSmall={true} shadow={false}>
                            {v}
                        </CustomTag>;
                    })}
                    <Valid valid={valid}>
                        {valid ? 'Available' : 'Closed'}
                    </Valid>
                </CategoryContainer>
            </ItemContainer>
        </Link>
    );
}

export default NewBody;