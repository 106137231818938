import styled from "styled-components";
import { COLORS as c } from "../../../utils/styles/colors";
import { useEffect, useRef, useState } from "react";
import SizeBox from "../../../components/util/SizeBox";
import BasicButton from "../../../components/basic/BasicButton";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ActionAPI, { WEB3_ERRORS } from "../../../network/ActionAPI";
import CategorySelector from "../../../components/basic/CategorySelector";
import CustomTag from "../../../components/basic/CustomTag";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useMediaQuery } from "react-responsive";
import ColoredBtn from "../../../components/basic/ColoredBtn";
import Selector from "../../../components/basic/Selector";
import Spacer from "../../../components/util/Spacer";
import CircleLoading from "../../../components/basic/CircleLoading";
import Header from "../../../components/global/Header/index";
import Caver from "caver-js";
import WalletModal from "../../../components/basic/Modals/WalletModal";
import { getMypage } from "../../../utils/api/auth";
// import Header from "../../../components/global/header";

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  justify-content: center;

  padding: 80px 50px;
  box-sizing: border-box;
  margin-top: 72px;
`;

const BadgeBackBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  width: 100%;
  max-width: 980px;
  height: max-content;
  padding: 70px 80px;
  background-color: ${c.white};
  border-radius: 40px;
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
`;

const StepText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;

  color: ${c.blue};
`;

const OptionTitle = styled.div`
  margin-left: 30px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

const InputDesc = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: ${c.gray};
`;

const LinkDesc = styled.div`
  user-select: none;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  text-align: right;
  text-decoration-line: underline;

  color: ${c.light_gray};
`;

const InputBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${c.background};
  border-radius: 8px;

  width: 94%;
  height: 42px;
`;

const InputInner = styled.input`
  width: 100%;
  height: 100%;

  background: ${c.background};
  border-radius: 8px;
  border: 0px;

  margin-left: 10px;
  padding: 0px 10px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

const InputBodyDes = styled.textarea`
  width: 94%;
  height: 110px;

  background: ${c.background};
  border-radius: 8px;
  border: 0px;

  margin-left: 40px;
  padding: 10px 10px;
  box-sizing: border-box;
  max-lines: 20;

  font-family: Montserrat;
  resize: none;

  &:focus {
    outline: none;
  }
`;

const InputCount = styled.div`
  width: max-content;
  margin-right: 20px;

  color: ${c.light_gray};
  white-space: nowrap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

const SelectImage = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 94%;
  height: max-content;

  margin-left: 40px;
  padding: 20px 0px 20px 60px;
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #222222;
  border-radius: 12px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${c.black};
`;

const CategoryViewer = styled.div`
  width: 94%;
  height: max-content;
  min-height: 48px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  padding: 10px 20px;
  margin-left: 40px;
  box-sizing: border-box;
  background-color: ${c.background};
  border-radius: 8px;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 400px;
  height: max-content;
  padding: 60px 35px;
  box-sizing: border-box;

  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(196, 196, 196, 0.25);
  border-radius: 40px;

  top: 300px;
  right: 5%;
`;

const PreTitle = styled.p`
  align-self: start;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #777777;
`;

const BadgeTitle = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #222222;
`;

const BadgeId = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #222222;
`;

const BadgeImg = styled.img`
  width: 160px;
  height: 160px;

  background: #c4c4c4;
  border-radius: 24px;
  border: 1px solid #222222;
`;

const categoryList = ["MePe", "Web3.0"];

let contract = null;
let web3 = null;
let account = null;

function BadgeCreate() {
  const actionApi = new ActionAPI();
  const navigator = useNavigate();
  const fileRef = useRef();

  const isPc = useMediaQuery({ minWidth: 1080 });

  const [modal, setModal] = useState(false);
  const [chainIndex, setChainIndex] = useState(0);
  // const [price, setPrice] = useState("");
  const [recipient, setRecipient] = useState("");
  const [connectLoading, setConnectLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const [imgPreview, setImgPreview] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  // const [imgPath, setImgPath] = useState(null);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [external1, setExternal1] = useState("");
  const [category, setCategory] = useState([]);
  const [supply, setSupply] = useState("");
  // Gas Fee

  const [createLoading, setCreateLoading] = useState(false);
  const [profileImg, setProfileImage] = useState(
    localStorage.getItem("profileImage")
  );
  const [walletList, setWalletList] = useState([]);
  
  useEffect(()=>{
    (async () => {
      if (!localStorage.getItem("token")) {
        alert("Your session has ended. Please login again.")
        window.location.href = "/"
      } else {
        const getMypageResult = await getMypage(localStorage.getItem("userID")).then((data) => {
          console.log(data)
          if(data?.walletList){
            setWalletList(data.walletList)
          }
        })
      }
    })()
  }, [])

  const connectHandler = async () => {
    setModal(true);
    setConnectLoading(true);
    let result = WEB3_ERRORS.NO_WALLET;
    switch (chainIndex) {
      case 0:
        result = await actionApi.connect();
        break;
      case 1:
        result = await actionApi.klaytnConnect();
        break;
      default:
        break;
    }
    switch (result) {
      case WEB3_ERRORS.NO_WALLET:
        break;
      case WEB3_ERRORS.REJECTED:
        break;
      case WEB3_ERRORS.NET_ERROR:
        break;
      default:
        account = result.account;
        contract = result.contract;
        web3 = result.web3;
        setIsConnected(account);
        break;
    }
    setConnectLoading(false);
  };

  const imgSelectHandler = (e) => {
    fileRef.current.click();
  };

  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgPreview(reader.result);
        resolve();
      };
    });
  };

  const setFile = async (e) => {
    setImgFile(e.target.files[0]);
    await encodeFileToBase64(e.target.files[0]);
  };

  const createHandler = async () => {
    setCreateLoading(true);
    if (account && web3 && contract) {
      const result = await actionApi.createBadge(
        contract,
        account,
        supply,
        title,
        desc,
        external1,
        imgFile,
        category,
        recipient
      );
      if (result === WEB3_ERRORS.SUCCESS) {
        const id = await actionApi.getTotal(contract);
        const datas = {
          id: id,
          name: title,
          img: imgPreview,
        };
        navigator(`/badge/create/success`, { state: datas });
      }
    }
    setCreateLoading(false);
  };

  useEffect(() => {
    setRecipient("");
    account = null;
    contract = null;
    web3 = null;
    setIsConnected(false);
  }, [chainIndex]);

  return (
    <Container>
      {modal ? <WalletModal setModal={setModal} loading={connectLoading} setLoading={setConnectLoading}/> : null}
      <Header profileImg={profileImg} />
      {/* <Header/> */}
      {isPc ? (
        <>
          <PreviewContainer>
            <PreTitle>Preview</PreTitle>

            <SizeBox h={30} />
            <BadgeTitle>{title ? title : "Badge Title"}</BadgeTitle>

            <SizeBox h={6} />
            <BadgeId>Issued by @goyangtwo</BadgeId>

            <SizeBox h={20} />
            <BadgeImg src={imgPreview} placeholder={""} />

            <SizeBox h={30} />
            <ColoredBtn w={90} onClick={() => {}}>
              Claim
            </ColoredBtn>
          </PreviewContainer>
          <SizeBox w={40} />
        </>
      ) : null}
      <BadgeBackBoard>
        <Title>Create New Badge</Title>

        <SizeBox h={40} />
        <StepText>STEP 01. Set Chain d Price</StepText>

        <SizeBox h={20} />
        <OptionTitle>Chain & Price</OptionTitle>

        <SizeBox h={22} />
        <div className={"f-row"}>
          <SizeBox w={24} />
          <SizeBox h={36} w={180}>
            <Selector
              list={["Evmos", "Klaytn"]}
              index={chainIndex}
              setIndex={setChainIndex}
            />
          </SizeBox>

          <Spacer />
          {/*<SizeBox w={20} />*/}
          {/*<InputBody>*/}
          {/*  <InputInner*/}
          {/*    value={price}*/}
          {/*    onChange={(e) => setPrice(e.target.value)}*/}
          {/*    placeholder={"Price"}*/}
          {/*  />*/}
          {/*</InputBody>*/}
        </div>

        <SizeBox h={20} />
        <div className={"f-row"}>
          <SizeBox w={32} />
          <InputBody>
            <InputInner
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
              placeholder={"Recipient Wallet Address"}
            />
          </InputBody>

          <SizeBox w={20} />
          <SizeBox w={220} h={40}>
            <BasicButton
              mode={0}
              isValid={!isConnected}
              onClick={connectHandler}
            >
              {connectLoading ? (
                <SizeBox>
                  <CircleLoading color={c.marine} />
                </SizeBox>
              ) : isConnected ? (
                "Connected"
              ) : (
                "Connect Wallet"
              )}
            </BasicButton>
          </SizeBox>
        </div>

        <SizeBox h={10} />
        <div className={"f-row"}>
          <SizeBox w={40} />
          <InputDesc>The gas fee is at your own expense.</InputDesc>

          <Spacer />
          <LinkDesc>or find your wallet address</LinkDesc>
        </div>

        <SizeBox h={80} />
        <StepText>STEP 02. Fill Out the Badge Information</StepText>

        <SizeBox h={20} />
        <OptionTitle>Badge Title</OptionTitle>

        <SizeBox h={30} />
        <div className={"f-row"}>
          <SizeBox w={40} />
          <InputBody>
            <InputInner
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={30}
              placeholder={"Name"}
            />
            <InputCount>{title.length}/30</InputCount>
          </InputBody>
        </div>

        <SizeBox h={30} />
        <OptionTitle>Badge Image</OptionTitle>

        <SizeBox h={30} />
        <SelectImage onClick={imgSelectHandler}>Select Image</SelectImage>
        <input
          ref={fileRef}
          type="file"
          required
          name="file"
          onChange={setFile}
          style={{ display: "none" }}
        />

        <SizeBox h={30} />
        <OptionTitle>Description</OptionTitle>

        <SizeBox h={30} />
        <InputBodyDes value={desc} onChange={(e) => setDesc(e.target.value)} />

        <SizeBox h={30} />
        <OptionTitle>External link</OptionTitle>

        <SizeBox h={30} />
        <div className={"f-row"}>
          <SizeBox w={40} />
          <InputBody>
            <InputInner
              value={external1}
              onChange={(e) => setExternal1(e.target.value)}
              placeholder={"https://www.twitter.com/account"}
            />
          </InputBody>
        </div>

        <SizeBox h={30} />
        <OptionTitle>Category</OptionTitle>

        <SizeBox h={10} />
        <CategoryViewer>
          {category.map((value, index) => (
            <CustomTag
              onClick={() => {
                setCategory((array) => {
                  const index = array.indexOf(value);
                  if (index > -1) {
                    array.splice(index, 1);
                  }
                  return [...array];
                });
              }}
              isSmall={true}
              shadow={false}
            >
              {value}
            </CustomTag>
          ))}
        </CategoryViewer>

        <SizeBox h={10} />
        <div className={"f-row"}>
          <SizeBox w={40} />
          <CategorySelector list={categoryList} setCategory={setCategory} />
        </div>

        <SizeBox h={30} />
        <OptionTitle>Supply</OptionTitle>

        <SizeBox h={30} />
        <div className={"f-row"}>
          <SizeBox w={40} />
          <InputBody>
            <InputInner
              value={supply}
              onChange={(e) => setSupply(e.target.value)}
              placeholder={"0"}
            />
          </InputBody>
        </div>

        <SizeBox h={50} />
        <div className={"f-row j-center"}>
          <SizeBox w={"max-content"}>
            <BasicButton isValid={true} onClick={createHandler}>
              {createLoading ? (
                <SizeBox w={120}>
                  <CircleLoading color={c.white} />
                </SizeBox>
              ) : (
                "Create New Badge"
              )}
            </BasicButton>
          </SizeBox>
        </div>
      </BadgeBackBoard>
    </Container>
  );
}

export default BadgeCreate;
