import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../components/global/Header/index";
import { COLORS as palette } from "../../../utils/styles/colors";
import Typography from "../../../utils/styles/typography";
import $ from "jquery";
import RequestProgressBox from "./components/RequestProgressBox";
import { getTokenRequest } from "../../../utils/api/wallets";
import Footer from "../../../components/global/Footer";
import BackIcon from "../../../assets/icons/dashboard/icon-chevronLeft.svg";

window.$ = $;

const HeaderBox = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  margin-top: 70px;
`;

const HeaderContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
`;

const TitleBox = styled.div`
  width: 90%;
  max-width: 1080px;
  margin-top: 98px;
  ${Typography.Heading1}
  display: flex;
`;

const PageHeader = styled.div`
  width: 100%;
  height: 320px;
  border: 0;
  background-color: ${palette.white};
  display: flex;
  justify-content: center;
`;

const PageBackground = styled.div`
  background-color: ${palette.background};
  padding-bottom: 50px;
`;

const HeaderTitle = styled.div`
  width: 90%;
  height: auto;
  max-width: 1080px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  color: ${palette.black};
  margin-top: 220px;
`;

const OtherTabButton = styled.button`
  display: flex;
  justify-content: center;
  width: 180px;
  height: 36px;
  border: hidden;
  border-radius: 46px;
  background-color: ${palette.black};
  color: ${palette.white};
  box-shadow: 0px 2px 10px #c4c4c440;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
  align-items: center;
  margin-top: 98px;
`;

const BackButton = styled.button`
  width: 24px;
  height: 24px;
  background-image: url(${BackIcon});
  border: hidden;
  background-color: transparent;
  margin-right: 16px;
  margin-top: 4px;
`;

const RequestProgressPage = () => {
  const [userInfo, setUserInfo] = useState({
    profileImage: localStorage.getItem("profileImage"),
  });
  const [txList, setTxList] = useState([]);

  useEffect(() => {
    (async () => {
      const getTxResult = await getTokenRequest(
        localStorage.getItem("userID")
      ).then((data) => {
        console.log(data.result);
        setTxList(data.result);
      });
    })();
  }, []);

  const backOnClick = () => {
    window.location.href = "/finance";
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <PageBackground className={"f-column a-center"}>
          <Header profileImg={userInfo?.profileImage} />
          <HeaderBox>
            <HeaderContainer>
              <TitleBox>
                <BackButton onClick={backOnClick} />
                <>Request Progress</>
              </TitleBox>
            </HeaderContainer>
          </HeaderBox>
          <RequestProgressBox txList={txList} />
        </PageBackground>
        <Footer />
      </React.Fragment>
    </React.Fragment>
  );
};

export default RequestProgressPage;
