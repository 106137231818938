import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
// import IntroImage from "../../../assets/icons/dashboard/icon-image.svg";
import { COLORS as palette } from "../../../utils/styles/colors";
import {
  badgeDummyData,
  nftDummyData,
  poapDummyData,
  commentDummyData,
} from "../../../datas/DummyDatas";
import UserDummyData from "../../../assets/mepe_dummy_data/UserDummyData";

import DashboardMe from "./DashboardMe";
import Header from "../../../components/global/Header/index";
import { getMypage, checkSignup, getCollection } from "../../../utils/api/auth";
import { getFriendInfo, getUnhiddenComment } from "../../../utils/api/comments";
import Footer from "../../../components/global/Footer";

const MyPage = (props) => {
  // 로그인 성공했다고 가정하고
  const [profileImg, setprofileImg] = useState(
    localStorage.getItem("profileImage")
      ? localStorage.getItem("profileImage")
      : // : ""
        "https://daotool.s3.ap-northeast-2.amazonaws.com/static/user/d1b5e5d6-fc89-486b-99d6-b2a6894f9eafprofileimg-default.png"
  );
  const [userInfo, setUserInfo] = useState({
    // id: "oliverstyles",
    // id: localStorage.getItem("userID"),
    id: "",
    profileImage: "",
    // "https://daotool.s3.ap-northeast-2.amazonaws.com/static/user/d1b5e5d6-fc89-486b-99d6-b2a6894f9eafprofileimg-default.png",
    introduction: (
      <React.Fragment>
        Welcome to my Dashboard! <br />
        I'm highly interested in developing blockchain protocols.
      </React.Fragment>
    ),
    url: null,
    hits: 0,
    todayHits: 0,
    createdAt: "2022-05-10T03:33:40.000+00:00",
    nftRefreshLeft: 10,
    backImage: "",
    // "https://daotool.s3.ap-northeast-2.amazonaws.com/static/user/b9b3c242-2de2-4dd5-952c-106d9adcc4a5bannerimg-default.png",
    // nickname: "Oliver styles",
    nickname: "",
    // localStorage.getItem("userID"),
    index: 3,
  });
  const [socials, setSocials] = useState({
    discord: "",
    facebook: "",
    link: "",
    twitter: "",
  });
  const [walletList, setWalletList] = useState([]);
  const [followerNum, setFollowerNum] = useState(0);
  const [followingNum, setFollowingNum] = useState(0);
  const [friendNum, setFriendNum] = useState(0);
  const [badgeData, setBadgeData] = useState(badgeDummyData);
  const [nftData, setNftData] = useState(nftDummyData);
  const [poapData, setPoapData] = useState(poapDummyData);
  const [commentData, setCommentData] = useState(commentDummyData);
  const [backColor, setBackColor] = useState(palette.background);
  const [commentInfo, setCommentInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    setprofileImg(localStorage.getItem("profileImage"));
  }, [localStorage.getItem("profileImage")]);

  // useEffect(() => {
  //   console.log("hello");
  //   console.log(localStorage.getItem("userID"));
  //   (async () => {
  //     if (localStorage.getItem("userID")) {
  //       const getMypageResult = await getMypage(
  //         localStorage.getItem("userID")
  //       ).then((data) => {
  //         console.log(data);
  //         localStorage.setItem("userInfo", JSON.stringify(data.user));
  //         localStorage.setItem("socials", JSON.stringify(data.social));
  //         setUserInfo(data.user);
  //         setSocials(data.social);
  //       });
  //     }
  //   })();
  // }, []);
  
  // useEffect(()=>{
  //   if(location.pathname.startsWith("/mypage")){
  //     if(!localStorage.getItem("token")){
  //       alert("Your session has ended. Please login again.")
  //       window.location.href = "/"
  //     }
  //   }
  // }, [])

  useEffect(() => {
    console.log("herererer??????");
    (async () => {
      if (location.pathname.startsWith("/@")) {
        const dummyNameList = [
          "vitalikbuterin",
          "cocoisperson",
          "dudutheworld",
          "jangwonleebm",
        ];
        const pathParams = location.pathname.split("/");
        const userId = pathParams[1].slice(1);
        // console.log(userId);
        if (userId == localStorage.getItem("userID")) {
          window.location.href = "/mypage";
        } else if (dummyNameList.includes(userId)) {
          const userIndex = dummyNameList.indexOf(userId);

          // 존재하는 유저인지 확인
          console.log("yes");
          console.log(UserDummyData[userIndex].user);
          setUserInfo(UserDummyData[userIndex].user);
          setBadgeData(UserDummyData[userIndex].badges);
          setNftData(UserDummyData[userIndex].nfts);
          setPoapData(UserDummyData[userIndex].poaps);
          setCommentData(UserDummyData[userIndex].comments);
          setWalletList(UserDummyData[userIndex].user.wallets);
          // localStorage.setItem("walletList", JSON.stringify(UserDummyData[userIndex].user.wallets))
        } else {
          const checkSignupResult = await checkSignup(userId).then(
            async (data) => {
              console.log(data);
              if (data == 1) {
                //존재하는 사용자
                console.log(userId)
                const getMypageResult = await getMypage(userId).then((data) => {
                  console.log(data);
                  var profileImgObj = { profileImage: data.profileImg };
                  setUserInfo(Object.assign(data.user, profileImgObj));
                  setSocials(data.social);
                  // const newNFTArr = [
                  //   ...data.nftList.keplr,
                  //   ...data.nftList.ethereum,
                  //   ...data.nftList.polygon,
                  // ];

                  // if (newNFTArr.length > 0) {
                  //   const sortNFTArr = newNFTArr.sort(function (a, b) {
                  //     // 생성순으로 정렬
                  //     return b.obtainedAt - a.obtainedAt;
                  //   });
                  //   setNftData(sortNFTArr);
                  //   console.log(sortNFTArr);
                  // }
                  // if (data.poapList.length > 0) {
                  //   setPoapData(data.poapList);
                  // }
                  setFollowerNum(data.followerCount);
                  setFollowingNum(data.followingCount);
                  setFriendNum(data.friendCount);
                  setWalletList(data.walletList);
                  setCommentData(data.comments)
                });

                const getFriendInfoResult = await getFriendInfo(
                  localStorage.getItem("userID"),
                  userId
                ).then((data) => {
                  console.log(data);
                  setCommentInfo(data);
                });

                // const getUnhiddenCommentResult = await getUnhiddenComment(
                //   userId
                // ).then((data) => {
                //   console.log(data);
                //   setCommentData(data);
                // });
              } else {
                window.location.href = "/";
                alert("User page does not exist. Please check again.");
              }
            }
          );
        }
      } else {
        //mypage일 때

        if (!localStorage.getItem("userID")) {
          alert("Your session has ended. Please login again.");
          window.location.href = "/";
          localStorage.clear();
        }

        if (localStorage.getItem("userID")) {
          const getMypageResult = await getMypage(
            localStorage.getItem("userID")
          ).then((data) => {
            console.log(data);
            var profileImgObj = { profileImage: data.profileImg };
            localStorage.setItem(
              "userInfo",
              JSON.stringify(Object.assign(data.user, profileImgObj))
            );
            localStorage.setItem("socials", JSON.stringify(data.social));

            setUserInfo(Object.assign(data.user, profileImgObj));
            setUserInfo(data.user);
            setSocials(data.social);
            setFollowerNum(data.followerCount);
            setFollowingNum(data.followingCount);
            setCommentData(data.comments)
            setFriendNum(data.friendCount);
            setWalletList(JSON.parse(localStorage.getItem("walletList")));
          });

          // const getUnhiddenCommentResult = await getUnhiddenComment(
          //   localStorage.getItem("userID")
          // ).then((data) => {
          //   console.log(data);
          //   setCommentData(data);
          // });
        }
      }
    })();
  }, []);

  useEffect(() => {
    console.log("herererer??????");
    var userId = "";
    const dummyNameList = [
      "vitalikbuterin",
      "cocoisperson",
      "dudutheworld",
      "jangwonleebm",
    ];
    if (location.pathname.startsWith("/@")) {
      const pathParams = location.pathname.split("/");
      userId = pathParams[1].slice(1);
    } else {
      userId = localStorage.getItem("userID");
    }

    if (dummyNameList.includes(userId)) {
    } else if (userId) {
      (async () => {
        const getCollectionResult = await getCollection(userId).then((data) => {
          console.log(data);
          const newNFTArr = [
            ...data.nftList?.evmos,
            ...data.nftList?.keplr,
            ...data.nftList?.ethereum,
            ...data.nftList?.polygon,
            ...data.nftList?.solana,
            ...data.nftList?.kaikas,
          ];
          if (newNFTArr.length > 0) {
            const sortNFTArr = newNFTArr.sort(function (a, b) {
              // 생성순으로 정렬
              return a.obtainedAt - b.obtainedAt;
            });
            setNftData(sortNFTArr);
          }
          if (data.poapList.length > 0) {
            setPoapData(data.poapList);
          }
          setLoading(false);
        });
      })();
    }
  }, []);

  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  return (
    <div className={"f-column a-center"} style={{ backgroundColor: backColor }}>
      <Header profileImg={profileImg} />
      <DashboardMe
        userInfo={userInfo}
        badgeData={badgeData}
        nftData={nftData}
        poapData={poapData}
        commentData={commentData}
        socials={socials}
        walletList={walletList}
        followerNum={followerNum}
        followingNum={followingNum}
        friendNum={friendNum}
        commentInfo={commentInfo}
        loading={loading}
      />
      <Footer />
    </div>
  );
};

export default MyPage;
