import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

function CircleLoading({color}) {
    return (
        <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
                color: `${color}`,
                animationDuration: '550ms',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
            }}
            size={20}
            thickness={6}
        />
    );
}

export default CircleLoading;