import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import { badgeDummyData } from "../../../datas/DummyDatas";
import Header from "../../../components/global/Header/index";
import Footer from "../../../components/global/Footer";
import CommentManager from "./components/CommentManager";

const CommentsPage = () => {
  // 임시데이터
  const [userInfo, setUserInfo] = useState({
    // id: "oliverstyles",
    id: localStorage.getItem("userID") ? localStorage.getItem("userID") : "",
    profileImage: JSON.parse(localStorage.getItem("userInfo"))?.profileImg
      ? JSON.parse(localStorage.getItem("userInfo"))?.profileImg
      : "",
    introduction: (
      <React.Fragment>
        Welcome to my Dashboard! <br />
        I'm highly interested in developing blockchain protocols.
      </React.Fragment>
    ),
    url: null,
    hits: 0,
    todayHits: 0,
    createdAt: "2022-05-10T03:33:40.000+00:00",
    nftRefreshLeft: 10,
    backImage:
      "https://daotool.s3.ap-northeast-2.amazonaws.com/static/user/b9b3c242-2de2-4dd5-952c-106d9adcc4a5bannerimg-default.png",
    // nickname: "Oliver styles",
    nickname: localStorage.getItem("userID")
      ? localStorage.getItem("userID")
      : "",
    index: 3,
  });
  const [backColor, setBackColor] = useState(palette.background);

  return (
    <div className={"f-column a-center"} style={{ backgroundColor: backColor }}>
      <Header profileImg={userInfo?.profileImage} />
      <CommentManager />
      <Footer />
    </div>
  );
};

export default CommentsPage;
