import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NotValid from "../../../../assets/icons/dashboard/icon-x.svg";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import BackArrow from "../../../../assets/icons/dashboard/icon-leftArrow.svg";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import BasicButton from "../../../../components/basic/BasicButton";
import WalletButtonGroup from "../../../../components/basic/WalletGroup/WalletButtonGroup";
import CreateAccountConnectAnother from "./CreateAccountConnectAnother";
import { createUser } from "../../../../utils/api/auth.js";

const LogoContainer = styled.div`
  // margin-top: 80px;
  margin-top: 10%;
`;

const ModalContainer = styled.div`
  // width: 100%;
  height: 100vh;
  //max-width: 1240px;
  min-height: 900px;
  // margin-top: 90px;
  // margin-top: 10%;
  margin-bottom: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const ControlHeader = styled.div`
  height: 70px;
  // padding-top: 47.5px;
  padding-top: 5%;
  padding-left: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  align-items: center;
`;

const MainComment = styled.div`
  ${Typography.Heading2}
  text-align: center;
`;

const StateComment = styled.div`
  color: ${palette.marine};
  width: 100%;
  ${Typography.Heading2}
  text-align: center;
  color: ${palette.marine};
`;

const UserNameInputBox = styled.div`
  width: 80%;
  height: 64px;
  display: flex;
  justify-content: center;
  padding-right: 12px;
  padding-left: 21px;
  max-width: 360px;
  gap: 23px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 36px;
  border: 2px solid ${palette.blue};
  box-shadow: 0px 2px 10px 0px #c4c4c440;
  background-color: ${palette.background};
  margin: 0px auto;
  margin-bottom: 56px;
  margin-top: 32px;
`;

const UserNameInput = styled.div`
  width: 80%;
  max-width: 327px;
  padding-left: 10px;
  color: ${palette.blue};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  border: 0;
  background-color: transparent;
  margin: auto 0px;
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
`;

const BackArrowImage = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-right: 8px;
`;

const BackButton = styled.button`
  height: 24px;
  //   margin-top: 47.5px;
  display: flex;
  background-color: transparent;
  border: 0;
  ${Typography.Heading4}
  text-align: center;
  color: ${palette.gray};
`;

const NotNowButton = styled.button`
  width: 97px;
  max-width: 97px;
  height: 44px;
  background-color: ${palette.black};
  color: ${palette.white};
  border: 0;
  border-radius: 22px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin: auto 0px;
  align-items: right;
`;

const BottomContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const WalletButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MoreInfoCommand = styled.div`
  width: 90%;
  ${Typography.Heading4}
  text-align: center;
  margin: 0px auto;
  margin-top: 15px;
  color: ${palette.gray};
  margin-bottom: 200px;
`;

const CreateAccountConnectWallet = (props) => {
  const [userName, setUserName] = useState(props.userName);
  const [walletAddress, setWalletAddress] = useState("");
  const [walletId, setWalletId] = useState(0);
  const [signUpDone, setSignUpDone] = useState(false);

  // useEffect(() => {}, []);

  useEffect(() => {
    if (walletAddress != "") {
      (async () => {
        console.log("ra");
        const createUserId = await createUser(userName, walletAddress, walletId)
          .then((data) => {
            console.log(data);
            setSignUpDone(true);
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }
  }, [walletAddress]);

  const backButtonOnClick = () => {
    props.setPageStack(false);
  };

  return (
    <React.Fragment>
      {signUpDone ? (
        <CreateAccountConnectAnother
          setBackColor={props.setBackColor}
          walletAddress={walletAddress}
          walletId={walletId}
          userName={userName}
        />
      ) : (
        <ModalContainer>
          <ControlHeader>
            <BackButton onClick={backButtonOnClick}>
              <BackArrowImage src={BackArrow} />
              Go Back to Previous
            </BackButton>
          </ControlHeader>
          <div className={"f-column a-center"}>
            <ContentContainer>
              <LogoContainer>
                <StateComment>Success!</StateComment>
                <MainComment>Your User ID is as follows.</MainComment>
              </LogoContainer>
              <UserNameInputBox>
                <UserNameInput>{userName}</UserNameInput>
                <ModifiedCheck src={Valid} />
              </UserNameInputBox>
              <MainComment>
                Please verify your wallet address.
                <br />
                This will be used for login process.
              </MainComment>
              <WalletButtonContainer>
                <WalletButtonGroup
                  setPageStack={setWalletAddress}
                  setWalletId={setWalletId}
                />
              </WalletButtonContainer>
              <MoreInfoCommand>
                After creating an account, you can add or edit the wallet
                address to use for login.
              </MoreInfoCommand>
            </ContentContainer>
          </div>
        </ModalContainer>
      )}
    </React.Fragment>
  );
};

export default CreateAccountConnectWallet;
