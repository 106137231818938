import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { sendComment } from "../../../../utils/api/comments";

const BoxContainer = styled.div`
  width: 90%;
  max-width: 856px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
`;

const CommentCardHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 4px;
  height: 24px;
  align-items: center;
  margin-bottom: 40px;
  margin-left: 30px;
`;

const CommentProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid ${palette.light_gray};
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
  color: ${palette.black};
`;

const CommentCustomName = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
  color: ${palette.gray};
`;

const CommentBox = styled.div`
  width: 100%;
  height: 55px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
`;

const CommentTextBox = styled.textarea`
  width: 90%;
  max-width: 400px;
  height: 120px;
  background-color: ${palette.background};
  border: hidden;
  border-radius: 8px;
  padding: 13px 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  resize: none;
`;

const ButtonContainer = styled.div`
  width: 93%;
  display: flex;
  justify-content: right;
  margin-top: 24px;
  gap: 18px;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 10;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.white};
  background-color: ${palette.marine};
  z-index: 10;
`;

function CommentFriend({
  className,
  onClose,
  maskClosable,
  visible,
  userImg,
  userId,
  userName,
  friendId,
}) {
  const [comment, setComment] = useState("");

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const commentOnChange = (e) => {
    setComment(e.target.value);
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  const sendOnClick = async () => {
    const sendCommentResult = await sendComment(userId, friendId, comment)
      .then((data) => {
        console.log(data);
        if (data?.code == 200 && data?.isSuccess == true) {
          alert("Send the comment successfully!");
          onClose();
        } else {
          alert("Something's wrong! Please try again.");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Something's wrong! Please try again.");
      });
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>Comment Friend</PopupTitle>
            <BoxContainer>
              <CommentCardHeader>
                <CommentProfileImage src={userImg} />
                <CommentUserId>@{userId}</CommentUserId>
                <CommentCustomName>{userName}</CommentCustomName>
              </CommentCardHeader>
              <CommentTextBox
                value={comment}
                onChange={(e) => commentOnChange(e)}
                placeholder="Leave a comment"
              />
            </BoxContainer>

            <ButtonContainer>
              <LeftButton onClick={closeOnClick}>Cancel</LeftButton>
              <RightButton onClick={sendOnClick}>Send</RightButton>
            </ButtonContainer>

            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

CommentFriend.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 440;
  min-height: 440px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 36px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

const BottomButton = styled.button`
  width: 187px;
  height: 42px;
  border-radius: 21px;
  background-color: #4673e9;
  color: #ffffff;
  font-family: NeoDunggeunmo Pro;
  font-size: 18px;
  border: 0px;
  margin-top: 34px;
  position: absolute;
  right: 40px;
  z-index: 5;
  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(70, 115, 233, 0.5);
  }
`;

export default CommentFriend;
