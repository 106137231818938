import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { GasPrice } from "@cosmjs/stargate";
require("dotenv").config();

const RPC = "https://rpc.constantine-1.archway.tech:443"; // Or, titus deployments: `const RPC = "https://rpc.titus-1.archway.tech:443";`
const BECH32_PREFIX = "archway";
const userAddress = process.env.ACCOUNT_ADDRESS; // Your account address (pub address for mnemonic)
// const mnemonic = process.env.ACCOUNT_MNEMONIC; // Your test mnemonic
const mnemonic =
  "output change inhale junk green food devote universe material tray maple people";

let user, CosmWasmClient, queryHandler, gasPrice;
const init = async () => {
  // User wallet
//   console.log(mnemonic);
  user = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic, {
    prefix: BECH32_PREFIX,
  });
  // RPC access
  CosmWasmClient = await SigningCosmWasmClient.connectWithSigner(RPC, user);
  // Reference to querying a contract using the 'smart' query module
  queryHandler = CosmWasmClient.queryClient.wasm.queryContractSmart;
  // Gas price
  gasPrice = GasPrice.fromString("0.002uconst");

  console.log("dApp Initialized", {
    user: user,
    client: CosmWasmClient,
    queryHandler: queryHandler,
    gasPrice: gasPrice,
  });
};

export default init;
