import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { setPinnedComment } from "../../../../utils/api/comments";

const BoxContainer = styled.div`
  width: 90%;
  max-width: 600px;
  height: 600px;
  min-height: 540px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const CommentCard = styled.button`
  width: 96%;
  height: 132px;
  text-align: center;
  margin: 12px;
  line-height: 100px;
  // box-shadow: 0px 2px 10px #c4c4c440;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${palette.background};
  position: relative;
  padding: 20px;
  border: hidden;
  position: relative;
`;

const SelectedCard = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000055;
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: justified;
  border: hidden;
  border-radius: 8px;
`;

const CommentCardHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 4px;
  height: 24px;
`;

const CommentProfileImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
  color: ${palette.black};
`;

const CommentCustomName = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
  color: ${palette.gray};
`;

const CommentBox = styled.div`
  width: 100%;
  height: 55px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
  color: ${palette.black};
`;

const CreatedDate = styled.div`
  width: 100%;
  text-align: right;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  color: ${palette.gray};
`;

const ButtonContainer = styled.div`
  // width: 93%;
  display: flex;
  justify-content: right;
  gap: 18px;
`;

const BottomContainer = styled.div`
  width: 84%;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 10;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.white};
  background-color: ${palette.marine};
  z-index: 10;
`;

const SelectedNumber = styled.div`
  ${Typography.Heading2}
  color: ${palette.marine};
`;

function PinnedComments({
  className,
  onClose,
  maskClosable,
  visible,
  comments,
}) {
  const [commentList, setCommentList] = useState(comments);
  const [visibleList, setVisibleList] = useState([]);
  const [initVisibleList, setInitVisibleList] = useState([]);

  useEffect(() => {
    console.log(comments);
    var tmpList = visibleList;
    comments.map((val, idx) => {
      if (val.pinned) {
        tmpList.push(val.commentIdx);
      }
    });
    const set = new Set(tmpList);
    setVisibleList([...set]);
    setInitVisibleList([...set]);
  }, [comments]);

  useEffect(() => {
    console.log(visibleList);
  }, [visibleList]);

  const commentOnClick = (idx) => {
    var tmpList = visibleList;
    if (tmpList.includes(idx)) {
      tmpList.splice(tmpList.indexOf(idx), 1);
    } else {
      if (visibleList.length <= 2) {
        tmpList.push(idx);
      }
    }
    const set = new Set(tmpList);
    setVisibleList([...set]);
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  const doneOnClick = async () => {
    console.log(visibleList);
    const setUnpinnedCommentResult = await setPinnedComment(
      localStorage.getItem("userID"),
      initVisibleList,
      "false"
    ).then(async () => {
      const setPinnedCommentResult = await setPinnedComment(
        localStorage.getItem("userID"),
        visibleList,
        "true"
      ).then((data) => {
        console.log(data);
        onClose();
      });
    });
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>Select Pinned Comments</PopupTitle>
            <BoxContainer>
              {comments.map((val, idx) => (
                <CommentCard onClick={() => commentOnClick(val.commentIdx)}>
                  {visibleList.includes(val.commentIdx) ? (
                    <SelectedCard>Selected</SelectedCard>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <CommentCardHeader>
                    <CommentProfileImage src={val.profileImg} />
                    <CommentUserId>{val.friendNickname}</CommentUserId>
                    <CommentCustomName>@{val.commentFrom}</CommentCustomName>
                  </CommentCardHeader>
                  <CommentBox>{val.commentMessage}</CommentBox>
                  <CreatedDate>{val.createdAt}</CreatedDate>
                </CommentCard>
              ))}
            </BoxContainer>

            <BottomContainer>
              <SelectedNumber>{visibleList.length}/3 Selected</SelectedNumber>
              <ButtonContainer>
                <LeftButton onClick={closeOnClick}>Cancel</LeftButton>
                <RightButton onClick={doneOnClick}>Done</RightButton>
              </ButtonContainer>
            </BottomContainer>

            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

PinnedComments.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 680px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 800px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 36px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

const BottomButton = styled.button`
  width: 187px;
  height: 42px;
  border-radius: 21px;
  background-color: #4673e9;
  color: #ffffff;
  font-family: NeoDunggeunmo Pro;
  font-size: 18px;
  border: 0px;
  margin-top: 34px;
  position: absolute;
  right: 40px;
  z-index: 5;
  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(70, 115, 233, 0.5);
  }
`;

export default PinnedComments;
