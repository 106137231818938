import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import LogoImage from "../../../assets/images/main_logo_image.svg";
import AlarmIcon from "../../../assets/icons/dashboard/icon-alert.svg";
import SearchIcon from "../../../assets/icons/dashboard/icon-search.svg";
import Hamburger from "hamburger-react";
import Sidebar from "react-sidebar";
import { checkUnreadNoti } from "../../../utils/api/notifications";

const HeaderContainer = styled.div`
  width: 100%;
  height: 48px;
  background-color: ${palette.white};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  box-shadow: 0px 2px 10px #c4c4c440;
  z-index: 10;
  position: fixed;
  top: 0px;
`;

const LogoButton = styled.button`
  width: 92;
  height: 24px;
  margin: auto 0px;
  background-color: transparent;
  border: 0;
`;

const LogoImageContainer = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin: auto 0px;
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-right: 13px;
`;

const AlarmButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${palette.white};
  border: 0;
  box-shadow: 0px 2px 10px #c4c4c440;
  position: relative;
  margin: auto 0px;
`;

const SearchButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${palette.white};
  border: 0;
  box-shadow: 0px 2px 10px #c4c4c440;
  position: relative;
  margin: auto 0px;
`;

const AlarmImage = styled.img`
  width: 24px;
  height: 24px;
  margin: auto;
`;

const SearchImage = styled.img`
  width: 24px;
  height: 24px;
  margin: auto;
`;

const AlarmPoint = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${palette.light_red};
  position: absolute;
  right: 0;
  top: 0;
  margin-left: 2px;
  margin-bottom: 2px;
`;

const SidebarContainer = styled.div`
  width: 300px;
  height: 700px;
  color: black;
  background-color: white;
`;

const SidebarLine = styled.div`
  border-bottom: 1px solid #dddddd;
  width: 100%;
  height: 3px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
`;

const SidebarContext = styled.button`
  width: auto;
  height: 70px;
  border: 0;
  background-color: white;
  text-align: left;
  padding-left: 4%;
  font-size: 18px;
  font-weight: 500;
  font-family: Apple SD Gothic Neo, Spoqa Han Sans Neo, sans-serif;
  font-style: normal;
`;

const MobileHeader = (props) => {
  const [profileImg, setProfileImg] = useState(props.profileImg);
  const [newNotiExist, setNewNotiExist] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [auth, setAuth] = useState(true);

  useEffect(() => {
    setProfileImg(props.profileImg);
    if(!props.profileImg){
      // 기본 프로필 이미지로 설정
    }
  }, [props.profileImg]);

  useEffect(() => {
    // 새로운 noti가 있는지 체크하는 api 호츌
    (async () => {
      if (localStorage.getItem("userID")) {
        const checkUnreadNotiResult = await checkUnreadNoti(
          localStorage.getItem("userID")
        ).then((data) => {
          setNewNotiExist(data);
          //true : 남은 거 있음
          //false: 남은 거 없음
        });
      }
    })();
  }, []);

  const menuName = ["Leaderboard", "My Page"];

  const moveToPage = (index) => {
    if (index == 0) {
      window.location.href = "/leaderboard";
    } else if (index == 1) {
      window.location.href = "/mypage";
    } else {
      alert("서비스 준비중입니다.");
    }
  };

  const logoOnClick = () => {
    window.location.href = "/mypage"
  }

  const alarmOnClick = () => {
    window.location.href = "/noti";
  };

  return (
    <HeaderContainer>
      {isOpen ? (
        <Sidebar
          style={{ position: "fixed", zIndex: "5", height: "100%" }}
          sidebar={
            <div style={{ position: "relative", zIndex: "5", height: "100%" }}>
              <SidebarContainer
                style={{ position: "relative", zIndex: "5", height: "100%" }}
              >
                <SidebarLine />
                {menuName.map((value, index) => (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{ paddingTop: "23px", paddingLeft: "30px" }}
                    ></div>
                    <SidebarContext
                      onClick={() => {
                        moveToPage(index);
                      }}
                    >
                      {value}
                    </SidebarContext>
                  </div>
                ))}
              </SidebarContainer>
            </div>
          }
          open={isOpen}
          onSetOpen={setOpen}
          styles={
            ({ sidebar: { background: "white" } },
            { root: { height: "100vh" } })
          }
          pullRight={false}
        ></Sidebar>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Hamburger
        size={16}
        style={{ width: "12px", height: "12px" }}
        toggled={isOpen}
        toggle={setOpen}
      />
      <LogoButton onClick={logoOnClick}>
        <LogoImageContainer src={LogoImage} />
      </LogoButton>
      <ButtonContainer>
        {/* <TextButton>Discover Badges</TextButton>
        <TextButton>My Page</TextButton> */}
        <SearchButton>
          <SearchImage src={SearchIcon} />
        </SearchButton>
        <AlarmButton onClick={alarmOnClick}>
          {newNotiExist ? <AlarmPoint /> : <React.Fragment></React.Fragment>}
          <AlarmImage src={AlarmIcon} />
        </AlarmButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default MobileHeader;
