import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import LogoImage from "../../../assets/images/main_logo_image.svg";

const Container = styled.div`
  width: 100vw;
  height: auto;
  background-color: ${palette.footer};
  display: flex;
  justify-content: center;
  padding: 30px 0px;
`;

const InnerContainer = styled.div`
  width: 90%;
`;

const LogoBox = styled.img`
  width: 144px;
  margin-bottom: 10px;
`;

const CompanyInfo = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  margin-bottom: 10px;
`;

const ContactInfo = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
`;

const MobileFooter = () => {
  return (
    <Container>
      <InnerContainer>
        <LogoBox src={LogoImage} />
        <CompanyInfo>
          Privacy Policy | Terms of service
          <br />
          <div style={{ fontWeight: "600" }}>
            © 2022 Blockwave Labs. All rights reserved.
          </div>
        </CompanyInfo>
        <ContactInfo>Contact: contact@blockwavelabs.io</ContactInfo>
      </InnerContainer>
    </Container>
  );
};

export default MobileFooter;
