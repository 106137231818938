import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../components/global/Header/index";
import Footer from "../../../components/global/Footer";
import { COLORS as palette } from "../../../utils/styles/colors";
import MainBanner from "./components/MainBanner";
import UserRank from "./components/UserRank";
import useWindowDimensions from "../../../utils/functions/useWindowDimensions";
import { getRank } from "../../../utils/api/leader";
import { followingList } from "../../../utils/api/friends";
import MiddleAd from "../../../assets/images/middleAd.svg";
import SideAd1 from "../../../assets/images/sideAd1.svg";
import SideAd2 from "../../../assets/images/sideAd2.svg";
import MyRank from "./components/MyRank";

const ContentContainer = styled.div`
  width: 95%;
  max-width: 1440px;

  margin-top: 28px;
`;

const LeftContainer = styled.div`
  width: 70%;
  max-width: 1080px;
`;

const RightContainer = styled.div`
  width: 30%;
  max-width: 330px;
  margin-left: 30px;
`;

const ContentContainerMobile = styled.div`
  width: 95%;
  margin-top: 28px;
`;

const MiddleAdContainer = styled.img`
  width: 100%;
  border-radius: 16px;
`;

const SideAdContainer = styled.img`
  width: 100%;
  margin-top: 10px;
`;

const LeaderboardPage = () => {
  const { height, width } = useWindowDimensions();
  const [userID, setUserID] = useState(localStorage.getItem("userID"));
  const [rankList, setRankList] = useState([]);
  const [topCreatedAt, setTopCreatedAt] = useState({});
  const [myRecord, setMyRecord] = useState({});
  const [topTodayFollows, setTopTodayFollows] = useState({});
  const [topTodayHits, setTopTodayHits] = useState({});
  const [order, setOrder] = useState(1);
  const [myFollowingList, setMyFollowingList] = useState([]);
  const [followIdList, setFollowIdList] = useState([]);

  useEffect(() => {
    (async () => {
      // if (userID) {
      const getRankResult = await getRank(order, userID).then((data) => {
        console.log(data.userList);
        setRankList(data.userList);
        setTopCreatedAt(data.topCreatedAt);
        setTopTodayFollows(data.topTodayFollows);
        setTopTodayHits(data.topTodayHits);
        setMyRecord(data.myRecord);
      });
      // }
    })();
  }, [order]);

  useEffect(() => {
    (async () => {
      if (userID) {
        const getFollowingList = await followingList(userID).then((data) => {
          setMyFollowingList(data);
          var tmpFollowingIdList = data.map((val) => {
            return val.id;
          });
          setFollowIdList(tmpFollowingIdList);
        });
      }
    })();
  }, []);

  useEffect(() => {}, [rankList]);

  return (
    <div
      className={"f-column a-center"}
      style={{ backgroundColor: palette.background }}
    >
      <Header
        profileImg={JSON.parse(localStorage.getItem("userInfo"))?.profileImg}
      />
      <MainBanner />
      {width > 600 ? (
        <ContentContainer style={{ display: "flex" }}>
          <LeftContainer>
            {/* <MiddleAdContainer src={MiddleAd} /> */}
            <UserRank
              userID={userID}
              userList={rankList}
              followingList={followIdList}
              setOrder={setOrder}
            />
          </LeftContainer>
          <RightContainer>
            <MyRank myrecord={myRecord} />
            <SideAdContainer src={SideAd1} />
            <SideAdContainer src={SideAd2} />
          </RightContainer>
        </ContentContainer>
      ) : (
        <ContentContainer>
          <MyRank myrecord={myRecord} />
          <SideAdContainer src={SideAd1} />
          <SideAdContainer src={SideAd2} />
          <UserRank
            userID={userID}
            userList={rankList}
            followingList={followIdList}
            setOrder={setOrder}
          />
        </ContentContainer>
      )}
      <Footer />
    </div>
  );
};

export default LeaderboardPage;
