import { acceptFriendReq } from "../../../../utils/api/friends";
import { getFriendInfo } from "../../../../utils/api/comments";
import { getTokenRequestDetail } from "../../../../utils/api/wallets";
import WalletList from "../../../../datas/WalletListData";

const friendAcceptAction = async (
  reqFrom,
  reqTo,
  reqNickname,
  setIsAccept,
  setAcceptNotiInfo
) => {
  console.log(reqFrom, reqTo, reqNickname, setIsAccept, setAcceptNotiInfo);
  const acceptFriendReqResult = await acceptFriendReq(
    reqFrom,
    reqTo,
    reqNickname
  ).then(async (data) => {
    console.log(data);
    if (data.code == 200) {
      const getFriendInfoResult = await getFriendInfo(reqFrom, reqTo).then(
        (info) => {
          console.log(info);
          setIsAccept(true);
          setAcceptNotiInfo(info);
        }
      );
    }
  });
  // 친구 수락 api 호출
};

const beFriendSuccessfullyAction = () => {
  // 그 친구 홈페이지로 이동하는 기능 수행
};

const welcomeAcceptAction = () => {
  // 머 딱히 암것도 안함
  // 이 알람을 이제 확인했다고 체크
};

const getCommentAcceptAction = () => {
  // 그 코멘트 확인하러 마이페이지 또는 댓글페이지로 이동
};

const getFollowAcceptAction = () => {
  // 이 알람을 이제 확인했다고 체크? 또는 + 그 팔로워 페이지로 방문?
};

const getWelcomePoap = () => {
  window.location.href = "http://POAP.xyz/claim/3x0wub";
};

const acceptTokenRequest = async (
  setDetailBox,
  setSendBox,
  setType,
  setRequestInfo,
  notiIdx
) => {
  // setDetailBox();
  const getTokenRequestDetailResult = await getTokenRequestDetail(
    notiIdx,
    localStorage.getItem("userID")
  ).then((data) => {
    console.log(data);
    console.log(JSON.parse(localStorage.getItem("walletList")));
    var confirmInfo = {
      receiverAddress: data.reqWalletAddress,
      senderAddress: JSON.parse(localStorage.getItem("walletList"))[0]
        .walletAddress,
      receiverIcon:
        WalletList[WalletList.findIndex((v) => v.walletName == data.walletType)]
          .walletIcon,
      senderIcon: JSON.parse(localStorage.getItem("walletList"))[0].walletIcon,
      amount: data.reqTokenAmount,
      chainId: data.chainID,
      currency: data.udenom,
      memo: data.memo,
      myImg: data.reqToDetail.profileImg,
      friendImg: data.reqFromDetail.profileImg,
      friendId: data.reqFromDetail.id,
      index: data.index,
    };
    console.log(confirmInfo);
    setRequestInfo(confirmInfo);
  });
  setType("send-in-noti");
  setSendBox(true);
  // alert("Coming soon!");
};

const acceptTokenRequestResend = async (
  setDetailBox,
  setSendBox,
  setType,
  setRequestInfo,
  notiIdx
) => {
  // setDetailBox();
  const getTokenRequestDetailResult = await getTokenRequestDetail(
    notiIdx,
    localStorage.getItem("userID")
  ).then((data) => {
    console.log(data);
    console.log(JSON.parse(localStorage.getItem("walletList")));
    var confirmInfo = {
      senderAddress: data.reqWalletAddress,
      receiverAddress: JSON.parse(localStorage.getItem("walletList"))[0]
        .walletAddress,
      receiverIcon:
        WalletList[WalletList.findIndex((v) => v.walletName == data.walletType)]
          .walletIcon,
      // senderIcon: JSON.parse(localStorage.getItem("walletList"))[0].walletIcon,
      senderIcon:
        WalletList[WalletList.findIndex((v) => v.walletName == data.walletType)]
          .walletIcon,
      amount: data.reqTokenAmount,
      chainId: data.chainID,
      currency: data.udenom,
      memo: data.memo,
      myImg: data.reqToDetail.profileImg,
      friendImg: data.reqFromDetail.profileImg,
      // friendId: data.reqFromDetail.id,
      friendId: data.toUser,
      index: data.index,
    };
    console.log(confirmInfo);
    setRequestInfo(confirmInfo);
  });
  setType("request-in-noti");
  setSendBox(true);
  // alert("Coming soon!");
};

const NotiForm = [
  {},
  {
    NotiType: 1,
    NotiTitle: "Welcome",
    NotiAcceptButtonName: "Check",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: welcomeAcceptAction,
  },
  {
    NotiType: 2,
    NotiTitle: "Friend Request",
    NotiAcceptButtonName: "Accept",
    NotiDeniedButtonName: "Deny",
    NotiAcceptAction: friendAcceptAction,
  },
  {
    NotiType: 3,
    NotiTitle: "Friend Request Accepted",
    NotiAcceptButtonName: "",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: beFriendSuccessfullyAction,
  },

  {
    NotiType: 4,
    NotiTitle: "New Comment",
    NotiAcceptButtonName: "",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: getCommentAcceptAction,
  },
  {
    NotiType: 5,
    NotiTitle: "New Follower",
    NotiAcceptButtonName: "",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: getFollowAcceptAction,
  },
  {
    NotiType: 6,
    NotiTitle: "Event",
    NotiAcceptButtonName: "",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: getWelcomePoap,
  },
  {
    NotiType: 7,
    NotiTitle: "Receive Token",
    NotiAcceptButtonName: "",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: getWelcomePoap,
  },
  {
    NotiType: 8,
    NotiTitle: "Token Request",
    NotiAcceptButtonName: "Send",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: acceptTokenRequest,
  },
  {
    NotiType: 9,
    NotiTitle: "Token Request Declined",
    NotiAcceptButtonName: "Resend",
    NotiDeniedButtonName: "Later",
    NotiAcceptAction: acceptTokenRequestResend,
  },
];

export default NotiForm;
