import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import { followerList } from "../../../../utils/api/friends";
import Typography from "../../../../utils/styles/typography";

const FollowButton = styled.button`
  max-width: 420px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${palette.gray};
  padding: 8px 16px 8px 16px;
  ${Typography.Heading3}
  margin: 0px auto;
  width: 100%;
`;

const BoxContainer = styled.div`
  width: 90%;
  height: 600px;
  max-width: 856px;
  min-height: 288px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SideContainer = styled.div`
  display: flex;
  margin: auto 0px;
`;

const CommentCardHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 4px;
  height: 24px;
`;

const CommentProfileImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const CommentInfoBox = styled.div`
  align-self: center;
  margin-left: 12px;
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
`;

const CommentCustomName = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
`;

function FollowerList({ className, onClose, maskClosable, visible, userName }) {
  const [personList, setPersonList] = useState([]);

  useEffect(() => {
    (async () => {
      const followerListResult = await followerList(userName).then((data) => {
        console.log(data);
        setPersonList(data);
      });
    })();
  }, [userName]);
  const handleCopyClipBoard = async (text) => {
    var textarea = document.createElement("textarea");
    textarea.value = text; // 복사할 메시지
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 9999); // For IOS
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("링크 복사 완료!");
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  const userOnClick = (id) => {
    window.location.href = `/@${id}`;
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>{personList.length} Followers</PopupTitle>
            <BoxContainer>
              {personList.map((val, idx) => (
                <FollowButton onClick={() => userOnClick(val.id)}>
                  <SideContainer>
                    <CommentCardHeader>
                      <CommentProfileImage src={val.profileImage} />
                      <CommentInfoBox>
                        <CommentUserId>@{val.id}</CommentUserId>
                        <CommentCustomName>{val.nickname}</CommentCustomName>
                      </CommentInfoBox>
                    </CommentCardHeader>
                  </SideContainer>
                  <SideContainer></SideContainer>
                </FollowButton>
              ))}
            </BoxContainer>
            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

FollowerList.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 740px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 36px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

export default FollowerList;
