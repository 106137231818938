import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonBoard from "../../../components/ButtonBoard";
import Header from "../../../components/global/Header/index";
import { COLORS as palette } from "../../../utils/styles/colors";
import ChainInfo from "../../../data/chainInfo";
import MyTokens from "./components/MyTokens";
import CosmosBg from "../../../assets/images/cosmosBackground.svg";
import SpaceBg from "../../../assets/images/spaceBackground.svg";
import MyCosmosNft from "./components/MyCosmosNft";

const PageHeader = styled.div`
  width: 100%;
  height: 320px;
  border: 0;
  background-color: ${palette.black};
  background-image: url(${CosmosBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
`;

const PageBackground = styled.div`
  background-color: ${palette.black};
  background-image: url(${SpaceBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const HeaderTitle = styled.div`
  width: 90%;
  height: auto;
  max-width: 1080px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  color: ${palette.white};
  margin-top: 220px;
`;

const Web3Page = () => {
  const [userInfo, setUserInfo] = useState({
    profileImage: localStorage.getItem("profileImage"),
  });
  const [walletList, setWalletList] = useState([
    {
      walletName: "Keplr",
      walletId: 1,
      tokens: ChainInfo,
    },
    {
      walletName: "Cosmostation",
      walletId: 2,
      tokens: ChainInfo,
    },
  ]);

  useEffect(() => {
    setUserInfo({
      profileImage: localStorage.getItem("profileImage"),
    });
  }, [localStorage.getItem("profileImage")]);
  return (
    <React.Fragment>
      <PageBackground className={"f-column a-center"}>
        <Header profileImg={userInfo?.profileImage} />
        <PageHeader>
          <HeaderTitle>Cosmos🪐</HeaderTitle>
        </PageHeader>
        <MyTokens wallet={walletList} />
        <MyCosmosNft />
        {/* <ButtonBoard /> */}
      </PageBackground>
    </React.Fragment>
  );
};

export default Web3Page;
