import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import { MdPeopleOutline, MdOutlineFileDownload } from "react-icons/md";
import { CSVLink, CSVDownload } from "react-csv";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import axios from "axios";

const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  min-height: 500px;
  background-color: ${palette.white};
  border-radius: 40px;
  padding: 30px;
  margin-top: 32px;
`;

const TitleBox = styled.div`
  margin-top: 8px;
  margin-left: 20px;
  ${Typography.Heading2}
  margin-bottom: 20px;
`;

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 13px;
`;

const ProfileName = styled.div`
  margin: auto 0px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MembersInfoHeadBox = styled.div`
  width: 100%;
  //   margin: 0px auto;
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const MembersInfoHeadLeft = styled.div`
  font-family: Roboto Mono;
  font-size: 14px;
  color: ${palette.gray};
  display: flex;
`;

const MembersTitle = styled.div`
  font-family: Roboto Mono;
  font-size: 18px;
  color: #c4c4c4;
  margin: auto 0px;
`;

const CSVDownloadButton = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: 19px;
  font-size: 22px;
  color: #c4c4c4;
`;

const MembersInfoHeadRight = styled.div`
  font-family: Roboto Mono;
  font-size: 12px;
  color: #ffffff;
`;

const TxnHashContent = styled.div`
  display: flex;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const Styles = styled.div`
  //   padding: 1rem;
  width: 100%;
  color: ${palette.gray};
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 500;
  table {
    border-spacing: 0;
    border: 0;
    width: 100%;
    thead {
        tr {
            th {
                color: ${palette.gray};
                padding-bottom: 18px;
                border-bottom: 1px solid #c4c4c4;
                text-align: left;
                :first-child {
                    padding-left: 20px;
                    text-align: left;
                }
                :last-child {
                  text-align: right;
                  padding-right: 20px;
                }
            }
        }
    },
    td {
      margin: 0;
      padding-top: 17px;
      padding-bottom: 17px;
      border-bottom: 1px solid #c4c4c4;
      border-right: 0;
      :first-child {
        // color: #ffffff;
        // font-size: 24px;
        // font-family: NeoDunggeunmo Pro;
        padding-left: 20px;
        display: flex;
      },
    }
  }
  .pagination {
    width: 100%;
    padding: 0.5rem;
    padding-bottom: 200px;
    text-align: center;
  }
`;

const ConvertCsvButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 36px;
  border-radius: 45px;
  border: 2px solid ${palette.black};
  background-color: ${palette.white};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: center;
`;

const ButtonBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const TransactionButtonBox = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
`;

const TransactionButton = styled.div`
  padding: 5px 20px;
  font-family: Roboto Mono;
  font-size: 14px;
  color: #777777;
  text-align: center;
`;

function relativeDays(timestamp) {
  if (timestamp) {
    var date = new Date(+timestamp * 1000);
    const rtf = new Intl.RelativeTimeFormat("en", {
      numeric: "auto",
    });
    const oneDayInMs = 1000 * 60 * 60 * 24;
    console.log(date);
    console.log(date.getTime());
    const daysDifference = Math.round(
      (date.getTime() - new Date().getTime()) / oneDayInMs
    );

    return rtf.format(daysDifference, "day");
  }
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  console.log(page);

  // Render the UI for your table
  return (
    <React.Fragment>
      <pre></pre>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {index == 0 || index == 2 || index == 3 ? (
                        <React.Fragment>
                          <TxnHashContent>
                            {/* <ProfileName>{cell.render("Cell")}</ProfileName> */}
                            <ProfileName>{data[i][index]}</ProfileName>
                          </TxnHashContent>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {/* <ProfileName>{cell.render("Cell")}</ProfileName> */}
                          {index == 4 ? (
                            <ProfileName
                              style={{
                                color: palette.black,
                                fontWeight: "700",
                              }}
                            >
                              {data[i][index] / Math.pow(10, 18) + " MATIC"}
                            </ProfileName>
                          ) : (
                            <React.Fragment>
                              {index == 6 ? (
                                <ProfileName>
                                  {relativeDays(data[i][index])}
                                </ProfileName>
                              ) : (
                                <ProfileName>{data[i][index]}</ProfileName>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </React.Fragment>
  );
}

function TransactionTable(props) {
  const [transactionClickStatus, setTransactionClickStatus] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.walletAddress != "") {
      (async () => {
        const result = await axios
          .get(
            `https://api.polygonscan.com/api?module=account&action=txlist&address=${
              props.walletAddress
            }&startblock=0&endblock=99999999&page=1&offset=10&sort=asc&apikey=${"53NM1NGFKJ8WND2UJRK5NPEZ3UIUK1AB3A"}`,
            {
              headers: {
                "Content-Type": "appliction/json",
              },
            }
          )
          .then((data) => {
            console.log(data);
            var tmpData = [];
            var tableColumns = [
              "hash",
              "functionName",
              "from",
              "to",
              "value",
              "cumulativeGasUsed",
              "timeStamp",
            ];
            data?.data?.result?.map((value, index) => {
              var tmpRow = [];
              tableColumns.map((val2, idx2) => {
                tmpRow.push(value[val2]);
              });
              tmpData.push(tmpRow);
            });
            setData(tmpData);
            console.log(tmpData);
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }
  }, [props.walletAddress]);

  const TransactionButtonList = [
    "Transactions",
    "Internal Txns",
    "Erc20 Token Txns",
    "Erc721 Token Txns",
    "Erc1155 Token Txns",
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "TxnHash",
        accessor: "TxnHash",
      },
      {
        Header: "Method",
        accessor: "Method",
      },
      {
        Header: "From",
        accessor: "From",
      },
      {
        Header: "To",
        accessor: "To",
      },
      {
        Header: "Value",
        accessor: "Value",
      },
      {
        Header: "Tnx Fee",
        accessor: "Tnx Fee",
      },
      {
        Header: "Time",
        accessor: "Time",
      },
    ],
    []
  );

  // const data = [
  //   [
  //     "firstname",
  //     "lastname",
  //     "email",
  //     "firstname",
  //     "lastname",
  //     "email",
  //     "email",
  //   ],
  // ];

  useEffect(() => {}, [transactionClickStatus]);

  return (
    <React.Fragment>
      <Container>
        <TitleBox>My Polygon Transactions</TitleBox>
        <Styles>
          <Table columns={columns} data={data} />
        </Styles>

        <MembersInfoHeadRight>
          <MembersInfoHeadLeft>
            <MembersTitle>Transactions</MembersTitle>
          </MembersInfoHeadLeft>
        </MembersInfoHeadRight>
        <ButtonBar>
          <CSVLink data={data} filename={"My Polygon Transactions"}>
            <ConvertCsvButton>
              Export CSV <MdOutlineFileDownload style={{ fontSize: "18px" }} />
            </ConvertCsvButton>
          </CSVLink>
        </ButtonBar>
      </Container>
    </React.Fragment>
  );
}

export default TransactionTable;
