import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import LogoImage from "../../../assets/images/main_logo_image.svg";

const Container = styled.div`
  width: 100vw;
  height: 180px;
  background-color: ${palette.footer};
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SideContainer = styled.div`
  display: flex;
  gap: 48px;
  height: 50px;
`;

const LogoBox = styled.img`
  width: 144px;
`;

const CompanyInfo = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
`;

const ContactInfo = styled.div`
  height: 100%;
  padding-top: 20px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
`;

const DesktopFooter = () => {
  return (
    <Container>
      <InnerContainer>
        <SideContainer>
          <LogoBox src={LogoImage} />
          <CompanyInfo>
            Privacy Policy | Terms of service
            <br />
            <div style={{ fontWeight: "600" }}>
              © 2022 Blockwave Labs. All rights reserved.
            </div>
          </CompanyInfo>
        </SideContainer>
        <SideContainer>
          <ContactInfo>Contact: contact@blockwavelabs.io</ContactInfo>
        </SideContainer>
      </InnerContainer>
    </Container>
  );
};

export default DesktopFooter;
