import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {COLORS as c} from "../../utils/styles/colors";
import {ReactComponent as DownArrow} from "../../assets/icons/icon-down_arrow.svg";
import Spacer from "../util/Spacer";
import SizeBox from "../util/SizeBox";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  width: 94%;
  height: 100%;
  background-color: ${c.background};
  border: solid ${c.gray} ${p => p.border ? '1px' : '0px'};
  border-radius: 8px;

  position: relative;
`;

const SelectedItem = styled.div`
  user-select: none;
  cursor: pointer;
  
  display: flex;
  align-items: center;
  
  width: calc(100%);
  height: 60px;

  line-height: 50px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;

  padding-left: 16px;
  border-radius: 10px;
  border: none;

  position: relative;
  transition: 0.2s;

  &:hover {
    background: ${c.light_gray};
  }
`;

const DownArrowIcon = styled(DownArrow)`
  top: -4px;
  transition: 0.2s;
  transform: rotate(${props => props.active === 'true' ? '0deg' : '-180deg'});
`;

const OptionWrapper = styled.div`
  width: 100%;
  max-height: 0px;

  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: ${c.white};
  transition: 0.2s;
  position: absolute;
  top: 60px;

  ${props => props.active === 'true' ? 'opacity: 1;\n  max-height: 130px;\n  z-index: 1;' : null}
  &::-webkit-scrollbar {
    width: 3px;
    background: ${c.light_gray};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${c.light_blue};
    border-radius: 3px;
  }
`;

const OptionItem = styled.div`
  user-select: none;
  cursor: pointer;
  
  display: flex;
  align-items: center;
  flex-direction: row;

  width: calc(100%);
  height: 60px;
  padding-left: 16px;
  background-color: ${props => props.selected ? c.light_gray : 'transparent'};

  line-height: 43px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: left;

  transition: 0.2s;

  &:hover {
    background-color: ${c.light_gray};
  }
`;


function CategorySelector({list = [], setCategory, border = false, ...props}) {
    const [active, setActive] = useState(false);
    const ref = useRef(null);

    const itemClickHandler = (value) => {
        setActive(false);
        setCategory(array => {
            if(!array.includes(value)) {
                return [...array, value];
            } else {
                return [...array];
            }
        });
    }

    const clickEvent = e => {
        if (ref.current !== null) {
            if (!ref.current.contains(e.target)) {
                setActive(false);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickEvent);

        return () => {
            window.removeEventListener('click', clickEvent);
        }
    }, []);

    return (
        <Container ref={ref} border={border}>
            <SelectedItem ref={ref} onClick={e => setActive(value => !value)}>
                Select
                <Spacer/>
                <DownArrowIcon active={active.toString()}/>

                <SizeBox w={20}/>
            </SelectedItem>

            <OptionWrapper active={active.toString()}>
                {list.length === 0 ? <OptionItem onClick={() => setActive(false)}>
                    Empty
                </OptionItem> : null}
                {
                    list.map((value, idx) => (
                        <OptionItem key={idx} onClick={() => itemClickHandler(value)}>
                            {value}
                        </OptionItem>
                    ))
                }
            </OptionWrapper>
        </Container>
    );
}

export default CategorySelector;