import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import Typography from "../../../utils/styles/typography";
import LogoImage from "../../../assets/images/main_logo_image.svg";
import AlarmIcon from "../../../assets/icons/dashboard/icon-alert.svg";
import ProfileDropbox from "./ProfileDropbox";
import NotificationBox from "./NotificationBox";
import { checkUnreadNoti } from "../../../utils/api/notifications";
import DefaultProfile from "../../../assets/icons/dashboard/icon-defaultProfile.svg";

const HeaderContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 40px;
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px #c4c4c440;
  z-index: 10;
  position: fixed;
  top: 0px;
`;

const LogoButton = styled.button`
  width: 173px;
  height: 48px;
  margin: auto 0px;
  background-color: transparent;
  border: 0;
`;

const LogoImageContainer = styled.img`
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.div`
  margin: auto 0px;
  display: flex;
  gap: 30px;
  position: relative;
`;

const TextButton = styled.button`
  ${Typography.Heading3}
  border: 0;
  background-color: transparent;
`;

const AlarmButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.white};
  border: 0;
  box-shadow: 0px 2px 10px #c4c4c440;
  position: relative;
  margin: auto 0px;
`;

const AlarmImage = styled.img`
  width: 24px;
  height: 24px;
  margin: auto;
`;

const AlarmPoint = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${palette.light_red};
  position: absolute;
  right: 0;
  top: 0;
  margin-left: 2px;
  margin-bottom: 2px;
`;

const ProfileButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${palette.white};
  border: 0;
  box-shadow: 0 0 0 1px ${palette.light_gray};
  overflow: hidden;
  margin: auto 0px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
`;

const DesktopHeader = (props) => {
  const [profileImg, setProfileImg] = useState(props.profileImg);
  const [newNotiExist, setNewNotiExist] = useState(true);
  const [dropBoxOn, setDropBoxOn] = useState(false);
  const [notiBoxOn, setNotiBoxOn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!props.profileImg) {
      // 기본 프로필 이미지로 설정
      setProfileImg(DefaultProfile);
    } else {
      setProfileImg(props.profileImg);
    }
  }, [props.profileImg]);

  useEffect(() => {
    // 새로운 noti가 있는지 체크하는 api 호츌
    (async () => {
      if (localStorage.getItem("userID")) {
        const checkUnreadNotiResult = await checkUnreadNoti(
          localStorage.getItem("userID")
        ).then((data) => {
          setNewNotiExist(data);
          //true : 남은 거 있음
          //false: 남은 거 없음
        });
      }
    })();
  }, []);

  const profileButtonOnClick = () => {
    setDropBoxOn(!dropBoxOn);
  };

  const mypageOnClick = () => {
    window.location.href = "/mypage";
  };

  const leaderboardpageOnClick = () => {
    window.location.href = "/leaderboard";
  };

  const financeOnClick = () => {
    window.location.href = "/finance";
  };

  const DiscoverBadgeOnClick = () => {
    window.location.href = "/minting";
  };

  const cosmosOnClick = () => {
    window.location.href = "/cosmos";
  };

  const solanaOnClick = () => {
    window.location.href = "/solana";
  };

  const polygonOnClick = () => {
    window.location.href = "/polygon";
  };

  const alarmOnClick = () => {
    // window.location.href = "/noti";
    if (location.pathname.startsWith("/noti")) {
      window.location.reload();
    } else {
      setNotiBoxOn(!notiBoxOn);
    }
  };

  const logoOnClick = () => {
    window.location.href = "/mypage";
  };

  return (
    <HeaderContainer>
      <LogoButton onClick={logoOnClick}>
        <LogoImageContainer src={LogoImage} />
      </LogoButton>
      <ButtonContainer>
        {/* <TextButton onClick={polygonOnClick}>
          <BetaIcon>Beta</BetaIcon>
          Polygon
        </TextButton> */}
        {/* <TextButton onClick={solanaOnClick}>
          <BetaIcon style={{ left: "137px" }}>Beta</BetaIcon>
          <BetaIcon>Beta</BetaIcon>
          Solana
        </TextButton> */}
        {/* <TextButton onClick={cosmosOnClick}>
          <BetaIcon style={{ left: "230px" }}>Beta</BetaIcon>
          Cosmos
        </TextButton> */}
        <TextButton onClick={financeOnClick}>
          {/* <BetaIcon style={{ left: "50px" }}>Beta</BetaIcon> */}
          Finance
        </TextButton>
        <TextButton onClick={DiscoverBadgeOnClick}>Discover Badges</TextButton>
        <TextButton onClick={leaderboardpageOnClick}>Leaderboard</TextButton>
        <TextButton onClick={mypageOnClick}>My Page</TextButton>
        <AlarmButton onClick={alarmOnClick}>
          {newNotiExist ? <AlarmPoint /> : <React.Fragment></React.Fragment>}
          <AlarmImage src={AlarmIcon} />
        </AlarmButton>
        {notiBoxOn ? (
          <NotificationBox
            visible={notiBoxOn}
            closable={true}
            maskClosable={true}
            onClose={alarmOnClick}
          />
        ) : (
          <></>
        )}
        <ProfileButton onClick={profileButtonOnClick}>
          <ProfileImage src={profileImg} />
        </ProfileButton>
        {dropBoxOn ? <ProfileDropbox /> : <React.Fragment></React.Fragment>}
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default DesktopHeader;
