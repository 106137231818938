import axios from "axios";
import WalletList from "../../datas/WalletListData";

export const createWallet = async (userID, walletAddress, walletType) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/wallets/create`,
      `{"jwtToken":"${localStorage.getItem(
        "token"
      )}", "userID":"${userID}", "walletAddress":"${walletAddress}", "walletType":"${
        WalletList[walletType].walletName
      }"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const deleteWallet = async (userID, walletAddress) => {
  let returnValue = "";
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/wallets/delete`,
      `{"jwtToken":"${localStorage.getItem(
        "token"
      )}", "userID":"${userID}", "walletAddress":"${walletAddress}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result;
    })
    .catch(() => {});

  return returnValue;
};

export const findUserId = async (walletAddress) => {
  let returnValue = 0;
  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST +
        `/wallets/users?walletAddress=${walletAddress}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.code == 404) {
        alert("Invalid wallet. Please connect with another wallet.");
        window.location.href = "/";
      }
      returnValue = data.data.result;
      if (!returnValue) {
        returnValue = data.data.code;
        //data.data.code = 404
      }
    });

  return returnValue;
};

export const remitmentTx = async (sendData) => {
  let returnValue = 0;
  console.log("내가 보내는 토큰", sendData.value);
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/wallet/transactions/remitment`,
      `{"jwtToken":"${localStorage.getItem("token")}", "toWalletAddress": "${
        sendData.toWalletAddress
      }", "fromWalletAddress": "${sendData.fromWalletAddress}", "toUser": "${
        sendData.toUser
      }", "fromUser": "${sendData.fromUser}", "gasPrice": "${
        sendData.gasPrice
      }", "gas": "${sendData.gas}", "value": "${sendData.value}", "chainID": "${
        sendData.chainID
      }", "memo": "${sendData.memo}", "udenom": "${
        sendData.udenom
      }", "walletType": "${sendData.walletType}", "txHash": "${
        sendData.txHash
      }"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const requestTx = async (sendData) => {
  let returnValue = 0;
  console.log("내가 보내는 토큰", sendData.reqTokenAmount);
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/wallet/transactions/request`,
      `{"jwtToken":"${localStorage.getItem("token")}", "reqWalletAddress": "${
        sendData.reqWalletAddress
      }", "reqTokenAmount": "${sendData.reqTokenAmount}", "toUser": "${
        sendData.toUser
      }", "fromUser": "${sendData.fromUser}", "chainID": "${
        sendData.chainID
      }", "memo": "${sendData.memo}", "udenom": "${
        sendData.udenom
      }", "walletType": "${sendData.walletType}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const getTx = async (userID) => {
  let returnValue = 0;
  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST +
        `/wallet/transactions/all?userID=${userID}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const getTokenRequest = async (userID) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/wallet/transactions/requests/all?userID=${userID}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data; // .requestTo, .requestFrom
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const cancelRequest = async (userID, tokenReqIdx) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/wallet/transactions/request/cancel?tokenReqIdx=${tokenReqIdx}`,
      `{"userID":"${userID}","jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data; // .requestTo, .requestFrom
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const getRequestStatus = async (userID, tokenReqIdx) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/wallet/transactions/request/status?tokenReqIdx=${tokenReqIdx}`,
      `{"userID":"${userID}","jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data; // .requestTo, .requestFrom
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const declineRequest = async (userID, tokenReqIdx) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/wallet/transactions/request/decline?tokenReqIdx=${tokenReqIdx}`,
      `{"userID":"${userID}","jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data; // .requestTo, .requestFrom
    })
    .catch(() => {
      //로그인을 다시 하게끔..?
      alert("Something is wrong.");
      //로그아웃하고 메인화면으로 가게끔?
    });

  return returnValue;
};

export const getTokenRequestDetail = async (notiIdx, userID) => {
  //이걸 호출해야 해당 noti가 확인완료 상태로 변경됨.
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/wallet/transactions/request/detail?tokenReqIdx=${notiIdx}`,
      `{"jwtToken":"${localStorage.getItem("token")}","user":"${userID}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        //   alert("Your session has ended. Please log in again.");
        //   localStorage.clear();
        //   window.location.href = "/";
      } else {
        returnValue = data.data.result; // true: 아직 안 읽은 거 있음 (빨강 표시) false: 다 확인.
      }
    })
    .catch(() => {});

  return returnValue;
};
