import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import DesktopFooter from "./DesktopFooter";
import MobileFooter from "./MobileFooter";
import useWindowDimensions from "../../../utils/functions/useWindowDimensions";

const Footer = () => {
  const { width, height } = useWindowDimensions();

  return (
    <React.Fragment>
      {width > 650 ? <DesktopFooter /> : <MobileFooter />}
    </React.Fragment>
  );
};

export default Footer;
