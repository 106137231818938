import axios from "axios";

export const getAllNoti = async (userID) => {
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/notification/all?userID=${userID}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      } else {
        returnValue = data.data.result; //noti List
      }
    })
    .catch(() => {});

  return returnValue;
};

export const checkUnreadNoti = async (userID) => {
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/notification/left?userID=${userID}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        //   alert("Your session has ended. Please log in again.");
        //   localStorage.clear();
        //   window.location.href = "/";
      } else {
        returnValue = data.data.result; // true: 아직 안 읽은 거 있음 (빨강 표시) false: 다 확인.
      }
    })
    .catch(() => {});

  return returnValue;
};

export const getNotiDetail = async (notiIdx, userID) => {
  //이걸 호출해야 해당 noti가 확인완료 상태로 변경됨.
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/notification?notiID=${notiIdx}`,
      `{"jwtToken":"${localStorage.getItem("token")}","userID":"${userID}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        //   alert("Your session has ended. Please log in again.");
        //   localStorage.clear();
        //   window.location.href = "/";
      } else {
        returnValue = data.data.result; // true: 아직 안 읽은 거 있음 (빨강 표시) false: 다 확인.
      }
    })
    .catch(() => {});

  return returnValue;
};

export const deleteListNoti = async (notiIdxArr, userID) => {
  //이걸 호출해야 해당 noti가 확인완료 상태로 변경됨.
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .delete(process.env.REACT_APP_DB_HOST + `/notifications`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: `{"jwtToken":"${localStorage.getItem(
        "token"
      )}","userID":"${userID}", "idxArr":[${notiIdxArr}]}`,
    })
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      } else {
        returnValue = data.data.result; // true: 아직 안 읽은 거 있음 (빨강 표시) false: 다 확인.
      }
    })
    .catch(() => {});

  return returnValue;
};

export const deleteAllNoti = async (userID) => {
  //이걸 호출해야 해당 noti가 확인완료 상태로 변경됨.
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .delete(process.env.REACT_APP_DB_HOST + `/notification/all`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: `{"jwtToken":"${localStorage.getItem(
        "token"
      )}","userID":"${userID}"}`,
    })
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      } else {
        returnValue = data.data.result; // true: 아직 안 읽은 거 있음 (빨강 표시) false: 다 확인.
      }
    })
    .catch(() => {});

  return returnValue;
};

export const checkAllNoti = async (userID) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/notification/all/check`,
      `{"jwtToken":"${localStorage.getItem("token")}", "userID":"${userID}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        //   alert("Your session has ended. Please log in again.");
        //   localStorage.clear();
        //   window.location.href = "/";
      } else {
        returnValue = data.data.result; // true: 아직 안 읽은 거 있음 (빨강 표시) false: 다 확인.
      }
    })
    .catch(() => {});

  return returnValue;
};
