import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletListData from "../../../../datas/WalletListData";
import TrashIcon from "../../../../assets/icons/dashboard/icon-trash.svg";
import AddWalletAddress from "../../../../components/basic/AddWalletAddress/AddWalletAddress";
import { deleteWallet } from "../../../../utils/api/wallets";

const SettingHeader = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  margin-top: 70px;
`;

const FullContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  min-height: 300px;
  margin-top: 30px;
  margin-bottom: 90px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const TitleBox = styled.div`
  width: 90%;
  max-width: 1080px;
  margin-top: 98px;
  ${Typography.Heading1}
`;

const ContentHeader = styled.div`
  display: flex;
  width: 90%;
  max-width: 1080px;
  justify-content: space-between;
  margin: 40px auto;
`;

const ContentHeaderText = styled.div`
  ${Typography.Heading2}
  margin: auto 0px;
`;

const ContentHeaderButton = styled.button`
  width: 130px;
  height: 36px;
  border-radius: 20px;
  border: hidden;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
`;

const WalletButton = styled.button`
  max-width: 480px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: ${palette.light_gray_alpha20};
  border: 0;
  border-radius: 10px;
  padding: 12px 16px 12px 16px;
  ${Typography.Heading3}
  margin: 0px auto;
  width: 100%;
  margin-bottom: 10px;
`;

const WalletIcon = styled.img`
  width: 36px;
  height: 36px;
`;

const WalletName = styled.div`
  ${Typography.Heading3}
  margin: auto 0px;
  margin-left: 16px;
`;

const BoxContainer = styled.div`
  width: 90%;
  max-width: 856px;
  min-height: 288px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
`;

const WalletAddress = styled.div`
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  color: ${palette.gray};
`;

const SideContainer = styled.div`
  display: flex;
`;

const TrashButton = styled.button`
  border: hidden;
  background-color: transparent;
  width: 32px;
  height: 32px;
  margin-left: 16px;
`;

const TrashImage = styled.img`
  width: 24px;
  height: 24px;
`;

const WalletManager = () => {
  const [walletList, setWalletList] = useState(
    JSON.parse(localStorage.getItem("walletList"))
  );
  const [trashClickTic, setTrashClickTic] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // console.log(walletList);
    localStorage.setItem("walletList", JSON.stringify(walletList));
  }, [trashClickTic, modalVisible]);

  const trashOnClick = async (index) => {
    if (walletList.length > 1) {
      // 지갑이 하나도 없으면 안되므로 1개가 넘었을 때만 가능한 기능.
      let tmpWalletList = walletList;
      const deleteWalletResult = await deleteWallet(
        localStorage.getItem("userID"),
        tmpWalletList[index].walletAddress
      ).then((data) => {
        tmpWalletList.splice(index, 1);
        setWalletList(tmpWalletList);
        setTrashClickTic(!trashClickTic);
      });
    }

    // localStorage.setItem("walletList", JSON.stringify(tmpWalletList));
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const addButtonOnClick = () => {
    setModalVisible(true);
  };

  return (
    <React.Fragment>
      {modalVisible ? (
        <AddWalletAddress
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
          setWalletList={setWalletList}
          walletList={walletList}
          mode={"setting"}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <SettingHeader>
        <TitleBox>Settings</TitleBox>
      </SettingHeader>
      <FullContainer>
        <ContentHeader>
          <ContentHeaderText>Manage Wallet Address</ContentHeaderText>
          <ContentHeaderButton onClick={addButtonOnClick}>
            Add Wallet
          </ContentHeaderButton>
        </ContentHeader>
        <BoxContainer>
          {walletList.map((val, idx) => (
            <WalletButton>
              <SideContainer>
                <WalletIcon
                  src={
                    WalletListData[
                      WalletListData.findIndex(
                        (x) => x.walletName === val.walletType
                      )
                    ]?.walletIcon
                  }
                />
                <WalletName>{val.walletType}</WalletName>
              </SideContainer>
              <SideContainer>
                <WalletAddress>
                  {val.walletAddress.substr(0, 6) +
                    "..." +
                    val.walletAddress.substr(
                      val.walletAddress.length - 6,
                      val.walletAddress.length
                    )}
                </WalletAddress>
                <TrashButton onClick={() => trashOnClick(idx)}>
                  <TrashImage src={TrashIcon} />
                </TrashButton>
              </SideContainer>
            </WalletButton>
          ))}
        </BoxContainer>
      </FullContainer>
    </React.Fragment>
  );
};

export default WalletManager;
