import { css } from "styled-components";

export const COLORS = {
  // main
  white: "#FFF",
  black: "#222222",
  light_black: "#444444",
  gray: "#777777",
  light_gray: "#C4C4C4",
  light_gray_alpha20: "#C4C4C433",
  background: "#F8F9FB",
  footer: "#E8EAEF",
  modal_back: "rgba(47, 47, 54, 0.7)",
  noti_back: "#FCFCFF",
  noti_action_button: "#EEEEFF",

  // red 계열
  light_red: "#FF6666",
  orange: "#F6851B",
  yellow: "#FBCA1F",
  pink: "#FF55E3",

  // blue 계열
  blue: "#5383FF",
  light_blue: "#22CAFF",
  marine: "#5552FF",
  lavender: "#B6A4FF",
  lavender_alpha25: "#BBC2DE40",
  valid: "#F0F5FC",

  // green 계열
  green: "#32E2AD",
  lime: "#B0FF30",

  //sns
  sns_twitter: "#00ACEE",
  sns_facebook: "#1650CB",
  sns_discord: "#5865F2",
  sns_linkedin: "#1396DB",
};
