import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletBox from "./WalletBox";

const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  min-height: 500px;
  background-color: ${palette.white};
  border-radius: 40px;
  padding: 30px;
  margin-top: 32px;
`;

const TitleBox = styled.div`
  margin-top: 8px;
  margin-left: 20px;
  ${Typography.Heading2}
`;

const SendTokenButton = styled.button`
  padding: 9px 24px;
  border: hidden;
  background-color: ${palette.marine};
  color: ${palette.white};
  border-radius: 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
`;

const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const MyTokens = (props) => {
  const [walletList, setWalletList] = useState(props.wallet);

  useEffect(() => {
    console.log(props.wallet);
    setWalletList(props.wallet);
  }, [props.wallet]);

  return (
    <Container>
      <ContainerHeader>
        <TitleBox>My Tokens</TitleBox>
        <SendTokenButton onClick={() => alert("Service is being prepared.")}>
          Send tokens by selecting user profile
        </SendTokenButton>
      </ContainerHeader>
      {walletList.map((val, idx) => (
        <WalletBox wallet={val} setWalletAddress={props.setWalletAddress} />
      ))}
    </Container>
  );
};

export default MyTokens;
