import styled from 'styled-components';
import {COLORS as c} from "../../../utils/styles/colors";

import {ReactComponent as PeopleImg} from "../../../assets/images/img-TeleBanner.svg";
import Evmos from "../../../assets/icons/chain/icon-evmos.png";
import Klaytn from "../../../assets/icons/chain/klaytn-logo.png";
import Pro from "../../../assets/images/d-f.png";
import MBanner from "../../../assets/images/dum/mint_banner.png";
import MBadge from "../../../assets/images/dum/mint_main.png";
import Profile from "../../../assets/images/img-profile.svg";
import Creator from "../../../assets/images/dudu_image.svg";
import Margin from "../../../components/util/Margin";
import SizeBox from "../../../components/util/SizeBox";

import Linked from "../../../assets/icons/otherService/icon-coloredLinkedIn.svg";
import Twitter from "../../../assets/icons/otherService/icon-coloredTwitter.svg";
import Facebook from "../../../assets/icons/otherService/icon-coloredFacebook.svg";
import Instagram from "../../../assets/icons/otherService/icon-coloredInstagram.svg";
import Discord from "../../../assets/icons/otherService/icon-coloredDiscord.svg";
import ImageLoader from "../../../components/util/ImageLoader";
import BasicButton from "../../../components/basic/BasicButton";
import UrlImageLoader from "../../../components/util/UrlImageLoader";
import Spacer from "../../../components/util/Spacer";
import CustomTag from "../../../components/basic/CustomTag";
import CircularProgress from '@mui/material/CircularProgress';
import {useEffect, useState} from "react";
import Header from "../../../components/global/Header/index";
// import Header from "../../../components/global/header";


const TopBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 1320px;
  height: 400px;
  min-height: max-content;

  top: -10px;

  position: relative;
  margin-top: 72px;
`;

const BannerImg = styled.div`
  background: url(${MBanner}) no-repeat;
  background-size: cover;
  
  height: 90%;
  width: 100%;
  max-width: 1310px;

  position: absolute;
`;

const TopBannerFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  width: 100%;
  height: 95%;

  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);

  position: absolute;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  position: absolute;
`;

const LinkedInBtn = styled.img`
  cursor: pointer;

  content: url(${Linked});
  width: 32px;
  height: 32px;
`;

const TwitterBtn = styled.img`
  cursor: pointer;

  content: url(${Twitter});
  width: 32px;
  height: 32px;
`;

const FacebookBtn = styled.img`
  cursor: pointer;

  content: url(${Facebook});
  width: 32px;
  height: 32px;
`;

const InstagramBtn = styled.img`
  cursor: pointer;

  content: url(${Instagram});
  width: 32px;
  height: 32px;
`;

const DiscordBtn = styled.img`
  cursor: pointer;

  content: url(${Discord});
  width: 32px;
  height: 32px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 1200px;
`;

const BackBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;


  width: ${props => `${props.w}%`};
  min-width: ${props => `${props.min}px`};
  height: ${props => `${props.h}px`};

  border-radius: 40px;
  backdrop-filter: opacity(0.9);
  background: ${c.white};

  padding: ${props => `${props.padding}`};
  box-sizing: border-box;
`;

const CommonText = styled.pre`
  font-size: ${props => `${props.size}px`};
  font-weight: ${props => props.w};
  color: ${props => props.color};
  text-align: start;

  white-space: pre-wrap;
`;

const Box = styled.img`
  width: 70px;
  height: 70px;

  background: ${c.light_gray};
`;

function BadgeDetail() {
    const [loading, setLoading] = useState(true);
    const [profileImg, setProfileImg] = useState(localStorage.getItem("profileImage"));

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <div className={'f-column a-center'}>
            <Header profileImg={profileImg}/>
            {/* <Header/> */}
            {loading ? <>
                    <SizeBox h={122}/>
                    <CircularProgress/>
                </> :
                <>
                    <TopBanner>
                        <BannerImg/>
                        <TopBannerFilter>
                            <SizeBox h={50}/>
                            <Margin left={10} right={180} className={'f-row j-end'}>
                                <div className={'row'}>
                                    <LinkedInBtn/>
                                    <SizeBox w={10}/>

                                    <TwitterBtn/>
                                    <SizeBox w={10}/>

                                    <FacebookBtn/>
                                    <SizeBox w={10}/>

                                    <InstagramBtn/>
                                    <SizeBox w={10}/>

                                    <DiscordBtn/>
                                    <SizeBox w={10}/>
                                </div>
                            </Margin>
                        </TopBannerFilter>

                        <ProfileContainer>
                            <CommonText size={22} w={800} color={c.black}>
                                1 Day 1 Coffee Membership
                            </CommonText>

                            <SizeBox h={5}/>
                            <CommonText size={10} w={600} color={c.black}>
                                Issued by @goyangtwo
                            </CommonText>

                            <SizeBox h={15}/>
                            <ImageLoader w={120} src={MBadge} round={20}/>

                            <SizeBox h={20}/>
                            <div className={'row'}>
                                <BasicButton mode={3} isValid={true}>
                                    Sell
                                </BasicButton>

                                <SizeBox w={10}/>
                                <BasicButton mode={4} isValid={true}>
                                    Edit
                                </BasicButton>
                            </div>
                        </ProfileContainer>
                    </TopBanner>

                    <SizeBox h={42}/>
                    <BodyContainer>
                        <div className={'f-row'}>
                            <BackBox w={60} h={240} min={700} padding={'40px 60px'}>
                                <CommonText w={700} size={16} color={c.black}>
                                    Information
                                </CommonText>

                                <SizeBox h={15}/>
                                <div className={'f-row'}>
                                    <SizeBox w={10}/>
                                    <div className={'f-column j-start'}>
                                        <div className={'f-row j-start a-center'}>
                                            <CommonText color={c.gray} size={14} w={600}>
                                                · chain
                                            </CommonText>

                                            <SizeBox w={16}/>
                                            <CommonText color={c.black} size={16} w={700}>
                                                Klaytn
                                            </CommonText>
                                        </div>

                                        <SizeBox h={10}/>
                                        <div className={'f-row j-start a-center'}>
                                            <CommonText color={c.gray} size={14} w={600}>
                                                · supply
                                            </CommonText>

                                            <SizeBox w={16}/>
                                            <CommonText color={c.black} size={16} w={700}>
                                                10
                                            </CommonText>
                                        </div>

                                        <SizeBox h={10}/>
                                        <div className={'f-row j-start a-center'}>
                                            <CommonText color={c.gray} size={14} w={600}>
                                                · duration
                                            </CommonText>

                                            <SizeBox w={16}/>
                                            <CommonText color={c.black} size={16} w={700}>
                                                None
                                            </CommonText>
                                        </div>

                                        <SizeBox h={10}/>
                                        <div className={'f-row j-start a-center'}>
                                            <CommonText color={c.gray} size={14} w={600}>
                                                · price
                                            </CommonText>

                                            <SizeBox w={16}/>
                                            <ImageLoader w={20} h={20} src={Klaytn}/>

                                            <SizeBox w={5}/>
                                            <CommonText color={c.black} size={16} w={700}>
                                                0.00 Klaytn
                                            </CommonText>
                                        </div>
                                    </div>
                                </div>
                            </BackBox>

                            <SizeBox w={30}/>
                            <BackBox w={30} h={240} min={400} padding={'40px'}>
                                <CommonText color={c.black} size={16} w={700}>
                                    Creator
                                </CommonText>

                                <SizeBox h={20}/>
                                <div className={'f-row j-start'}>
                                    <ImageLoader src={Creator} round={'100%'} border={true}/>

                                    <SizeBox w={20}/>
                                    <div className={'f-column a-start j-center'}>
                                        <CommonText w={800} color={c.black} size={22}>
                                            Goyangtwo
                                        </CommonText>

                                        <SizeBox h={10}/>
                                        <div className={'row'}>
                                            <SizeBox w={10}/>
                                            <div className={'f-column'}>
                                                <CommonText w={600} size={12} color={c.gray}>
                                                    @goyangtwo
                                                </CommonText>

                                                <SizeBox h={20}/>
                                                <div className={'row a-center'}>
                                                    <CommonText w={700} color={c.light_red} size={16}>
                                                        43
                                                    </CommonText>

                                                    <SizeBox w={5}/>
                                                    <CommonText w={600} size={10} color={c.gray}>
                                                        Comments
                                                    </CommonText>

                                                    <SizeBox w={15}/>
                                                    <CommonText w={700} color={c.blue} size={16}>
                                                        143
                                                    </CommonText>

                                                    <SizeBox w={5}/>
                                                    <CommonText w={600} size={10} color={c.gray}>
                                                        Friends
                                                    </CommonText>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </BackBox>
                        </div>

                        <SizeBox h={30}/>
                        <div className={'f-row'}>
                            <BackBox w={60} h={420} min={700} padding={'40px 60px'}>
                                <CommonText w={700} size={16} color={c.black}>
                                    description
                                </CommonText>
                                <SizeBox h={15}/>
                                <div className={'f-row'}>
                                    <SizeBox w={10}/>
                                    <div className={'f-column j-start'}>
                                        <div className={'f-row j-start a-center'}>
                                            <CommonText color={c.black} size={12} w={500}>
                                                This badge is presented to the member of the 1Day 1 Coffee Membership.<br/>
                                                With this membership, You can get 1 Free Drink everyday.<br/>
                                                Track your cafe journey and get special gifts.<br/>
                                                Enjoy your special experiences while holding this membership.
                                            </CommonText>
                                        </div>
                                    </div>
                                </div>

                                <SizeBox h={50}/>
                                <CommonText w={700} size={16} color={c.black}>
                                    External Link
                                </CommonText>
                                <div className={'f-row'}>
                                    <SizeBox w={10}/>
                                    <div className={'f-column j-start'}>
                                        <SizeBox h={12}/>
                                        <div className={'f-row j-start a-center'}>
                                            <CommonText color={c.black} size={12} w={500}>
                                                1Day1Coffee.com
                                            </CommonText>
                                        </div>
                                    </div>
                                </div>

                                <SizeBox h={50}/>
                                <CommonText w={700} size={16} color={c.black}>
                                    Category
                                </CommonText>
                                <div className={'f-row'}>
                                    <div className={'f-column j-start'}>
                                        <SizeBox h={16}/>
                                        <div className={'f-row j-start a-center'}>
                                            <CustomTag shadow={false}>
                                                MePe
                                            </CustomTag>

                                            <CustomTag shadow={false}>
                                                Web3.0
                                            </CustomTag>
                                        </div>
                                    </div>
                                </div>
                            </BackBox>

                            <SizeBox w={30}/>
                            <div className={'column'}>
                                <BackBox w={70} h={330} min={400} padding={'40px 50px'}>
                                    <div className={'f-row a-center j-center'}>
                                        <CommonText w={700} color={c.black} size={16}>
                                            owner 1
                                        </CommonText>

                                        <Spacer/>
                                        <CommonText w={600} size={14} color={c.gray}>
                                            + more
                                        </CommonText>
                                    </div>

                                    <SizeBox h={20}/>
                                    <OwnerItem data={{id: '@goyangtwo', mintingDate: '22. 04. 12.'}}/>
                                    {/*<OwnerItem data={{id: '@thesupercat', mintingDate: '22. 04. 12.'}}/>*/}
                                    {/*<OwnerItem data={{id: '@thesupercat', mintingDate: '22. 04. 12.'}}/>*/}
                                    {/*<OwnerItem data={{id: '@thesupercat', mintingDate: '22. 04. 12.'}}/>*/}
                                </BackBox>

                                {/*<SizeBox h={30}/>*/}
                                {/*<BackBox w={70} h={300} min={400} padding={'40px 50px'}>*/}
                                {/*    <CommonText color={c.black} size={16} w={700}>*/}
                                {/*        related badges*/}

                                {/*        <SizeBox h={20}/>*/}
                                {/*        <div className={'f-row'}>*/}
                                {/*            <Box/>*/}

                                {/*            <SizeBox w={12}/>*/}
                                {/*            <Box/>*/}

                                {/*            <SizeBox w={12}/>*/}
                                {/*            <Box/>*/}

                                {/*            <SizeBox w={12}/>*/}
                                {/*            <Box/>*/}
                                {/*        </div>*/}

                                {/*        <SizeBox h={12}/>*/}
                                {/*        <div className={'f-row'}>*/}
                                {/*            <Box/>*/}

                                {/*            <SizeBox w={12}/>*/}
                                {/*            <Box/>*/}

                                {/*            <SizeBox w={12}/>*/}
                                {/*            <Box/>*/}

                                {/*            <SizeBox w={12}/>*/}
                                {/*            <Box/>*/}
                                {/*        </div>*/}
                                {/*    </CommonText>*/}
                                {/*</BackBox>*/}
                            </div>
                        </div>
                    </BodyContainer>

                    <SizeBox h={100}/>
                </>
            }
        </div>
    );
}

/////

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 48px;

  margin: 4px 0px;
`;

const IdContainer = styled.div`
  display: flex;

  width: 216px;

  font-weight: 600;
  font-size: 16px;
  color: ${c.light_black};
`;

const DateContainer = styled.pre`
  display: flex;
  text-align: start;

  width: max-content;

  font-weight: 500;
  font-size: 12px;
  color: ${c.gray};
`;

function OwnerItem({data}) {
    return (
        <ItemContainer>
            <SizeBox w={32} h={32}>
                <UrlImageLoader w={32} round={'100%'}/>
            </SizeBox>

            <SizeBox w={18}/>
            <IdContainer>
                {data.id}
            </IdContainer>

            <Spacer/>
            <DateContainer>
                {data.mintingDate}
            </DateContainer>
        </ItemContainer>
    );
}

export default BadgeDetail;