import axios from "axios";

export const getFriendInfo = async (userID, friendID) => {
  let returnValue = [];
  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST +
        `/comments/new?userID=${userID}&friendID=${friendID}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
      // userNickname : {userID} 자기 자신이 설정한 nickname
      // userFriendNickname : {friendID}가 {userID}에게 설정한 친구 닉네임
    });

  return returnValue;
};

export const sendComment = async (userID, friendID, comment) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/comments/new?friendID=${friendID}`,
      `{"userID":"${userID}", "jwtToken":"${localStorage.getItem(
        "token"
      )}", "message":"${comment}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return returnValue;
};

export const getUnhiddenComment = async (userID) => {
  let returnValue = [];
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/comments/unhidden?userID=${userID}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
    });

  return returnValue;
};

export const setPinnedComment = async (userID, idxArr, isPin) => {
  let returnValue = [];
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/comments/pinned?userID=${userID}&pin=${isPin}`,
      `{"jwtToken":"${localStorage.getItem("token")}", "idxArr":[${idxArr}]}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
      if (!data.data.isSuccess) {
        alert("Something's wrong! Please try again.");
      }
    })
    .catch((err) => {
      alert("Something's wrong! Please try again.");
    });

  return returnValue;
};

export const setHideComment = async (userID, commentIdx, isHide) => {
  let returnValue = [];
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/comments/hidden?userID=${userID}&commentIdx=${commentIdx}&hide=${isHide}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
      if (!data.data.isSuccess) {
        alert("Something's wrong! Please try again.");
      }
    })
    .catch((err) => {
      alert("Something's wrong! Please try again.");
    });

  return returnValue;
};

export const getAllComments = async (userID) => {
  let returnValue = [];
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/comments/all/private?userID=${userID}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
    });

  return returnValue;
};

export const getPublicComments = async (userID) => {
  let returnValue = [];
  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST +
        `/comments/all?userID=${userID}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //commentsList & pinnedCommentList
    });

  return returnValue;
};
