import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import { addFriend } from "../../../../utils/api/friends";

const BoxContainer = styled.div`
  // width: 90%;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 600px;
  margin: 0px auto;
  margin-top: 30px;
  margin-bottom: 37px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ButtonContainer = styled.div`
  width: 93%;
  display: flex;
  justify-content: right;
  margin-top: 24px;
  gap: 18px;
`;

const LeftButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.marine};
  background-color: ${palette.white};
  z-index: 10;
`;

const RightButton = styled.button`
  height: 36px;
  padding: 7px 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  border-radius: 20px;
  border: 2px solid ${palette.marine};
  color: ${palette.white};
  background-color: ${palette.marine};
  z-index: 10;
`;

const ProfileImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 20px;
`;

const RequestMessage = styled.div`
  ${Typography.Heading4}
  text-align: left;
  color: ${palette.black};
  margin-bottom: 30px;
`;

const NicknameTextbox = styled.input`
  width: 100%;
  height: 42px;
  border-radius: 8px;
  border: hidden;
  background-color: ${palette.background};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 16px;
`;

function AddFriend({
  className,
  onClose,
  maskClosable,
  visible,
  friendInfo,
  setStatus,
}) {
  const [friend, setFriend] = useState({});
  const [customNickname, setCustomNickname] = useState("");

  useEffect(() => {
    console.log(friendInfo);
    setFriend(friendInfo);
  }, [friendInfo]);

  useEffect(() => {
    console.log(friend);
  }, [friend]);

  const closeOnClick = () => {
    onClose();
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const nickNameOnChange = (e) => {
    setCustomNickname(e.target.value);
  };

  const sendOnClick = async () => {
    if (localStorage.getItem("userID")) {
      const addFriendResult = await addFriend(
        friend.userId,
        localStorage.getItem("userID"),
        customNickname
      );
      setStatus(1);
      onClose();
    } else {
      alert("Your session has ended. Please log in again.");
      localStorage.clear();
      window.location.href = "/";
    }
    // request 보내는 api
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ContentContainer>
            <PopupTitle>Add Friend</PopupTitle>
            <BoxContainer>
              <ProfileImg src={friendInfo?.img} />
              <RequestMessage>
                You request {friendInfo?.userId} as a friend. <br /> Give your
                friend a nickname.
              </RequestMessage>
              <NicknameTextbox
                placeholder="Nickname"
                value={customNickname}
                onChange={(e) => nickNameOnChange(e)}
              />
            </BoxContainer>

            <ButtonContainer>
              <RightButton onClick={sendOnClick}>Send</RightButton>
            </ButtonContainer>

            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </ContentContainer>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

AddFriend.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: white;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 420px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 50px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
  margin-bottom: 36px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

const BottomButton = styled.button`
  width: 187px;
  height: 42px;
  border-radius: 21px;
  background-color: #4673e9;
  color: #ffffff;
  font-family: NeoDunggeunmo Pro;
  font-size: 18px;
  border: 0px;
  margin-top: 34px;
  position: absolute;
  right: 40px;
  z-index: 5;
  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(70, 115, 233, 0.5);
  }
`;

export default AddFriend;
