import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletBox from "./WalletBox";

const Container = styled.div`
  width: 90%;
  max-width: 1080px;
  min-height: 920px;
  background-color: ${palette.white};
  border-radius: 40px;
  padding: 30px;
  margin-top: 32px;
`;

const TitleBox = styled.div`
  margin-top: 8px;
  margin-left: 20px;
  ${Typography.Heading2}
`;

const MyTokens = (props) => {
  const [walletList, setWalletList] = useState(props.wallet);

  useEffect(() => {
    console.log(props.wallet);
    setWalletList(props.wallet);
  }, [props.wallet]);

  return (
    <Container>
      <TitleBox>My Tokens</TitleBox>
      {walletList.map((val, idx) => (
        <WalletBox wallet={val} />
      ))}
    </Container>
  );
};

export default MyTokens;
