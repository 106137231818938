import { send } from "process";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Web3 from "web3";
import HyunjuToken from "./HyunjuToken.json";
import init from "../actions/connectWallet";
import connectKeplrWallet from "../actions/connectWalletKeplr";

const Container = styled.div`
  width: 80%;
  margin-top: 200px;
  height: 500px;
  border-radius: 20px;
  background-color: #dddddd;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  padding-left: 30px;
  gap: 30px;
`;

const SimpleButton = styled.div`
  width: 300px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px #c4c4c440;
  background-color: #ffffff;
  color: #222222;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  justify-content: center;
  margin: 20px;
`;

const SimpleText = styled.div`
  width: 300px;
  height: 40px;
  border-radius: 10px;
  //   box-shadow: 0px 2px 10px #c4c4c440;
  background-color: transparent;
  color: #222222;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  justify-content: center;
  margin: 20px;
`;

const ButtonBoard = () => {
  const [balance, setBalance] = useState([-1]);

  useEffect(() => {}, []);

  useEffect(() => {
    console.log(balance);
  }, [balance]);

  const transferOnClick = () => {
    console.log("transfer2OnClick");
    const Web3js = new Web3(
      new Web3.providers.HttpProvider(
        "https://rinkeby.infura.io/v3/a44652a4e710425db0ef59036a6d4236"
      )
    );
    const privateKey = "private key"; //Your Private key environment variable
    let tokenAddress = "0xBCc3aaC3010e744405d3CfE134D562ee0dC66196"; // Demo Token contract address
    let toAddress = "0xed6631bD706BC910A37cdc41ACd48a5d94F7bCC0"; // where to send it
    let fromAddress = "0x07B0ea6D444B9B66F3A7709FB1fA75BcDCD67A16"; // Your address
    let contractABI = HyunjuToken.abi;
    let contract = new Web3js.eth.Contract(contractABI, tokenAddress, {
      from: fromAddress,
    });
    let amount = Web3js.utils.toHex(Web3js.utils.toWei("1")); //1 DEMO Token
    let data = contract.methods.transfer(toAddress, amount).encodeABI();

    console.log(data);

    sendErcToken();

    function sendErcToken() {
      let txObj = {
        gas: Web3js.utils.toHex(100000),
        to: tokenAddress,
        value: "0x00",
        data: data,
        from: fromAddress,
      };
      Web3js.eth.accounts.signTransaction(
        txObj,
        privateKey,
        (err, signedTx) => {
          if (err) {
            // return callback(err);
            console.log(err);
            return err;
          } else {
            console.log(signedTx);
            return Web3js.eth.sendSignedTransaction(
              signedTx.rawTransaction,
              (err, res) => {
                if (err) {
                  console.log(err);
                } else {
                  console.log(res);
                }
              }
            );
          }
        }
      );
    }
  };

  const transactionOnClick = () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://rinkeby.infura.io/v3/a44652a4e710425db0ef59036a6d4236"
      )
    );
    const toAddress = "0x07B0ea6D444B9B66F3A7709FB1fA75BcDCD67A16";
    const privateKey = "private key";
    web3.eth.accounts.signTransaction(
      {
        to: toAddress,
        value: "300000000000000000",
        chainId: 4,
        gas: 210000,
      },
      privateKey,
      function (err, result) {
        if (err) {
          console.error(err);
          return;
        }
        console.log("rawTransaction ${result.rawTransaction}");
        web3.eth
          .sendSignedTransaction(result.rawTransaction)
          .on("receipt", console.log);
      }
    );
  };

  const transfer2OnClick = () => {
    let tokenAddress = "0xBCc3aaC3010e744405d3CfE134D562ee0dC66196"; //HJT token address
    let toAddress = "0x07B0ea6D444B9B66F3A7709FB1fA75BcDCD67A16";
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://rinkeby.infura.io/v3/a44652a4e710425db0ef59036a6d4236"
      )
    );
    // Use BigNumber
    let decimals = web3.toBigNumber(18);
    let amount = web3.toBigNumber(100);
    let minABI = HyunjuToken.abi;
    // Get ERC20 Token contract instance
    let contract = web3.eth.contract(minABI).at(tokenAddress);
    // calculate ERC20 token amount
    let value = amount.times(web3.toBigNumber(10).pow(decimals));
    // call transfer function
    contract.transfer(toAddress, value, (error, txHash) => {
      // it returns tx hash because sending tx
      console.log(toAddress);
      console.log(value);
      console.log(txHash);
    });
  };

  const transfer3OnClick = () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://rinkeby.infura.io/v3/a44652a4e710425db0ef59036a6d4236"
      )
    );
    const hyunjuToken = HyunjuToken;

    const main = async () => {
      const account1 = await web3.eth.accounts.privateKeyToAccount(
        "a24f15ae1f485612ec6a773c8f884195f7c02eb8e228e12824919cac48ae0fc5"
      );

      const hyunjuAbi = hyunjuToken.abi;
      const hyunjuObj = new web3.eth.Contract(
        hyunjuAbi,
        "0xBCc3aaC3010e744405d3CfE134D562ee0dC66196"
      );

      console.log("hihi");

      hyunjuObj.methods
        .transfer(account1.address, 10 * 10 ** 10)
        .send({ from: account1.address }, (err, hash) => {
          console.log(err);
          console.log(hash);
        });
    };
    main();
  };

  return (
    <Container>
      {/* <SimpleButton onClick={transactionOnClick}>Transaction</SimpleButton> */}
      {/* <SimpleButton onClick={() => transferOnClick()}>Transfer</SimpleButton> */}
      <SimpleButton onClick={() => init()}>Init</SimpleButton>
      <SimpleButton onClick={() => connectKeplrWallet(setBalance)}>
        ConnectKeplrWallet
      </SimpleButton>
      {balance.map((val, idx) => (
        <React.Fragment>
          {val != -1 ? (
            <SimpleText>
              {idx} chain 현재 자산 : {val}
            </SimpleText>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default ButtonBoard;
