import {
  MetamaskOnClick,
  KaikasOnClick,
  KeplrOnClick,
  PhantomOnClick,
  CoinbaseOnClick,
  CosmostationOnClick,
  BinanceOnClick,
  WalletConnectOnClick
} from "../components/basic/WalletGroup/WalletConnectActions";
import {
  metamaskSend,
  keplrSend,
  kaikasSend
} from "../components/basic/WalletGroup/WalletSendActions";
import MetaMaskIcon from "../assets/icons/wallets/icon-metamask.svg";
import WalletConnectIcon from "../assets/icons/wallets/icon-walletConnect.svg";
import CoinbaseWalletIcon from "../assets/icons/wallets/icon-coinbase.svg";
import BinanceWalletIcon from "../assets/icons/wallets/icon-binance.svg";
import PhantomIcon from "../assets/icons/wallets/icon-phantom.svg";
import KeplrIcon from "../assets/icons/wallets/icon-kepler.svg";
import CosmostationIcon from "../assets/icons/wallets/icon-cosmostation.svg";
import KaikasIcon from "../assets/icons/wallets/icon-kaikas.svg";

const WalletList = [
  {
    walletName: "Metamask",
    deepLink: "https://metamask.app.link/dapp/mepe.app",
    walletIcon: MetaMaskIcon,
    action: MetamaskOnClick,
    sendAction: metamaskSend,
    walletId: 0,
  },
  {
    walletName: "Keplr",
    deepLink:"",
    walletIcon: KeplrIcon,
    action: KeplrOnClick,
    sendAction: keplrSend,
    walletId: 1,
  },
  {
    walletName: "Cosmostation",
    deepLink:"",
    walletIcon: CosmostationIcon,
    action: CosmostationOnClick,
    walletId: 2,
  },
  {
    walletName: "Kaikas",
    deepLink:"",
    walletIcon: KaikasIcon,
    action: KaikasOnClick,
    sendAction: kaikasSend,
    walletId: 3,
  },
  {
    walletName: "Phantom",
    deepLink:"",
    walletIcon: PhantomIcon,
    action: PhantomOnClick,
    walletId: 4,
  },
  {
    walletName: "WalletConnect",
    deepLink:"",
    walletIcon: WalletConnectIcon,
    action: WalletConnectOnClick,
    walletId: 5,
  },
  {
    walletName: "Coinbase Wallet",
    deepLink:"",
    walletIcon: CoinbaseWalletIcon,
    action: CoinbaseOnClick,
    walletId: 6,
  },
  {
    walletName: "Binance Wallet",
    deepLink:"",
    walletIcon: BinanceWalletIcon,
    action: BinanceOnClick,
    walletId: 7,
  },
];

export default WalletList;
