import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletList from "../../../../datas/WalletListData";
import DropBox from "./dropbox";
import useWindowDimensions from "../../../../utils/functions/useWindowDimensions";
import MetamaskChainlist from "./MetamaskChainlist";
import KaikasChainlist from "./KaikasChainlist";
import FriendList from "./FriendList";
import FinalConfirmation from "./FinalConfirmation";
import MarineArrow from "../../../../assets/icons/dashboard/icon-marineArrow.svg";
import { getWalletList } from "../../../../utils/api/auth";
import { friendList } from "../../../../utils/api/friends";

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 70%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
`;

const FullContainer = styled.div`
  width: 100%;
`;

const HalfSide = styled.div`
  align-items: center;
  position: relative;
`;

const ProfileImage = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px auto;
`;

const ChooseFriendButton = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid ${palette.light_gray};
  color: ${palette.marine};
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  background-color: ${palette.white};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const UserNameBox = styled.div`
  margin-top: 8px;
  color: ${palette.black};
  ${Typography.Heading2}
  text-align: center;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const SendButton = styled.button`
  width: 116px;
  height: 35px;
  border-radius: 20px;
  background-color: ${palette.marine};
  border: hidden;
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
`;

const CheckBox = styled.div`
  width: 100%;
  text-align: left;
  line-height: 30px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const SubTitleBox = styled.div`
  ${Typography.Heading3}
  color: ${palette.gray};
  width: 100%;
`;

const TokenAmountContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
`;

const AmountInputContainer = styled.div`
  width: 100%;
  max-width: 390px;
  height: 54px;
  background-color: ${palette.background};
  border-radius: 10px;
  padding: 9px 10px;
  display: flex;
  justify-content: space-between;
`;

const AmountInputBox = styled.input`
  width: 50%;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: ${palette.light_gray};
  border: hidden;
  padding: 7.5px;
  background-color: transparent;
  &::-webkit-input-placeholder {
    color: ${palette.light_gray};
  }
  &:focus {
    outline: none;
  }
`;

const AmountInputRightBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const BalanceValue = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: right;
  color: ${palette.gray};

  width: 70px;
`;

const MaxButton = styled.button`
  width: 52px;
  height: 24px;
  border: hidden;
  border-radius: 8px;
  background-color: ${palette.white};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: ${palette.marine};
`;

const TextBox = styled.textarea`
  height: 72px;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  padding-left: 16px;
  padding-top: 15px;
  background-color: ${palette.background};
  border: 0;
  border-radius: 8px;
  position: relative;
  resize: none;
  &::-webkit-input-placeholder {
    color: ${palette.light_gray};
  }
`;

const MaximumCharNum = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  ${Typography.Body}
  color: ${palette.light_gray};
`;

const ArrowImg = styled.img`
  width: 72px;
`;

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

const SendBox = (props) => {
  const [senderWallet, setSenderWallet] = useState(
    localStorage.getItem("currentWalletType")
  );
  const [senderAddress, setSenderAddress] = useState(
    localStorage.getItem("currentWallet")
  );
  const [chainNetwork, setChainNetwork] = useState("0x4"); // Rinkeby test network
  const [receiverAddress, setReceiverAddress] = useState(
    localStorage.getItem("currentWallet")
  );
  const [receiverWallet, setReceiverWallet] = useState(
    localStorage.getItem("currentWalletType")
  );
  const [balance, setBalance] = useState(0);
  const [sendAmount, setSendAmount] = useState("0x38D7EA4C68000");
  const [transactionFee, setTransactionFee] = useState("0x2710");
  const [transactionHash, setTransactionHash] = useState("");
  const [setting, setSetting] = useState({});
  // const [userWalletList, setUserWalletList] = useState([
  //   {
  //     chain: "default",
  //     createdAt: "2022-07-19T12:46:40.000+00:00",
  //     index: 300,
  //     user: "gkrry2723",
  //     walletAddress: "0xbddddF70A6cB07Ec91FECd1297d306DC7bc11a7F",
  //     walletIcon:
  //       "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/845ade92-6615-4aeb-93e1-6d4d0138a1f68.png",
  //     walletType: "Kaikas",
  //   },
  // ]); // for dummydata
  const [userWalletList, setUserWalletList] = useState(
    JSON.parse(localStorage.getItem("walletList")).filter((v) => v.walletType == "Metamask" || v.walletType == "Kaikas")
  );
  // const [friendWalletList, setFriendWalletList] = useState([
  //   {
  //     chain: "default",
  //     createdAt: "2022-07-19T12:46:40.000+00:00",
  //     index: 300,
  //     user: "gkrry2723",
  //     walletAddress: "0xbddddF70A6cB07Ec91FECd1297d306DC7bc11a7F",
  //     walletIcon:
  //       "https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet_kind_icon/845ade92-6615-4aeb-93e1-6d4d0138a1f68.png",
  //     walletType: "Kaikas",
  //   },
  // ]); // for dummydata
  const [friendWalletList, setFriendWalletList] = useState([]);
  const [memo, setMemo] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [friendModalVisible, setFriendModalVisible] = useState(false);
  const [finalConfirmationVisible, setFinalConfirmationVisible] =
    useState(false);
  // const [friendId, setFriendId] = useState(localStorage.getItem("userID")); // for dummydata
  // const [friendImg, setFriendImg] = useState(
  //   localStorage.getItem("profileImage")
  // );
  const [friendId, setFriendId] = useState("");
  const [friendImg, setFriendImg] = useState("");
  const [senderIcon, setSenderIcon] = useState(
    WalletList[localStorage.getItem("currentWalletType")].walletIcon
  );
  const [receiverIcon, setReceiverIcon] = useState(
    WalletList[localStorage.getItem("currentWalletType")].walletIcon
  );
  const { width, height } = useWindowDimensions();
  const [Chainlist, setChainlist] = useState(KaikasChainlist);
  const [isInit, setIsInit] = useState(true);
  const [isSet, setIsSet] = useState(false);
  const [urlInfo, setUrlInfo] = useState(props.urlInfo);
  const [token, setToken] = useState("ETH")
  const [tokenImg, setTokenImg] = useState("https://daotool.s3.ap-northeast-2.amazonaws.com/static/wallet-icon/a98c2058-8754-4046-86dd-70268b31ba0f2_2.png")

  // console.log(senderAddress);
  // console.log(props.urlInfo);

  useEffect(() => {
    console.log("hello");
    if (Object.keys(props.urlInfo).length) {
      setIsSet(true);
      console.log(props.urlInfo);
      // setSenderWallet(props.urlInfo.walletType);
      // setSenderAddress(props.urlInfo.fromAddr);
      // setChainNetwork(props.urlInfo.chainId);
      // setReceiverAddress(props.urlInfo.toAddr);
      // setReceiverWallet(props.urlInfo.walletType);
      setSendAmount(
        parseInt(
          Number("0x" + Number(props.urlInfo.value * 10 ** 18).toString(16))
        )
      );
      // setMemo(props.urlInfo.memo);
      setAmount(props.urlInfo.value);
      setFriendId(props.urlInfo.toUser);
      setUrlInfo(props.urlInfo);
      (async () => {
        const friendListResult = await friendList(
          localStorage.getItem("userID")
        ).then((data) => {
          console.log(data);
          setFriendImg(
            data[data.findIndex((v) => v.friend == props.urlInfo.toUser)]
              .friendProfileImg
          );
        });
      })();
    }
  }, [props.urlInfo]);

  useEffect(() => {
    setSetting({
      fromAddr: senderAddress,
      toAddr: receiverAddress,
      value: sendAmount,
      chainId: chainNetwork,
    });
  }, [
    senderWallet,
    senderAddress,
    chainNetwork,
    receiverAddress,
    receiverWallet,
    balance,
    sendAmount,
    transactionFee,
  ]);

  useEffect(() => {
    console.log(senderWallet);
    if (
      senderWallet == 0 ||
      senderWallet == "Metamask" ||
      urlInfo.walletType == "Metamask"
    ) {
      console.log("metamask");
      setChainlist(MetamaskChainlist);
      setSenderWallet(0);
    } else if (
      senderWallet == 3 ||
      senderWallet == "Kaikas" ||
      urlInfo.walletType == "Kaikas"
    ) {
      console.log("kaikas");
      setChainlist(KaikasChainlist);
      setSenderWallet(3);
    }
  }, [senderWallet, urlInfo]);

  useEffect(() => {
    console.log(senderAddress);
  }, [
    senderAddress,
    receiverAddress,
    senderIcon,
    receiverIcon,
    currency,
    chainNetwork,
  ]);

  useEffect(() => {
    const hexString = "0x" + Number(amount * 10 ** 18).toString(16);
    console.log(hexString);
    const convertToNumber = parseInt(Number(hexString));
    console.log(convertToNumber);
    setSendAmount(hexString);
  }, [amount]);

  useEffect(() => {
    console.log(currency);
  }, [currency]);

  useEffect(() => {
    (async () => {
      const getWalletListResult = await getWalletList(friendId)
        .then((data) => {
          console.log(data);
          var tmpData = data.filter((v) => v.walletType == "Metamask" || v.walletType == "Kaikas");
          // setFriendWalletList(data);
          setFriendWalletList(tmpData)
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, [friendId]);

  useEffect(() => {}, [props]);

  const sendOnClick = async () => {
    const networkVersion = await window.ethereum.request({
      method: "net_version",
    });
    console.log(networkVersion);

    if (
      receiverAddress &&
      amount &&
      chainNetwork &&
      friendId &&
      (isInt(Number(amount)) || isFloat(Number(amount)))
    ) {
      setFinalConfirmationVisible(true);
    } else {
      if (!(isInt(Number(amount)) || isFloat(Number(amount)))) {
        alert("The amount value must be a number.");
      } else {
        alert("There are values that have not yet been filled.");
      }
    }
  };

  const memoOnChange = (e) => {
    console.log("hi");
    if (e.target.value.length <= 100) {
      setMemo(e.target.value);
    } else {
      setMemo(e.target.value.substr(0, 100));
    }
  };

  const amountOnChange = (e) => {
    setAmount(e.target.value);
  };

  const closeFriendModal = () => {
    setFriendModalVisible(false);
  };

  const chooseFriendOnClick = () => {
    setFriendModalVisible(true);
  };

  const closeFinalConfirmationModal = () => {
    setFinalConfirmationVisible(false);
  };

  return (
    <FullContainer>
      {friendModalVisible ? (
        <FriendList
          visible={friendModalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeFriendModal}
          userName={localStorage.getItem("userID")}
          setFriendId={setFriendId}
          setFriendImg={setFriendImg}
          setFriendWalletList={setFriendWalletList}
        />
      ) : (
        <>
          {finalConfirmationVisible ? (
            <FinalConfirmation
              visible={finalConfirmationVisible}
              closable={true}
              maskClosable={true}
              onClose={closeFinalConfirmationModal}
              type="send"
              confirmInfo={{
                receiverAddress: receiverAddress,
                senderAddress: senderAddress,
                receiverIcon: receiverIcon,
                senderIcon: senderIcon,
                senderWallet: senderWallet,
                amount: amount,
                chainId: chainNetwork,
                currency: currency,
                memo: memo,
                myImg: localStorage.getItem("profileImage"),
                friendImg: friendImg,
                friendId: friendId,
              }}
              sendFunction={WalletList[senderWallet].sendAction}
            />
          ) : (
            <></>
          )}
        </>
      )}
      {width > 1000 ? (
        <>
          <SubContainer>
            <Container>
              <HalfSide>
                <ProfileImage
                  style={{
                    backgroundImage: `url(${localStorage.getItem(
                      "profileImage"
                    )})`,
                  }}
                />
                <UserNameBox>You</UserNameBox>
                <DropBox
                  isInit={isInit}
                  setIsInit={setIsInit}
                  setSenderAddress={setSenderAddress}
                  setSenderWallet={setSenderWallet}
                  setSenderIcon={setSenderIcon}
                  itemList={userWalletList}
                  dropboxType="myWallet"
                  isSet={isSet}
                  urlInfo={urlInfo}
                />
              </HalfSide>
              <ArrowImg src={MarineArrow} />
              <HalfSide>
                {friendImg ? (
                  <ChooseFriendButton
                    onClick={chooseFriendOnClick}
                    style={{
                      backgroundImage: `url(${friendImg})`,
                    }}
                  />
                ) : (
                  <ChooseFriendButton onClick={chooseFriendOnClick}>
                    ?
                  </ChooseFriendButton>
                )}
                {friendId ? (
                  <UserNameBox>{friendId}</UserNameBox>
                ) : (
                  <UserNameBox>Choose Friend</UserNameBox>
                )}
                <DropBox
                  setReceiverAddress={setReceiverAddress}
                  setReceiverIcon={setReceiverIcon}
                  itemList={friendWalletList}
                  dropboxType="friendWallet"
                  isSet={isSet}
                  urlInfo={urlInfo}
                />
              </HalfSide>
            </Container>
          </SubContainer>

          <SubContainer>
            <Container>
              <SubTitleBox>
                Token Amount
                <p style={{ display: "inline", color: palette.light_red }}>*</p>
              </SubTitleBox>
            </Container>
          </SubContainer>
          <SubContainer>
            <Container>
              <TokenAmountContainer>
                <DropBox
                  setChainNetwork={setChainNetwork}
                  setCurrency={setCurrency}
                  itemList={Chainlist}
                  dropboxType="chain"
                  isSet={isSet}
                  urlInfo={urlInfo}
                />
                <AmountInputContainer>
                <DropBox
                  setToken={setToken}
                  setTokenImg={setTokenImg}
                  tokenImg={tokenImg}
                  dropboxType="token"
                  isSet={isSet}
                  urlInfo={urlInfo}
                />
                  <AmountInputBox
                    value={amount}
                    onChange={(e) => {
                      amountOnChange(e);
                    }}
                    placeholder="Amount"
                  />
                  <AmountInputRightBox>
                    <BalanceValue>Balance: -</BalanceValue>
                    <MaxButton>max</MaxButton>
                  </AmountInputRightBox>
                </AmountInputContainer>
              </TokenAmountContainer>
            </Container>
          </SubContainer>
          <SubContainer>
            <Container>
              <SubTitleBox>Memo (Optional)</SubTitleBox>
            </Container>
          </SubContainer>
          <SubContainer>
            <Container style={{ position: "relative" }}>
              <TextBox
                maxlength={100}
                value={memo}
                onChange={(e) => {
                  memoOnChange(e);
                }}
                placeholder="Leave a badge memo"
              />
              <MaximumCharNum>{memo.length}/100</MaximumCharNum>
            </Container>
          </SubContainer>
        </>
      ) : (
        <div style={{ position: "relative" }}>
          <HalfSide>
            <ProfileImage
              style={{
                backgroundImage: `url(${localStorage.getItem("profileImage")})`,
              }}
            />
            <UserNameBox>You</UserNameBox>
            <DropBox
              setSenderAddress={setSenderAddress}
              itemList={userWalletList}
              isSet={isSet}
              urlInfo={urlInfo}
            />
            <DropBox
              setSenderAddress={setSenderAddress}
              setSenderIcon={setSenderIcon}
              itemList={userWalletList}
              dropboxType="myWallet"
              isSet={isSet}
              urlInfo={urlInfo}
            />
          </HalfSide>
          <HalfSide>
            {friendImg ? (
              <ChooseFriendButton
                onClick={chooseFriendOnClick}
                style={{
                  backgroundImage: `url(${friendImg})`,
                }}
              />
            ) : (
              <ChooseFriendButton onClick={chooseFriendOnClick}>
                ?
              </ChooseFriendButton>
            )}
            {friendId ? (
              <UserNameBox>{friendId}</UserNameBox>
            ) : (
              <UserNameBox>Choose Friend</UserNameBox>
            )}
            <DropBox
              setReceiverAddress={setReceiverAddress}
              setReceiverIcon={setReceiverIcon}
              itemList={friendWalletList}
              dropboxType="friendWallet"
              isSet={isSet}
              urlInfo={urlInfo}
            />
          </HalfSide>
          <SubTitleBox>
            Token Amount
            <p style={{ display: "inline", color: palette.light_red }}>*</p>
          </SubTitleBox>
          <DropBox
            setChainNetwork={setChainNetwork}
            setCurrency={setCurrency}
            itemList={Chainlist}
            dropboxType="chain"
            isSet={isSet}
            urlInfo={urlInfo}
          />
          <AmountInputContainer>
            <AmountInputBox
              value={amount}
              onChange={(e) => {
                amountOnChange(e);
              }}
              placeholder="Amount"
            />
            <AmountInputRightBox>
              <BalanceValue>Balance: 18</BalanceValue>
              <MaxButton>max</MaxButton>
            </AmountInputRightBox>
          </AmountInputContainer>
          <SubTitleBox>Memo (Optional)</SubTitleBox>
          <TextBox
            maxlength={100}
            value={memo}
            onChange={(e) => {
              memoOnChange(e);
            }}
            placeholder="Leave a badge memo"
            style={{ width: "100%" }}
          />
          <MaximumCharNum>{memo.length}/100</MaximumCharNum>
        </div>
      )}
      <SubContainer>
        <Container>
          <BottomContainer>
            <SendButton onClick={sendOnClick}>Continue</SendButton>
          </BottomContainer>
        </Container>
      </SubContainer>
    </FullContainer>
  );
};

export default SendBox;
