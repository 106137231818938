import styled from 'styled-components';
import {COLORS as c} from "../../utils/styles/colors";

const Container = styled.button`
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  
  width: ${props => `${props.w}px`};
  height: 36px;

  background-color: ${props => props.color};
  border-radius: 20px;
  border: none;

  color: ${c.white};
  font-weight: 700;
  font-size: 14px;
  
  transition: 0.2s;
  
  &:hover {
    transform: translateY(-10%);
    box-shadow: 1px 2px 1px ${c.light_gray};
  }
`;

function ColoredBtn({w =  190, color = c.marine, onClick = () => {}, ...props}) {
    return (
        <Container w={w} color={color} onClick={onClick}>
            {props.children}
        </Container>
    );
}

export default ColoredBtn;