import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletListData from "../../../../datas/WalletListData";
import TrashIcon from "../../../../assets/icons/dashboard/icon-trash.svg";
import AddWalletAddress from "../../../../components/basic/AddWalletAddress/AddWalletAddress";
import {
  getAllComments,
  getPublicComments,
  getUnhiddenComment,
  setHideComment,
} from "../../../../utils/api/comments";
import PinnedIcon from "../../../../assets/icons/dashboard/icon-pinned.svg";
import { useLocation } from "react-router-dom";
import PinnedComments from "../../MyPage/components/PinnedComments";
import CommentFriend from "../../MyPage/components/CommentFriend";
import HideIcon from "../../../../assets/icons/dashboard/icon-hide.svg";
import ShowIcon from "../../../../assets/icons/dashboard/icon-show.svg";

const SettingHeader = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${palette.white};
  margin-top: 70px;
`;

const FullContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  // min-height: 300px;
  margin-top: 30px;
  margin-bottom: 0px;
  border-radius: 40px;
  background-color: ${palette.white};
`;

const TitleBox = styled.div`
  width: 90%;
  max-width: 1080px;
  margin-top: 98px;
  ${Typography.Heading1}
`;

const ContentHeader = styled.div`
  display: flex;
  width: 90%;
  max-width: 1080px;
  justify-content: space-between;
  margin: 40px auto;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const ContentHeaderText = styled.div`
  ${Typography.Heading2}
  margin: auto 0px;
`;

const BoxContainer = styled.div`
  width: 90%;
  max-width: 1080px;
  // min-height: 288px;
  margin: 0px auto;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const CommentBox = styled.div`
  width: 100%;
  height: 72px;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  background-color: ${palette.background};
  border-radius: 16px;
  margin-bottom: 20px;
  align-items: center;
`;

const ContentsBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const RightSideBox = styled.div`
  display: flex;
`;

const CommentCardHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 4px;
  height: 24px;
  width: 300px;
  align-items: center;
  margin-left: 30px;
`;

const CommentProfileImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 4px;
  border: 1px solid ${palette.light_gray};
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
`;

const CommentCustomName = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
  color: ${palette.gray};
`;

const CreatedDate = styled.div`
  width: 160px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  color: ${palette.gray};
  display: flex;
  align-items: center;
`;

const CommentText = styled.div`
  width: 100%;
  height: 55px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 90%;
  max-width: 1080px;
  display: flex;
  justify-content: right;
  margin: 0px auto;
`;

const EditPinnedCommentButton = styled.button`
  width: 219px;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.white};
  border: 2px solid ${palette.black};
  color: ${palette.black};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  // margin-top: 20px;
  margin-bottom: 30px;
`;

const PinnedMark = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: -30px;
  margin-left: -10px;
  text-align: left;
`;

const HideShowMark = styled.button`
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: hidden;
  margin-right: 30px;
`;

const CommentButton = styled.button`
  width: auto;
  height: 36px;
  border-radius: 18px;
  background-color: ${palette.black};
  border: 2px solid ${palette.black};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  // margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 24px;
  padding-right: 24px;
`;

function pad(n) {
  return n < 10 ? "0" + n : n;
}

const CommentManager = () => {
  const [walletList, setWalletList] = useState(
    JSON.parse(localStorage.getItem("walletList"))
  );
  const [trashClickTic, setTrashClickTic] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [commentList, setCommentList] = useState({}); // for all comment
  const [isUserPage, setIsUserPage] = useState(false); // false : my page
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [commentData, setCommentData] = useState({}); // for pinned comment
  // const [hideComment, setHideComment] = useState([]);
  const [hideButtonClick, setHideButtonClick] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname.slice(1).split("/")[0]);
    console.log("@" + localStorage.getItem("userID"));
    if (
      location.pathname.slice(1).split("/")[0] !=
      "@" + localStorage.getItem("userID")
    ) {
      setIsUserPage(true);
    }
  }, []);

  useEffect(() => {
    // console.log(walletList);
    localStorage.setItem("walletList", JSON.stringify(walletList));
  }, [trashClickTic, modalVisible]);

  useEffect(() => {
    (async () => {
      if (
        location.pathname.slice(1).split("/")[0] ==
        "@" + localStorage.getItem("userID")
      ) {
        const getAllCommentsResult = await getAllComments(
          location.pathname.slice(1).split("/")[0].substring(1)
        ).then((data) => {
          setCommentList(data);
          console.log(data?.pinnedCommentList);
          // var tmpHideArr = []
          // data?.commentsList?.map((val, idx) => {
          //   if(val.hide){
          //     tmpHideArr.push(val.commentIdx)
          //   }
          // })
          // setHideComment(tmpHideArr)
        });
      } else {
        const getPublicCommentsResult = await getPublicComments(
          location.pathname.slice(1).split("/")[0].substring(1)
        ).then((data) => {
          setCommentList(data);
          console.log(data?.pinnedCommentList);
        });
      }

      const getUnhiddenCommentResult = await getUnhiddenComment(
        location.pathname.slice(1).split("/")[0].substring(1)
      ).then((data) => {
        console.log(data);
        setCommentData(data);
      });
    })();
  }, [hideButtonClick]);

  const closeModal = () => {
    setModalVisible(false);
    window.location.reload();
  };

  const pinnedOnClick = () => {
    if (commentList) {
      setModalVisible(true);
    }
  };

  const commentCloseModal = () => {
    setCommentModalVisible(false);
  };

  const commentOnClick = () => {
    if (!localStorage.getItem("token")) {
      alert("Your session has ended. Please login again.");
      window.location.href = "/";
      localStorage.clear();
    } else {
      setCommentModalVisible(true);
    }
  };

  const hideMarkOnClick = async (idx) => {
    const setHideCommentResult = await setHideComment(
      localStorage.getItem("userID"),
      idx,
      true
    ).then((data) => {
      setHideButtonClick(!hideButtonClick);
    });
  };

  const showMarkOnClick = async (idx) => {
    const setHideCommentResult = await setHideComment(
      localStorage.getItem("userID"),
      idx,
      false
    ).then((data) => {
      setHideButtonClick(!hideButtonClick);
    });
  };

  return (
    <React.Fragment>
      {modalVisible ? (
        <PinnedComments
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
          comments={commentData}
        />
      ) : (
        <React.Fragment>
          {commentModalVisible ? (
            <CommentFriend
              visible={commentModalVisible}
              closable={true}
              maskClosable={true}
              onClose={commentCloseModal}
              userImg={commentList?.userImg} //내 프로필 이미지. 일단 임의로.
              userId={commentList?.userID} //내 userId
              userName={commentList?.userFriendNickname} // 이 사람이 나한테 지정한 customName.(api로 불러와)
              friendId={commentList?.friendID}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      )}
      <SettingHeader>
        <TitleBox>All Comments</TitleBox>
      </SettingHeader>
      <FullContainer>
        <ContentHeader>
          <ContentHeaderText>Pinned Comments</ContentHeaderText>
        </ContentHeader>
        <BoxContainer>
          {commentList?.pinnedCommentList?.map((val, idx) => (
            <CommentBox>
              <ContentsBox>
                {val.pinned ? <PinnedMark src={PinnedIcon} /> : <></>}
                <CommentCardHeader>
                  <CommentProfileImage src={val.profileImg} />
                  <CommentUserId>{val.commentFrom}</CommentUserId>
                  <CommentCustomName>{val.friendNickname}</CommentCustomName>
                </CommentCardHeader>
              </ContentsBox>
              <CommentText>{val.commentMessage}</CommentText>
              <RightSideBox>
                <CreatedDate>
                  {pad(new Date(val.createdAt).getFullYear().toString())}.
                  {pad(new Date(val.createdAt).getUTCMonth() + 1)}.
                  {pad(new Date(val.createdAt).getUTCDate())}{" "}
                  {pad(new Date(val.createdAt).getUTCHours())}:
                  {pad(new Date(val.createdAt).getUTCMinutes())}:
                  {pad(new Date(val.createdAt).getUTCSeconds())}
                </CreatedDate>
              </RightSideBox>
            </CommentBox>
          ))}
        </BoxContainer>
        <ButtonContainer>
          {isUserPage ? (
            <CommentButton onClick={commentOnClick}>
              comments for {location.pathname.slice(1).split("/")[0]}
            </CommentButton>
          ) : (
            <EditPinnedCommentButton onClick={pinnedOnClick}>
              Edit Pinned Comments
            </EditPinnedCommentButton>
          )}
        </ButtonContainer>
      </FullContainer>

      <FullContainer style={{marginBottom:"50px"}}>
        <ContentHeader>
          <ContentHeaderText>All Comments</ContentHeaderText>
        </ContentHeader>
        <BoxContainer>
          {commentList?.commentsList?.map((val, idx) => (
            <CommentBox>
              <ContentsBox>
                <CommentCardHeader>
                  <CommentProfileImage src={val.profileImg} />
                  <CommentUserId>{val.commentFrom}</CommentUserId>
                  <CommentCustomName>{val.friendNickname}</CommentCustomName>
                </CommentCardHeader>
              </ContentsBox>
              <CommentText>{val.commentMessage}</CommentText>
              <RightSideBox>
                <CreatedDate>
                  {pad(new Date(val.createdAt).getFullYear().toString())}.
                  {pad(new Date(val.createdAt).getUTCMonth() + 1)}.
                  {pad(new Date(val.createdAt).getUTCDate())}{" "}
                  {pad(new Date(val.createdAt).getUTCHours())}:
                  {pad(new Date(val.createdAt).getUTCMinutes())}:
                  {pad(new Date(val.createdAt).getUTCSeconds())}
                </CreatedDate>
                {isUserPage ? (
                  <></>
                ) : (
                  <>
                    {val.hide ? (
                      <HideShowMark
                        onClick={() => showMarkOnClick(val.commentIdx)}
                        style={{ backgroundImage: `url(${ShowIcon})` }}
                      />
                    ) : (
                      <HideShowMark
                        onClick={() => hideMarkOnClick(val.commentIdx)}
                        style={{ backgroundImage: `url(${HideIcon})` }}
                      />
                    )}
                  </>
                )}
              </RightSideBox>
            </CommentBox>
          ))}
        </BoxContainer>
      </FullContainer>
    </React.Fragment>
  );
};

export default CommentManager;
