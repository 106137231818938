import React, { useState, useRef, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import { BigNumber } from "@uniswap/sdk";
import styled, { keyframes, css } from "styled-components";
import escapeStringRegex from "escape-string-regexp";
import { darken } from "polished";
import Tooltip from "@reach/tooltip";
import "@reach/tooltip/styles.css";
import { isMobile } from "react-device-detect";

import DropIcon from "../../../../../assets/icons/dashboard/icon-caretDown.svg";
import { COLORS as palette } from "../../../../../utils/styles/colors";
// import tokenListJson from "./tokenList.json";
import tokenListJson from "./tokenListMini.json";
import { getAllTokenList } from "../axios/tokens";
import { useWeb3React, useTokenContract } from "../../hooks";
import {
  isAddress,
  calculateGasMargin,
  formatToUsd,
  formatTokenBalance,
  formatEthBalance,
} from "../../utils";
import { ReactComponent as DropDown } from "../../assets/images/dropdown.svg";
import Modal from "../Modal";
import TokenLogo from "../TokenLogo";
import SearchIcon from "../../assets/images/magnifying-glass.svg";
import {
  useTransactionAdder,
  usePendingApproval,
} from "../../contexts/Transactions";
import {
  useTokenDetails,
  useAllTokenDetails,
  INITIAL_TOKENS_CONTEXT,
} from "../../contexts/Tokens";
import { useAddressBalance } from "../../contexts/Balances";
import { ReactComponent as Close } from "../../assets/images/x.svg";
import { transparentize } from "polished";
import Circle from "../../assets/images/circle-grey.svg";
import { useETHPriceInUSD, useAllBalances } from "../../contexts/Balances";

const GAS_MARGIN = ethers.utils.bigNumberify(1000);

const white = "#FFFFFF";
const black = "#000000";

const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280,
};

const mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    accumulator[size] = (...args) => css`
      @media (max-width: ${MEDIA_WIDTHS[size]}px) {
        ${css(...args)}
      }
    `;
    return accumulator;
  },
  {}
);

const darkMode = false;

const Theme = {
  white,
  black,
  textColor: darkMode ? white : "#010101",
  greyText: darkMode ? white : "#6C7284",

  // for setting css on <html>
  backgroundColor: darkMode ? "#333639" : white,

  modalBackground: darkMode ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.5)",
  inputBackground: darkMode ? "#202124" : white,
  placeholderGray: darkMode ? "#5F5F5F" : "#E1E1E1",
  shadowColor: darkMode ? "#000" : "#2F80ED",

  // grays
  concreteGray: darkMode ? "#292C2F" : "#FAFAFA",
  mercuryGray: darkMode ? "#333333" : "#E1E1E1",
  silverGray: darkMode ? "#737373" : "#C4C4C4",
  chaliceGray: darkMode ? "#7B7B7B" : "#AEAEAE",
  doveGray: darkMode ? "#C4C4C4" : "#737373",
  mineshaftGray: darkMode ? "#E1E1E1" : "#2B2B2B",
  activeGray: darkMode ? "#292C2F" : "#F7F8FA",
  buttonOutlineGrey: darkMode ? "#FAFAFA" : "#F2F2F2",
  tokenRowHover: darkMode ? "#404040" : "#F2F2F2",

  //blacks
  charcoalBlack: darkMode ? "#F2F2F2" : "#404040",
  // blues
  zumthorBlue: darkMode ? "#212529" : "#EBF4FF",
  malibuBlue: darkMode ? "#E67AEF" : "#5CA2FF",
  royalBlue: darkMode ? "#DC6BE5" : "#2F80ED",
  loadingBlue: darkMode ? "#e4f0ff" : "#e4f0ff",

  // purples
  wisteriaPurple: "#DC6BE5",
  // reds
  salmonRed: "#FF6871",
  // orange
  pizazzOrange: "#FF8F05",
  // yellows
  warningYellow: "#FFE270",
  // pink
  uniswapPink: "#DC6BE5",
  //green
  connectedGreen: "#27AE60",

  //branded
  metaMaskOrange: "#E8831D",

  //specific
  // textHover: darkMode ? Theme.uniswapPink : Theme.doveGray,

  // connect button when loggedout
  buttonFaded: darkMode ? "#DC6BE5" : "#737373",

  // media queries
  mediaWidth: mediaWidthTemplates,
  // css snippets
  flexColumnNoWrap: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flexRowNoWrap: css`
    display: flex;
    flex-flow: row nowrap;
  `,
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.img`
  animation: 2s ${rotate} linear infinite;
  width: 16px;
  height: 16px;
`;

const BorderlessInput = styled.input`
  color: ${Theme.textColor};
  font-size: 1rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  background-color: ${Theme.inputBackground};

  [type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${Theme.chaliceGray};
  }
`;

const SubCurrencySelect = styled.button`
  ${Theme.flexRowNoWrap}
  padding: 4px 50px 4px 15px;
  margin-right: -40px;
  line-height: 0;
  height: 2rem;
  align-items: center;
  border-radius: 2.5rem;
  outline: none;
  cursor: pointer;
  user-select: none;
  background: ${Theme.zumthorBlue};
  border: 1px solid ${Theme.royalBlue};
  color: ${Theme.royalBlue};
`;

const InputRow = styled.div`
  ${Theme.flexRowNoWrap}
  // align-items: center;
  // padding: 0.25rem 0.85rem 0.75rem;
  width: 58px;
  height: 100%;
  border-radius: 10px;
  background-color: ${palette.white};
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 10px #c4c4c440;
  padding: 8px 16px;
  margin: 0px;
  border: hidden;
`;

const Input = styled(BorderlessInput)`
  font-size: 1.5rem;
  color: ${({ error }) => error && Theme.salmonRed};
  background-color: ${Theme.inputBackground};
  -moz-appearance: textfield;
`;

const StyledBorderlessInput = styled(BorderlessInput)`
  min-height: 2.5rem;
  flex-shrink: 0;
  text-align: left;
  padding-left: 1.6rem;
  background-color: ${Theme.concreteGray};
`;

const CurrencySelect = styled.button`
  align-items: center;
  font-size: 1rem;
  color: ${({ selected }) => (selected ? Theme.textColor : Theme.royalBlue)};
  height: 2rem;
  border: 1px solid
    ${({ selected }) => (selected ? Theme.mercuryGray : Theme.royalBlue)};
  border-radius: 2.5rem;
  background-color: ${({ selected }) =>
    selected ? Theme.concreteGray : Theme.zumthorBlue};
  outline: none;
  cursor: pointer;
  user-select: none;

  border-radius: 10px;
  background-color: ${palette.white};
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 10px #c4c4c440;
  padding: 8px 16px;
  margin: 0px;
  border: hidden;
  height: 100%;

  :hover {
    border: 1px solid
      ${({ selected }) =>
        selected
          ? darken(0.1, Theme.mercuryGray)
          : darken(0.1, Theme.royalBlue)};
  }

  :focus {
    border: 1px solid ${darken(0.1, Theme.royalBlue)};
  }

  :active {
    background-color: ${Theme.zumthorBlue};
  }
`;

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDropDown = styled(DropDown)`
  margin: 0 0.5rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected }) => (selected ? Theme.textColor : Theme.royalBlue)};
  }
`;

const InputPanel = styled.div`
  ${Theme.flexColumnNoWrap}
  box-shadow: 0 4px 8px 0 ${transparentize(0.95, Theme.shadowColor)};
  position: relative;
  border-radius: 1.25rem;
  background-color: ${Theme.inputBackground};
  z-index: 1;
`;

const Container = styled.div`
  border-radius: 1.25rem;
  border: 1px solid
    ${({ error }) => (error ? Theme.salmonRed : Theme.mercuryGray)};

  background-color: ${Theme.inputBackground};
  :focus-within {
    border: 1px solid ${Theme.malibuBlue};
  }
`;

const LabelRow = styled.div`
  ${Theme.flexRowNoWrap}
  align-items: center;
  color: ${Theme.doveGray};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem;
  span:hover {
    cursor: pointer;
    color: ${darken(0.2, Theme.doveGray)};
  }
`;

const LabelContainer = styled.div`
  flex: 1 1 auto;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ErrorSpan = styled.span`
  color: ${({ error }) => error && Theme.salmonRed};
  :hover {
    cursor: pointer;
    color: ${({ error }) => error && darken(0.1, Theme.salmonRed)};
  }
`;

const TokenModal = styled.div`
  ${Theme.flexColumnNoWrap}
  width: 100%;
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 1rem;
  height: 60px;
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${Theme.textColor};
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const SearchContainer = styled.div`
  ${Theme.flexRowNoWrap}
  justify-content: flex-start;
  padding: 0.5rem 1.5rem;
  background-color: ${Theme.concreteGray};
`;

const TokenModalInfo = styled.div`
  ${Theme.flexRowNoWrap}
  align-items: center;
  padding: 1rem 1.5rem;
  margin: 0.25rem 0.5rem;
  justify-content: center;
  user-select: none;
`;

const TokenList = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const TokenModalRow = styled.div`
  ${Theme.flexRowNoWrap}
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  user-select: none;

  #symbol {
    color: ${Theme.doveGrey};
  }

  :hover {
    background-color: ${Theme.tokenRowHover};
  }

  ${Theme.mediaWidth.upToMedium`
    padding: 0.8rem 1rem;
    padding-right: 2rem;
  `}
`;

const TokenRowLeft = styled.div`
  ${Theme.flexRowNoWrap}
  align-items : center;
`;

const TokenSymbolGroup = styled.div`
  ${Theme.flexColumnNoWrap};
  margin-left: 1rem;
`;

const TokenFullName = styled.div`
  color: ${Theme.chaliceGray};
`;

const FadedSpan = styled.span`
  color: ${Theme.royalBlue};
`;

const TokenRowBalance = styled.div`
  font-size: 1rem;
  line-height: 20px;
`;

const TokenRowUsd = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Theme.chaliceGray};
`;

const TokenRowRight = styled.div`
  ${Theme.flexColumnNoWrap};
  align-items: flex-end;
`;

const StyledTokenName = styled.span`
  margin: 0 0.25rem 0 0.25rem;
  max-width: 200px;
  font-family: Roboto Mono;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: ${palette.light_black};
`;

const SpinnerWrapper = styled(Spinner)`
  margin: 0 0.25rem 0 0.25rem;
  color: ${Theme.chaliceGray};
  opacity: 0.6;
`;

const SideBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin: auto 0px;
`;

const DropIconBox = styled.img`
  width: 24px;
  height: 24px;
`;

export default function CurrencyInputPanel({
  onValueChange = () => {},
  allBalances,
  renderInput,
  onCurrencySelected = () => {},
  title,
  description,
  extraText,
  extraTextClickHander = () => {},
  errorMessage,
  disableUnlock,
  disableTokenSelect,
  selectedTokenAddress = "",
  showUnlock,
  value,
  urlAddedTokens,
}) {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const tokenContract = useTokenContract(selectedTokenAddress);
  const { exchangeAddress: selectedTokenExchangeAddress } =
    useTokenDetails(selectedTokenAddress);

  const pendingApproval = usePendingApproval(selectedTokenAddress);

  const addTransaction = useTransactionAdder();

  // const allTokens = useAllTokenDetails()
  const allTokens = tokenListJson.tokens;
  // const allTokens = [];

  const { account } = useWeb3React();

  const userTokenBalance = useAddressBalance(account, selectedTokenAddress);

  // useEffect(() => {
  //   console.log(allTokens);
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     const allTokensResult = await getAllTokenList().then((data) => {
  //       setAllTokens(data)
  //     })
  //   })()
  // }, []);

  function renderUnlockButton() {
    if (
      disableUnlock ||
      !showUnlock ||
      selectedTokenAddress === "ETH" ||
      !selectedTokenAddress
    ) {
      return null;
    } else {
      if (!pendingApproval) {
        return (
          <SubCurrencySelect
            onClick={async () => {
              let estimatedGas;
              let useUserBalance = false;
              estimatedGas = await tokenContract.estimate
                .approve(
                  selectedTokenExchangeAddress,
                  ethers.constants.MaxUint256
                )
                .catch((e) => {
                  console.log("Error setting max token approval.");
                });
              if (!estimatedGas) {
                // general fallback for tokens who restrict approval amounts
                estimatedGas = await tokenContract.estimate.approve(
                  selectedTokenExchangeAddress,
                  userTokenBalance
                );
                useUserBalance = true;
              }
              tokenContract
                .approve(
                  selectedTokenExchangeAddress,
                  useUserBalance
                    ? userTokenBalance
                    : ethers.constants.MaxUint256,
                  {
                    gasLimit: calculateGasMargin(estimatedGas, GAS_MARGIN),
                  }
                )
                .then((response) => {
                  addTransaction(response, { approval: selectedTokenAddress });
                });
            }}
          >
            {t("unlock")}
          </SubCurrencySelect>
        );
      } else {
        return <SubCurrencySelect>{t("pending")}</SubCurrencySelect>;
      }
    }
  }

  function _renderInput() {
    if (typeof renderInput === "function") {
      return renderInput();
    }

    return (
      // <InputRow>
      <>
        <Input
          type="number"
          min="0"
          step="0.000000000000000001"
          error={!!errorMessage}
          placeholder="0.0"
          onChange={(e) => onValueChange(e.target.value)}
          onKeyPress={(e) => {
            const charCode = e.which ? e.which : e.keyCode;

            // Prevent 'minus' character
            if (charCode === 45) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          value={value}
        />
        {renderUnlockButton()}
        <CurrencySelect
          selected={!!selectedTokenAddress}
          onClick={() => {
            if (!disableTokenSelect) {
              setModalIsOpen(true);
            }
          }}
        >
          <Aligner>
            {selectedTokenAddress ? (
              <TokenLogo address={allTokens[selectedTokenAddress].logoURI} />
            ) : null}
            {
              <StyledTokenName>
                {(allTokens[selectedTokenAddress] &&
                  allTokens[selectedTokenAddress].symbol) ||
                  t("selectToken")}
              </StyledTokenName>
            }
            {!disableTokenSelect && (
              <SideBox>
                <DropIconBox selected={!!selectedTokenAddress} src={DropIcon} />
              </SideBox>
              // <StyledDropDown selected={!!selectedTokenAddress} />
            )}
          </Aligner>
        </CurrencySelect>
      </>
      // </InputRow>
    );
  }

  return (
    // <InputPanel>
    <>
      {/* <Container error={!!errorMessage}> */}
      {/* <LabelRow> */}
      {/* <LabelContainer>
            <span>{title}</span> <span>{description}</span>
          </LabelContainer> */}
      {/* <ErrorSpan
            data-tip={"Enter max"}
            error={!!errorMessage}
            onClick={() => {
              extraTextClickHander();
            }}
          >
            <Tooltip
              label="Enter Max"
              style={{
                background: "hsla(0, 0%, 0%, 0.75)",
                color: "white",
                border: "none",
                borderRadius: "24px",
                padding: "0.5em 1em",
                marginTop: "-64px",
              }}
            >
              <span>{extraText}</span>
            </Tooltip>
          </ErrorSpan> */}
      {/* </LabelRow> */}
      {_renderInput()}
      {/* </Container> */}
      {!disableTokenSelect && (
        <CurrencySelectModal
          isOpen={modalIsOpen}
          onDismiss={() => {
            setModalIsOpen(false);
          }}
          urlAddedTokens={urlAddedTokens}
          onTokenSelect={onCurrencySelected}
          allBalances={allBalances}
        />
      )}
    </>
    // </InputPanel>
  );
}

function CurrencySelectModal({
  isOpen,
  onDismiss,
  onTokenSelect,
  urlAddedTokens,
}) {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const { exchangeAddress } = useTokenDetails(searchQuery);

  // const allTokens = useAllTokenDetails();
  // const allTokens = [];
  const allTokens = tokenListJson.tokens;

  const { account, chainId } = useWeb3React();

  // BigNumber.js instance
  const ethPrice = useETHPriceInUSD();

  // all balances for both account and exchanges
  // let allBalances = useAllBalances();
  let allBalances = [];

  const _usdAmounts = Object.keys(allTokens).map((k) => {
    if (
      ethPrice &&
      allBalances[account] &&
      allBalances[account][k] &&
      allBalances[account][k].value
    ) {
      let ethRate = 1; // default for ETH
      let exchangeDetails = allBalances[allTokens[k].exchangeAddress];
      if (
        exchangeDetails &&
        exchangeDetails[k] &&
        exchangeDetails[k].value &&
        exchangeDetails["ETH"] &&
        exchangeDetails["ETH"].value
      ) {
        const tokenBalance = new BigNumber(exchangeDetails[k].value.toString());
        const ethBalance = new BigNumber(
          exchangeDetails["ETH"].value.toString()
        );
        ethRate = ethBalance.div(tokenBalance);
      }
      const USDRate = ethPrice
        .times(ethRate)
        .times(
          new BigNumber(10)
            .pow(allTokens[k].decimals)
            .div(new BigNumber(10).pow(18))
        );
      const balanceBigNumber = new BigNumber(
        allBalances[account][k].value.toString()
      );
      const usdBalance = balanceBigNumber
        .times(USDRate)
        .div(new BigNumber(10).pow(allTokens[k].decimals));
      return usdBalance;
    } else {
      return null;
    }
  });
  const usdAmounts =
    _usdAmounts &&
    Object.keys(allTokens).reduce(
      (accumulator, currentValue, i) =>
        Object.assign({ [currentValue]: _usdAmounts[i] }, accumulator),
      {}
    );

  const tokenList = useMemo(() => {
    return Object.keys(allTokens)
      .sort((a, b) => {
        if (allTokens[a].symbol && allTokens[b].symbol) {
          const aSymbol = allTokens[a].symbol.toLowerCase();
          const bSymbol = allTokens[b].symbol.toLowerCase();

          // pin ETH to top
          if (
            aSymbol === "ETH".toLowerCase() ||
            bSymbol === "ETH".toLowerCase()
          ) {
            return aSymbol === bSymbol
              ? 0
              : aSymbol === "ETH".toLowerCase()
              ? -1
              : 1;
          }

          // then tokens with balance
          if (usdAmounts[a] && !usdAmounts[b]) {
            return -1;
          } else if (usdAmounts[b] && !usdAmounts[a]) {
            return 1;
          }

          // sort by balance
          if (usdAmounts[a] && usdAmounts[b]) {
            const aUSD = usdAmounts[a];
            const bUSD = usdAmounts[b];

            return aUSD.gt(bUSD) ? -1 : aUSD.lt(bUSD) ? 1 : 0;
          }

          // sort alphabetically
          return aSymbol < bSymbol ? -1 : aSymbol > bSymbol ? 1 : 0;
        } else {
          return 0;
        }
      })
      .map((k) => {
        let balance;
        let usdBalance;
        // only update if we have data
        if (
          k === "ETH" &&
          allBalances[account] &&
          allBalances[account][k] &&
          allBalances[account][k].value
        ) {
          balance = formatEthBalance(allBalances[account][k].value);
          usdBalance = usdAmounts[k];
        } else if (
          allBalances[account] &&
          allBalances[account][k] &&
          allBalances[account][k].value
        ) {
          balance = formatTokenBalance(
            allBalances[account][k].value,
            allTokens[k].decimals
          );
          usdBalance = usdAmounts[k];
        }
        return {
          name: allTokens[k].name,
          symbol: allTokens[k].symbol,
          logoURI: allTokens[k].logoURI,
          address: k,
          balance: balance,
          usdBalance: usdBalance,
        };
      });
  }, [allBalances, allTokens, usdAmounts, account]);

  const filteredTokenList = useMemo(() => {
    const list = tokenList.filter((tokenEntry) => {
      const inputIsAddress = searchQuery.slice(0, 2) === "0x";

      // check the regex for each field
      const regexMatches = Object.keys(tokenEntry).map((tokenEntryKey) => {
        // if address field only search if input starts with 0x
        if (tokenEntryKey === "address") {
          return (
            inputIsAddress &&
            typeof tokenEntry[tokenEntryKey] === "string" &&
            !!tokenEntry[tokenEntryKey].match(
              new RegExp(escapeStringRegex(searchQuery), "i")
            )
          );
        }
        return (
          typeof tokenEntry[tokenEntryKey] === "string" &&
          !!tokenEntry[tokenEntryKey].match(
            new RegExp(escapeStringRegex(searchQuery), "i")
          )
        );
      });
      return regexMatches.some((m) => m);
    });
    // If the user has not inputted anything, preserve previous sort
    if (searchQuery === "") return list;
    return list.sort((a, b) => {
      return a.symbol.toLowerCase() === searchQuery.toLowerCase() ? -1 : 1;
    });
  }, [tokenList, searchQuery]);

  function _onTokenSelect(address) {
    setSearchQuery("");
    onTokenSelect(address);
    onDismiss();
  }

  function renderTokenList() {
    // console.log(allTokens);
    if (isAddress(searchQuery) && exchangeAddress === undefined) {
      return <TokenModalInfo>Searching for Exchange...</TokenModalInfo>;
    }
    if (
      isAddress(searchQuery) &&
      exchangeAddress === ethers.constants.AddressZero
    ) {
      return (
        <>
          <TokenModalInfo>{t("noExchange")}</TokenModalInfo>
          <TokenModalInfo>
            <Link to={`/create-exchange/${searchQuery}`}>
              {t("createExchange")}
            </Link>
          </TokenModalInfo>
        </>
      );
    }
    if (!filteredTokenList.length) {
      return <TokenModalInfo>{t("noExchange")}</TokenModalInfo>;
    }

    // console.log(filteredTokenList);

    return filteredTokenList.map(
      ({ address, symbol, name, logoURI, balance, usdBalance }) => {
        const urlAdded =
          urlAddedTokens && urlAddedTokens.hasOwnProperty(address);
        const customAdded =
          address !== "ETH" &&
          INITIAL_TOKENS_CONTEXT[chainId] &&
          !INITIAL_TOKENS_CONTEXT[chainId].hasOwnProperty(address) &&
          !urlAdded;

        return (
          <TokenModalRow key={address} onClick={() => _onTokenSelect(address)}>
            <TokenRowLeft>
              <TokenLogo address={logoURI} size={"2rem"} />
              <TokenSymbolGroup>
                <div>
                  <span id="symbol">{symbol}</span>
                  <FadedSpan>
                    {urlAdded && "(Added by URL)"}{" "}
                    {customAdded && "(Added by user)"}
                  </FadedSpan>
                </div>
                <TokenFullName> {name}</TokenFullName>
              </TokenSymbolGroup>
            </TokenRowLeft>
            <TokenRowRight>
              {balance ? (
                <TokenRowBalance>
                  {balance && (balance > 0 || balance === "<0.0001")
                    ? balance
                    : "-"}
                </TokenRowBalance>
              ) : account ? (
                <SpinnerWrapper src={Circle} alt="loader" />
              ) : (
                "-"
              )}
              <TokenRowUsd>
                {usdBalance && !usdBalance.isNaN()
                  ? usdBalance.isZero()
                    ? ""
                    : usdBalance.lt(0.01)
                    ? "<$0.01"
                    : "$" + formatToUsd(usdBalance)
                  : ""}
              </TokenRowUsd>
            </TokenRowRight>
          </TokenModalRow>
        );
      }
    );
  }

  // manage focus on modal show
  const inputRef = useRef();

  function onInput(event) {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
  }

  function clearInputAndDismiss() {
    setSearchQuery("");
    onDismiss();
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={clearInputAndDismiss}
      minHeight={60}
      maxHeight={50}
      initialFocusRef={isMobile ? undefined : inputRef}
    >
      <TokenModal>
        <ModalHeader>
          <p>Select Token</p>
          <CloseIcon onClick={clearInputAndDismiss}>
            <CloseColor alt={"close icon"} />
          </CloseIcon>
        </ModalHeader>
        <SearchContainer>
          <img src={SearchIcon} alt="search" />
          <StyledBorderlessInput
            ref={inputRef}
            type="text"
            placeholder={
              isMobile ? t("searchOrPasteMobile") : t("searchOrPaste")
            }
            onChange={onInput}
          />
        </SearchContainer>
        <TokenList>{renderTokenList()}</TokenList>
      </TokenModal>
    </Modal>
  );
}
