import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";

const Container = styled.div`
  width: 100%;
  min-height: 210px;
  border-radius: 16px;
  background-color: ${palette.white};
  padding: 10px;
`;

const CommentInfoBox = styled.div`
  height: 100%;
  align-self: center;
  margin-left: 12px;
`;

const CommentCustomName = styled.div`
  ${Typography.Heading2}
  margin-top: auto;
`;

const CommentUserId = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: auto;
  margin-bottom: 3px;
  color: ${palette.gray};
`;

const CommentProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
`;

const CommentCardHeader = styled.div`
  display: flex;
  justify-content: left;
  gap: 4px;
  height: auto;
`;

const MyRankBox = styled.div`
  width: 100%;
  min-height: 110px;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 10px;
  padding: 15px;
  margin: 0px auto;
  margin-top: 10px;
`;

const LoginButton = styled.button`
  width: 100%;
  height: 100px;
  border: hidden;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  border-radius: 16px;
`;

const MyRank = (props) => {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [userName, setUserName] = useState(
    userInfo?.nickname ? userInfo.nickname : ""
  );
  const [profileImg, setprofileImg] = useState(
    localStorage.getItem("profileImage")
  );

  const [myRecord, setMyRecord] = useState(props.myrecord);

  useEffect(() => {
    setMyRecord(props.myrecord);
    console.log(props.myrecord);
  }, [props.myrecord]);

  return (
    <Container>
      {userInfo ? (
        <React.Fragment>
          <CommentCardHeader>
            <CommentProfileImage src={profileImg} />
            <CommentInfoBox>
              <CommentCustomName>
                {userName ? userName : userInfo.id}
              </CommentCustomName>
              <CommentUserId>@{userInfo.id}</CommentUserId>
            </CommentInfoBox>
          </CommentCardHeader>
          <MyRankBox>
            Today's Rank <br /> <br />
            <li>Joined Time Ranking: {myRecord.createdAt}</li>
            <li>Daily Followers Ranking: {myRecord.todayFollows}</li>
            <li>Daily hits Ranking: {myRecord.todayHits}</li>
          </MyRankBox>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <LoginButton
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Login
          </LoginButton>
        </React.Fragment>
      )}
    </Container>
  );
};

export default MyRank;
