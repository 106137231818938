import axios from "axios";

export const addFollowing = async (reqTo, reqFrom) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/following/request?userID=${reqTo}`,
      `{"jwtToken":"${localStorage.getItem("token")}", "reqFrom":"${reqFrom}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      } else {
        returnValue = data.data;
      }
    })
    .catch(() => {});

  return returnValue;
};

export const deleteFollowing = async (reqTo, reqFrom) => {
  let returnValue = "";
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/following/delete?userID=${reqTo}`,
      `{"jwtToken":"${localStorage.getItem("token")}", "reqFrom":"${reqFrom}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data;
    })
    .catch(() => {});

  return returnValue;
};

export const followingList = async (userID) => {
  //userID가 팔로잉하고 있는 유저들 리스트
  let returnValue = [];
  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST + `/following/following?userID=${userID}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
    });

  return returnValue;
};

export const followerList = async (userID) => {
  //userID를 팔로우하고 있는 유저들 리스트
  let returnValue = [];
  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST + `/following/follower?userID=${userID}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
    });

  return returnValue;
};

export const isFollowing = async (myId, userId) => {
  //myId가 userId를 following 하고 있는지 확인
  let returnValue = "";
  const result = await axios
    .get(
      process.env.REACT_APP_DB_HOST +
        `/following?userID1=${userId}&userID2=${myId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
      //true면 팔로잉 중, false면 언팔 중
    });

  return returnValue;
};

// 친구 신청하기
export const addFriend = async (reqTo, reqFrom, reqNickname) => {
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST + `/friends/request?user=${reqTo}`,
      `{"jwtToken":"${localStorage.getItem(
        "token"
      )}", "reqFrom":"${reqFrom}", "reqNickname":"${reqNickname}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false) {
        alert("Your session has ended. Please log in again.");
        localStorage.clear();
        window.location.href = "/";
      }
      returnValue = data.data;
    })
    .catch(() => {});

  return returnValue;
};

export const acceptFriendReq = async (reqTo, reqFrom, reqNickname) => {
  console.log(reqFrom, reqTo, reqNickname);
  var requestOptions = {
    method: "PATCH",
    headers: {
      // "Access-Control-Allow-Private-Network": true,
      // "Access-Control-Request-Private-Network": true,
      "Content-Type": "application/json",
    },
    body: `{"jwtToken": "${localStorage.getItem(
      "token"
    )}","reqTo": "${reqTo}","reqFrom": "${reqFrom}","reqNickname": "${reqNickname}"}`,
    // redirect: "follow",
  };

  let returnValue = {};

  const result = await fetch(
    process.env.REACT_APP_DB_HOST + `/friends/request?accept=true`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      returnValue = JSON.parse(result);
      console.log(returnValue);
      if (returnValue.message != "요청에 성공하였습니다.") {
        if (returnValue.message == "토큰 값이 맞지 않습니다.") {
          alert("Your session has ended. Please log in again.");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert(returnValue.message);
        }
      } else {
        console.log("Accept the request successfully!");
      }
    })
    .catch((error) => console.log("error", error));

  return returnValue;
};

export const denyFriendReq = async (reqFrom, reqTo) => {
  var requestOptions = {
    method: "PATCH",
    headers: {
      // "Access-Control-Allow-Private-Network": true,
      // "Access-Control-Request-Private-Network": true,
      "Content-Type": "application/json",
    },
    body: `{"jwtToken": "${localStorage.getItem(
      "token"
    )}","reqTo": "${reqTo}","reqFrom": "${reqFrom}"}`,
    // redirect: "follow",
  };

  let returnValue = {};

  const result = await fetch(
    process.env.REACT_APP_DB_HOST + `/friends/request?accept=false`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      returnValue = JSON.parse(result);
      console.log(returnValue);
      if (returnValue.message != "요청에 성공하였습니다.") {
        if (returnValue.message == "토큰 값이 맞지 않습니다.") {
          alert("Your session has ended. Please log in again.");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert(returnValue.message);
        }
      } else {
        console.log("Deny the request successfully!");
      }
    })
    .catch((error) => console.log("error", error));

  return returnValue;
};

export const editFriendNickname = async (reqFrom, reqTo, newNickname) => {
  var requestOptions = {
    method: "PATCH",
    headers: {
      // "Access-Control-Allow-Private-Network": true,
      // "Access-Control-Request-Private-Network": true,
      "Content-Type": "application/json",
    },
    body: `{"jwtToken": "${localStorage.getItem(
      "token"
    )}","user": "${reqFrom}","friend": "${reqTo}","newNickname": "${newNickname}"}`,
    // redirect: "follow",
  };

  let returnValue = {};

  const result = await fetch(
    process.env.REACT_APP_DB_HOST + `/friends/nickname`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      returnValue = JSON.parse(result);
      console.log(returnValue);
      if (returnValue.message != "요청에 성공하였습니다.") {
        if (returnValue.message == "토큰 값이 맞지 않습니다.") {
          alert("Your session has ended. Please log in again.");
          localStorage.clear();
          window.location.href = "/";
        } else {
          alert(returnValue.message);
        }
      } else {
        console.log("Deny the request successfully!");
        returnValue = returnValue.result;
        console.log(returnValue);
      }
    })
    .catch((error) => console.log("error", error));

  return returnValue;
};

export const friendList = async (userID) => {
  //userID를 팔로우하고 있는 유저들 리스트
  let returnValue = [];
  const result = await axios
    .get(process.env.REACT_APP_DB_HOST + `/friends?userID=${userID}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => {
      console.log(data.data);
      returnValue = data.data.result; //following list
    });

  return returnValue;
};

// 친구 요청 현황
// none: 친구신청을 안한 상태
// wait: 친구신청을 했는데 아직 수락받지 않은 상황일때
// friend: 이미 친구인 상황
export const checkIsFriend = async (reqFrom, reqTo) => {
  // reqTo, reqFrom : userId 형태
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/friends/request/status?reqFrom=${reqFrom}&reqTo=${reqTo}`,
      `{"jwtToken":"${localStorage.getItem("token")}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.isSuccess == false || data.data.code != 200) {
        // alert("Your session has ended. Please log in again.");
        // localStorage.clear();
        // window.location.href = "/";
        returnValue = "logout"; //로그아웃 된 상태라서 확인 불가. 로그인 버튼 띄워주기
      } else {
        returnValue = data.data.result;
      }
    })
    .catch(() => {});

  return returnValue;
};


// 친구 신청 관련 더 자세한 정보 확인
export const getFriendReqDetail = async (userId, friendReqIdx) => {
  let returnValue = 0;
  const result = await axios
    .post(
      process.env.REACT_APP_DB_HOST +
        `/friends/request/detail?friendReqID=${friendReqIdx}`,
      `{"jwtToken":"${localStorage.getItem("token")}","userID":"${userId}"}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((data) => {
      console.log(data.data);
      if (data.data.result) {
        returnValue = data.data.result;
      }
    })
    .catch((error) => console.log("error", error));
};
