import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS as palette } from "../../../utils/styles/colors";
import Typography from "../../../utils/styles/typography";
import CheckIcon from "../../../assets/icons/dashboard/icon-bluecheck.svg";
import WalletList from "../../../datas/WalletListData";
import RightArrow from "../../../assets/icons/dashboard/icon-whiteRightArrow.png";

const Container = styled.button`
  display: flex;
  padding: 16px;
  background-color: ${palette.light_gray_alpha20};
  border-radius: 10px;
  width: 100%;
  max-width: 370px;
  height: 64px;
  max-height: 64px;
  border: 0;
  position: relative;
`;

const WalletFullInfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StateInfo = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.blue};
  display: flex;
  height: 22px;
`;

const WalletIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const WalletInfoBox = styled.div`
  display: flex;
  justify-content: left;
`;

const WalletName = styled.div`
  ${Typography.Heading3}
  margin: auto 0px;
  margin-left: 8px;
`;

const WalletAddress = styled.div`
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: auto 0px;
  color: ${palette.gray};
`;

const CheckImage = styled.img`
  width: 12px;
  height: 12px;
  margin: auto 0px;
  margin-left: 2px;
`;

const HoverContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${palette.blue}DD;
  border-radius: 10px;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: right;
  padding: 16px;
`;

const HoverText = styled.div`
  width: 100%;
  ${Typography.Heading4}
  text-align: right;
  color: ${palette.white};
  margin: auto 0px;
`;

const RightArrowBox = styled.img`
  width: 24px;
  height: 24px;
  margin: auto 0px;
  margin-left: 4px;
`;

function isMobileDevice() {
  return (
    ("ontouchstart" in window || "onmsgesturechange" in window) &&
    !window.ethereum
  );
}

const metamaskOnClick = (onConnected) => {
  // if (!window.ethereum) {
  //   alert("Get MetaMask!");
  //   return;
  // }
  return new Promise(async (resolve, reject) => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      console.log(accounts[0]);
      alert(accounts[0]);
      onConnected(accounts[0]);
    } catch (error) {
      if (isMobileDevice()) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          onConnected(accounts[0]);
        } catch (error) {
          alert(error.message);
          console.error(error.message);
        }
      } else {
        alert(error.message);
        console.error(error.message);
      }
    }
  });
  // const accounts = await window.ethereum.request({
  //   method: "eth_requestAccounts",
  // });
};

async function checkIfWalletIsConnected(onConnected) {
  if (window.ethereum) {
    console.log("nnn");
    // const accounts = await window.ethereum.request({
    //   method: "eth_accounts",
    // });

    // if (accounts.length > 0) {
    //   const account = accounts[0];
    //   onConnected(account);
    //   return;
    // }

    if (isMobileDevice()) {
      await metamaskOnClick(onConnected); // metamask
    }
  }
}

const ConnectedWalletButton = (props) => {
  const [walletAddress, setWalletAddress] = useState(props.walletAddress);
  const [currentConnectWalletAddress, setCurrentConncetWalletAddress] =
    useState("tmp");
  const [walletIdNum, setWalletIdNum] = useState(-1);
  const [mouseOn, setMouseOn] = useState(false);

  useEffect(() => {
    checkIfWalletIsConnected(setCurrentConncetWalletAddress);
  }, []);

  useEffect(() => {
    if (walletAddress.length > 15) {
      setWalletAddress(
        walletAddress.substr(0, 6) +
          "..." +
          walletAddress.substr(walletAddress.length - 6, walletAddress.length)
      );
    }
  }, []);

  useEffect(() => {
    // console.log(props);
    var tmpcurrentConnectWalletAddress = currentConnectWalletAddress;
    if (Array.isArray(tmpcurrentConnectWalletAddress)) {
      if (props.walletAddress.startsWith("cosmos")) {
        tmpcurrentConnectWalletAddress = currentConnectWalletAddress[0];
      } else if (props.walletAddress.startsWith("stars")) {
        tmpcurrentConnectWalletAddress = currentConnectWalletAddress[1];
      } else {
        tmpcurrentConnectWalletAddress = "";
      }
    }
    console.log(currentConnectWalletAddress);
    console.log(props.walletAddress);
    if (
      props.setConnectSuccess &&
      props.walletAddress.toUpperCase() ==
        tmpcurrentConnectWalletAddress.toUpperCase()
    ) {
      props.setConnectSuccess(true);
      console.log("success!");
      localStorage.setItem("currentAddress", tmpcurrentConnectWalletAddress);
      // alert("Login success! Welcome to Mepe!");
    } else if (
      tmpcurrentConnectWalletAddress != "tmp" &&
      props.walletAddress.toUpperCase() !=
        tmpcurrentConnectWalletAddress.toUpperCase()
    ) {
      alert("Dismatch Wallet Address! Try again with correct wallet account.");
    }
  }, [currentConnectWalletAddress]);

  const connectOnClick = async (walletID) => {
    setWalletIdNum(walletID);
    console.log(walletID);
    localStorage.setItem("currentWalletType", walletID);
    const connectWalletResult = await WalletList[walletID]
      .action(setCurrentConncetWalletAddress)
      .then((data) => {
        console.log("hello");
        console.log(data);
      })
      .catch((err) => console.log("???"));
  };

  return (
    <React.Fragment>
      {isMobileDevice() ? (
        <a href={props.deepLink}>
          <Container
          //  onClick={() => connectOnClick(props.walletId)}
          >
            <WalletFullInfoBox>
              <WalletInfoBox>
                <WalletIcon src={props.img} />
                <WalletName>{props.name}</WalletName>
              </WalletInfoBox>
              <WalletAddress>{walletAddress}</WalletAddress>
            </WalletFullInfoBox>
          </Container>
        </a>
      ) : (
        <Container
          onMouseEnter={() => setMouseOn(true)}
          onMouseLeave={() => setMouseOn(false)}
          onClick={() => connectOnClick(props.walletId)}
        >
          <WalletFullInfoBox>
            <WalletInfoBox>
              <WalletIcon src={props.img} />
              <WalletName>{props.name}</WalletName>
            </WalletInfoBox>
            <WalletAddress>{walletAddress}</WalletAddress>
          </WalletFullInfoBox>
          {mouseOn ? (
            <HoverContainer>
              <HoverText>Let’s Sign In!</HoverText>
              <RightArrowBox src={RightArrow} />
            </HoverContainer>
          ) : (
            <></>
          )}
        </Container>
      )}
    </React.Fragment>
  );
};

export default ConnectedWalletButton;
