const kaikasOnClick = (setFunction) => {
  return new Promise(async (resolve, reject) => {
    // var returnValue = 0;
    console.log("start");
    try {
      const wallet = await window.klaytn.enable();
      let version = await window.klaytn.networkVersion;
      console.log(version);

      console.log(wallet[0]);
      setFunction(wallet[0]);
    } catch (error) {
      //   alert("metamask 확장 프로그램을 설치해주세요.");
      alert(error.message);
      console.error(error.message);
    }
  });
};

export default kaikasOnClick;
