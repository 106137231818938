import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";
import { COLORS as palette } from "../../../../utils/styles/colors";
import Typography from "../../../../utils/styles/typography";
import WalletListData from "../../../../datas/WalletListData";
import Valid from "../../../../assets/icons/dashboard/icon-circleCheck.svg";
import { login } from "../../../../utils/api/auth";

//import CloseButton from './CloseButton'
// import ClosebtnIcon from "../images/common/close1.png"

const WalletIcon = styled.img`
  width: 32px;
  height: 32px;
  margin: auto 0px;
`;

const WalletName = styled.div`
  ${Typography.Heading3}
  height: auto;
  margin: auto 0px;
  margin-left: 8px;
  color: ${palette.light_black};
`;

const VerifiedBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 6.5px;
  height: 15px;
`;

const VerifiedText = styled.div`
  ${Typography.Body}
  color: ${palette.blue};
  margin: auto 0px;
  margin-right: 2px;
`;

const VerifiedIcon = styled.img`
  width: 14px;
  height: 14px;
  margin: auto 0px;
`;

const SubString = styled.div`
  ${Typography.Body}
  color: ${palette.gray};
  margin-top: 19px;
`;

const WalletAddress = styled.div`
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.gray};
  margin: auto 0px;
`;

const ContentHeaderButton = styled.button`
  width: 120px;
  height: 36px;
  border-radius: 20px;
  border: hidden;
  background-color: ${palette.marine};
  color: ${palette.white};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justified;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomButtonBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const InnerContent = styled.div`
  width: 90%;
  max-width: 370px;
  min-height: 485px;
  height: auto;
  padding-bottom: 82px;
  margin: 0px auto;
`;

const UserNameInputBox = styled.div`
  width: 100%;
  height: 87px;
  display: flex;
  justify-content: space-between;
  padding-right: 29px;
  padding-left: 22px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  background-color: ${palette.valid};
  margin: 0px auto;
  margin-bottom: 30px;
`;

const UserIDMark = styled.div`
  ${Typography.Body}
  color: ${palette.blue};
  margin-bottom: 16px;
`;

const UserNameLeftBox = styled.div`
  padding-top: 16px;
`;

const UserNameRightBox = styled.div`
  padding-top: 39px;
`;

const UserNameFinal = styled.div`
  width: auto;
  height: auto;
  ${Typography.Heading3}
  border: 0;
  background-color: transparent;
  margin: auto 0px;
  color: ${palette.black};
`;

const ModifiedCheck = styled.img`
  width: 24px;
  max-width: 24px;
  height: 24px;
  border: 0;
  margin: auto 0px;
  margin-left: 8px;
`;

const WalletBox = styled.div`
  width: 100%;
  height: 87px;
  padding: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  background-color: ${palette.background};
  margin: 0px auto;
  border: 1px solid ${palette.blue};
`;

const WalletInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
`;

const WalletInfoLeft = styled.div`
  display: flex;
`;

const WalletInfoRight = styled.div`
  display: flex;
`;

const SemiTitle = styled.div`
  ${Typography.Heading3}
  margin-bottom: 12px;
`;

function CreateAccountSuccessModal({
  className,
  onClose,
  maskClosable,
  visible,
  walletAddress,
  walletId,
  userName,
}) {
  console.log(walletAddress);
  console.log(walletId);
  console.log(userName);

  const handleCopyClipBoard = async (text) => {
    var textarea = document.createElement("textarea");
    textarea.value = text; // 복사할 메시지
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 9999); // For IOS
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("Copy address completed.");
  };

  const moveToScan = async (addr) => {
    window.location.href = "https://www.blockchain.com/eth/address/" + addr;
  };

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const closeOnClick = () => {
    onClose();
  };

  const startOnClick = async () => {
    const loginResult = await login(userName).then((data) => {
      console.log(data);
      // login success
      window.location.href = "/mypage";
    });

    // window.location.href = "/mypage"; // api not working test code
  };

  return (
    <React.Fragment>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <InnerContent>
            <PopupTitle>
              Successfully
              <br /> Create Account!
            </PopupTitle>
            <SemiTitle>Your User ID</SemiTitle>
            <UserNameInputBox style={{ borderColor: `${palette.blue}` }}>
              <UserNameLeftBox>
                <UserIDMark>User ID</UserIDMark>
                <UserNameFinal>{userName}</UserNameFinal>
              </UserNameLeftBox>
              <UserNameRightBox>
                <ModifiedCheck src={Valid} />
              </UserNameRightBox>
            </UserNameInputBox>
            <SemiTitle>Connected Wallet</SemiTitle>
            {walletId == 1 ? (
              <>
                {walletAddress.map((v, i) => (
                  <WalletBox style={i == 1 ? { marginTop: "10px" } : {}}>
                    <WalletInfoBox>
                      <WalletInfoLeft>
                        <WalletIcon
                          src={WalletListData[walletId]?.walletIcon}
                        />
                        <WalletName>
                          {WalletListData[walletId]?.walletName}
                        </WalletName>
                      </WalletInfoLeft>
                      <WalletInfoRight>
                        <WalletAddress>
                          {v.substr(0, 6) +
                            "..." +
                            v.substr(v.length - 6, v.length)}
                        </WalletAddress>
                      </WalletInfoRight>
                    </WalletInfoBox>
                    <VerifiedBox>
                      <VerifiedText>Verified</VerifiedText>
                      <VerifiedIcon src={Valid} />
                    </VerifiedBox>
                  </WalletBox>
                ))}
              </>
            ) : (
              <WalletBox>
                <WalletInfoBox>
                  <WalletInfoLeft>
                    <WalletIcon src={WalletListData[walletId]?.walletIcon} />
                    <WalletName>
                      {WalletListData[walletId]?.walletName}
                    </WalletName>
                  </WalletInfoLeft>
                  <WalletInfoRight>
                    <WalletAddress>
                      {walletAddress.substr(0, 6) +
                        "..." +
                        walletAddress.substr(
                          walletAddress.length - 6,
                          walletAddress.length
                        )}
                    </WalletAddress>
                  </WalletInfoRight>
                </WalletInfoBox>
                <VerifiedBox>
                  <VerifiedText>Verified</VerifiedText>
                  <VerifiedIcon src={Valid} />
                </VerifiedBox>
              </WalletBox>
            )}

            <SubString>
              Please check the wallet address connected with the user ID.
            </SubString>
            <BottomButtonBox>
              <ContentHeaderButton onClick={startOnClick}>
                Start MEPE
              </ContentHeaderButton>
            </BottomButtonBox>
            <CloseButton onClick={closeOnClick}>
              <RiCloseFill />
            </CloseButton>
          </InnerContent>
        </ModalInner>
      </ModalWrapper>
    </React.Fragment>
  );
}

CreateAccountSuccessModal.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  //   width: 100%;
  //   max-width: 500px;
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 1);
  border-radius: 15px;
  width: 90%;
  max-width: 520px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  // padding: 40px 20px;
  margin-top: 50px;
  color: ${palette.black};
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  min-height: 647px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
`;

const PopupTitle = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 auto;
  padding-top: 80px;
  color: ${palette.black};
  ${Typography.Heading1}
  margin-bottom: 30px;
`;

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 5;
`;

export default CreateAccountSuccessModal;
