import styled from "styled-components";
import {COLORS as c} from "../../utils/styles/colors";
import {ReactComponent as SearchImg} from "../../assets/icons/dashboard/icon-search.svg";
import SizeBox from "../util/SizeBox";

const InputBoard = styled.div`
  display: flex;
  align-items: center;
  
  border-radius: 8px;
  background-color: ${c.background};
`;

const InputIcon = styled.button`
  cursor: pointer;

  height: max-content;
  display: flex;

  padding: 0px;
  margin: 0px;

  background-color: transparent;
  border: none;
`;

const InputBody = styled.input`
  outline: none;
  border: none;
  width: 100%;
  height: 38px;
  
  padding: 0px 0px 0px 10px;
  
  background-color: transparent;

  &::-webkit-input-placeholder {
    color: ${c.gray};
    font-size: 14px;
    font-weight: 600;
  }

  &:focus {
    outline: none;
  }
`;

function BasicInput({ref, input, iconClick, onChange, iconOn = true, holder = 'Search', ...props}) {
    return (
        <InputBoard>
            <div className={'f-row a-center j-center'}>
                <SizeBox w={10}/>
                {iconOn ? <InputIcon>
                    <SearchImg style={{color: c.gray}} onClick={iconClick}/>
                </InputIcon> : null}
                <InputBody ref={ref} placeholder={holder} value={input} onChange={onChange}/>
            </div>
        </InputBoard>
    );
}

export default BasicInput;